//Styled Components
import {
    Wrapper,
    Modal
} from './elements'

//Components
import Header from "../../components/Header";
import LogoWhatsApp from "../../components/LogoWhatsApp";
import Footer from "../../components/Footer";

const TerminosYCondiciones = () => {
  return (
    <>
        <Header noLinks={true}/>
        <Wrapper>
            <Modal>
                <h1>Terminos y Condiciones</h1>
                <p>
                    Este contrato describe los términos y condiciones generales (los «Términos y Condiciones Generales») aplicables al acceso a la información y/o uso de los servicios y funcionalidades («los Servicios») ofrecidos por AUXILIAR-T S.A (en adelante Move On) dentro del sitio moveonbylibra.com.ar, el asegurado de Libra que desee acceder y/o usar el sitio o los servicios podrá hacerlo sujetándose a los Términos y Condiciones Generales, junto con todas las demás políticas y principios que rigen moveonbylibra.com.ar y que son incorporados al presente por referencia. En consecuencia, todas las visitas y cada uno de las consultas y solicitudes que se realicen en este sitio, como asimismo sus efectos jurídicos, quedarán regidos por estas reglas y sometidas a la legislación aplicable en la República Argentina.
                    Los Términos y Condiciones Generales contenidos en este instrumento se aplicarán y se entenderán como formando parte de cada uno de los actos que se ejecuten o celebren mediante los sistemas de comercialización comprendidos en este sitio web entre los usuarios de este sitio y Move On, CUALQUIER PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES GENERALES, LOS CUALES TIENEN UN CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DE UTILIZAR EL SITIO Y/O LOS SERVICIOS.
                    En forma previa a cada solicitud del servicio, el Usuario deberá leer, entender y aceptar todas las condiciones establecidas en los Términos y Condiciones Generales y en las Políticas de Move On S.A
                    Si utilizas este Sitio significa que has aceptado plenamente las condiciones establecidas en los Términos y Condiciones Generales y en las Políticas de Libra Compañía Argentina de Seguros S.A. Por ello, te obligas a cumplir expresamente con las mismas.
                </p>
                <p>
                    01) Capacidad
                    Los Servicios sólo están disponibles para personas que tengan capacidad legal para contratar. No podrán utilizar los servicios las personas que no tengan esa capacidad, los menores de edad o Usuarios de que hayan sido suspendidos temporalmente o inhabilitados definitivamente. Si estás solicitando un servicio como empresa, debes tener capacidad para contratar a nombre de tal entidad y de obligar a la misma en los términos de este Acuerdo.
                </p>
                <p>
                    02) Registración
                    Es obligatorio completar el formulario de identificación como asegurado  en todos sus campos con datos válidos para la adquisición de servicios ofrecidos en este sitio. El futuro Usuario deberá completarlo con su información personal de manera exacta, precisa y verdadera («Datos Personales») y asume el compromiso de actualizar los Datos Personales conforme resulte necesario. Move On podrá utilizar diversos medios para identificar a asegurados, pero  NO se responsabiliza por la certeza de los Datos Personales provistos por sus Usuarios. Los Usuarios garantizan y responden, en cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de los Datos Personales ingresados.
                    Move On se reserva el derecho de solicitar algún comprobante y/o dato adicional a efectos de corroborar los Datos Personales, así como de suspender temporal o definitivamente a aquellos Usuarios cuyos datos no hayan podido ser confirmados. En estos casos de inhabilitación, se dará de baja la solicitud realizada, sin que ello genere algún derecho a resarcimiento.
                    El Usuario dispondrá, una vez registrado, de un nombre y contraseña o clave definitiva que le permitirá el acceso personalizado, confidencial y seguro. El Usuario tendrá la posibilidad de cambiar la clave de acceso, para lo cual deberá sujetarse al procedimiento establecido en el sitio respectivo. El Usuario se obliga a mantener la confidencialidad de su Clave de acceso, asumiendo totalmente la responsabilidad por el mantenimiento de la confidencialidad de su clave secreta registrada en este sitio web, la cual le permite efectuar compras, solicitar servicios y obtener información. Dicha clave es de uso personal y su entrega a terceros, no involucra responsabilidad de Libra Compañía Argentina de Seguros S.A. o de las empresas en caso de mala utilización.

                    El Usuario será responsable por todas las operaciones efectuadas en su Cuenta, Cuenta por sí y/o por terceros, pues el acceso a la misma está restringido al ingreso y uso de su Clave Secreta, de conocimiento exclusivo del Usuario. El Usuario se compromete a notificar a Move On en forma inmediata y por medio idóneo y fehaciente, cualquier uso no autorizado de su Cuenta, así como el ingreso por terceros no autorizados a la misma. Se aclara que está prohibida la venta, cesión o transferencia de la Clave bajo ningún título.

                    Move On se reserva el derecho de rechazar cualquier solicitud de registración o de cancelar una registración previamente aceptada, sin que esté obligado a comunicar o exponer las razones de su decisión y sin que ello genere algún derecho a indemnización o resarcimiento.
                </p>
                <p>
                    03) Privacidad de la Información
                    Para utilizar los Servicios ofrecidos por moveonbylibra.com.ar, los Usuarios deberán facilitar determinados datos de carácter personal. Su información personal se procesa y almacena en servidores o medios magnéticos que mantienen altos estándares de seguridad y protección tanto física como tecnológica. Para mayor información sobre la privacidad de los Datos Personales y casos en los que será revelada la información personal, se pueden consultar nuestras Políticas de Privacidad.
                </p>
                <p>
                    04) Modificaciones del Acuerdo
                    Move On podrá modificar los Términos y Condiciones Generales en cualquier momento haciendo públicos en el Sitio los términos modificados. Asimismo, Move On deberá notificar a los Usuarios las modificaciones a través del envío de un link al e-mail del Usuario que le permitirá acceder a los nuevos Términos y Condiciones. Todos los términos modificados entrarán en vigor a los 10 (diez) días de su notificación vía e-mail. Dentro de los 5 (cinco) días siguientes a la recepción del e-mail, el Usuario deberá comunicar por e-mail a Move On si no acepta las modificaciones; en ese caso quedará disuelto el vínculo contractual y será inhabilitado como Usuario siempre que no tenga deudas pendientes. Vencido este plazo, se considerará que el Usuario acepta los nuevos términos y el contrato continuará vinculando a ambas partes.
                </p>
                <p>
                    05) Procedimiento para hacer uso de este sitio de internet.
                    En el servicio ofrecido por medio de este sitio, Move On informará, de manera inequívoca y fácilmente accesible, los pasos que deben seguirse para requerirlo, e informará, cuando corresponda, si el documento electrónico en que se formalice será archivado y si éste será accesible al consumidor. El sólo hecho de seguir los pasos que para tales efectos se indican en este sitio para efectuar una solicitud, equivale a aceptar que efectivamente Move On ha dado cumplimiento a las condiciones contenidas en este artículo. Indicará, además, su dirección de correo postal o electrónico y los medios técnicos a disposición del consumidor para identificar y corregir errores en el envío o en sus datos.
                </p>
                <p>
                    06) Perfeccionamiento de los contratos celebrados a través de este sitio.
                    A través de este sitio web Move On ofrecerá el servicio de asistencia vehicular, que podrá ser aceptado utilizando los mecanismos que el mismo sitio ofrece para ello. Toda aceptación quedará sujeta a la condición suspensiva de que Move On valide la transacción. En consecuencia, para toda operación que se efectúe en este sitio, la confirmación y/o validación o verificación por parte de Move On, será requisito para el perfeccionamiento del requerimiento. Para validar la transacción Move On deberá verificar:
                    Una vez requerido el servicio se procederá a la verificación de datos personales y datos del vehículo cargados por el asegurado, se revisará que no existan datos inválidos o que difieran con los documentos válidos para la solicitud (DNI, registro del vehículo) en caso de encontrar solicitudes sin documentación cargada o bien con datos faltantes, se les solicitará al asegurado por las vías disponibles para así ser normalizados en el legajo correspondiente.
                </p>
                <p>
                    07) Uso de los datos personales registrados en el sitio.
                    Los datos referidos en estos términos y condiciones tendrán como finalidad validar las solicitudes realizadas y mejorar la labor de información y comercialización de los productos y servicios. En ningún caso serán traspasados a terceros. El titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los mismos en forma gratuita a intervalos no inferiores a seis meses, salvo que se acredite un interés legítimo al efecto conforme lo establecido en el artículo 14, inciso 3 de la Ley N° 25.326. La DIRECCIÓN NACIONAL DE PROTECCIÓN DE DATOS PERSONALES, Órgano de Control de la Ley N° 25.326, tiene la atribución de atender las denuncias y reclamos que se interpongan en relación con el incumplimiento de las normas sobre protección de datos personales.
                </p>
                <p>
                    08) Violaciones del Sistema o Bases de Datos
                    No está permitida ninguna acción o uso de dispositivo, software, u otro medio tendiente a interferir tanto en las actividades y operatoria de Move On como en las ofertas, descripciones, cuentas o bases de datos de Move On. Cualquier intromisión, tentativa o actividad violatoria o contraria a las leyes sobre derecho de propiedad intelectual y/o a las prohibiciones estipuladas en este contrato harán pasible a su responsable de las acciones legales pertinentes, y a las sanciones previstas por este acuerdo, así como lo hará responsable de indemnizar los daños ocasionados.
                </p>
                <p>
                    09) Propiedad Intelectual
                    Los contenidos de las pantallas relativas a los servicios de moveonbylibra.com.ar como así también la información, datos, textos gráficos, imágenes, fotografías, audio y video clips, logotipos, iconos, programas, bases de datos, redes, archivos que permiten al Usuario acceder y usar su Cuenta, son de propiedad de las Aseguradoras y están protegidas por las leyes y los tratados internacionales de derecho de autor, marcas, patentes, modelos y diseños industriales. El uso indebido y la reproducción total o parcial de dichos contenidos quedan prohibidos, salvo autorización expresa y por escrito de Libra.
                </p>
                <p>
                    10) Sanciones. Suspensión de operaciones
                </p>
                <p>
                    12) Tarifas. Facturación
                    La registración en moveonbylibra.com.ar es gratuita. Al solicitar un servicio de auxilio, el usuario deberá haber pagado el precio expresado en la Póliza de seguro contratada.
                </p>
                <p>
                    13) Ley Aplicable
                    Este acuerdo estará regido en todos sus puntos por las leyes vigentes en la República Argentina, las cuales podrán ser consultadas en el siguiente sitio: www.mecon.gov.ar.
                </p>
                <p>
                    13) Ley Aplicable
                    Este acuerdo estará regido en todos sus puntos por las leyes vigentes en la República Argentina, las cuales podrán ser consultadas en el siguiente sitio: www.mecon.gov.ar.
                </p>
                <p>
                    14) Domicilio
                    Se fija como domicilio de Move On, la calle Lavalle 1528, piso 2, oficina C de la Ciudad Autónoma de Buenos Aires. 
                </p>
            </Modal>
        </Wrapper>
        <Footer/>
        <LogoWhatsApp/>
    </>
  )
}

export default TerminosYCondiciones