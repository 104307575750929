import styled from "styled-components"

export const Overlay = styled.div`  
    position: absolute;
    visibility:  ${props => props.show ? "visible" : "hidden"};
    opacity: ${props => props.show ? 1 : 0};
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    z-index: 4;
`
export const PopupWrapper = styled.div`
    margin: 70px auto;
    padding: 25px;
    background: #fff;
    border-radius: 5px;
    width: 90vw;
    height: 90vh;
    position: relative;
    transition: all 5s ease;
    z-index: 5;
    overflow-y: scroll;
    text-align: justify;


    @media screen and (max-width: 700px) {
      width: 100%;

      img{
          width: 100%;
      }
    }
`

export const Popupheader = styled.div`
    margin: 40px 5px 0 1px;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
    height: 60px;
    width: 100%;
    margin-bottom: 40px;

`
export const PopupClose = styled.div`
    font-size: 40px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
    display: flex;
    width: 90vw;
    align-items: flex-end;
    justify-content: flex-end;
    position: fixed;
    padding-right: 50px;

    &:hover{
        cursor: pointer;
        color: #000;
    }
`
export const PopupTitle = styled.h2`
    font-weight: bold;
`


export const PopupContent = styled.div`
    margin: 30px 0;
    overflow: auto;
    font-size: 18px;
`
export const ActionPromptWrapper = styled.div`
    display: flex;
    gap: 5px;
    width: 100%;
    height: 100%;
    justify-content: right;
    align-items: right;
`