import {
    LoadingWrapper,
    LoadingText,
    Spin,
} from './elements'


const Loading = ({ text, children, iconSize}) => {
    return (
        <LoadingWrapper className='loader'>
                <Spin size={iconSize}></Spin>
            {
                text?
                    <LoadingText>
                        {text}
                    </LoadingText>
                    :''
            }
                {children}
        </LoadingWrapper>
    )
}

export default Loading
