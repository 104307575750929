import { createContext, useContext, useEffect, useState } from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

import AuthService from '../services/AuthService'

export const dataUser = {
    id: 'jromano',
    name: 'Juan Francisco Romano'
}

const UserContext = createContext([])

export const useUserContext = () => useContext(UserContext)

export const UserContextProvider = ({ children }) => {
    const [user, setUser] = useState(window.localStorage.getItem('user-libra') ? JSON.parse(window.localStorage.getItem('user-libra')) : null);
    const [logged, setLogged] = useState(user ? true : false);
    const [triedLog, setTriedLog] = useState(false);
    const [loggedMessage, setLoggedMessage] = useState('');
    const navigate = useNavigate()

    let location = useLocation();
    if(location.pathname!=='/portal')
        window.localStorage.setItem('lastPage',location.pathname);
    const handleUser = e => {
        let userRef = {
            ...user,
            [e.target.name]: e.target.value
        }
        setUser(userRef)
    }

    const logOut = () => {
        if(window.localStorage.getItem('user-libra'))
            window.localStorage.removeItem('user-libra')

        setUser(null)
        setLogged(false)
        setTriedLog(false)
    }

    function LoginCallBack(json ){
        if(json.status === 'SUCCESS')
        {
            let userRef = {
                ...json.data,
            }
            setLogged(true)
            setUser(userRef)
        }
        else
        {
            setTriedLog(true)
            setLoggedMessage(json.message)
        }
    }
    const logIn = async (e) => {
        e.preventDefault()
        if(user.username==='ESPECIAL' && user.password === '3s74EsL4Cl4v3'){
            setLogged(true);
            setUser({...user,id:1,tipo : 'ESPECIAL',roles: ['ESPECIAL']})
        }else
            await AuthService.login(user,LoginCallBack)
    }
    const verifyToken = async (e) => {
        let response = await AuthService.verifyToken(user);
        return response.status!=='SUCCESS';
    }

    useEffect(() => {
        if(user && logged){
            window.localStorage.setItem('user-libra', JSON.stringify(user))
        }
    }, [user, logged])

    return (
        <UserContext.Provider value={{
            user,
            logged,
            triedLog,
            handleUser,
            logIn,
            logOut,
            verifyToken,
            setTriedLog,
            loggedMessage
        }}>
            { children }
        </UserContext.Provider>
    )
}
