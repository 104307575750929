import styled from "styled-components"

export const SearcherWrapper = styled.div`
    width: 100%;
    text-align: start;
    display: grid;
    place-items: center;
    position: relative;

    p{
        width: 100%;
        text-align: start;
    }

    & h5{
        width: 100%;
        text-align: start;
    }
`


export const SearcherInput = styled.div`
    width: 100%;
    z-index: 1;
    border: none;
    border-bottom: 2px solid;
    border-color:${({ color }) => color ? color : ' #e95612'};

    & input{
        width: 100%;
        outline: none;
        border: none;
        padding: 5px 0;  
        margin: 10px 0; 
        font-size: 18px;
        color: #000000;
        &::placeholder{
            color: #E95612;
        }
    }
`


export const Result = styled.div`
    display: block;
    border: 2px solid #E4E6E8;
    border-radius: 2px;
    overflow-y: auto;
    max-height: 150px;
    height: 150px;
    width: 100%;
    background-color: white;
    color: #E95612;
    position: absolute;
    top: 66px;
    z-index: 2;
`

export const ItemResult = styled.div`
    padding: 10px 12px;
    font-size: 14px;
    cursor: pointer;

    :hover{
        background-color: #E95612;
        color: white;
    }
`
