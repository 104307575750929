//React Imports
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

//Styled Components
import {
    AdminHomePage,
    AdminHomeWrapper,
    PrestadoresListWrapper,
    LoadingWrapper,
    Imagen,
    EmptyWrapper,
    Dates,
    Overlay,
    Instructivo,

} from './elements'

//Components

import Table from '../../components/Table'

//Context
import { useUserContext } from '../../context/UserContext'
import Loading from '../../components/Loading'
import {data,columns} from './data';

//service
import AdminService from '../../services/AdminService'
import DatePicker from "../../components/DatePicker";
import Button from "../../components/Button";
import {usePortalTicketContext} from "../../context/PortalTicketContext";
const getFirstAndLastDateOfMonth = ({
                                        year = undefined,
                                        month = undefined,
                                    } = {}) => {
    const date = new Date();
    if (year !== undefined && month !== undefined) {
        date.setMonth(month);
        date.setFullYear(year);
    }
    const initDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const finishDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return {
        initDate,
        finishDate,
    };
};
const Admin =  () => {
    const [loading, setLoading] = useState(true)
    const { user } = useUserContext()
    const [prestadores, setPrestadores] = useState(null)
    const [totales,setTotales] = useState(null)

    const {
        setFilters,
        filters
    } = usePortalTicketContext()
    const navigate = useNavigate()

    const tableInitialState = {
        sortBy: [
          { desc: false, id: "nombre" },
        ],
      };
    useEffect( () => {
        setLoading(true)
        AdminService.getPrestadores(filters,user.token,(res) => {
            if(res.data && Object.keys(res.data).length > 0){
                setPrestadores(res.data.list)
                setTotales(res.data.totales)
            }
            setLoading(false);
        });

    },[filters])
    return (
        <>

            <AdminHomePage>
                <Imagen className={"superior"} src={`${process.env.PUBLIC_URL}/assets/images/huella clara.webp`} alt={'image'}/>
                {
                    loading?
                        <LoadingWrapper>
                            <Loading iconSize={'100px'}></Loading>
                        </LoadingWrapper>
                        :
                        <AdminHomeWrapper>
                            <PrestadoresListWrapper id={"prestadoresList"}>
                                <div id={"tittle"}>
                                    <h3>Listado de prestadores</h3>
                                    <hr/>
                                </div>
                                <div id={"lista"}>
                                    <Dates>
                                        <DatePicker
                                            title={"Desde:"}
                                            datePicker={filters.initDate}
                                            setDatePicker={(date) => {
                                                let aux = { ...filters };
                                                aux.initDate = date;
                                                setFilters(aux);
                                            }}
                                            minDate={new Date(2022, 5, 1)}
                                            maxDate={filters.finishDate} />
                                        <DatePicker
                                            title={"Hasta:"}
                                            datePicker={filters.finishDate}
                                            setDatePicker={(date) => {
                                                let aux = { ...filters };
                                                aux.finishDate = date;
                                                setFilters(aux);
                                            }}
                                            minDate={filters.initDate}
                                        />
                                    </Dates>
                                    {
                                        prestadores && prestadores.length>0?
                                            <>
                                                <Table
                                                    columns={columns}
                                                    data={prestadores}
                                                    initialState={tableInitialState}
                                                    withCellBorder
                                                    withRowBorder
                                                    sortable={true}
                                                    totales={totales}
                                                    withPagination
                                                    rowProps={row => ({
                                                        onClick: () => navigate('/portal/admin/prestador/'+row.original.id),
                                                        style: {
                                                            cursor: "pointer"
                                                        }
                                                    })}/>
                                            </>
                                            :
                                            <EmptyWrapper>No posee servicios para aprobar</EmptyWrapper>
                                    }
                                </div>
                            </PrestadoresListWrapper>

                        </AdminHomeWrapper>
                }
            </AdminHomePage>
        </>
    )
}

export default Admin
