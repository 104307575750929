import styled from 'styled-components'

export const FooterWrapper = styled.footer`
    width: 100%;
    background-color: #E95612;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;

    @media screen and (max-width: 690px) {
        flex-direction: column;
    }
`

export const Column = styled.div`
    text-align: start;
    font-size: 14px;
    color: white;
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    justify-content: start;
`

export const FooterPart = styled.div`
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 15px;

    p, a{
        padding: 0 15px;
        color: #fff;
        font-weight: bold;
    }
    
    p a{
        padding: 0;
    }

    a{
        text-decoration: none;
    }

    a:hover{
        color: #000;
    }

    img{
        width: 200px;
        margin-left: 14px;
    }
`


export const Line = styled.div`
    width: 1px;
    border-left: 1px solid white;

    @media screen and (max-width: 690px) {
        width: 90%;
        height: 1px;
        border-left: unset;
        border-bottom: 1px solid white;
        margin: auto;
    }
`