//Styled Components
import {
  Wrapper,
  WrapperContent
} from './elements'

//Components
import Section from "../../components/Section"
import Button from '../../components/Button'

const Home = ({ title = null }) => {

  function redirectWebApp() {
    window.open("/webApp", '_blank')
  }

  return (
    <Section
      minHeight={'fit-content'}
      compName='home'
    >
        <Wrapper>
          <img loading={"lazy"} className={'fondo'} src={`${process.env.PUBLIC_URL}/assets/images/home-opcion2.webp`} alt={'fondo'}/>
          <WrapperContent>
            <h1>ASISTIMOS EN LOS<br/>MOMENTOS CLAVES</h1>
            <h2>¡Pedí tu servicio online!</h2>
            <Button clickHandler={redirectWebApp} text={'Click aquí'}/>
          </WrapperContent>
        </Wrapper>
    </Section>
  )
}

export default Home
