//React
import { useEffect, useState, useRef } from 'react';

//Styled Element
import {
  StyledSection,
  Wrapper,
  SectionContent,
  Limit
} from './elements'

//Context
import { useHeaderContext } from '../../context/HeaderContext';


function useOnScreen(refInicio, refFin) {
  const [isOnScreen, setIsOnScreen] = useState(false);
  const observerInicioRef = useRef(null);
  const observerFinRef = useRef(null);

  useEffect(() => {
    observerInicioRef.current = new IntersectionObserver(([entry]) =>
      setIsOnScreen(entry.isIntersecting)
    );
    observerFinRef.current = new IntersectionObserver(([entry]) =>
      setIsOnScreen(entry.isIntersecting)
    );
  }, []);

  useEffect(() => {
    observerInicioRef.current.observe(refInicio.current);
    observerFinRef.current.observe(refFin.current);

    return () => {
      observerInicioRef.current.disconnect();
      observerFinRef.current.disconnect();
    };
  }, [refInicio, refFin]);

  return isOnScreen;
}

const Section = ({ compName = null, minHeight, mediaHeight, color, styled = false, image, icono, recta = false, translate='0px', children, actualizar = true }) => {
  const elementInicioRef = useRef(null);
  const elementFinRef = useRef(null);
  const isOnScreen = useOnScreen(elementInicioRef, elementFinRef);
  const { setSectionVisible } = useHeaderContext()

  if(actualizar)
  {
    if(isOnScreen){
      setSectionVisible(compName)
    }
  }

  return (
    <StyledSection ref={elementInicioRef} minHeight={minHeight} mediaHeight={mediaHeight} color={color}>
      <Limit id={compName}/>
      {
        //Wrapper da forma con logo
        styled?
          <Wrapper
            recta={recta}
            translate={translate}
          >
            <img loading={"lazy"} className='icono' src={icono} alt={'imagen'}/>
            <img loading={"lazy"} className='fondo' src={image} alt={'imagen'}/>
            <SectionContent>
              {children}
            </SectionContent>
          </Wrapper>
        :
          children
      }
      <div ref={elementFinRef}></div>
    </StyledSection>
  )
}

export default Section
