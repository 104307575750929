//React
import { createContext, useContext, useState } from 'react'

//Service
import NovedadService from '../services/NovedadService'
import {useUserContext} from "./UserContext";

const NovedadesContext = createContext([])

export const useNovedadesContext = () => useContext(NovedadesContext)

export const NovedadesContextProvider = ({ children }) => {
    const [novedades, setNovedades] = useState([])
    const [nuevaNovedad, setNuevaNovedad] = useState(
        {
            titulo: null,
            subtitulo: null,
            descripcion: null,
            imagen: null,
            tipo_modelo: 1,
            video: 'No',
            link_video: ''
        }
    )
    const [novedadesLoading, setNovedadesLoading] = useState(false)
    const [novedadesCreating, setNovedadesCreating] = useState(false)
    const [novedadesDeleting, setNovedadesDeleting] = useState(false)
    const { user } = useUserContext()

    const changeHandler = (obj, name) => {
        setNuevaNovedad({
            ...nuevaNovedad,
            [name]: obj
        })
    }

    const limpiarNovedad = () => {
        setNuevaNovedad({
            titulo: null,
            subtitulo: null,
            descripcion: null,
            imagen: null,
            tipo_modelo: 1,
            video: 'No',
            link_video: ''
        })
    }

    const createNovedad = () => {
        setNovedadesCreating(true)
        NovedadService.create(user.token,nuevaNovedad, (novedades) => {
            setNovedades(novedades)
            limpiarNovedad()
            setNovedadesCreating(false)
        })
    }

    const deleteNovedad = (id) => {
        setNovedadesDeleting(true)

        NovedadService.deleteNovedad(user.token,id, (novedades) => {
            setNovedades(novedades)
            limpiarNovedad()
            setNovedadesDeleting(false)
        })
    }

    const editNovedad = () => {
        setNovedadesCreating(true)
        NovedadService.editNovedad(user.token, nuevaNovedad, (novedades) => {
            setNovedades(novedades)
            limpiarNovedad()
            setNovedadesCreating(false)
        })
    }

    const setNuevaNovedadById = (id) => {
        let novedadToSet

        novedades.forEach((e) => {
            if(e.id === id)
                novedadToSet = e
        })

        setNuevaNovedad(novedadToSet)
    }

    const getNovedades = () => {
        setNovedadesLoading(true)
        NovedadService.getAll((novedades) => {
            setNovedades(novedades)
            setNovedadesLoading(false)
        })
    }

    return (
        <NovedadesContext.Provider value={{
            novedades,
            nuevaNovedad,
            novedadesLoading,
            novedadesCreating,
            novedadesDeleting,
            setNuevaNovedad,
            changeHandler,
            createNovedad,
            editNovedad,
            deleteNovedad,
            setNuevaNovedadById,
            limpiarNovedad,
            getNovedades
        }}>
            { children }
        </NovedadesContext.Provider>
    )
}

