import styled from 'styled-components'

export const WrapperPreview = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #E95612;
    padding-top: 50px;

    h1{
        color: white;
    }
`