import ImageDTO from "../DTOs/ImageDTO";
const {token_LIBRA, url} = require('./constantes');
async function resizePublicImage(path,pathToSave,widht,heigth,fit,callBack) {
    try{
        // add file to FormData object
        const fd = new FormData();
        let blob = await fetch (path).then (r => r.blob ());
        let imageDTO = new ImageDTO({imagen:blob,w:widht,h:heigth,fit:fit})
        Object.keys(imageDTO).forEach(val =>{
            fd.append(val,imageDTO[val]);
        })
        let response = await fetch(`${url}/imagen/resizeImage`, {
            method: 'POST',
            headers: {
                'authorization': 'Bearer '+ token_LIBRA
            },
            body: fd,
        }).then(res => {
            return res.json()
        }).then(json => {
            callBack(json.data);
        }).catch(res => {
            console.log(res);
        })
        return response;
    }catch (e){
        //console.log(e);

    }
}
const ImageService = {
    resizePublicImage,
};


export default ImageService
