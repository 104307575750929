const {token_LIBRA, url} = require('./constantes');

async function getProfileData(tokenResponse) {
try{

    let response = await fetch(`${url}/form-ext/google/account`, {
        method: 'POST',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'authorization': 'Bearer '+ token_LIBRA
        },
       body: JSON.stringify({
        credential: tokenResponse,
       })
    }).then(res => {
        return res.json()
    }).catch(res => {
        return res.json()
    })
    return response;
}catch (e){
    console.log(e);
}
}

export default getProfileData;
