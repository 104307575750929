import { createContext, useContext, useState } from 'react'
import TicketDTO from '../DTOs/Ticket';
import TicketService from '../services/Ticket';
import ClienteService from '../services/Cliente';
import UbicacionService from '../services/Ubicacion';

const TicketContext = createContext([])

export const useTicketContext = () => useContext(TicketContext)

export const TicketContextProvider = ({ children }) => {
    const [ticket, setTicket] = useState(window.localStorage.getItem('ticket') ? JSON.parse(window.localStorage.getItem('ticket')) : new TicketDTO({}))
    const [state,setState] = useState(window.localStorage.getItem('estado-ticket') ? parseInt(window.localStorage.getItem('estado-ticket')) : 0)
    const [result,setResult] = useState(null);
    const [address, setAddress] = useState({
        longitud: "",
        latitud: "",
        calle: "",
        numero: "",
        entreC1: "",
        entreC2: "",
        lugar: "",
        alto: null,
        ancho: null,
        ruta: "",
        km: "",
        localidad: "",
        provincia: "",
        pais: "",
        referencias: "",
    })

    const [telefonos, setTelefonos] = useState({
        cod1:"",
        numero1:"",
        cod2:"",
        numero2:"",
        cod3:"",
        numero3:""
    })

    const handleTicket = (name, value) => {
        let ticketRef = {
            ...ticket,
            [name]: value
        }
        window.localStorage.setItem("ticket",JSON.stringify(ticketRef));
        setTicket(ticketRef)
    }

    const nextState = () => {
        let x = state;
        let newState = x + 1 > 9 ? 9 : x + 1;
        window.localStorage.setItem("ticket",JSON.stringify(ticket));
        window.localStorage.setItem("estado-ticket",newState);
        setState(newState);
    }

    const lastState = () => {
        let x = state;
        let newState = x - 1 < 0 ? 0 : x - 1;
        window.localStorage.setItem("ticket",JSON.stringify(ticket));
        window.localStorage.setItem("estado-ticket",newState);
        if(newState === 3)
        {
            setAddress(ticket.origen)
        }
        if(newState === 7)
        {
            setAddress(ticket.destino)
        }
        setState(newState);
    }

    const rollBack = () => {
        let ticketAux = window.localStorage.getItem('ticket');
        setTicket(new TicketDTO({}));
        setTelefonos({
            cod1:"",
            numero1:"",
            cod2:"",
            numero2:"",
            cod3:"",
            numero3:""
        })
        setAddress({
            longitud: "",
            latitud: "",
            calle: "",
            numero: "",
            entreC1: "",
            entreC2: "",
            subsuelo: "",
            alto: null,
            ancho: null,
            ruta: "",
            km: "",
            localidad: "",
            provincia: "",
            pais: "",
            referencias: "",
        })
        setState(0);
        if(!ticketAux){
            return;
        }
        window.localStorage.removeItem('ticket')
        window.localStorage.removeItem('estado-ticket')
    }

    /**
     * Obtiene el cliente con la data de la primer pantalla en la webApp
     * @param clientData
     * @returns {Promise<void>}
     */

    const saveTicket = async () => {
        let ticketCreated = await TicketService.guardarTicket(ticket);
        if(ticketCreated.status === 'SUCCESS')
        {
            window.localStorage.removeItem('ticket')
            window.localStorage.removeItem('estado-ticket')
            return true
        }
        else
            return false;
    }

    const getCar = async (clientData) => {
        let res = await ClienteService.specialSearch(clientData);
        if(res.status !== 'SUCCESS'){
            return res;
        }
        handleTicket('auto',res.data);
        setResult(res);
        return true;
    }

    const setUbicacion = async (tipo) => {
        if(typeof address.longitud === 'object')
            address.longitud = ""
        if(typeof address.latitud === 'object')
            address.longitud = ""
        let res = await UbicacionService.getOrCreate(address);
        if(res.status !== 'SUCCESS'){
            return res.message;
        }
        window.localStorage.setItem("ticket",JSON.stringify({
            ...ticket,
            [tipo]: res.data
        }));
        setTicket({
            ...ticket,
            [tipo]: res.data
        })
        setResult(res);
        setAddress({
            longitud: "",
            latitud: "",
            calle: "",
            numero: "",
            entreC1: "",
            entreC2: "",
            subsuelo: "",
            alto: null,
            ancho: null,
            ruta: "",
            km: "",
            localidad: "",
            provincia: "",
            pais: "",
            referencias: "",
        })
        return true;
    }
    const setGoogleAddress = (tipo) => {
        handleTicket(tipo,address);
        nextState();
    }

    return (
        <TicketContext.Provider value={{
            ticket,
            state,
            result,
            address,
            nextState,
            lastState,
            handleTicket,
            saveTicket,
            rollBack,
            getCar,setGoogleAddress,
            setUbicacion,
            setState,setTicket,
            setAddress,telefonos, setTelefonos
        }}>
            { children }
        </TicketContext.Provider>
    )
}
