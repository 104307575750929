export const tiposAsistencia = [
    {
        "id": "Vehicular/Viajero",
        "nombre": "Vehicular/Viajero"
    },
    {
        "id": "Hogar/Comercio/Consorcio",
        "nombre": "Hogar/Comercio/Consorcio"
    },
    {
        "id": "Otros",
        "nombre": "Otros"
    },
]

export const tiposGestion = [
    {
        "id": "Consulta",
        "nombre": "Consulta"
    },
    {
        "id": "Reclamo",
        "nombre": "Reclamo"
    },
]

export const esProductor = [
    {
        "id": "si",
        "nombre": "Si"
    },
    {
        "id": "no",
        "nombre": "No"
    },
]