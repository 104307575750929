import styled from 'styled-components'
import {
    FaWhatsapp
} from 'react-icons/fa'

export const Animacion = styled.div`
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 3;
    cursor: pointer;
    transition: all 1s ease-out;

    &:hover{
        transform: rotateY(360deg);

        & .logo{
            background-color: green;

            .noHover{
                display: none;
            }

            .hover{
                display: block;
            }

            &:after{
                border-right: 20px solid green;
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
            }
        }
    }

    &.hovering{
        & .logo{
            background-color: green;
    
            .noHover{
                display: none;
            }
    
            .hover{
                display: block;
            }
    
            &:after{
                border-right: 20px solid green;
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
            }
        }
    }
`

export const Wrapper = styled.div`
    background-color: white;
    padding: 8px;
    border-radius: 50%;
    filter: drop-shadow(2px 4px 6px rgba(0,0,0, 0.6));

    img{
        width: 60px;
    }

    .noHover{
        display: block;
    }

    .hover{
        display: none;
    }

    &:after{
        content: "";
        position: absolute;
        bottom: -6px;
        left: -6px;
        transform: rotate(-45deg);
        border-right: 20px solid white;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
    }
`

export const Message = styled.div`
    width: 250px;
    height: 150px;
    background-color: white;
    position: fixed;
    bottom: 60px;
    right: 75px;
    border-radius: 50%;
    z-index: 3;
    filter: drop-shadow(2px 4px 6px rgba(0,0,0, 0.6));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:before{
        content: "";
        position: absolute;
        bottom: 20px;
        right: 4px;
        transform: rotate(-135deg);
        border-right: 20px solid white;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
    }

    textarea{
        width: 200px;
        height: 70px;
        padding: 5px;
        resize: none;
        border: none;
        outline: none;
        margin-bottom: 10px;
    }

    p{
        text-align: center;
        font-size: 14px;
        margin-top: 10px;
    }

    .button{
        text-decoration: none;
        background-color: white;
        padding: 6px 12px;
        border: none;
        cursor: pointer;
        font-weight: 600;
        color: #E95612;
    }
`

export const IconGreen = styled(FaWhatsapp)`
    color: green;
    font-size: 3rem;
`

export const IconWhite = styled(FaWhatsapp)`
    color: white;
    font-size: 3rem;
`