//react
import {useEffect, useState} from "react"
import { useNavigate } from 'react-router-dom'

//Styled components
import {
    Wrapper,
    WrapperContent,
    ButtonWrapper,
    ListRadioWrapper,
    Form
} from './elements'

//Components
import Searcher from '../../components/Searcher'
import Button from '../../components/Button'
import Input from "../../components/Input"
import TelefonoInput from "../../components/TelefonoInput"
import Message from "../../components/Message"
import { FormBox } from "../../components/FormBox"
import Login from "../../components/oAuth_Login"
import InputRadio from "../../components/radioInput"
import Select from "../../components/Select"
import FileUpload from "../../components/FileUpload"
//Context

import { useFormContext } from "../../context/FormContext"

//Data
import { provincias, isInProvincias } from "./provincias"
import { tiposAsistencia,tiposGestion,esProductor } from "./tipos"


const Forms = ({ form_id = null }) => {
    const [error,setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const navigate = useNavigate()
    //let {form_id} = useParams();

    return(
        <Wrapper>
        {
            error?
                <Message mensaje={error} action={
                        () => {
                            setError(null)
                        }
                    }
                />
            :
                ""
        }
         {
            success?
                <Message type={'success'} mensaje={success} action={
                    () => {
                        navigate('/')
                    }
                }
                />
                :
                ""
        }
        {
           (form_id === 'sumate')?
                <SumateFormComponent setError ={setError} success={setSuccess}/>
            :
                (form_id === 'consultas')?
                    <ConsultaFormComponent setError ={setError}  success={setSuccess}/>
                :
                    <div>En construccion</div>
        }

    </Wrapper>
    )
}

const SumateFormComponent = ({setError, success}) => {

    //context
    const {saveForm} = useFormContext();
    const navigate = useNavigate()
    const [values,setValues] = useState({});
    const [provincia, setProvicia] = useState({})
    const [localidades, setLocalidades] = useState([])
    const [first, setFirst] = useState(true)


    const checkLogin = () => {

        let res = false;

        if(values && values.email){
            res = true;
        }
        else{
            let profileData =  window.localStorage.getItem('profileData') ? JSON.parse(window.localStorage.getItem('profileData'))  : {};

            if (profileData && profileData.email){
                values.email =  profileData.email;
                res = true;
            }
        }

        if (res){
            checkSumateFields();
        }
        else{
            setError("Debe iniciar sesion en Google");
        }
    }

    const checkSumateFields = async () => {
        if ( values.nombre && values.apellido){
            if (values.provincia && values.localidad){
                if (values.codtelefono && values.numerotelefono){
                    if (values.mensaje){

                        let res = await saveForm(values,'Sumate');

                        if (res.status === 'SUCCESS'){
                            success('Su formulario fue cargado con éxito.')
                         }
                         else{
                            setError("Ocurrió un error inesperado: "+res.message);
                         }
                    }
                    else{
                        setError("Debe ingresar un mensaje.");
                    }
                }
                else{
                    setError("Debe ingresar un número de teléfono")
                }
            }
            else{
                setError("Debe ingresar la provincia y localidad");
            }
        }
        else{
            setError("Debe ingresar al menos un nombre y un apellido");
        }
    }


    const addProvincia = (obj) => {
        setValues({
            ...values,
            provincia: obj.nombre
        })
       setProvicia(obj)
    }

    const addLocalidad = (obj) => {
        setValues({
            ...values,
            localidad: obj.nombre
        })
    }

    useEffect(() => {
         if(!first)
         {
             if(provincia.id)
             {
                 if(localidades.length <= 0)
                 {
                     fetch(`https://apis.datos.gob.ar/georef/api/municipios?provincia=${provincia.id}&campos=id,nombre&max=1000`)
                     .then((data) => data.json())
                     .then((json) => setLocalidades(json.municipios))
                 }
             }
             else
             {
                setValues({
                    ...values,
                    localidad: null
                })
                setLocalidades([])

             }
         }
         else
             setFirst(false)
    },[provincia])


    return (
        <WrapperContent>
            <FormBox isHeader={true} title={"Sumate como prestador"}>
            <hr></hr>
            <Login idValue={"email"} values={values} changeValues={setValues} loginError={setError}/>
            </FormBox>
            <FormBox isHeader={false} isRequired={true} title={"Nombre"}>
                <Input idValue={"nombre"} values={values} changeValues={setValues} placeholder={"Nombre"} textColor={"lightgray"}/>
            </FormBox>
            <FormBox isHeader={false} isRequired={true} title={"Apellido"}>
                <Input idValue={"apellido"} values={values} changeValues={setValues} placeholder={"Apellido"}  textColor={"lightgray"}/>
            </FormBox>
            <FormBox isHeader={false} isRequired={true}  title={"Provincia de residencia"}>
                <Searcher add={addProvincia} listValues={provincias} textColor={"lightgray"}/>
            </FormBox>
            <FormBox isHeader={false} isRequired={true} title={"Localidad"}>
                <Searcher add={addLocalidad} listValues={localidades} textColor={"lightgray"}/>
            </FormBox>
            <FormBox isHeader={false} isRequired={true} title={"Teléfono"}>
                <TelefonoInput id={"telefono"} value={values} setValue={setValues} textColor={"#000000"} onlyNumbers={true}/>
            </FormBox>
            <FormBox isHeader={false} isRequired={true} title={"Mensaje"}>
                <Input idValue={"mensaje"} values={values} changeValues={setValues} placeholder={"Mensaje"}  textColor={"lightgray"}/>
            </FormBox>

             <ButtonWrapper>
                 <Button clickHandler={() => navigate('/')} text={'Volver'} isOrangeSection={true}/>
                 <Button clickHandler={() => checkLogin()} text={'Enviar'}  isOrangeSection={true}/>
             </ButtonWrapper>
        </WrapperContent>
    );
}

const ConsultaFormComponent = ({setError, success}) => {

    const [values,setValues] = useState({});
    const [files,setFiles] = useState({});
    const [productor,setProductor] = useState(false);
    const navigate = useNavigate()
     //context
     const {saveForm} = useFormContext();

    const  checkProductor = () => {

        let res = false;

        if (values.esproductor === 'si'){
            if (values.mail_productor && (values.codtelefono_productor && values.numerotelefono_productor)){
                res = true;
            }
            else{
                setError("Debe completar ambos campos Mail de Prestador y Teléfono del Prestador");
            }
        }
        else{
            values.mail_productor = '';
            values.telefono_productor = '';
            res = true;
        }

        return res;
    }

    const checkLogin = () => {

        let res = false;

        if(values && values.email){
            res = true;
        }
        else{
            let profileData =  window.localStorage.getItem('profileData') ? JSON.parse(window.localStorage.getItem('profileData'))  : {};

            if (profileData && profileData.email){
                values.email =  profileData.email;
                res = true;
            }
        }

        if (res){
            checkConsultasFields();
        }
        else{
            setError("Debe iniciar sesion en Google");
        }
    }

    const checkConsultasFields = async () => {

        let res;
        if( !values.tipoasistencia){
            setError("Debe seleccionar el tipo de asistencia solicitada");
        }
        else{
            if (!values.nombrecompleto){
                setError("El campo Nombre y apellido no puede ser vacio");
            }
            else{

                if (!values.codtelefono || !values.numerotelefono){
                    setError("El campo Teléfono del Titular del Servicio no puede ser vacio");
                }
                else{
                    if (!values.observaciones){
                        setError("El campo observaciones no puede ser vacio");
                    }
                    else{//paso todos los chequeos

                        if (values.esproductor){
                            res = checkProductor();

                            if (res){
                                if (files === {}){
                                    setError("Debe adjuntar al menos un archivo");
                                }
                                else{
                                    values.archivo = files;
                                    //paso los chequeos, es hora de enviar el archivo!

                                    res = await saveForm(values,'Consultas')

                                     if (res.status === 'SUCCESS'){
                                        success('Su formulario fue cargado con éxito.')
                                     }
                                     else{
                                        setError("Ocurrió un error inesperado: "+res.message);
                                     }
                                }
                            }
                        }

                        else{
                            setError("Debe indicar si es productor o no");
                        }
                    }
                }
            }
        }
    }

    useEffect(() => {
        setProductor(false);
       if (values.esproductor && values.esproductor === 'si'){
            setProductor(true);
       }
       else{
        console.log('Las opciones de mail y telefono NO se van a mostrar')
       }
   },[values.esproductor])

    return (
        <WrapperContent>
          <FormBox isHeader={true} title={"Consultas y sugerencias"}>
              <hr></hr>
              <Login idValue={"email"} values={values} changeValues={setValues} loginError={setError}/>
          </FormBox>
          <FormBox isHeader={false} isRequired={true}  title={"Tipo de asistencia Solicitada"}>
                <Select idValue={"tipoasistencia"} values={values}  options={tiposAsistencia} changeValues={setValues}/>
            </FormBox>
            <FormBox isHeader={false} isRequired={true} title={"Apellido, Nombre del Titular del Servicio"}>
                <Input idValue={"nombrecompleto"} values={values} changeValues={setValues} placeholder={"Nombre y apellido"} textColor={"lightgray"}/>
            </FormBox>
            <FormBox isHeader={false} isRequired={false} title={"Póliza"}>
                <Input idValue={"poliza"} values={values} changeValues={setValues} placeholder={"Póliza"}  textColor={"lightgray"}/>
            </FormBox>
            <FormBox isHeader={false} isRequired={false} title={"Mail de Contacto Titular del Servicio"}>
                <Input type={'email'} idValue={"mail_titular"} values={values} changeValues={setValues} placeholder={"Email"} textColor={"lightgray"}/>
            </FormBox>
            <FormBox isHeader={false} isRequired={true} title={"Teléfono del Titular del Servicio"}>
                <TelefonoInput id={"telefono"} value={values} setValue={setValues} textColor={"#000000"}/>
            </FormBox>
            <FormBox isHeader={false} isRequired={true} title={"¿Es Prestador?"}>
                <InputRadio idValue={"esproductor"} options={esProductor} values={values} changeValues={setValues} textColor={"#000000"}/>
            </FormBox>
            <FormBox isHeader={false} isRequired={true} title={"Mail de prestador"} show={productor}>
                <Input  type={'email'} idValue={"mail_productor"} values={values} changeValues={setValues} placeholder={"Email"} textColor={"lightgray"}/>
            </FormBox>
            <FormBox isHeader={false} isRequired={true} title={"Teléfono del Prestador"} show={productor}>
                <TelefonoInput id={"telefono_productor"} value={values} setValue={setValues} textColor={"#000000"}/>
            </FormBox>
            <FormBox isHeader={false} isRequired={true}  title={"Tipo de gestión"}>
               <Select idValue={"tipogestion"} values={values}  options={tiposGestion} changeValues={setValues}/>
            </FormBox>
            <FormBox isHeader={false} isRequired={true} title={"Observaciones"}>
                <Input idValue={"observaciones"} values={values} changeValues={setValues} placeholder={"Observaciones"} textColor={"lightgray"}/>
            </FormBox>
            <FormBox isHeader={false} isRequired={true} title={"Archivos adjuntos"}>
                <FileUpload label={''} files={files} setFiles={setFiles}/>
            </FormBox>
            <ButtonWrapper>
                 <Button clickHandler={() => navigate('/')} text={'Volver'} isOrangeSection={true}/>
                 <Button clickHandler={() => checkLogin()} text={'Enviar'}  isOrangeSection={true}/>
             </ButtonWrapper>
        </WrapperContent>
    )
}


export default Forms;
