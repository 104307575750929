import { useState, useEffect, useRef, Children, isValidElement, cloneElement } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import {EmptyWrapper} from "../Servicio/elements";
import {DirectionPane,Row,DirectionWrapper,ImgWrapper,WrapperMap} from "./elements";
import Loading from "../Loading";

const render = (status) => {
    return <h1>{status}</h1>;
};

const Map = ({
                 onIdle,
                 children,
                 directionRequest,
                 style,
                 route,
                 ...options
             }) => {
    const ref = useRef(null)
    const [map, setMap] = useState(null)
    const [loading, setLoading] = useState(false) // initial zoom
    const [infoRoute, setInfoRoute] = useState(null)
    const [directionsRenderer, setDirectionsRenderer] = useState(null)
    const google = window.google
    function makeMarker(position, iconName, title, map) {
        var icons = {
            base: new google.maps.MarkerImage(
                // URL
                `${process.env.PUBLIC_URL}/assets/images/markerIcons/3.webp`,
                // (width,height)
                new google.maps.Size(60,60),
                // The origin point (x,y)
                new google.maps.Point(0, 0),
                // The anchor point (x,y)
                new google.maps.Point(22, 32)),
            start: new google.maps.MarkerImage(
                // URL
                `${process.env.PUBLIC_URL}/assets/images/markerIcons/2.webp`,
                // (width,height)
                new google.maps.Size(60,60),
                // The origin point (x,y)
                new google.maps.Point(0, 0),
                // The anchor point (x,y)
                new google.maps.Point(22, 32)),
            end: new google.maps.MarkerImage(
                // URL
                `${process.env.PUBLIC_URL}/assets/images/markerIcons/1.webp`,
                // (width,height)
                new google.maps.Size(60,60),
                // The origin point (x,y)
                new google.maps.Point(0, 0),
                // The anchor point (x,y)
                new google.maps.Point(22, 32))
        };
        new google.maps.Marker({
            position: position,
            map: map,
            animation: google.maps.Animation.DROP,
            icon: icons[iconName],
            title: title
        });
    }
    function secondsToHms(d) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h + (h == 1 ? " hora, " : " horas, ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " minuto, " : " minutos, ") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? " segundo" : " segundos") : "";
        return hDisplay + mDisplay + sDisplay;
    }
    function roundTwoDec(total) {
        return  Math.round((total + Number.EPSILON) * 100) / 100;
    }
function calcularRuta(e){
    setLoading(true);
    let directionsService = new window.google.maps.DirectionsService();
    directionsService.route(directionRequest).then((result)=>{
            if(result && result.routes.length >0) {
                if(result.routes.length > 1)
                    result.routes  = [result.routes[0]];
                let aux = [];
                let total = 0;
                let time = 0;
                if(result.routes[0].legs[0]) {
                    makeMarker(result.routes[0].legs[0].start_location, 'base', "BASE", map);
                    aux.push({
                        src:`${process.env.PUBLIC_URL}/assets/images/markerIcons/3.webp`,
                        direction:`${result.routes[0].legs[0].start_address}`,
                        estimated:`${result.routes[0].legs[0].distance.text}, ${result.routes[0].legs[0].duration.text} aproximadamente.`
                    })
                    total+=result.routes[0].legs[0].distance.value;
                    time+=result.routes[0].legs[0].duration.value;
                }
                if(result.routes[0].legs[1]) {
                    makeMarker(result.routes[0].legs[1].start_location, 'start', "desde", map);
                    aux.push({
                        src:`${process.env.PUBLIC_URL}/assets/images/markerIcons/2.webp`,
                        direction:`${result.routes[0].legs[1].start_address}`,
                        estimated:`${result.routes[0].legs[1].distance.text}, ${result.routes[0].legs[1].duration.text} aproximadamente.`
                    })
                    total+=result.routes[0].legs[1].distance.value;
                    time+=result.routes[0].legs[1].duration.value;
                }
                if(result.routes[0].legs[2]) {
                    makeMarker(result.routes[0].legs[2].start_location, 'end', "hasta", map);
                    aux.push({
                        src:`${process.env.PUBLIC_URL}/assets/images/markerIcons/1.webp`,
                        direction:`${result.routes[0].legs[2].start_address}`,
                        estimated:`${result.routes[0].legs[2].distance.text}, ${result.routes[0].legs[2].duration.text} aproximadamente.`
                    })
                    aux.push({
                        src:`${process.env.PUBLIC_URL}/assets/images/markerIcons/3.webp`,
                        direction:`${result.routes[0].legs[0].start_address}`,
                        estimated:``
                    })
                    total+=result.routes[0].legs[2].distance.value;
                    time+=result.routes[0].legs[2].duration.value;
                }
                aux.push({
                    direction: `Total: ${roundTwoDec(total/1000)} KM - ${secondsToHms(time)} aprox.`,
                    estimated:``
                })
                let auxDir = new window.google.maps.DirectionsRenderer({directions:result,suppressMarkers:true})
                auxDir.setMap(map);
                //auxDir.setPanel(div);
                setInfoRoute(aux);
                setDirectionsRenderer(auxDir);
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        })
    }
    useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {}));
        }
        if(map && !directionsRenderer){
            calcularRuta();
        }
        if (map) {
            map.setOptions(options);
        }
    }, [ref, map]);
    useEffect(() => {
        if (map) {

            ["idle"].forEach((eventName) =>
                google.maps.event.clearListeners(map, eventName)
            );

            if (onIdle) {
                map.addListener("idle", () => onIdle(map));
            }
        }
    }, [map, onIdle]);

    return (
        <>
            <div ref={ref} style={style}/>
            {
                loading ?
                    <Loading/>
                    :
                    <>
                        <DirectionPane id={'directionPane'}>
                            {
                                directionsRenderer ?
                                    infoRoute && infoRoute.length > 0 ?
                                        infoRoute.map((item, index) => {
                                            return (
                                                <>
                                                    <Row>
                                                        <ImgWrapper>
                                                            <img loading={"lazy"} width={'width: 60px;'} src={item.src}/>
                                                        </ImgWrapper>
                                                        <DirectionWrapper>
                                                            {item.direction}
                                                        </DirectionWrapper>
                                                    </Row>
                                                    <Row>
                                                        {item.estimated}
                                                    </Row>
                                                </>
                                            )
                                        })
                                        :
                                        <></>
                                    :
                                    <EmptyWrapper>No se pudo calcular la ruta.</EmptyWrapper>
                            }
                        </DirectionPane>

                    </>
            }
            {Children.map(children, (child) => {
                if (isValidElement(child)) {
                    // set the map prop on the child component
                    return cloneElement(child, {map});
                }
            })}
        </>
    );
};

const GoogleMap = ({ directionRequest }) => {
    const [zoom, setZoom] = useState(15) // initial zoom
    const [center, setCenter] = useState({
        lat: -38.416097,
        lng: -63.616672
    })
    const google = window.google
    const onIdle = (m) => {
        setZoom(m.getZoom());
        setCenter(m.getCenter().toJSON());
    };

    var mapStyle =[
        {featureType:'poi',stylers:[{visibility:'off'}]}
    ];
    return (
        <WrapperMap   >
            <Wrapper apiKey={"AIzaSyASt-NINMRlLKrAW737rajd-yZERxWyPig"} render={render}>
                <Map
                    directionRequest={directionRequest}
                    libraries= {['drawing', 'geometry', 'places', 'visualization']}
                    center={center}
                    zoom={zoom}
                    onIdle={onIdle}
                    styles={mapStyle}
                    style={{ flexGrow: "1", height: "100%", width: "100%" }}
                >
                </Map>
            </Wrapper>
        </WrapperMap>
    )
}

export default GoogleMap
