//Styled Components
import { useEffect } from 'react'
import { useState } from 'react'
import {
    PieChart,Cell,
    Pie,Legend,Tooltip
} from 'recharts'
const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
        return (
            <>
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: "#ffff",
                        padding: "5px",
                        border: "1px solid #cccc"
                    }}
                >
                    <label>{`${payload[0].name} : ${payload[0].value}`}</label>
                </div>
            </>
        )
    }else{

            return null;
        }
}
const PieChartComp = ({dataKey,nameKey, data=[],colors=[]}) => {
        const [widthChart, setWidthChart] = useState(window.innerWidth * 0.8);

        useEffect(() => {
            const updateWindowDimensions = () => {
                setWidthChart(window.innerWidth)
            }

            window.addEventListener("resize", updateWindowDimensions);

            return () => window.removeEventListener("resize", updateWindowDimensions)
        }, [window.innerWidth])
    return (
        <PieChart width={window.innerWidth * 0.85} height={window.innerHeight * 0.5}>
            <Pie
                data={data}
                color="#000000"
                dataKey={dataKey}
                nameKey={nameKey}
                cx="50%"
                cy="50%"
                outerRadius={120}
                fill="#8884d8"
            >
                {data.map((entry, index) => (
                    <Cell
                        key={`cell-${index}`}
                        fill={colors[index % colors.length]}
                    />
                ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
            <Legend />
        </PieChart>
    )
}

export default PieChartComp
