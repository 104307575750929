//React Imports
import { useState, useEffect,useMemo,useCallback } from 'react'
import { format,isBefore,isAfter } from 'date-fns';

//Styled Components
import {
    TarifaHomePage,
    TarifaHomeWrapper,
    RowTwoColumns,
    Wrapper,
    TarifaListWrapper,
    LoadingWrapper,
    Imagen,
    EmptyWrapper,
    ModalWrapper,
    Row,
    Column,Volver
} from './elements'

//Components
import Table from '../../../components/Table'
import {GiContract,GiExpand} from 'react-icons/gi';
import TablaTarifa from "../../../components/TablaTarifa";


//Context
import { useUserContext } from '../../../context/UserContext'
import Loading from '../../../components/Loading'
import { data, columns_tarifa, columns_programacion, columns_prestadores } from './data'

//service
import AdminService from '../../../services/AdminService'
import DatePicker from "../../../components/DatePicker"
import Button from "../../../components/Button"
import Modal from "../../../components/Modal"
import Message from "../../../components/Message"
import adminService from "../../../services/AdminService"
import {useNavigate} from "react-router-dom";
import {RiArrowGoBackLine} from "react-icons/ri";


const AsignarTarifa = ({ idTarifas=[],cancel = null, asignar = null}) => {
    const [loading, setLoading] = useState(true)
    const [prestadores, setPrestadores] = useState(null)
    const [selected, setSelected] = useState([])
    const { user } = useUserContext();
    const tableInitialState = {
        sortBy: [
            { desc: true, id: "cantTickets" },
        ],
    };

    const checkPage = function (){
        let aux = [ ...selected];
        let x = document.getElementById('all-check-prestadores');
        let inputList = document.getElementsByName("filaPrestadores");
        if(inputList.length === 0){
            return x.checked = false;
        }
        let counter = inputList.length;
        inputList.forEach(input =>{
            let id = input.id.split('-')[0];
            if(aux.includes(id))
                counter--;
        });
        setSelected(aux);
        x.checked = counter===0;
        x.onchange = (e)=>{
            checkAll(e,aux)
        }
    }

    const checkAll = function (event, listSelected) {
        let value = event.target.checked;
        let inputList = document.getElementsByName("filaPrestadores");
        let aux = [ ...listSelected];

        aux.forEach(sel =>{
            sel.value = value;
        })
        /*
            if(value){
                inputList.forEach(input =>{
                    let id = input.id.split('-')[0];
                    input.checked =value;
                    if(!aux.includes(id))
                        aux.push(id);
                })
            }else {
                inputList.forEach(input =>{
                    let id = input.id.split('-')[0];
                    input.checked =value;
                    aux = aux.filter(val =>val!==id);
                })
            }
         */
        setSelected(aux)
    }

    const changeSelect = function (row) {
        let idIndex = `${prestadores[row.index].id}`;
        let inputCH = document.getElementById(row.cells[4].value.props.id);
        let aux = [ ...selected];
        aux.forEach(sel =>{
            if(sel.id==idIndex)
                sel.value = inputCH.checked;
        })
        /*
            if(inputCH.checked){
                if(selected.includes(idIndex)){
                    let aux = selected.filter(elem => elem !== idIndex);
                    setSelected(aux);
                }
                inputCH.checked = false;
            }else{
                if(!selected.find(elem => elem === idIndex)){
                    let aux = [...selected];
                    aux.push(idIndex);
                    setSelected(aux);
                }
                inputCH.checked = true;
            }
         */
        setSelected(aux);
    }
    useEffect( () => {
        AdminService.getPrestadores(null,user.token,(res) => {

            if(res && Object.keys(res).length > 0){
                let aux = [];
                res.data.forEach((prestador,index) =>{
                    let x = idTarifas;
                    let flagIn = idTarifas.find(val =>parseInt(val)===prestador.grupoID) !==undefined;
                    prestador.check = <input type={'checkbox'} name={'filaPrestadores'} defaultChecked={flagIn} id={prestador.id+'-prcheck'} onChange={()=>{}} ></input>;
                    aux.push({id:prestador.id,value:flagIn});
                })
                if(!columns_prestadores.find(col => col.accessor==='check'))
                    columns_prestadores.push(
                        {
                            Header: <input type={'checkbox'} defaultChecked={false} id={"all-check-prestadores"} onChange={(e)=>{checkAll(e)}} ></input>,
                            accessor: 'check',
                        });
                setSelected(aux);
                setPrestadores(res.data)
            }
            setLoading(false);
        });
    },[])

    return(
        <>
            {
                loading ?
                    <Loading iconSize={'100px'}/>
                :
                    <>
                        <Wrapper id={"grupoList"}>
                            <div id={"tittle"}>
                                <h3>Listado de proveedores</h3>
                            </div>
                            <div id={"lista"}>
                                {
                                    prestadores && prestadores.length>0?

                                        <Table
                                            columns={columns_prestadores}
                                            data={prestadores}
                                            initialState={tableInitialState}
                                            chechAllId={checkPage}
                                            withCellBorder
                                            withRowBorder
                                            withSorting
                                            withPagination
                                            rowProps={row => ({
                                                onClick: () => changeSelect(row),
                                                style: {
                                                    cursor: "pointer"
                                                }
                                            })}/>
                                        :
                                        <EmptyWrapper>No hay prestadores en la base</EmptyWrapper>
                                }
                            </div>
                            <RowTwoColumns>
                                <Button clase={'cancelar'} isOrangeSection={true}  text={"Cancelar"} clickHandler={cancel}/>
                                <Button clase={'aceptar'}  text={"Asignar"} clickHandler={()=>asignar(selected,idTarifas)}/>
                            </RowTwoColumns>
                        </Wrapper>
                    </>
            }
            </>
    )
}

let TableModelTarifa =({rowProps=null,emptyMessage="No hay información para mostrar",filtered = [], columns, tableInitialState, checkPage,paginate=false,globalSearch=false, renderRowSubComponent})=>{

    return(
        <>
            {
                filtered.length>0 ?
                    checkPage?
                        <Table
                            columns={columns}
                            data={filtered}
                            initialState={tableInitialState}
                            chechAllId={checkPage}
                            withCellBorder
                            withRowBorder
                            withSorting
                            withPagination
                            paginate={paginate}
                            rowProps={rowProps}
                            renderRowSubComponent={renderRowSubComponent}/>
                        :
                        <Table
                            columns={columns}
                            data={filtered}
                            initialState={tableInitialState}
                            withCellBorder
                            withRowBorder
                            withSorting
                            withPagination
                            paginate={paginate}
                            globalSearch={globalSearch}
                            renderRowSubComponent={renderRowSubComponent}/>

                    :
                    <EmptyWrapper>{emptyMessage}</EmptyWrapper>
            }
        </>
    )
}
const Tarifa =  () => {
    const columns = useMemo(
        () => [
            {
                // Build our expander column
                id: 'expander', // Make sure it has an ID
                Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
                    <span {...getToggleAllRowsExpandedProps()}>
                {isAllRowsExpanded ? <GiContract/> : <GiExpand/>}
                    </span>
                ),
                minWidth: 50,
                width: 50,
                maxWidth: 50,
                Cell: ({ row }) => {
                    // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                    // to build the toggle for expanding a row
                    return (
                        <span
                            {...row.getToggleRowExpandedProps({
                                style: {
                                    // We can even use the row.depth property
                                    // and paddingLeft to indicate the depth
                                    // of the row
                                    paddingLeft: `${row.depth * 2}rem`,
                                },
                            })}
                        >
                          {row.isExpanded ?<GiContract/> : <GiExpand/>}
                        </span>
                    )
                }
            },
            {
                Header: 'Nombre',
                accessor: 'nombre',
            },
            {
                Header: 'Descripcion',
                accessor: 'descripcion',
            },
            {
                Header: 'Desde',
                accessor: 'fechaInicioSTR',
            },
            {
                Header: 'Hasta',
                accessor: 'fechaFinSTR',
            },
        ],
        []
    )

    const [loading, setLoading] = useState(true)
    const { user } = useUserContext()
    const [grupo, setGrupo] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [date, setDate] = useState(new Date());
    const [filters, setFilters] = useState({
        tipo: "TODOS",
        tipoVehiculo: "TODOS",
    })
    const [selected, setSelected] = useState([])
    const [showAsignar, setShowAsignar] = useState(false)
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const navigate = useNavigate();


    const tableInitialState = {
        sortBy: [
            { desc: false, id: "tipo" },
        ],
      };

    const checkPage = function (){
        return;
        let aux = [ ...selected];
        let x = document.getElementById('all-check');
        let inputList = document.getElementsByName("grupoRow");
        if(inputList.length === 0){
            return x.checked = false;
        }
        let counter = inputList.length;
        inputList.forEach(input =>{
            let id = input.id;
            if(aux.includes(id)) {
                input.checked = true;
                counter--;
            }
        });
        setSelected(aux);
        x.checked = counter===0;
        x.onchange = (e)=>{
            checkAll(e,aux)
        }

    }
    const checkAll = function (event,listSelected) {
        let value = event.target.checked;
        let inputList = document.getElementsByName("grupoRow");
        let aux = [ ...listSelected];

        if(value){
            inputList.forEach(input =>{
                let id = input.id;
                input.checked =value;
                if(!aux.includes(id))
                    aux.push(id);
            })
        }else {
            inputList.forEach(input =>{
                let id = input.id;
                input.checked =value;
                aux = aux.filter(val =>val!==id);
            })
        }
        setSelected(aux);
    }
    const openAsignar = function (event) {

        if(selected.length === 0 || selected.length > 1){
            setError("Debe seleccionar un grupo tarifario")
        }else
            setShowAsignar(true);
    }
    const asignarTarifas = async function (prIds,gruposIds) {
        if(prIds.length===0 || gruposIds===0){
            return setError("Debe seleccionar al menos un prestador");
        }

        let res = await adminService.asignarTarifas({ prestadores:prIds,grupos:gruposIds },user.token)
        if (res.status === 'SUCCESS') {
            setSuccess(`Se asignaron las ${gruposIds.length} grupos a ${prIds.length} prestadores.`);
        } else {
            setError( res.message);
        }

    }

    const changeSelect = function (event) {
        let idIndex = event.target.id;

        let inputCH = event.target;

        if(inputCH.checked){
            if(!selected.find(elem => elem === idIndex)){
                let aux = [...selected];
                aux.push(idIndex);
                setSelected(aux);
            }
        }else{
            if(selected.includes(idIndex)){
                let aux = selected.filter(elem => elem !== idIndex);
                setSelected(aux);
            }
        }
    }
    useEffect(()=>{

        let aux = [...grupo];
        if(aux.length > 0 && filters.tipo!=="TODOS")
            aux = aux.filter(grupo=>{return grupo.tipo===filters.tipo});
        if(aux.length > 0 && filters.tipoVehiculo!=="TODOS")
            aux = aux.filter(grupo=>{return grupo.tipoVehiculo===filters.tipoVehiculo});
        setFiltered(aux);

    },[filters])
    useEffect(()=>{

        let aux = [...grupo];
        if(aux.length > 0)
            aux = aux.filter(grupo=>{return isBefore(grupo.fechaInicio,date) && isAfter(grupo.fechaFin,date)});
        setFiltered(aux);

    },[date])

    useEffect( () => {

        AdminService.getTarifaWithFilters(user.token,(grupos) => {
            if(grupos && grupos.length > 0){

                if(!columns.find(col => col.accessor==='check'))
                    columns.push(
                        {
                            Header: '',//<input type={'checkbox'} defaultChecked={false} id={"all-check"}  ></input>
                            accessor: 'check',
                        });
                grupos.forEach((grupo,index) =>{
                    grupo.isExpanded = false;
                    let progComun,progEsp;
                    grupo.programacion.forEach( prog => prog.tipo==='COMUN'? progComun=prog:progEsp=prog);
                    grupo.tarifa.forEach(tarifa => tarifa.programacion = tarifa.tipo === 'COMUN'? progComun: progEsp);
                    grupo.fechaInicio = new Date(grupo.fechaInicio);
                    grupo.fechaFin = new Date(grupo.fechaFin);
                    grupo.fechaInicioSTR = format(grupo.fechaInicio, "dd/MM/yyyy");
                    grupo.fechaFinSTR = format(grupo.fechaFin, "dd/MM/yyyy");
                    grupo.check = <input type={'radio'} name={'grupoRow'} defaultChecked={selected.find(elem => elem === grupo.id)!==undefined} id={grupo.id} onChange={(e)=>{changeSelect(e)}} ></input>;
                });
                setGrupo(grupos);
                setFiltered(grupos);
            }
            setLoading(false);
        }).catch(err=>setLoading(false));
    },[])
    function prettyHours(dayProg) {
        if(!dayProg){
            return '';
        }
        let arr = dayProg.split(' | ');
        let string = '';
        for(let i = 0 ; i < arr.length ; i++){
            let schedule = arr[i];
            if( schedule.length===11){
                 string += schedule.slice(0,2)+':'+schedule.slice(2,9)+':'+schedule.slice(9,11)
            }
            if(arr.length-1 > i && 0 <= i)
                string += ' y ';
        }
        return string;
    }
    const renderRowSubComponent =  useCallback(
        ({ row }) => {
            return row.original.nombre?
                (
                    <TableModelTarifa filtered={row.original.tarifa} columns={columns_tarifa} renderRowSubComponent={renderRowSubComponent}/>
                )
                :
                (<pre
                    style={{
                        fontSize: '10px',
                        }}
                        >
                        <Row>
                            <Column>
                                <Row><strong>Lunes: </strong>{ prettyHours(row.original.programacion.lunes) }</Row>
                                <Row><strong>Martes: </strong>{prettyHours(row.original.programacion.martes) }</Row>
                                <Row><strong>Miercoles: </strong>{prettyHours(row.original.programacion.miercoles) }</Row>
                                <Row><strong>Jueves: </strong>{prettyHours(row.original.programacion.jueves) }</Row>
                            </Column>
                            <Column>
                                <Row><strong>Viernes:  </strong>{prettyHours(row.original.programacion.viernes) }</Row>
                                <Row><strong>Sabado:  </strong>{prettyHours(row.original.programacion.sabado) }</Row>
                                <Row><strong>Domingo:  </strong>{prettyHours(row.original.programacion.domingo) }</Row>
                                <Row><strong>Feriados:  </strong>{prettyHours(row.original.programacion.feriados) }</Row>
                            </Column>
                        </Row>
                </pre>
                )
        },
        []
    )

    const editar = () => {
        if(selected.length > 0)
            navigate(`./alta/${selected[0]}`)
    }

    //const data = useMemo(() => makeData(5, 5, 5), [])
    return (
        <>
            <TarifaHomePage>
                <Volver to={'/portal/admin'}>
                    <RiArrowGoBackLine/>
                </Volver>
                <Imagen className={"superior"} src={`${process.env.PUBLIC_URL}/assets/images/huella clara.webp`} alt={'image'}/>
                {
                    loading?
                        <LoadingWrapper>
                            <Loading iconSize={'100px'}></Loading>
                        </LoadingWrapper>
                    :
                        <TarifaHomeWrapper>
                            <TarifaListWrapper id={"grupoList"}>
                                <div id={"tittle"}>
                                    <h3>Listado de proveedores</h3>
                                    <hr/>
                                    <Button text={'+'} clickHandler={() => navigate('./alta')}/>
                                </div>
                                <div className='date'>
                                    <DatePicker
                                        title={"Vigente en:"}
                                        datePicker={date}
                                        setDatePicker={setDate}
                                        dateFormat="dd/MM/yyyy"
                                        showMonthYearPicker
                                    />
                                </div>
                                <div id={"lista"}>
                                    {
                                        filtered.length>0?

                                            <TableModelTarifa
                                                columns={columns}
                                                filtered={filtered}
                                                initialState={tableInitialState}
                                                paginate={true}
                                                globalSearch={true}
                                                checkPage={checkPage}
                                                rowProps={row => ({
                                                    onClick: () => {}
                                                })}
                                                renderRowSubComponent={renderRowSubComponent}/>
                                            :
                                            <EmptyWrapper>No hay grupos disponibles.</EmptyWrapper>
                                    }
                                </div>
                                <Button clase={'submit-button'} text={"Asignar grupo"} clickHandler={()=>openAsignar(true)}/>
                                <Button clase={'submit-button'} text={"Editar"} clickHandler={()=>editar()}/>
                            </TarifaListWrapper>
                            {
                                showAsignar ?
                                    <Modal id={'asignar'} tittle={'Asignar grupo'} width={'95%'} setFlag={false}>
                                        <AsignarTarifa idTarifas={selected} asignar={asignarTarifas} cancel={()=>{setShowAsignar(false)}}/>
                                    </Modal>
                                    :
                                    ''
                            }
                        </TarifaHomeWrapper>
                }
            </TarifaHomePage>

            <ModalWrapper>

                {
                    error ?
                        <Message mensaje={error} action={
                            () => {
                                setError(null)
                            }
                        }
                        />
                        :
                        ""
                }
                {
                    success ?
                        <Message type={'success'} mensaje={success} action={
                            () => {
                                setSuccess(null)
                            }
                        }
                        />
                        :
                        ""
                }
            </ModalWrapper>
        </>
    )
}

export default Tarifa
