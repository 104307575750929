export const data = [
    {
        "prestadores": [
            {
                "catTickets": "20",
                "nombre": "Juan Romano",
                "username": "jromano",
                "calificacion": "5",
                "id": "9",
                "localidad":"Buenos Aires",

            },
            {
                "catTickets": "10",
                "nombre": "Nicolas de Miguel",
                "username": "ndemiguel",
                "calificacion": "3",
                "id": "10",
                "localidad":"Buenos Aires",

            },
            {
                "catTickets": "10",
                "nombre": "Juan Pablo Fernandez",
                "username": "jpfernandez",
                "calificacion": "4",
                "id": "11",
                "localidad":"Buenos Aires",
            },
        ]
    }
]

export const columns = [
    {
        Header: '#Base',
        accessor: 'cantBase'
    },
    {
        Header: '#Pendientes',
        accessor: 'cantPendiente',
    },
    {
        Header: '#Aprobados',
        accessor: 'cantAprobado'
    },
    {
        Header: '#Rechazados',
        accessor: 'cantRechazado'
    },
    {
        Header: 'Nombre',
        accessor: 'nombre'
    },
    {
        Header: 'Mail',
        accessor: 'mail'
    },
]
