//React imports
import {
    useState,
    useEffect,
    useRef
} from 'react'

//Styled Components
import {
    SearcherWrapper,
    SearcherInput,
    Result,
    ItemResult
} from './elements'

//hooks
import { useOnClickOutside } from '../../hooks/useOnClickOutside'

const Searcher = ({ text = null, add, fieldName, listValues, placeholder = '', inputDefaultValue = "", textColor, valueFromList = false, data = null }) => {
    const [value, setValue] = useState(inputDefaultValue)
    const [filtered, setFiltered] = useState(listValues)
    const [display, setDisplay] = useState(false)
    const container = useRef()
    useOnClickOutside(container, () => setDisplay(false));


    const handleChange = (e) => {
        let val = e.target.value
        if(val !== "" && listValues && listValues.length > 0)
        {
            setFiltered(
                listValues.filter(obj => `${obj.nombre}`.toLowerCase().includes(val.toLowerCase()))
            )
            
            if(valueFromList && filtered.length === 1 && e && e.nativeEvent.inputType !== "deleteContentBackward")
            {
                add(filtered[0], fieldName)
                setValue(filtered[0].nombre)
            }
            else
            {
                let obj = {
                    nombre:val,
                    id:null
                }
                add(obj, fieldName)
                setValue(val)
            }
        }
        else
        {
            setValue(val)
        }
    }

    const handleAdd = (e, obj, name)=> {
        e.preventDefault()

        setDisplay(false)
        add(obj, fieldName)
        setValue(name)

        setFiltered(
            listValues.filter(obj => `${obj.nombre}`.toLowerCase().includes(name.toLowerCase()))
        )
    }

    useEffect(() => {
        if(listValues && listValues.length <= 0)
            setValue("")
    }, [listValues])

    useEffect(() => {
        if(inputDefaultValue)
            setValue(inputDefaultValue)
        if(data && data[fieldName])
            setValue(data[fieldName])
    }, [inputDefaultValue])

    return (
        <SearcherWrapper 
            ref={container}
        >
            {
                text?
                    <p>{text}</p>
                :
                    ""
            }
            <SearcherInput color={textColor}>
                {
                    listValues && listValues.length <= 0?
                        <input 
                            value={""} 
                            type={'text'} 
                            onClick={() => setDisplay(true)}
                            onChange={(e) => handleChange(e)} 
                            placeholder={placeholder} 
                            readOnly 
                            autoComplete="nope"
                        />
                    :
                        <input 
                            value={value} 
                            type={'text'} 
                            onClick={() => setDisplay(true)}
                            onChange={(e) => handleChange(e)} 
                            placeholder={placeholder} 
                            autoComplete="nope"
                        />
                }
            </SearcherInput>
            {
                filtered.length > 0 && display
                ?
                    <Result>
                        {
                            filtered.map((obj, index) => {
                                let name = `${obj.nombre}${obj.apellido? " " + obj.apellido : ''}`
                                return (
                                    <ItemResult key={index} id={index} onClick={(e) => handleAdd(e, obj, name)}>
                                        <p>{name.trim()}</p>
                                    </ItemResult>
                                )
                            })
                        }
                    </Result>
                :
                    ""
            }
        </SearcherWrapper>
    )
}

export default Searcher
