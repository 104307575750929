//React imports
import {
    useState,
    useEffect,
    useRef
} from 'react'

//Styled Componentsx
import {
    Wrapper,
    Options,
    Item,
    DropDown
} from './elements'

//hooks
import { useOnClickOutside } from '../../hooks/useOnClickOutside'

const StyledSelect = ({ options, id, data, setter, className, selectedOption = true }) => {
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState(options[0])
    const container = useRef()
    useOnClickOutside(container, () => setOpen(false));

    
    const onClickHandler = (index) => {
        setSelected(options[index])
        setter({
            ...data,
            [id]: options[index]
        })
    }

    useEffect(() => {
        if(selectedOption)
        {
            if(data[id])
            {
                setSelected(data[id])
            }
            else
            {
                setter({
                    ...data,
                    [id]: options[0]
                })
            }
        }
    }, [])

    return (
        <Wrapper ref={container} className={`select ${className}`} onClick={() => setOpen(!open)}>
            <span>{selected}</span>
            <DropDown/>
            {
                open ?
                    <Options>
                        {
                        options.map((element, index) => {
                            let result = null

                            if(element === selected)
                            {
                                result = <Item className='active' onClick={() => onClickHandler(index)}>
                                            <span>{element}</span>
                                        </Item>
                            }
                            else
                            {
                                result = <Item onClick={() => onClickHandler(index)}>
                                            <span>{element}</span>
                                        </Item>
                            }
                            return result
                        })
                        }
                    </Options>
                :
                    <></>
            }
        </Wrapper>
    )
}

export default StyledSelect