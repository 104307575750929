import styled from "styled-components"

export const LogoWrapper = styled.div`
    width: 100%;
    height: 100%;
    align-self: center;
    justify-self: center;
    background-color: ${(props) => props.backgroundColor ? props.backgroundColor : 'white'};

    & img{
        width: 100%;
        height: 100%;
    }
`