
const {token_LIBRA, url} = require('./constantes');


async function getFilters(modelos,then) {
    try{
         await fetch(`${url}/autos/getFiltros`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+ token_LIBRA
            }
        }).then(res => {
            return res.json()
        }).catch(res => {
            return res
        }).then(then);
    }catch (e){
        //console.log(e);
    }
}


async function getById(id, callback) {
    try{
        await fetch(`${url}/modelos/${id}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+ token_LIBRA
            },
        }).then(res => {
            return res.json()
        }).catch(res => {
            return res.json()
        }).then((json) => callback(json))
    }catch (e){
        //console.log(e);
    }
}

// send `POST` request
const AutoService = {
    getFilters,
    getById
};


export default AutoService
