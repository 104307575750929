import styled from 'styled-components'

export const StyledInput = styled.input` 
    width: 100%;
    border: none;
    border-bottom: 2px solid;
    border-color: ${({ color }) => color ? color : ' #e95612'};
    outline: none;
    font-size: 18px;
    color: #000000;
    &::placeholder{
        color:${({ color }) => color ? color : ' #e95612'};
    }

  &::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type=number] {
    -moz-appearance: textfield;
  }
`

export const Wrapper = styled.div`
    width: ${({ width }) => width ? width : '100%'}; 
    color: ${({ color }) => color ? color : ' #e95612'};
    text-align:  center;
`
export const RadioGroupWrapper = styled.label`
  float: left;
  vertical-align: middle;
  
  input[type='radio']{   
    float: left;
    clear: left;
    width: 50px;
    line-height: 20px;

 }
`