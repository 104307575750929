//React
import { useEffect, useState, useRef } from 'react';

//Styled Element
import {
    FlexColumn,
    FlexRow,
    Wrapper,
    Button,
    ModalFormWrapper,
    FormWrapper,
    Edit,
    FormItem, Detail
} from './elements'


import Modal from "../Modal";
import MultiFileUpload from "../MultiFileUpload";
import FileUpload from "../FileUpload";
import GoogleMap from "../GoogleDirections";
import {Accept, Reject, Map, MapDisabled, OptionsButtons} from "../Novedad/elements";
import gruerosAppService from "../../services/GruerosAppService";
import {ButtonWrapper} from "../../pages/HomeGrueros/elements";
import {useUserContext} from "../../context/UserContext";
import {ModalEdit,ModalDetail,ModalMessage} from './auxComponents'
import Input from '../Input';
import Tooltip from '../Tooltip';
//Contexts
import useWindowDimensions from "../../hooks/windowDimiensions";
import {usePortalTicketContext} from "../../context/PortalTicketContext";

const ESTADOS= {
    0:"Pendiente",
    1:"Aprobado",
    2:"Rechazado",
}
/*
mapa direccion distinta
<FormItem>
    <h5>kilomentros Extras, seleccione el nuevo destino:</h5>
    <MapDestination setAddress={(address) => setAdicionales({...adicionales, direccion: address})}/>
</FormItem>
 */
const Service = ({ nombre = '', editable = false, ticket, admin = false, base = null}) => {
    const [files, setFiles] = useState([])
    const [mapModal, setMapModal] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [edit, setEdit] = useState(false)
    const [adicionales, setAdicionales] = useState({
        kmRipioNieveState: false,
        movidaState: false
    })
    const [detail, setDetail] = useState(false)
    const {user} = useUserContext();
    const {width} = useWindowDimensions();

    const {
         setSuccess,setError
    } = usePortalTicketContext()
    const showPopUp = () => {
        setMapModal(!mapModal);
    }
    function roundTwoDec(total) {
        return  (Math.round((total + Number.EPSILON) * 100) / 100).toLocaleString();
    }
    const aceptService = async () => {
        setShowConfirm(false);
        let adarray = [];

        if(adicionales.kmRipioNieveState && adicionales.kmRipioNieve){
            if( adicionales.kmRipioNieve && adicionales.kmRipioNieve !== ''){
                adarray.push({
                    servicioID:ticket.id,
                    codigo:'KM Ripio/nieve',
                    valor_crm:'SI',
                    valor_api:parseInt(adicionales.kmRipioNieve)
                })
            }

        }
        if(adicionales.peajesCant ){
            let x = parseInt(adicionales.peajesCant);
            let vapi=[];
            for(let i = 1; i <=x; i ++){
                let value = adicionales[`peaje${i}`];
                if(value===undefined || value===''){
                    setError('Debe completar todos los valores de peaje o disminuir la cantidad.');
                    return;
                }
                vapi.push(value)
            }
            adarray.push({
                servicioID:ticket.id,
                codigo:'Peajes',
                valor_crm:x,
                valor_api:vapi.join('|')
            })
        }
        let x = document.getElementById("observaciones").value;
        let values ={
            id:ticket.id,
            observaciones: x,
            value:1,
            adicionales:adarray,
            archivo:files
        }
        let res = await gruerosAppService.changeState(values,user.token)
        if (res.status === 'SUCCESS') {
            setFiles([])
            setSuccess('Servicio aceptado.')
        } else {
            setError("Ocurrió un error inesperado: " + res.message);
        }
    }

    const rejectService = async () => {
        setShowConfirm(false);
        let rechazo = {
            value:document.getElementById("value").value,
            observacion:document.getElementById("observaciones").value,
            categoria:document.getElementById("categoria").value
        }
        let res = await gruerosAppService.changeState({id:ticket.id,archivo:files,observaciones: rechazo.observacion,rechazo: JSON.stringify(rechazo), value: 2},user.token)
        if (res.status === 'SUCCESS') {
            setFiles([])
            setSuccess('Se guardo el servicio rechazado.')
        } else {
            setError("Ocurrió un error inesperado: " + res.message);
        }
    }
    const peajes = () => {
        let inputPeajes = []

        if(adicionales.peajesCant && adicionales.peajesCant > 0)
        {
            for(let i = 0; i < adicionales.peajesCant; i++)
            {
                inputPeajes.push(
                    <Input key={i} type={'number'} noNegative={true} values={adicionales} idValue={`peaje${i + 1}`} changeValues={setAdicionales} placeholder={`Valor Peaje ${i + 1}`}/>
                )
            }
        }
        else
            inputPeajes = ''
        return inputPeajes
    }

    const onUpload = (faux) => {
        let x =[...files];
        for(let i = 0; i < faux.length; i++){
            if(x.find(file => file.name===faux[i].name) === undefined)
                x.push(faux[i])
        }
        setFiles(x);
    };
    const onRemove = (index) => {
        let x =[...files];
        x.splice(index,1);
        setFiles(x);
    };
    return (
    <>
        {
            edit?
                <ModalEdit ticket={ticket} close={()=>setEdit(!edit)}/>
            :
                ''
        }
        {
            detail?
                <ModalDetail admin={admin} ticket={ticket} close={()=>setDetail(!detail)}/>
            :
                ''
        }
        <Wrapper>
            {
                showButtons?
                    <OptionsButtons>
                        <Tooltip tooltip={'Ver detalle'}>
                            <Detail onClick={()=>setDetail(!detail)} />
                        </Tooltip>
                        {
                            admin?
                                <Tooltip tooltip={'Editar'}>
                                    <Edit onClick={()=>setEdit(!edit)}/>
                                </Tooltip>
                                :
                                ''
                        }
                        {
                            ticket.directionRequest === null?

                                <Tooltip tooltip={ticket.servicio==='Mecanica Ligera'? 'Servicio sin recorrido':'Error en una dirección'}>
                                    <MapDisabled/>
                                </Tooltip>
                                :
                                <Tooltip tooltip={'Ver Mapa'}>
                                    <Map onClick={showPopUp}/>
                                </Tooltip>
                        }
                        {
                            editable?
                                    <>
                                        <Tooltip tooltip={'Confirmar'}>
                                            <Accept onClick={()=>setShowConfirm("aceptar")}/>
                                        </Tooltip>
                                        <Tooltip tooltip={'Rechazar'}>
                                            <Reject onClick={()=>setShowConfirm("error")}/>
                                        </Tooltip>
                                    </>
                                :
                                    <>
                                    </>
                        }
                    </OptionsButtons>
                    :
                    ''
            }
            <FlexColumn onClick={()=>{setShowButtons(true);setTimeout(function(){setShowButtons(false)}, 2500);}}>
                {
                    width > 500?
                    <>
                        <FlexRow>
                            <div className={ESTADOS[ticket.estado]}>
                                <strong>Estado: </strong><span>{ESTADOS[ticket.estado] }</span>
                            </div>
                            <div>
                                <strong>Total: </strong>{roundTwoDec(ticket.precio+ticket.precio_adicional)}$
                            </div>
                        </FlexRow>
                        <FlexRow>
                            <div style={{display: 'flex',flexDirection:'column'}}>
                                <Tooltip tooltip={'Fecha del servicio'}><strong>{ticket.fecha}</strong></Tooltip>
                                <Tooltip tooltip={'Fecha de solicitud'}><strong>{ticket.fecha_original}</strong></Tooltip>
                            </div>
                            <div className={"textCenter"}>
                                <Tooltip tooltip={'Número de ticket'}><strong>  {ticket.numero} </strong></Tooltip>
                                <Tooltip tooltip={'Patente del cliente'}><strong>  {ticket.patente} </strong></Tooltip>
                            </div>
                            <div>
                                <strong>KM: </strong>{ticket.totalKM}
                            </div>
                        </FlexRow>
                        <div>
                            <strong>Desde: </strong>{ticket.desde}
                        </div>
                        <div>
                            <strong>Hasta: </strong>{ticket.hasta}
                        </div>
                        {
                            ticket.soloAdicionales?
                                <div className='adicionalesComputer'>
                                    <strong>Solo Adicionales (No mapa de ruta) </strong>
                                </div>
                            :
                                ''
                        }
                    </>
                    :
                    <>
                        <div className={ESTADOS[ticket.estado]}>
                            <strong>Estado: </strong><span>{ESTADOS[ticket.estado] }</span>
                        </div>
                        <div>
                            <Tooltip tooltip={'Fecha del servicio'}><strong>{ticket.fecha}</strong></Tooltip>
                            <Tooltip tooltip={'Fecha de solicitud'}><strong>{ticket.fecha_original}</strong></Tooltip>

                        </div>
                        <div className={"textCenter"}>
                            <Tooltip tooltip={'Número de ticket'}><strong>  {ticket.numero} </strong></Tooltip>
                            <Tooltip tooltip={'Patente del cliente'}><strong>  {ticket.patente} </strong></Tooltip>
                        </div>
                        <FlexRow>
                            <div>
                                <strong>Total: </strong>{ticket.precio+ticket.precio_adicional}$
                            </div>
                            <div>
                                <strong>KM: </strong>{ticket.totalKM}
                            </div>
                        </FlexRow>
                        <div>
                            <strong>Desde: </strong>{ticket.desde}
                        </div>
                        <div>
                            <strong>Hasta: </strong>{ticket.hasta}
                        </div>
                        {
                            ticket.soloAdicionales?
                                <div className='adicionales'>
                                    <strong>Solo Adicionales (No mapa de ruta) </strong>
                                </div>
                            :
                                ''
                        }
                    </>

                }
            </FlexColumn>

        </Wrapper>
        {
            mapModal?
                <Modal id={'map'} tittle={`Ticket: ${ticket.numero}  Proveedor: ${nombre}`} width={'90%'} setFlag={showPopUp}>
                    <GoogleMap directionRequest={ticket.directionRequest}/>
                </Modal>
                :
                ''
        }
        {
            showConfirm === 'aceptar'?
                <Modal id={'confirm'} tittle={'Confirmar servicio'} width={'70%'} setFlag={false}>
                    <ModalFormWrapper>
                        <FormWrapper>
                            <h3>Adicionales</h3>
                            <FormItem>
                                <h5>Peajes:</h5>
                                <Input type={'number'} max={5} noNegative={true} values={adicionales} idValue={'peajesCant'} changeValues={setAdicionales} placeholder={'Seleccione la cantidad de peajes'}/>
                                {
                                    peajes()
                                }
                            </FormItem>
                            <FormItem>
                                {
                                    adicionales.kmRipioNieveState?
                                        <Input type={'number'} noNegative={true} values={adicionales} idValue={'kmRipioNieve'} changeValues={setAdicionales} placeholder={'Ingrese los kilometros'}/>
                                        :
                                        <Button className='classic' onClick={() => setAdicionales({...adicionales, kmRipioNieveState: true})}>KM x Ripio/Nieve</Button>
                                }
                            </FormItem>
                            <FormItem>
                                <h5>Comprobantes:</h5>
                                <span>Puede seleccionar más de un archivo</span>
                                <MultiFileUpload
                                    multiple={'multiple'}
                                    files={files}
                                    updateFilesCb={onUpload} removeFile={onRemove}
                                />
                            </FormItem>

                            <h3>Observaciones</h3>
                            <FormItem>
                                <textarea type="textArea" id={"observaciones"}/>
                            </FormItem>
                            <ButtonWrapper>
                                <Button onClick={()=>setShowConfirm(false)} cancelar={true} isOrangeSection={false}>Cancelar</Button>
                                <Button onClick={aceptService} isOrangeSection={true}>Aceptar</Button>
                            </ButtonWrapper>
                        </FormWrapper>
                    </ModalFormWrapper>
                </Modal>
                :
                ''
        }
        {
            showConfirm === "error"?
                <Modal id={'reject'} tittle={'Rechazar servicio'} width={'70%'} setFlag={false}>
                    <ModalFormWrapper>
                        <FormWrapper>
                            <div  className={'row'}>
                                <span>Puede seleccionar más de un archivo</span>
                                <MultiFileUpload
                                    multiple={'multiple'}
                                    files={files}
                                    updateFilesCb={onUpload} removeFile={onRemove}
                                />
                            </div>

                            <div className={'row'}>
                                <h5>Categoria</h5>
                                <select name="categoria" id="categoria">
                                    <option value="Faltan kilomentros" selected>Faltan kilomentros</option>
                                    <option value="Faltan adicionales">Faltan adicionales</option>
                                    <option value="Destino diferente">Destino diferente</option>
                                    <option value="Origen diferente">Origen diferente</option>
                                    <option value="Tarifa incorrecta">Tarifa incorrecta</option>
                                    <option value="Otro">Otro</option>
                                </select>
                            </div>
                            <div className={'row'}>
                                <h5>Valor (diferencia en KM)</h5>
                                <input type="number" id={"value"}/>
                            </div>
                            <div className={'row'}>
                                <h5>Observaciones</h5>
                                <textarea type="textArea" id={"observaciones"}/>
                            </div>
                            <ButtonWrapper>
                                <Button onClick={()=>setShowConfirm(false)} cancelar={true} isOrangeSection={false}>Cancelar</Button>
                                <Button className={'reject'} onClick={rejectService} isOrangeSection={true}>Rechazar</Button>
                            </ButtonWrapper>
                        </FormWrapper>
                    </ModalFormWrapper>
                </Modal>
                :
                ''
        }
    </>
  )
}

export default Service
