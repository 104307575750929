//React
import { useState, useEffect } from "react"

//Styled Components
import styled from "styled-components"
import {
    AiFillCloseCircle,
    AiOutlineCloseCircle,
    AiOutlineCheckCircle
} from "react-icons/ai";
import {
    MdErrorOutline
} from "react-icons/md";

//Components
import GoogleMap from '../../components/GoogleMap'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Loading from '../../components/Loading'

//Context
import { usePortalTicketContext } from '../../context/PortalTicketContext'
import adminService from "../../services/AdminService";
import {useUserContext} from "../../context/UserContext";
import {useParams} from "react-router-dom";
import gruerosAppService from "../../services/GruerosAppService";
import DatePicker from "../DatePicker";



const Overlay = styled.div`
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 222;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Modal = styled.div`
    width: 90vw;
    height: 80vh;
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    position: relative;
    padding: 50px 50px;
    & #details{
      & p,span{
        text-align: end;
      }
    }
    h1{
        color: #e95612;
        margin: 10px 0;
    }

    h2{
        color: #e95612;
        margin: 10px 0;
    }

  @media screen and (max-width: 500px){
    padding: 31px 5px 0;
    width: 100%;
    height: 90%;
    border-radius: 0;
    margin-top: 85px;
    &ItemModal{
      margin: 5px;
    }
  }
`

const ItemModal = styled.div`
    width: 100%; 
    display: ${props => props.id==='imagenes'? 'none':'flex'};
    flex-direction: column;
    align-items: flex-start;
    min-width: 320px;
    padding: 15px 15px;
    margin-bottom: 25px;
    border: dashed 1px #e95612;
    
    & .value{
      justify-content: space-between;
    }
    & .map{
        min-height: 500px;
    }

  @media screen and (max-width: 500px){
    min-width: auto;
    margin: 5px;
  }
`

const Row = styled.div`
    width: 100%;
    display: flex; 
    gap: 30px;
    .rechazo h4{
      width: 200px;
    }
  @media screen and (max-width: 1020px) {
    flex-wrap: wrap;
  }
`

const ImagesRow = styled.div`
    width: 100% !important;
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-around;
    gap: 30px;  
    img, embed{
      max-width: 100%; 
    }
`

const DataModal = styled.div`
    width: 100%;
    display: flex;
    color: #e95612;
    margin: 10px 0;
    padding: 0 25px;
    align-items: center;
    & .column{
      display: flex;
      flex-direction: column;
    }
    p{
        width: calc(100% - 150px);
        color: #000;
    }

    h4{
        width: 150px;
    }
 

    & input, select{
        width: calc(100% - 150px);
        padding: 10px 0 !important;
        border: none;
        border-bottom: 2px solid #e95612;
        color: #000;
        font-size: 18px;
        outline: none;

        & option{
            color: black;
        }
    }
    & .datePickerWrapper{
      width: auto;
      & input{
        text-align: center;
      }
    }
    & input{
        width: calc(100%);
    }
  input[type=range] {
    height: 34px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 11px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 1px 1px 1px #000000;
    background: #74A9D8;
    border-radius: 1px;
    border: 0px solid #010101;
  }
  input[type=range]::-webkit-slider-thumb {
    box-shadow: 1px 1px 1px #000031;
    border: 1px solid #00001E;
    height: 26px;
    width: 26px;
    border-radius: 15px;
    background: #FFFFFF;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -8px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #74A9D8;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 11px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 1px 1px 1px #000000;
    background: #74A9D8;
    border-radius: 1px;
    border: 0px solid #010101;
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000031;
    border: 1px solid #00001E;
    height: 26px;
    width: 26px;
    border-radius: 15px;
    background: #FFFFFF;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 11px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #74A9D8;
    border: 0px solid #010101;
    border-radius: 2px;
    box-shadow: 1px 1px 1px #000000;
  }
  input[type=range]::-ms-fill-upper {
    background: #74A9D8;
    border: 0px solid #010101;
    border-radius: 2px;
    box-shadow: 1px 1px 1px #000000;
  }
  input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 1px 1px 1px #000031;
    border: 1px solid #00001E;
    height: 26px;
    width: 26px;
    border-radius: 15px;
    background: #FFFFFF;
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #74A9D8;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #74A9D8;
  }

    @media screen and (max-width: 720px){

        padding: 0;
        align-items: flex-start;
        flex-direction: column;
        gap: 10px;
        p{
            width: 100%;
        } 
        h4{
            width: 100%;
        }
 
        & input, select{
            width: 100%;
        }
    }
`

const FixedWrapper = styled.div`
    position: absolute;
    top: 10px;
    right: 45px;
`

const IconClose = styled(AiFillCloseCircle)`
    position: absolute;
    font-size: 2rem;
    color: #e95612;
    cursor: pointer;
`
const Error = styled(AiOutlineCloseCircle)`
  position: fixed;
  font-size: 2rem;
  color: #ff0000;
  cursor: pointer;
`
const Success = styled(AiOutlineCheckCircle)`
  position: fixed;
  font-size: 2rem;
  color: #05af05;
  cursor: pointer;
`
const Message = styled(MdErrorOutline)`
  position: fixed;
  font-size: 2rem;
  color: #80b4de;
  cursor: pointer;
`

export const ModalEdit = ({ ticket, close }) => {
    const [data, setData] = useState({
        base: ticket.baseSTR,
        prestadorID: ticket.prestadorID,
        idTicket: ticket.id,
        estado: ticket.estado,
        fecha: new Date(ticket.fecha),
        excedenteKM: ticket.excedenteKM,
        tipo_tarifa: ticket.tipo,
        vehiculo_tarifa: ticket.tipo_vehiculo,
        adicional_tarifa: ticket.adicionales,
        totalKM: ticket.totalKM
    })
    const [basesEdit,setbasesEdit] = useState([])
    const [loading, setLoading] = useState(true)
    const [prestadores, setPrestadores] = useState([])
    var { id } = useParams();
    const {
        editServicio,filters
    } = usePortalTicketContext()
    const {
        user
    } = useUserContext()

    /*const google = window.google


    function roundTwoDec(total) {
        return  Math.round((total + Number.EPSILON) * 100) / 100;
    }

    const calcularRuta = () => {
        if(desde.coordenadas && hasta.coordenadas && (desde.coordenadas !== defaultAddress.desde || hasta.coordenadas !== defaultAddress.hasta))
        {
            let directionsService = new google.maps.DirectionsService();
            let baseCoordenadas =  {
                lat: data.coords_base? parseFloat(data.coords_base?.split(' ')[0]): parseFloat(ticket.base.split(' ')[0]),
                lng: data.coords_base? parseFloat(data.coords_base?.split(' ')[1]): parseFloat(ticket.base.split(' ')[1])
            }
            let directionRequest = {
                origin: baseCoordenadas,
                destination: baseCoordenadas,
                travelMode: 'DRIVING',
                waypoints: [
                    {
                        location: desde.coordenadas
                    },
                    {
                        location: hasta.coordenadas
                    }
                ]
            }
            directionsService.route(directionRequest).then((result)=>{

                if(result && result.routes.length > 0) {
                    let aux = {
                        tramo1: result.routes[0].legs[0].distance.value/1000,
                        tramo2: result.routes[0].legs[1].distance.value/1000,
                        tramo3: result.routes[0].legs[2].distance.value/1000
                    }
                    setRecorridoKM(roundTwoDec(aux.tramo2));
                    setTotalKM(roundTwoDec(aux.tramo1+aux.tramo2+aux.tramo3));
                }
            }).catch(err => {
                console.log(err);
            })
        }
    }*/

    const sendData = () => {
        let aux =
        {
            old_ticket:
                {
                    ...ticket
                },
            new_data:
                {
                    ...data,
                }
        }
        aux.new_data.fecha.setHours(aux.new_data.fecha.getHours()-3);
        aux.new_data.fecha = aux.new_data.fecha.toISOString();
        editServicio(aux)
    }

    /*useEffect(() => {
        if(click)
        {
            calcularRuta()
        }
    }, [desde.coordenadas, hasta.coordenadas]);*/


    useEffect(() => {
        setLoading(true);
        adminService.getBasesAndPrestadores(user.token,res=>{
            if(res.status==='SUCCESS'){
                let prestador = res.data.find(pr=>pr.userId==id);
                setPrestadores(res.data);
                setbasesEdit(prestador.base);
            }
            setLoading(false);
        })
    }, []);
    const filterPassedTime = (time) => {
        const currentDate = new Date(ticket.fecha_original);
        const selectedDate = new Date(time);
        return currentDate.getTime() <= selectedDate.getTime();
    };
    const filterPassedDate = (date) => {
        const currentDate = new Date(ticket.fecha_original);
        const selectedDate = new Date(date);
        currentDate.setDate(currentDate.getDate()-1);
        return currentDate.getTime() <= selectedDate.getTime();
    };

    return(
        <Overlay>
            <Modal>
                <FixedWrapper>
                    <IconClose onClick={close}/>
                </FixedWrapper>
                <h1>Modificar Ticket</h1>
                <ItemModal>
                    <h2>Información del Ticket:</h2>
                    <DataModal>
                        <h4>Número:</h4>
                        <p>{ticket.numero}</p>
                    </DataModal>
                    <DataModal>
                        <h4>Patente:</h4>
                        <p>{ticket.patente}</p>
                    </DataModal>
                    <DataModal>
                        <h4>Fecha de solicitud:</h4>
                        <p>{ticket.fecha_original}</p>
                    </DataModal>
                    <DataModal>
                        <h4>Fecha de servicio:</h4>
                        <DatePicker className={'datePickerWrapper'}
                            datePicker={new Date(data.fecha)}
                            dateFormat={'dd/MM/yyyy HH:mm'}
                            showTimeSelect={true}
                            setDatePicker={(date) => {
                                let aux = { ...data };
                                aux.fecha = date;
                                setData(aux);
                            }}
                            filterTime={filterPassedTime}
                            filterDate={filterPassedDate}
                        />
                    </DataModal>
                    <DataModal>
                        <h4>Prestador:</h4>

                        {
                            loading ?
                                <Loading/>
                                :
                                <select defaultValue={parseInt(id)}
                                        onChange={
                                            (e) => {
                                                setbasesEdit(prestadores[e.target.key].base);
                                            }
                                        }
                                >
                                    {
                                        prestadores.map((pr,index)=>{
                                            return(
                                                <option key={index} value={pr.userId}>{pr.nombre}</option>
                                            )
                                        })
                                    }
                                </select>
                        }

                    </DataModal>
                    <DataModal>

                        <h4>Base:</h4>

                        {
                            loading ?
                                <Loading/>
                                :

                                <select id={'base'} defaultValue={filters.base}
                                        onChange={
                                            (e) => {
                                                let base = basesEdit.find(base=>base.id===e.target.value);
                                                setData({
                                                    ...data,
                                                    base:e.target.text,
                                                    coords_base:base.coordenadas,
                                                    prestadorID: base.id
                                                })
                                            }
                                        }
                                >
                                    {
                                        basesEdit.map((pr,index)=>{
                                            return(
                                                <option key={index} value={pr.id}>{pr.base}</option>
                                            )
                                        })
                                    }
                                </select>
                        }
                    </DataModal>
                    <DataModal>
                        <h4>Distancia total (KM):</h4>
                        <Input values={data} idValue={'totalKM'} width={'calc(100% - 150px)'} changeValues={setData}/>
                    </DataModal>
                    <DataModal>
                        <h4>Estado:</h4>
                        <select defaultValue={ticket.estado}
                            onChange={
                                (e) => {
                                    setData({
                                        ...data,
                                        estado: parseInt(e.target.value)
                                    })
                                }
                            }
                        >
                            <option value='0'>Pendiente</option>
                            <option value='1'>Aceptado</option>
                            <option value='2'>Rechazado</option>
                            <option value='3'>Verificar</option>
                        </select>
                    </DataModal>
                </ItemModal>
                <ItemModal>
                    <h2>Tarifa del ticket:</h2>
                    <DataModal>
                        <h4>Tipo:</h4>
                        <select defaultValue={data.tipo_tarifa}
                            onChange={
                                (e) => {
                                    setData({
                                        ...data,
                                        tipo_tarifa: e.target.value
                                    })
                                }
                            }
                        >
                            <option value='COMUN'>Comun</option>
                            <option value='ESPECIAL'>Especial</option>
                        </select>
                    </DataModal>
                    <DataModal>
                        <h4>Vehiculo:</h4>
                        <select defaultValue={data.vehiculo_tarifa}
                            onChange={
                                (e) => {
                                    setData({
                                        ...data,
                                        vehiculo_tarifa: e.target.value
                                    })
                                }
                            }
                        >
                            <option value='LIVIANO'>LIVIANO</option>
                            <option value='SEMIPESADO'>Semipesado</option>
                            <option value='PESADO1'>Pesado 1</option>
                        </select>
                    </DataModal>
                    <DataModal>
                        <h4>Extraccion:</h4>
                        <select defaultValue={data.adicional_tarifa.findIndex(adicional=>adicional.codigo==='Extaccion') !== -1?'Extaccion':''}
                            onChange={
                                (e) => {
                                    let aux = data.adicional_tarifa;
                                    if(e.target.value==='Extaccion'){
                                        if(data.adicional_tarifa.findIndex(adicional=>adicional.codigo==='Extaccion')===-1){
                                            aux.push({codigo:"Extaccion",valor_crm:'SI'})
                                            setData({
                                                ...data,
                                                adicional_tarifa: aux
                                            })
                                        }
                                    }else{
                                        if(data.adicional_tarifa.findIndex(adicional=>adicional.codigo==='Extaccion')!==-1){
                                            aux = aux.filter(adicional=>adicional.codigo!=="Extaccion")
                                            setData({
                                                ...data,
                                                adicional_tarifa: aux
                                            })
                                        }
                                    }
                                }
                            }
                        >
                            <option value='Extaccion'>Si</option>
                            <option value=''>No</option>
                        </select>
                    </DataModal>
                    <DataModal>
                        <h4>Hora de trabajo:</h4>
                        <select defaultValue={data.adicional_tarifa.findIndex(adicional=>adicional.codigo==='Hora de Trabajo Mecanica') !== -1?data.adicional_tarifa.find(adicional=>adicional.codigo==='Hora de Trabajo Mecanica').valor_crm:''}
                            onChange={
                                (e) => {
                                    let aux = data.adicional_tarifa;
                                    if(e.target.value===''){
                                        if(data.adicional_tarifa.findIndex(adicional=>adicional.codigo==='Hora de Trabajo Mecanica')!==-1){
                                            aux = aux.filter(adicional=>adicional.codigo!=="Hora de Trabajo Mecanica")
                                            setData({
                                                ...data,
                                                adicional_tarifa: aux
                                            })
                                        }
                                    }else{
                                        let f = data.adicional_tarifa.findIndex(adicional=>adicional.codigo==='Hora de Trabajo Mecanica');
                                        if(f===-1){
                                            aux.push({codigo:"Hora de Trabajo Mecanica",valor_crm:e.target.value})
                                        }else{
                                            aux[f].valor_crm = e.target.value;
                                        }
                                        setData({
                                            ...data,
                                            adicional_tarifa: aux
                                        })
                                    }
                                }
                            }
                        >
                            <option value=''>No aplica</option>
                            <option value='0.5'>30 min</option>
                            <option value='1'>1h</option>
                            <option value='1.5'>1h 30min</option>
                            <option value='2'>2h</option>
                            <option value='3'>3h</option>
                        </select>
                    </DataModal>
                </ItemModal>
                <Button text={'Guardar Ticket'} clickHandler={sendData}/>
            </Modal>
        </Overlay>
    )
}
export const ModalRechazo = ({ticketNro,Fecha,rechazos = [],close}) =>{
    const {
        user
    } = useUserContext()
    async function getRechazoImg() {
        let res = await gruerosAppService.getRechazoImg(ticketNro,Fecha,user.token)
        if (res.status === 'SUCCESS') {
            for(let i = 0; i < res.data.length; i++){
                let img = res.data[i];
                let imgBlob = await gruerosAppService.getImage(img.src,user.token);
                let a;
                if(img.type === ".pdf"){
                    a = document.createElement('embed');
                }else{
                    a = document.createElement('img');
                }
                a.src = URL.createObjectURL(imgBlob);
                document.getElementById('image-'+img.id).appendChild(a);
            }
        }
    }
    useEffect(()=>{
        getRechazoImg();
    },[])
    return(
        <Overlay>
            <Modal >
                <FixedWrapper>
                    <IconClose onClick={close}/>
                </FixedWrapper>
                <h1>Rechazos realizados</h1>
                {
                    rechazos.map((rechazo,index)=>{
                        return (
                            <Row key={index}>
                                <ItemModal className={'rechazo'}>
                                    <h2>Rechazo: {index+1}</h2>
                                    <DataModal>
                                        <h4>Fecha:</h4>
                                        <p>{rechazo.createdAt.slice(0,10)}</p>
                                    </DataModal>
                                    <DataModal>
                                        <h4>Categoria:</h4>
                                        <p>{rechazo.categoria}</p>
                                    </DataModal>
                                    <DataModal>
                                        <h4>Valor (diferencia en KM):</h4>
                                        <p>{rechazo.value}</p>
                                    </DataModal>
                                    <DataModal>
                                        <h4>Observación:</h4>
                                        <p>{rechazo.observacion}</p>
                                    </DataModal>
                                    <DataModal>
                                        <ImagesRow id={'image-'+rechazo.id}></ImagesRow>
                                    </DataModal>
                                </ItemModal>
                            </Row>
                        );
                    })
                }

            </Modal>
        </Overlay>
    )
}
export const ModalComprobantes = ({Fecha,ticketNro = null,close}) =>{
    const [loading, setLoading] = useState(true);
    const [images,setImages] = useState([]);
    const {
        user
    } = useUserContext()
    async function getComprobantes() {
        let res = await gruerosAppService.getComprobantes(ticketNro,Fecha,user.token);
        //we put the tittle
        let tittle = document.getElementById('comprobantesText');
        tittle.innerHTML = res.data.length > 0? `Tiene ${res.data.length} comprobantes, aguarde mientras se cargan.` :'No se cargaron comprobantes al aceptar el servicio.';
        //then change container to visible
        let container = document.getElementById("wrapper");
        container.style.display = 'flex';
        if (res.status === 'SUCCESS') {
            for(let i = 0; i < res.data.length; i++){
                let img = res.data[i];
                let imgBlob = await gruerosAppService.getImage(img.src,user.token);
                let a;
                if(img.type === ".pdf"){
                    a = document.createElement('embed');
                }else{
                    a = document.createElement('img');
                    a.alt= img.name;
                }
                a.src = URL.createObjectURL(imgBlob);
                a.width = 500;
                a.height = 500;
                document.getElementById("imagesList").appendChild(a);
            }
            setImages(res.data);
        }
        setLoading(false);
    }
    useEffect(()=>{
        setLoading (true);
        getComprobantes();
    },[])
    return(
        <Overlay>
            <Modal >
                <FixedWrapper>
                    <IconClose onClick={close}/>
                </FixedWrapper>
                <h1>Comprobantes</h1>
                {
                    loading?
                        <Loading/>
                        :
                        <></>
                }
                <ItemModal id={'wrapper'}>
                    <h3 id={'comprobantesText'}></h3><br/><br/>
                    <ImagesRow id={'imagesList'}>

                    </ImagesRow>
                </ItemModal>
            </Modal>
        </Overlay>
    )
}
export const ModalDetail = ({admin=false, ticket, close }) => {
    const [click, setClick] = useState(false);
    const [showRechazos, setShowRechazos] = useState(false);
    const [showCompr, setShowCompr] = useState(false);
    function getEstado(estado) {
        switch (estado) {
            case 0:
                return 'Pendiente';
            case 1:
                return 'Aprobado';
            case 2:
                return 'Rechazado';
            case 3:
                return 'Verificar';
        }
    }
    function roundTwoDec(total) {
        return  Math.round((total + Number.EPSILON) * 100) / 100;
    }
    function calculateAdicional(tarifa,adicional) {
        let res = 0;
        switch (adicional.codigo) {
            case "Barra/2":{
                res =  parseInt(adicional.valor_api) * tarifa.barra2km ;
                break;
            }
            case "KM Ripio/nieve":{
                res =  parseInt(adicional.valor_api) * tarifa.kmripio ;
                break;
            }
            case "Movida":{
                res = tarifa.movida;
                break;
            }
            case "CON CARGA":{
                if(adicional.valor_crm==="SI")
                    res = tarifa.conCarga;
                break;
            }
            case "Extaccion":{
                if(adicional.valor_crm==="SI")
                    res = tarifa.extraccion;
                break;
            }
            case "Hora de Espera":{
                let x = parseFloat(adicional.valor_crm);
                res = x * tarifa.horaEspera;
                break;
            }
            case "Asistencia Mecanica":{
                res = tarifa.asistenciaMecanica;
                break;
            }
            case "Hora de Trabajo Mecanica":{
                let x = parseFloat(adicional.valor_crm);
                res = x * tarifa.horaMecanica;
                break;
            }
            case "Peajes":{
                if(!adicional.valor_api){
                    res = 0;
                    break;
                }
                let x = adicional.valor_api.split('|');
                let r = parseInt(adicional.valor_crm);
                res = x.length === r? x.reduce((v1,v2)=>{let parsed= parseInt(v2); return v1+parsed},0) : 0;
                break;
            }
        }
        return roundTwoDec(res);

    }

    return(
        <Overlay>
            <Modal >
                <FixedWrapper>
                    <IconClose onClick={close}/>
                </FixedWrapper>
                <h1>Detalle del Ticket</h1>
                <Row id={'details'}>
                    <ItemModal>
                        <h2>Informacion:</h2>
                        <DataModal>
                            <h4>Numero:</h4>
                            <p>{ticket.numero}</p>
                        </DataModal>
                        <DataModal>
                            <h4>Fecha:</h4>
                            <p>{ticket.fecha}</p>
                        </DataModal>
                        <DataModal>
                            <h4>tipo de servicio:</h4>
                            <p>{ticket.servicio}</p>
                        </DataModal>
                        <DataModal>
                            <h4>Base del prestador:</h4>
                            <p>{ticket.baseSTR}</p>
                        </DataModal>
                        <DataModal>
                            <h4>Total KM:</h4>
                            <p>{ticket.totalKM} km</p>
                        </DataModal>
                        <DataModal>
                            <h4>Base <span>&#8594;</span> Origen:</h4>
                            <p>{ticket.base_origen} km</p>
                        </DataModal>
                        <DataModal>
                            <h4>Origen <span>&#8594;</span> Destino:</h4>
                            <p>{ticket.origen_destino} km</p>
                        </DataModal>
                        <DataModal>
                            <h4>Destino <span>&#8594;</span> Base:</h4>
                            <p>{ticket.destino_base} km</p>
                        </DataModal>
                        <DataModal>
                            <h4>Estado:</h4>
                            <p>{getEstado(ticket.estado)}</p>
                        </DataModal>
                        <DataModal>
                            <h4>Desde:</h4>
                            <p>{ticket.desde}</p>
                        </DataModal>
                        <DataModal>
                            <h4>Hasta:</h4>
                            <p>{ticket.hasta}</p>
                        </DataModal>
                    </ItemModal>
                    <ItemModal>
                        <h2>Valor:</h2>
                            <>
                                {
                                    !ticket.soloAdicionales ?
                                        <DataModal className={"value"}>
                                            <h4>Total km:</h4>
                                            <span>{ticket.precio.toLocaleString()} $</span>
                                        </DataModal>
                                        :
                                        ''
                                }
                                {
                                    ticket.adicionales.map(adicional =>{
                                        return(
                                            <DataModal className={"value"}>
                                                <h4>{adicional.codigo==='Barra/2'?'Excedente':adicional.codigo}:</h4>
                                                <span>{calculateAdicional(ticket.tarifa,adicional).toLocaleString()} $</span>
                                            </DataModal>
                                        )
                                    })
                                }
                                <br/>
                                {

                                    <DataModal className={"value"}>
                                        <h4>Total:</h4>
                                        <span>{roundTwoDec(ticket.precio+ticket.precio_adicional).toLocaleString()} $</span>
                                    </DataModal>
                                }
                            </>
                    </ItemModal>
                    <ItemModal>
                        <h2>Tarifa:</h2>
                        <DataModal>
                            <h4>Grupo:</h4><div className={'column'}><span>{ticket.grupo.nombre}</span><span>({ticket.grupo.descripcion})</span></div>
                        </DataModal>
                        <DataModal className={"value"}>
                            <h4>Tipo:</h4><span>{ticket.tipo}</span>
                        </DataModal>
                        <DataModal className={"value"}>
                            <h4>Vehiculo:</h4><span>{ticket.tipo_vehiculo}</span>
                        </DataModal>
                        <DataModal>
                            <h4>Precio por km:</h4>
                            <p>{ticket.tarifa.total} $</p>
                        </DataModal>
                        <DataModal>
                            <h4>CON CARGA:</h4>
                            <p>{ticket.tarifa.conCarga} $</p>
                        </DataModal>
                        <DataModal>
                            <h4>Extraccion:</h4>
                            <p>{ticket.tarifa.extraccion} $</p>
                        </DataModal>
                        <DataModal>
                            <h4>Hora de Espera:</h4>
                            <p>{ticket.tarifa.horaEspera} $</p>
                        </DataModal>
                        <DataModal>
                            <h4>Hora de Trabajo:</h4>
                            <p>{ticket.tarifa.horaMecanica} $</p>
                        </DataModal>
                        <DataModal>
                            <h4>Asistencia Mecanica:</h4>
                            <p>{ticket.tarifa.asistenciaMecanica} $</p>
                        </DataModal>
                        <DataModal>
                            <h4>Excedente (por km):</h4>
                            <p>{ticket.tarifa.barra2km} $</p>
                        </DataModal>
                        <DataModal>
                            <h4>Movida:</h4>
                            <p>{ticket.tarifa.movida} $</p>
                        </DataModal>
                        <DataModal>
                            <h4>Ripio/nieve (por km):</h4>
                            <p>{ticket.tarifa.kmripio} $</p>
                        </DataModal>

                    </ItemModal>
                </Row>
                {
                    admin?
                        <Row>
                            <ItemModal>
                                <h2>Comentarios del operador:</h2>
                                {
                                    ticket.comentarios.split('|').map(comentario=>{
                                        let [fecha,coment]=comentario.split(' : ');
                                        return (
                                            <DataModal>
                                                <h4>{fecha}: </h4>
                                                <p>{coment}</p>
                                            </DataModal>
                                        )
                                    })
                                }
                            </ItemModal>
                            {
                                ticket.prestador_observaciones && ticket.prestador_observaciones.length > 0?
                                    <ItemModal>
                                        <h2>Comentarios del Prestador:</h2>
                                        <DataModal>
                                            <p>{ticket.prestador_observaciones}</p>
                                        </DataModal>
                                    </ItemModal>
                                    :
                                    ''
                            }
                        </Row>
                        :
                        ''
                }
                {
                    ticket.rechazo.length > 0?
                        <>
                            <Button text={'Ver rechazos'} clickHandler={()=>{setShowRechazos(true)}}/>
                        </>
                        :
                        ''
                }
                {
                    ticket.estado===1?
                        <>
                            <Button text={'Ver comprobantes'} clickHandler={()=>{setShowCompr(true)}}/>
                        </>
                        :
                        ''
                }
                {
                    showRechazos?
                        <ModalRechazo Fecha={ticket.fecha} ticketNro={ticket.numero} rechazos={ticket.rechazo} close={()=>{setShowRechazos(false)}}/>
                        :
                        <></>
                }
                {
                    showCompr?
                        <ModalComprobantes Fecha={ticket.fecha} ticketNro={ticket.numero} close={()=>{setShowCompr(false)}}/>
                        :
                        <></>
                }
            </Modal>
        </Overlay>
    )
}

export const ModalMessage = ({ type,message, close }) => {
    const [click, setClick] = useState(false);

    return(
        <Overlay>
            <Modal>
                <FixedWrapper>
                    <IconClose onClick={close}/>
                </FixedWrapper>
                {
                    type==='error'?
                        <>
                            <h1>¡Error!</h1>
                            <Row>
                                <Error/>
                                {message}
                            </Row>
                        </>
                        :
                            type === 'success'?
                                <>
                                    <h1>¡Exito!</h1>
                                    <Row>
                                        <Success/>
                                        {message}
                                    </Row>
                                </>
                            :

                                <>
                                    <h1>Información</h1>
                                    <Row>
                                        <Message/>
                                        {message}
                                    </Row>
                                </>
                }
            </Modal>
        </Overlay>
    )
}


