//React Imports
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

//Styled Components
import {
    PageWrapper,
    Fondo,
    Container,
    Row,
    Tittle,
    Column,
    Grid,
    LoadingWrapper
} from './elements'

//Components
import TablaTarifa from '../../../components/TablaTarifa'
import RelojDigital from '../../../components/RelojDigital'
import Input from "../../../components/Input"
import Button from "../../../components/Button"
import StyledSelect from "../../../components/StyledSelect"
import Loading from "../../../components/Loading"

//Context
import { useTarifaContext } from '../../../context/TarifaContext'


//DatePicker
import DatePicker, { registerLocale } from 'react-datepicker'
import es from "date-fns/locale/es"; // the locale you want
import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from 'react'
registerLocale(es);

const AltaTarifa = () => {
    const [loading, setLoading] = useState(true)
    const { grupo, setGrupo, horariosExtras, setHorariosExtras, saveTarifa, cargarTarifa, editTarifa, limpiarTarifa } = useTarifaContext()
    const navigate = useNavigate()
    const { id } = useParams()


    const handleHora = (newHora, id, tipo) => {
        let aux = {...grupo};
        
        aux.horarios[id][tipo] = newHora

        setGrupo(aux)
    }

    const handleHoraExtra = (newHora, index, tipo) => {
        let aux = [...horariosExtras];
        
        aux[index][tipo] = newHora

        setHorariosExtras(aux)
    }

    const handleVigencia = (dates,event)=>{
        let aux = {...grupo};
        aux.fechaInicio = dates[0];
        aux.fechaFin = dates[1];
        setGrupo(aux);
    }
    
    useEffect( () => {
        if(id)
            cargarTarifa(id, () => {setLoading(false)})
        else
        {
            limpiarTarifa()
            setLoading(false)
        }
    }, [])

    return (
        <PageWrapper>
            <Fondo className={"superior"} src={`${process.env.PUBLIC_URL}/assets/images/huella clara.webp`} alt={'image'}/>
            {
                loading?
                    <LoadingWrapper>
                        <Loading iconSize={'100px'}/>
                    </LoadingWrapper>
                :
                    <>
                        <Container>
                            <Button clase={'atras'} text={'Atras'} clickHandler={() => navigate('../')}/>
                            <h2>Crear tarifa</h2>
                            <hr/>
                            <Row>
                                <Input idValue={"nombre"} values={grupo} changeValues={setGrupo} placeholder={"Nombre"} textColor={"lightgray"}/>
                            </Row>
                            <Row>
                                <Input idValue={"descripcion"} values={grupo} changeValues={setGrupo} placeholder={"Descripcion"} textColor={"lightgray"}/>
                            </Row>
                            <Tittle>
                                <label>Vigencia</label>
                            </Tittle>
                            <Row>
                                <div>
                                    <DatePicker
                                        locale={es}
                                        selected={grupo.fechaInicio}
                                        onChange={handleVigencia}
                                        startDate={grupo.fechaInicio}
                                        endDate={grupo.fechaFin}
                                        selectsRange
                                        selectsDisabledDaysInRange
                                        inline
                                    />
                                </div>
                            </Row>
                            <Tittle>
                                <label>Precios</label>
                            </Tittle>
                            <TablaTarifa/>
                            <Tittle>
                                <label>Horarios tarifa comun</label>
                            </Tittle>
                            <Row>
                                <Column>
                                    <h2>Lunes</h2>
                                    <label htmlFor="lunes">Desde:</label>
                                    <RelojDigital hora={grupo.horarios.Lunes.desde} setHora={(hora) => handleHora(hora, 'Lunes', 'desde')}/>
                                    <label htmlFor="lunes">Hasta:</label>
                                    <RelojDigital hora={grupo.horarios.Lunes.hasta} setHora={(hora) => handleHora(hora, 'Lunes', 'hasta')}/>
                                </Column>
                                <Column>
                                    <h2>Martes</h2>
                                    <label htmlFor="martes">Desde:</label>
                                    <RelojDigital hora={grupo.horarios.Martes.desde} setHora={(hora) => handleHora(hora, 'Martes', 'desde')}/>
                                    <label htmlFor="martes">Hasta:</label>
                                    <RelojDigital hora={grupo.horarios.Martes.hasta} setHora={(hora) => handleHora(hora, 'Martes', 'hasta')}/>
                                </Column>
                                <Column>
                                    <h2>Miercoles</h2>
                                    <label htmlFor="miercoles">Desde:</label>
                                    <RelojDigital hora={grupo.horarios.Miercoles.desde} setHora={(hora) => handleHora(hora, 'Miercoles', 'desde')}/>
                                    <label htmlFor="miercoles">Hasta:</label>
                                    <RelojDigital hora={grupo.horarios.Miercoles.hasta} setHora={(hora) => handleHora(hora, 'Miercoles', 'hasta')}/>
                                </Column>
                                <Column>
                                    <h2>Jueves</h2>
                                    <label htmlFor="jueves">Desde:</label>
                                    <RelojDigital hora={grupo.horarios.Jueves.desde} setHora={(hora) => handleHora(hora, 'Jueves', 'desde')}/>
                                    <label htmlFor="jueves">Hasta:</label>
                                    <RelojDigital hora={grupo.horarios.Jueves.hasta} setHora={(hora) => handleHora(hora, 'Jueves', 'hasta')}/>
                                </Column>
                                <Column>
                                    <h2>Viernes</h2>
                                    <label htmlFor="viernes">Desde:</label>
                                    <RelojDigital hora={grupo.horarios.Viernes.desde} setHora={(hora) => handleHora(hora, 'Viernes', 'desde')}/>
                                    <label htmlFor="viernes">Hasta:</label>
                                    <RelojDigital hora={grupo.horarios.Viernes.hasta} setHora={(hora) => handleHora(hora, 'Viernes', 'hasta')}/>
                                </Column>
                                <Column>
                                    <h2>Sabado</h2>
                                    <label htmlFor="sabado">Desde:</label>
                                    <RelojDigital hora={grupo.horarios.Sabado.desde} setHora={(hora) => handleHora(hora, 'Sabado', 'desde')}/>
                                    <label htmlFor="sabado">Hasta:</label>
                                    <RelojDigital hora={grupo.horarios.Sabado.hasta} setHora={(hora) => handleHora(hora, 'Sabado', 'hasta')}/>
                                </Column>
                                <Column>
                                    <h2>Domingo</h2>
                                    <label htmlFor="domingo">Desde:</label>
                                    <RelojDigital hora={grupo.horarios.Domingo.desde} setHora={(hora) => handleHora(hora, 'Domingo', 'desde')}/>
                                    <label htmlFor="domingo">Hasta:</label>
                                    <RelojDigital hora={grupo.horarios.Domingo.hasta} setHora={(hora) => handleHora(hora, 'Domingo', 'hasta')}/>
                                </Column>
                                <Column>
                                    <h2>Feriados</h2>
                                    <label htmlFor="feriados">Desde:</label>
                                    <RelojDigital hora={grupo.horarios.Feriados.desde} setHora={(hora) => handleHora(hora, 'Feriados', 'desde')}/>
                                    <label htmlFor="feriados">Hasta:</label>
                                    <RelojDigital hora={grupo.horarios.Feriados.hasta} setHora={(hora) => handleHora(hora, 'Feriados', 'hasta')}/>
                                </Column>
                            </Row>
                            <Tittle>
                                <label>Horarios Extras</label>
                            </Tittle>
                            <Grid>
                                {
                                    horariosExtras.map((element, index) => {
                                        return(
                                            <div className='grid-container'>
                                                <Button text={'x'} clickHandler={() => {
                                                    let aux = [...horariosExtras]
                                                    aux.splice(index, 1)
                                                    setHorariosExtras(aux)
                                                }}/>
                                                <StyledSelect id='dia' 
                                                    data={element} 
                                                    setter={(nuevoObjeto) => {
                                                            let aux = [...horariosExtras]
                                                            aux = aux.filter((value) => value.id !== nuevoObjeto.id)
                                                            setHorariosExtras([...aux, nuevoObjeto])
                                                        }
                                                    } 
                                                    options={['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo', 'Feriado']} 
                                                />
                                                <label htmlFor="lunes">Desde:</label>
                                                <RelojDigital hora={element.desde} setHora={(hora) => handleHoraExtra(hora, index, 'desde')}/>
                                                <label htmlFor="lunes">Hasta:</label>
                                                <RelojDigital hora={element.hasta} setHora={(hora) => handleHoraExtra(hora, index, 'hasta')}/>
                                            </div>
                                        )
                                    })
                                }
                                <div className='grid-container'>
                                    <Button clase={'add'} text={'+'} clickHandler={() => {
                                        setHorariosExtras([
                                            ...horariosExtras,
                                            {
                                                id: horariosExtras.length,
                                                dia: 'Lunes',
                                                desde: '12:30',
                                                hasta: '12:30',
                                            }
                                        ])
                                    }}/>
                                </div>
                            </Grid>
                            {
                                id?
                                    <>
                                        <Button clase={'guardar'} text={'Editar'} clickHandler={async () => {
                                            setLoading(true)
                                            await editTarifa()
                                            navigate('../')
                                        }}/>
                                    </>
                                :
                                    ''
                            }
                            <Button clase={'guardar'} text={id ? 'Guardar Nueva' : 'Guardar'} clickHandler={async () => {
                                setLoading(true)
                                await saveTarifa()
                                navigate('../')
                            }}/>
                        </Container>
                    </>
            }
        </PageWrapper>
    )
}

export default AltaTarifa
