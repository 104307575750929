import styled from 'styled-components'
//Animaciones
import { 
    First,
    Second,
    Third,
    FirstMobile,
    SecondMobile,
    ThirdMobile
} from './animaciones'


export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    position: relative;

    h1{
        width: 80%;
        text-align: center;
        font-size: 3rem;
        margin: 50px 0;

        @media screen and (max-width: 1620px){
            font-size: 2rem;
        }

        @media screen and (max-width: 920px){
            font-size: 1.5rem;
        }
    }
`

export const MetodoWrapper = styled.div`
    height: 60vh;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    button{
        position: absolute;
        bottom: 10px;
        right: 125px;
    }

    img{
        height: 100%;
        filter: drop-shadow(10px 10px 2px #222);
    }

    &.izquierda{
        transform: scale(0.6) translateY(-270px) translateX(-500px);
        z-index: 2;
        animation: ${First} 15s infinite;
    }

    &.principal{
        z-index: 3;
        animation: ${Second} 15s infinite;
    }

    &.derecha{
        transform: scale(0.6) translateY(-270px) translateX(500px);
        z-index: 1;
        animation: ${Third} 15s infinite;
    }

    @media screen and (max-width: 1220px){
        height: 35vh;

        button{
            position: absolute;
            bottom: 10px;
            right: 70px;
            font-size: 0.8rem;
        }

        &.izquierda{
            transform: scale(0.5) translateY(-270px) translateX(-180px);
            z-index: 1;
            animation: ${FirstMobile} 15s infinite;
        }

        &.principal{
            z-index: 3;
            animation: ${SecondMobile} 15s infinite;
        }

        &.derecha{
            transform: scale(0.5) translateY(-270px) translateX(180px);
            z-index: 2;
            animation: ${ThirdMobile} 15s infinite;
        }
    }
`

export const Banner = styled.div`
    display: flex;
    justify-content: center;
    align-items:center;
    width: 100%;
    height: 80vh;
    position: relative;
`