//react
import { useState } from 'react'

//styled components
import {
    Wrapper,
    MetodoWrapper,
    Banner
} from './elements'

//Components
import Section from '../../components/Section'
import Button from '../../components/Button'

//tracker
import AnalyticsEventTracker from '../../components/AnalyticsEventTracker'

const Metodo = ({ imagen, text, action, clase }) => {

    return(
        <MetodoWrapper className={clase} imagen={imagen}>
            <img loading={"lazy"} className='principal' src={imagen} alt='imagen' />
            <Button text={text} clickHandler={(e) => action(e)}/>
        </MetodoWrapper>
    )
}

const Comunicate = () => {
    const gaEventTracker = AnalyticsEventTracker('Comunicate');
    const [show, setShow] = useState(1)

    const pedir = (e) => {
        e.preventDefault()
        gaEventTracker('ingreso webapp','webapp');
        window.open("/webapp", "_blank")
    }

    const llamar = (e) => {
        e.preventDefault()

        gaEventTracker('llamar numero whatsapp','whatsapp');
        window.open("tel:08001226883", "_blank")
    }

    const contactar = (e) => {
        e.preventDefault()
        gaEventTracker('contacto','contacto')
        window.open("https://wa.link/1ko1xl", "_blank")

    }

    return (
        <Section
            color={'#E95612'}
            minHeight='fit-content'
            compName={'comunicate'}
            actualizar={false}
        >
            <Wrapper>
                <h1>Te brindamos tres formas de servicios para comunicarte con nosotros de manera rápida y fácil.</h1>
                <Banner>
                    <Metodo
                        imagen={`${process.env.PUBLIC_URL}/assets/images/comunicate-pedir-banner.webp`}
                        text={'Pedir'}
                        action={pedir}
                        clase={show === 0 ? 'principal' : (show === 1 ? 'izquierda' : 'derecha')}
                    />
                    <Metodo
                        imagen={`${process.env.PUBLIC_URL}/assets/images/comunicate-contactar-banner.webp`}
                        text={'Llamar'}
                        action={llamar}
                        clase={show === 1 ? 'principal' : (show === 0 ? 'derecha' : 'izquierda')}
                    />
                    <Metodo
                        imagen={`${process.env.PUBLIC_URL}/assets/images/comunicate-llamar-banner.webp`}
                        text={'Contactar'}
                        action={contactar}
                        clase={show === 2 ? 'principal' : (show === 1 ? 'derecha' : 'izquierda')}
                    />
                </Banner>
            </Wrapper>
        </Section>
    )
}

export default Comunicate
