//React Imports
import {useEffect, useState} from 'react'
import { useTable, usePagination, useGlobalFilter, useFilters, useAsyncDebounce, useExpanded,useSortBy } from 'react-table'
import {BsArrowUpShort,BsArrowDownShort} from 'react-icons/bs'

//Styled Components
import {
    Styles,
    PageSizeWrapper,
    Pagination,
    PaginationButtons,
    HeaderFilters,
    BackArrow,
    NextArrow,
} from './elements'


const GlobalFilter = ({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) =>  {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <span>
        Buscar{' '}
        <input
            value={value || ""}
            onChange={e => {
                setValue(e.target.value);
                onChange(e.target.value);
            }}
        />
        </span>
    )
}

const Table = ({ totales=null,columns, data, renderRowSubComponent,paginate = true,rowProps,globalSearch = true, chechAllId=null, sortable=false}) => {
    const initialPageSize = 10;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        visibleColumns,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize,expanded},
        state,
        preGlobalFilteredRows,
        setGlobalFilter
    } = useTable({
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: initialPageSize },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
    )

    const pageSelector = () => {
        let selectors = []

        let start = pageIndex - 1
        if(start < 0)
        {
            start = 0
        }

        for(let i = 0; i < 3 && i + start < pageCount; i++)
        {
            selectors.push(
                <button
                    key={i}
                    className={pageIndex === i + start ? 'active' : ''}
                    id={i}
                    onClick={e => gotoPage(i + start)}
                >
                    {i + start + 1}
                </button>
            )
        }

        return selectors
    }

    useEffect(()=>{
        if(chechAllId)
            console.log('entro')
            //chechAllId();
    },[pageIndex]);
    return (
        <>
            {
                !paginate && !globalSearch?
                    <></>
                    :
                    <HeaderFilters>
                        {
                            paginate ?
                                <PageSizeWrapper>
                                    <p>Mostrar</p>
                                    <select
                                        value={pageSize}
                                        onChange={e => {
                                            setPageSize(Number(e.target.value))
                                        }}
                                    >
                                        {[initialPageSize, 20, 30, 40, 50].map(pageSize => (
                                            <option key={pageSize} value={pageSize}>
                                                {pageSize}
                                            </option>
                                        ))}
                                    </select>
                                </PageSizeWrapper>
                                :
                                <></>
                        }
                        {
                            globalSearch?
                                <GlobalFilter
                                    preGlobalFilteredRows={preGlobalFilteredRows}
                                    globalFilter={state.globalFilter}
                                    setGlobalFilter={setGlobalFilter}
                                />
                                :
                                <></>
                        }
                    </HeaderFilters>
            }
            <Styles>
                <table {...getTableProps()}>
                    <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                        {
                            sortable?
                                headerGroup.headers.map((column,index) => (
                                    columns[index].canSort === false?
                                        <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                        </th>
                                    :
                                        <th {...column.getHeaderProps()} onClick={() => column.toggleSortBy(!column.isSortedDesc)}>
                                            <div>
                                                {column.render('Header')}
                                                {
                                                    column.isSorted ?
                                                        column.isSortedDesc ?
                                                            <BsArrowUpShort color={"white"} size={"22px"}/>
                                                            :
                                                            <BsArrowDownShort color={"white"} size={"22px"}/>
                                                        :
                                                        ""
                                                }
                                            </div>
                                        </th>
                                ))
                                :
                                headerGroup.headers.map(column => {
                                    return (
                                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                    )
                                })
                        }

                        </tr>
                    ))}

                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                        <>

                            {
                                rowProps?
                                    <tr   {...row.getRowProps(rowProps(row) )}>
                                        {row.cells.map(cell => {
                                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        })}
                                    </tr>
                                    :
                                    <tr   {...row.getRowProps( )}>
                                        {row.cells.map(cell => {
                                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        })}
                                    </tr>
                            }
                            {row.isExpanded ? (
                                <tr className={'expandible'}>
                                    <td className={'tdExp'} colSpan={visibleColumns.length}>
                                        {/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
                                        {renderRowSubComponent({ row })}
                                    </td>
                                </tr>
                            ) : null}
                        </>
                        )
                    })}
                    {
                        totales?

                            <tr style={{background:"#9a9a9a",color :"white"}}>
                                <td>
                                    TOTALES
                                </td>
                                <td>
                                    {totales.totalPendiente}
                                </td>
                                <td>
                                    {totales.totalAprobado}
                                </td>
                                <td>
                                    {totales.totalRechazado}
                                </td>
                                <td>
                                    {totales.totalTickets}
                                </td>
                                <td>
                                    {data.length}
                                </td>
                                <td>
                                </td>
                            </tr>
                            :
                            <></>
                    }
                    </tbody>
                </table>
            </Styles>
            {
                paginate?
                    <Pagination>
                        <PaginationButtons>
                            <button onClick={() => gotoPage(0)}>
                                Primera
                            </button>
                            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                                <BackArrow/>
                            </button>
                            {
                                pageSelector()
                            }
                            <button onClick={() => nextPage()} disabled={!canNextPage}>
                                <NextArrow/>
                            </button>
                            <button onClick={() => gotoPage(pageCount - 1)}>
                                Ultima
                            </button>
                        </PaginationButtons>
                        <p>{`Pagina ${pageIndex + 1} de ${pageCount !== 0 ? pageCount : 1}`}</p>
                    </Pagination>
                    :
                    <></>
            }
        </>
    )
}

export default Table
