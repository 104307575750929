import React from "react";
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import getProfileData from "../../services/Login_oAuth";
import { useState } from "react";

//elements
import {
    GoogleLoginWrapper,
    LoggedWrapper,
    LoggedThumbnailSection,
    LoggedData,
    ValidEmail }
     from "./elements";

const Login = ({idValue, values, changeValues,loginError}) => {

    const [loggedIn,setLoggedIn] = useState(window.localStorage.getItem('profileData')? true : false);
    const [profileData,setProfileData] = useState(window.localStorage.getItem('profileData') ? JSON.parse(window.localStorage.getItem('profileData'))  : {})

    const Success = async (res) => {
        const data = await getProfileData(res.credential)

        if (data.status && data.status === 'SUCCESS'){

            setLoggedIn(true);
            setProfileData(data.data);
            window.localStorage.setItem('profileData',JSON.stringify(data.data))

            changeValues(
                {
                    ...values,
                    [idValue]: profileData.email
                }
            )
        }
        else{
            loginError("No se ha podido loguear con google. Por favor actualice la pagina y vuelva a intentar.")
        }

    }

    const Failure = (res) => {
        console.log('Logueo fallido:',res);
    }

    return (
        <GoogleLoginWrapper>
            {
                loggedIn?
                    <LoggedWrapper>
                        <LoggedThumbnailSection>
                            <img loading={"lazy"} src={profileData.picture}/>
                        </LoggedThumbnailSection>
                        <LoggedData>
                            <p className="name">{profileData.name}</p>
                            <p style={{display: "flex", justifyContent: "center"}}><ValidEmail show={profileData.email_verified}/>{profileData.email}</p>
                        </LoggedData>
                    </LoggedWrapper>
                :
                    <GoogleLogin
                        onSuccess={Success}
                        onError={Failure}
                        isSignedIn={true}
                        useOneTap
                    />
            }

        </GoogleLoginWrapper>
    )
}

export default Login;
