//react
import {useEffect, useState} from 'react'
import { BiLogOut } from 'react-icons/bi'
import { AiOutlineLogin } from 'react-icons/ai'

//Styled Components
import {
    HeaderWrapper,
    Nav,
    WrapperLogo,
    StyledLink,
    MenuLogo,
    Exit,
    MiMenuLogo,
    MiMenuLogoWrapper,
    LinkPortal,
    NavPortal,
    StyledA,
    DownloadApp
} from './elements'

//context
import { useHeaderContext } from '../../context/HeaderContext'
import { useUserContext } from '../../context/UserContext'

//Data
import { rutas } from './data'
import { Link } from 'react-router-dom'

const HeaderLink = ({ type = 'a', idLink = null, icono, text, hover, to, target }) => {
  const { sectionVisible } = useHeaderContext()
  return(
    <>
      {
        type === 'a'?
          <StyledA className={sectionVisible === idLink ? "hover" : ""}  href={`${to}`} target={ target ? '_blank' : '_self'}>
            <img loading={"lazy"} className='noHover-img' src={icono} alt='logo'/>
            <img loading={"lazy"} className='hover-img' src={hover} alt='logo'/>
            <p>{text}</p>
          </StyledA>
        :
            type === 'link-icon'?
              <StyledLink className={sectionVisible === idLink ? "hover" : ""}  to={`${to}`} target={ target ? '_blank' : '_self'}>
                  {icono}
                <span>{text}</span>
              </StyledLink>
            :
              <StyledLink className={sectionVisible === idLink ? "hover" : ""}  to={`${to}`} target={ target ? '_blank' : '_self'}>
                <img loading={"lazy"} className='noHover-img' src={icono} alt='logo'/>
                <img loading={"lazy"} className='hover-img' src={hover} alt='logo'/>
                <p>{text}</p>
              </StyledLink>
      }
    </>
  )
}


const Header = ({ noLinks = false, portal= false,height, background = 'white' }) => {
  //const [changeHeader, setChangeHeader] = useState(false)
  const [open, isOpen] = useState(false)
  const { user, logOut } = useUserContext()

  //Efecto difuminado
  /*useEffect(() => {
    const handler = () => {
      setChangeHeader((changeHeader) => {
        if (
          !changeHeader &&
          (document.body.scrollTop > 20 ||
            document.documentElement.scrollTop > 20)
        ) {
          return true;
        }

        if (
          changeHeader &&
          document.body.scrollTop < 4 &&
          document.documentElement.scrollTop < 4
        ) {
          return false;
        }

        return changeHeader;
      });
    };

    window.addEventListener("scroll", handler);
    return () => window.removeEventListener("scroll", handler);
  }, []);*/

  return (
    <HeaderWrapper height={height} changeHeader={null} background={background}>
      <WrapperLogo>
          {
            background === 'white'?
              <Link to={"/"}>
                <img loading={"lazy"} src={`${process.env.PUBLIC_URL}/assets/images/logo-negro.webp`} alt={'imagen'}/>
              </Link>
            :
              <Link to={"/"}>
                <img loading={"lazy"} src={`${process.env.PUBLIC_URL}/assets/images/logo-blanco.webp`} alt={'imagen'}/>
              </Link>
          }
      </WrapperLogo>
      {
        noLinks || portal?
          ''
        :
          <>
            <MenuLogo onClick={() => isOpen(!open)}/>
            <Nav className='normal' onClick={() => {
                if(open)
                  isOpen(!open)
              }}
            open={open}
            >
              <Exit portal={portal}/>
              <HeaderLink
                idLink={"home"}
                icono={`${process.env.PUBLIC_URL}/assets/images/logos/home.webp`}
                hover={`${process.env.PUBLIC_URL}/assets/images/logos/home-hover.webp`}
                text={'Home'}
                to={'/#home'}
              />
              <HeaderLink
                idLink={"seguridad"}
                icono={`${process.env.PUBLIC_URL}/assets/images/logos/seguridad.webp`}
                hover={`${process.env.PUBLIC_URL}/assets/images/logos/seguridad-hover.webp`}
                text={'Seguridad'}
                to={'/#seguridad'}
              />
              <HeaderLink
                idLink={"telefonosUtiles"}
                icono={`${process.env.PUBLIC_URL}/assets/images/logos/telefonosutiles.webp`}
                hover={`${process.env.PUBLIC_URL}/assets/images/logos/telefonosutiles-hover.webp`}
                text={'Telefonos Utiles'}
                to={'#telefonosUtiles'}
              />
              <HeaderLink
                idLink={"asistenciaVehicular"}
                icono={`${process.env.PUBLIC_URL}/assets/images/logos/asistencia.webp`}
                hover={`${process.env.PUBLIC_URL}/assets/images/logos/asistencia-hover.webp`}
                text={'Asistencia Vehicular'}
                to={'#asistenciaVehicular'}
              />
              <HeaderLink
                idLink={"quienesSomos"}
                icono={`${process.env.PUBLIC_URL}/assets/images/logos/quienessomos.webp`}
                hover={`${process.env.PUBLIC_URL}/assets/images/logos/quienessomos-hover.webp`}
                text={'Quienes Somos'}
                to={'#quienesSomos'}
              />
              <HeaderLink
                type={'link'}
                icono={`${process.env.PUBLIC_URL}/assets/images/logos/consultas.webp`}
                hover={`${process.env.PUBLIC_URL}/assets/images/logos/consultas-hover.webp`}
                text={'Consultas y Sugerencias'}
                to={'/forms/consultas'}
              />
              <HeaderLink
                type={'link'}
                icono={`${process.env.PUBLIC_URL}/assets/images/logos/sumate.webp`}
                hover={`${process.env.PUBLIC_URL}/assets/images/logos/sumate-hover.webp`}
                text={'Sumate como Prestador'}
                to={'/forms/sumate'}
              />

                <HeaderLink
                    type = 'link-icon'
                    idLink={"login"}
                    icono={<AiOutlineLogin/>}
                    text={'Login'}
                    to={'/portal'}
                />
            </Nav>
          </>
      }
      {
        portal?
            <>
                <MiMenuLogoWrapper onClick={() => isOpen(!open)}>
                    <MiMenuLogo/>
                </MiMenuLogoWrapper>
                <NavPortal onClick={() => {
                    if(open)
                        isOpen(!open)
                }}
                     open={open}
                     portal={portal}
                >
                    <Exit portal={portal}/>
                    {
                      rutas.map((ruta, index) => {
                          return(
                            <>
                              {
                                user.roles.includes(ruta.rol)?
                                  <LinkPortal
                                    to={ruta.ruta}
                                  >
                                    <p>{ruta.nombre}</p>
                                  </LinkPortal>
                                :
                                  ''
                              }
                            </>
                          )
                      })
                    }
                    <LinkPortal
                      to={'#'}
                      onClick={logOut}
                    >
                        <p>Salir</p>
                        <BiLogOut/>
                    </LinkPortal>
                </NavPortal>
            </>
        :
            ''
      }
    </HeaderWrapper>
  )
}

export default Header
