import styled from "styled-components";
import {FaSpinner} from "react-icons/fa";

export const Tittle = styled.div`
  display: flex;
  width: 100%; 
  justify-content: space-between;
  align-items: center;
`
export const ModalWrapper = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  background: #adaaaa99;
  z-index: 1050;
  display: ${props => props.loading!=='false'  ?  "flex" : "none"};
  gap: 0px;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  padding-top: 40px;
`
export const Modal1= styled.div`
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 10px;
  overflow: auto;
  border-radius: 15px;
  width: ${props => props.width};
  height: ${props => props.width};
  max-height: calc(100vh - 160px);
  border-radius: 15px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  /* width */

  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */

  ::-webkit-scrollbar-thumb {
    background: #d57834;
    border-radius: 50px;
  }

  /* Handle on hover */

  ::-webkit-scrollbar-thumb:hover {
    background: #d57834;
  }

  & a {
    margin: 5px;

    & :hover {
      cursor: pointer;
    }
  }

  &Link {
    & :hover {
      cursor: pointer;
    }
  }

  & .close {
    bottom: 20px;
    right: 20px;
    align-items: center;
    justify-content: flex-end;
    grid-column: 3;

    & Button {
      display: flex;
      align-items: center;
      font-size: 14px;
      padding: 0 10px;
      gap: 10px;
    }
  }

  & #errorMsg {
    color: #D70909FC;
  }

  & .tittle {
    width: 100%;
  }

  svg {
    width: 100% !important;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`
export const PullRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.7em;
  width: 100%;
  @media only screen and (max-width: 800px) {
    gap: 5px;
    button{
      margin: 0;
      font-size: 15px;
    }
  }
`
