
const {token_LIBRA, url} = require('./constantes');

async function guardarSumateForm(sumateformDTO) {
    try{
            let response = await fetch(`${url}/form-ext/save/prestadores`, {
            method: 'POST',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+ token_LIBRA
            },
            body: JSON.stringify(sumateformDTO),
        }).then(res => {
            return res.json()
        }).catch(res => {
            return res.json()
        })
        return response;
    }catch (e){
        console.log(e)
    }
}

async function guardarContactoForm(ContactoformDTO) {
    try{

        //add file to formData object
        let fd = new FormData();
        Object.keys(ContactoformDTO).forEach(
            key => {

                if (key === 'archivo'){
                    fd.append(key, ContactoformDTO[key].file)
                }
                else
                    fd.append(key, ContactoformDTO[key])
                }
            );

        let response = await fetch(`${url}/form-ext/save/contacto`, {
            method: 'POST',
            headers: {
                'authorization': 'Bearer '+ token_LIBRA
            },
            body: fd,
        }).then(res => {
            return res.json()
        }).catch(res => {
            return res.json()
        })
        return response;
    }catch (e){
        //console.log(e);

    }
}

// send `POST` request
const FormService = {
    guardarSumateForm,
    guardarContactoForm
};


export default FormService;
