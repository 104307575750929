//React Imports
import { useState, useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom"

//Styled Components
import {
    FileUploadWrapper,
    GruerosHomePage, RowTwoColumns,
    GruerosHomeWrapper,
    Dates,AgregarTicket,
    ButtonWrapper,
    Button,
    FormWrapper,
    Wrapper,
    Estadisticas,
    ServiciosListWrapper,
    LoadingWrapper,
    InfoProveedor,
    Imagen,
    EmptyWrapper,
    Graphs,
    Filter,
    Volver,
    Pagination,
    PaginationButtons,
    BackArrow,
    NextArrow
} from './elements'

//Components
import FileUpload from "../../components/FileUpload"
import Servicio from '../../components/Servicio'
import DatePicker from "../../components/DatePicker"
import Loading from '../../components/Loading'
import BarChartComp from "../../components/BarChartComp";
import PieChartComp from "../../components/PieChartComp";
import Modal from "../../components/Modal";
import Message from "../../components/Message"
import {RiArrowGoBackLine} from "react-icons/ri"

//Context
import { useUserContext } from '../../context/UserContext'
import { usePortalTicketContext } from '../../context/PortalTicketContext'
import useWindowDimensions from '../../hooks/windowDimiensions'

//Service
import gruerosAppService from "../../services/GruerosAppService"
import Input from "../../components/Input";
import AdminService from "../../services/AdminService";


const GruerosHome = () => {
    var { id } = useParams();
    const {
        loading,
        statics,
        bases,
        setFilters,
        filters,
        ticketsToShow,
        setId,
        error,
        getCrudo,
        setError,
        success,
        setSuccess,
        pagination,
        goToPage,
        nextPage,
        firstPage,
        lastPage,
        previousPage
    } = usePortalTicketContext()
    const { user } = useUserContext()

    //Facturacion
    const [files, setFiles] = useState({})
    const [facturaDate, setFacturaDate] = useState(new Date())
    const [confirmFiles, setConfirmFiles] = useState(false)
    const [add,setAdd] = useState(false)
    const [ticketNro,setTicketNro] = useState(null)
    const navigate = useNavigate()
    const { width } = useWindowDimensions()

    function cancelSendFiles() {
        setFiles({});
    }

    async function sendFiles() {
        setFiles({});
        let x = document.getElementById("observaciones").value;
        let res = await gruerosAppService.sendFactura({ observaciones: x, archivo: files, month: facturaDate },user.token)
        if (res.status === 'SUCCESS') {
            setSuccess('Su factura fue enviada con éxito.')
        } else {
            setError("Ocurrió un error inesperado: " + res.message);
        }
    }

    const pageSelector = () => {
        let selectors = []

        let cantBotones = width > 500 ? 5 : 1

        let start = Math.floor(pagination.page - (cantBotones / 2))

        if(start < 0)
        {
            start = 0
        }

        for(let i = 0; i < cantBotones && i + start < pagination.totalPages; i++)
        {
            selectors.push(
                <button
                    key={i}
                    className={pagination.page - 1 === i + start ? 'active' : ''}
                    id={i}
                    onClick={e => goToPage(i + start + 1)}
                >
                    {i + start + 1}
                </button>
            )
        }

        return selectors
    }

    useEffect(() => {
        if (Object.keys(files).length > 0)
            setConfirmFiles(true);
        else {
            setConfirmFiles(false);
        }
    }, [files])

    useEffect(() => {
        setId(id === undefined ? user.id : id)
    }, [])

    useEffect(() => {
    }, [success])

    function roundTwoDec(total) {
        return  (Math.round((total + Number.EPSILON) * 100) / 100).toLocaleString();
    }
    async function addTicket(e) {
        setAdd(false);
        if(!ticketNro.nuevoTicketNro){
            setError('Debe ingresar el numero de ticket.')
        }
        let res = await AdminService.addTicket(ticketNro.nuevoTicketNro,user.token)
        if (res.status === 'SUCCESS' && res.data) {
            setSuccess('El ticket se agrego correctamente.')
        } else {
            setError("Ocurrió un error inesperado: " + res.message);
        }
    }
    return (
        <GruerosHomePage>
            <Imagen className={"superior"} src={`${process.env.PUBLIC_URL}/assets/images/huella clara.webp`} alt={'image'} />
            {
                loading?
                    <LoadingWrapper>
                        <Loading iconSize={'100px'}></Loading>
                    </LoadingWrapper>
                    :
                    <GruerosHomeWrapper padding={user.tipo && user.tipo !== "GRUERO" }>
                        {
                            user.tipo && user.tipo !== "GRUERO" ?
                                <>
                                    <Volver to={'/portal/admin'}>
                                        <RiArrowGoBackLine/>
                                    </Volver>
                                    <AgregarTicket onClick={()=>{setAdd(true)}}>
                                        Agregar Ticket
                                    </AgregarTicket>
                                </>
                                :
                                <></>
                        }
                        <Estadisticas id={'statics'}>
                            <div>
                                <h3>Estadisticas (base actual)</h3>
                                <hr />
                            </div>
                            <p><strong>Total de tickets en el mes: </strong>
                                {statics.totalMes}</p>
                            <p><strong>Dias del mes con servicios: </strong>
                                {statics.diasDelMes}</p>
                            <p><strong>Total de tickets en la semana: </strong>
                                {statics.totalSemana}</p>

                            {
                                user.tipo && user.tipo !== "GRUERO" ?
                                    <>
                                        <p><strong>Facturado en el mes: </strong>
                                            {roundTwoDec(statics.facturadoMes)}$</p>
                                        <p><strong>Facturado filtro: </strong>
                                            {roundTwoDec(statics.facturadoFiltro)}$</p>
                                    </>
                                    :
                                    <></>
                            }
                        </Estadisticas>
                        <InfoProveedor id={"infoProveedor"}>
                            <div>
                                <h3>Información</h3>
                                <hr />
                            </div>
                            <p><strong>Nombre:</strong>
                                {statics.prestador.nombre}</p>
                            <p><strong>Contacto:</strong>
                                {statics.prestador.contacto}</p>
                            <p><strong>WhatsApp:</strong>
                                {statics.prestador.wpp}</p>
                            <p><strong>Horario:</strong>
                                {statics.prestador.horario}</p>
                        </InfoProveedor>
                        {
                            user.tipo && user.tipo === "GRUERO" ?

                                <FileUploadWrapper id={"fileUploaod"}>
                                    <div>
                                        <h3>Factura mensual</h3>
                                        <hr />
                                    </div>
                                    <div>
                                        <DatePicker
                                            title={"Mes facturado:"}
                                            datePicker={facturaDate}
                                            setDatePicker={setFacturaDate}
                                            dateFormat="MM/yyyy"
                                            minDate={new Date(2022, 5, 1)}
                                            showMonthYearPicker
                                        />
                                    </div>
                                    <FileUpload files={files} setFiles={setFiles} />
                                </FileUploadWrapper>
                                :
                                <></>
                        }
                        <ServiciosListWrapper id={"serviciosList"}>
                            <div id={"tittle"}>
                                <h3>Servicios pendientes</h3>
                                <hr />
                                <Filter>
                                    <h5>Base:</h5>
                                    <select name="base" id="base" defaultValue={bases}
                                        onChange={(e) => {
                                            setFilters({
                                                ...filters,
                                                base: e.target.value
                                            })
                                        }}
                                    >
                                        {
                                            Object.keys(bases).map((base) => {
                                                return (
                                                    <option value={bases[base]} key={bases[base]}>{base}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </Filter>
                                <Dates>
                                    <DatePicker
                                        title={"Desde:"}
                                        datePicker={filters.initDate}
                                        setDatePicker={(date) => {
                                            let aux = { ...filters };
                                            aux.initDate = date;
                                            setFilters(aux);
                                        }}
                                        minDate={new Date(2022, 5, 1)}
                                        maxDate={filters.finishDate}
                                    />
                                    <DatePicker
                                        title={"Hasta:"}
                                        datePicker={filters.finishDate}
                                        setDatePicker={(date) => {
                                            let aux = { ...filters };
                                            aux.finishDate = date;
                                            setFilters(aux);
                                        }}
                                        minDate={filters.initDate}
                                    />
                                </Dates>
                                <RowTwoColumns>
                                    <Filter>
                                        <h5>Numero de ticket o patente</h5>
                                        <input
                                            value={filters.ticket_numero}
                                            type={'text'}
                                            onChange={(e) => {
                                                let aux = { ...filters }
                                                aux.ticket_numero = e.target.value
                                                setFilters(aux)
                                            }}
                                        />
                                    </Filter>
                                    <Filter>
                                        <h5>Estado</h5 >
                                        <select defaultValue={filters.estado}
                                            onChange={
                                                (e) => {
                                                    let aux = { ...filters }
                                                    aux.estado = parseInt(e.target.value)
                                                    setFilters(aux)
                                                }
                                            }
                                        >
                                            <option value='0'>Pendiente</option>
                                            <option value='1'>Aceptado</option>
                                            <option value='2'>Rechazado</option>
                                            <option value='3'>Todos</option>
                                        </select>
                                    </Filter>
                                </RowTwoColumns>
                            </div>
                            <div className={pagination.loading ? 'loading' : ''} id={"lista"}>
                                {
                                    pagination.loading?
                                        <Loading iconSize={'100px'}></Loading>
                                    :
                                        ticketsToShow.length > 0 ?
                                            ticketsToShow.map((val, index) => {
                                                return (<Servicio nombre={statics.prestador.nombre}  editable={user.tipo === "GRUERO"} ticket={val} key={index} admin={ id ? true : false}/>)
                                            })
                                        :
                                            <EmptyWrapper>No posee servicios para aprobar</EmptyWrapper>
                                }
                            </div>
                            {
                                pagination.totalPages && pagination.totalPages > 1 ?
                                    <Pagination>
                                        <PaginationButtons>
                                            <button onClick={firstPage}>
                                                Primera
                                            </button>
                                            <button>
                                                <BackArrow onClick={previousPage}/>
                                            </button>
                                            {
                                                pageSelector()
                                            }
                                            <button>
                                                <NextArrow onClick={nextPage}/>
                                            </button>
                                            <button onClick={lastPage}>
                                                Ultima
                                            </button>
                                        </PaginationButtons>
                                        <p>{`Pagina ${pagination.page} de ${pagination.totalPages !== 0 ? pagination.totalPages : 1}`}</p>
                                    </Pagination>
                                :
                                    ''
                            }
                        </ServiciosListWrapper>
                        <Graphs id={"graphs"}>
                            <h3>Gráficos (base actual)</h3>
                            <strong>Gráfico mes:</strong>
                            <BarChartComp XdataKey={"Date"} listBars={[{ barDataKey: "totalCOunt", barColor: "#37943c" }]} data={statics.chartMes} />
                            <strong>Gráfico semana:</strong>
                            <BarChartComp XdataKey={"Date"} listBars={[{ barDataKey: "totalCOunt", barColor: "#37943c" }]} data={statics.chartSemana && statics.chartSemana.length > 0 ? statics.chartSemana : [{ Date: "No hay información" }]} />
                            <strong>Gráfico estados:</strong>
                            <PieChartComp dataKey={"totalCount"} nameKey={"estado"} data={statics.totalEstados}
                                          colors={["#d57834","#37943c","#a11a1a"]} />
                        </Graphs>
                    </GruerosHomeWrapper>
            }

            {
                confirmFiles ?
                    <Modal id={'confirmSend'} tittle={'Enviar Factura'} width={'70%'} setFlag={false}>
                        <Wrapper>
                            <FormWrapper>
                                <div>
                                    <DatePicker
                                        title={"Mes facturado:"}
                                        datePicker={facturaDate}
                                        setDatePicker={setFacturaDate}
                                        dateFormat="MM/yyyy"
                                        minDate={new Date(2022, 5, 1)}
                                        showMonthYearPicker
                                    />
                                </div>
                                <div className={'row'}>
                                    <h5>Archivo</h5>
                                    <label>{files.file && files.file.name ? files.file.name : 'No se selecciono un archivo'}</label>
                                </div>
                                <div className={'row'}>
                                    <h5>Observaciones</h5>
                                    <textarea type="textArea" id={"observaciones"} />
                                </div>
                                <ButtonWrapper>
                                    <Button onClick={cancelSendFiles} color={'rgb(255,0,0)'} isOrangeSection={false}>Cancelar</Button>
                                    <Button onClick={sendFiles} isOrangeSection={true}>Enviar</Button>
                                </ButtonWrapper>
                            </FormWrapper>
                        </Wrapper>
                    </Modal>
                    :
                    ''
            }
            {
                add ?
                    <Modal id={'addTicket'} tittle={'Añadir ticket'}   setFlag={()=>{setAdd(false)}}>
                        <Wrapper>
                            <FormWrapper>
                                <div className={'row'}>
                                    <h5>Ingrese el número de ticket</h5>
                                    <Input idValue={"nuevoTicketNro"} values={ticketNro} changeValues={setTicketNro} placeholder={"Número de ticket"} textColor={"lightgray"}/>
                                </div>
                                <ButtonWrapper>
                                    <Button onClick={addTicket} color={'rgb(236,79,31)'}  >Agregar</Button>
                                </ButtonWrapper>
                            </FormWrapper>
                        </Wrapper>
                    </Modal>
                    :
                    ''
            }
            {
                error ?
                    <Message mensaje={error} action={
                        () => {
                            setError(null)
                        }
                    }
                    />
                    :
                    ""
            }
            {
                success ?
                    <Message type={'success'} mensaje={success} action={
                        () => {
                            setSuccess(null);
                            getCrudo(id);
                        }
                    }
                    />
                    :
                    ""
            }
        </GruerosHomePage>
    )
}

export default GruerosHome
