import styled from "styled-components"
import { FaUserAlt, FaLock } from 'react-icons/fa'

export const LoginPage = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-color: #e95612;
  display: grid;
  place-items: center;
`

export const DownloadApp = styled.div`
    height: fit-content;
    border-radius: 50%;
    background: transparent;
    display: ${props => props.show?'flex':'none'};
    position: absolute; 
    left: 15px;
    top: 15px; 
    cursor: pointer;
    button{
      cursor: pointer;
      background: #a6a6a6;
      border-radius: 50%;
      color: white; 
      border:0px;
      padding:5px;
      box-shadow: 4px 4px 2px rgba(0, 0, 0, 0.5);
      
    }
`

export const LoginWrapper = styled.div`
  width: 350px;
  height: 450px;
  margin-top:160px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`
export const HuellaWrapper = styled.div`
  width: 640px;
  height: 100%;
  display: flex;
  justify-content: center;
  background-image: url("${process.env.PUBLIC_URL}/assets/images/cp.webp");
  background-position: center center;
  animation-name: animar;
  animation-duration: 2s;
  animation-iteration-count: easy;
  @keyframes animar {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

  @media screen and (max-width: 640px){
    width: 100%;
  }
`

export const LogoWrapper = styled.div`
  padding: 15px;
  height: 35%;
`

export const FormWrapper = styled.form`
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap:60px;
  position: relative;

  & p{
    color: #666;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  & span{
    color: red;
    margin: 15px 0;
    position: absolute;
    top: 130px; 
  }
`

export const Input = styled.div`
  width: 100%;
  border: solid 1px #ccc;
  margin: 10px 15px;
  position: relative;
  animation: zoom-in 2s ease;

  @keyframes zoom-in {
    0% {
      transform: scale(0, 0);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  & input{
    width: 100%;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px 45px 10px 10px;
  }
`

export const IconUser = styled(FaUserAlt)`
  position: absolute;
  right: 10px;
  top: calc(50% - 7px);
  color: #666;
  font-size: 14px;
`

export const IconPassword = styled(FaLock)`
  position: absolute;
  right: 10px;
  top: calc(50% - 7px);
  color: #666;
  font-size: 14px;
`

export const SubmitWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: auto;
  display: flex;

  &.end{
    justify-content: flex-end;
  }

  &.center{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  & button{
    z-index:2;
  }
`
