
const { url} = require('./constantes');

async function login(user,callBack) {
    try{
        // send `POST` request
        await fetch(`${url}/login/auth`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Origin': '*'
            },
            body: JSON.stringify(user),
        })
            .then(res => {
                return res.json()
            })
            .then(callBack)
            .catch(err => console.error(err))
    }catch (e){
        //console.log(e);
    }
}
async function verifyToken(user) {
    try{
        // send `POST` request
        let response = await fetch(`${url}/login/verifyToken`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Origin': '*',
                'authorization': 'Bearer ' + user.token
            },
        })
            .then(res => {
                return res.json()
            })
            .catch(err => {
                return err
            });
        return response;
    }catch (e){
        //console.log(e);
    }
}


// send `POST` request
const AuthService = {
    login,verifyToken
};


export default AuthService
