import styled from "styled-components"
import {
    AiFillEdit,
    AiFillDelete,AiOutlineClose,AiOutlineCheck
} from 'react-icons/ai'
import {
    FaMapMarkedAlt
} from 'react-icons/fa'


export const Wrapper = styled.div`
    background-color: rgba(210, 210, 210);
    box-shadow: 0px 0px 10px 5px rgb(0 0 0 / 50%);  
    position: relative;
    background-color: #E95612;
    border-radius: 15px;
    overflow: hidden;

    & .imagenText{
        color: white;
        margin: 10px;
        font-weight: 600;
        font-size: 18px;
        z-index: 3;
        position: absolute;
        bottom: 0;
        right: 0;
    }

    button{
        font-size: 10px;
        font-weight: 900;
        padding: 5px 12px;
        margin: 5px 0;
    }

    & .content{
        h3{
            font-size: 1.5rem;
        }

        h4{
            font-size: 1.1rem;
        }

        .description{
            width: 85%;
            max-height: 50px;
            margin: 15px 0;
            font-size: 0.8rem;
            overflow-wrap: break-word;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            @supports (display: -webkit-box) {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                white-space: normal;
            }
        }
    }

    &.small{
        img{
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        & .content{
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 3;
            color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding-left: 1rem;
        }

        &:before{
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: #E95612;
            z-index: 2;
            opacity: 60%;
        }
    }

    &.medium-vertical{
        display: flex;
        flex-direction: column;

        button{
            margin: 0;
        }

        img{
            z-index: 1;
            height: 55%;
        }

        & .content{
            position: absolute;
            width: 100%;
            height: 45%;
            z-index: 3;
            color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 5px;
            padding-left: 1rem;
            top: 55%;
            padding-top: 10px;
        }
    }

    &.medium-horizontal{
        display: flex;

        img{
            z-index: 1;
            width: 50%;
            height: 100%;
        }

        & .content{
            width: 50%;
            height: 100%;
            z-index: 3;
            color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 5px;
            padding-left: 1rem;
        }
    }

    &.large{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 40px;

        img{
            z-index: 1;
            height: 100%;
            width: 50%;
        }

        & .content{
            width: 50%;
            height: 100%;
            z-index: 3;
            color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 5px;
            padding-left: 2rem;
            bottom: 100px;
        }
    }

    &.preview{
        &.small{
            width: 350px;
            height: 250px;
        }

        &.medium-vertical{
            width: 350px;
            height: calc(500px + 40px);
        }

        &.medium-horizontal{
            width: calc(700px + 40px);
            height: 250px;
        }

        &.large{
            width: calc(1050px + 80px) ;
            height: calc(350px + 40px);
        }

        @media screen and (max-width: 1120px){
            transform: scale(0.7);
        }

        
        @media screen and (max-width: 920px){
            transform: scale(0.6);
        }

        @media screen and (max-width: 740px){
            transform: scale(0.55);
        }
        
        @media screen and (max-width: 670px){
            transform: scale(0.4);
        }
    }

    &.noPreview{
        &.small{
            height: 35vh;
            grid-column: span 1;
        }

        &.medium-vertical{    
            height: calc(70vh + 40px);    
            grid-column: span 1;
            grid-row: span 2;
        }

        &.medium-horizontal{    
            height: 35vh;    
            grid-column: span 2;
        }

        &.large{
            height: 50vh;
            grid-column: span 3;
            grid-row: span 2;
        }

        @media screen and (max-width: 920px){
            width: 75vw !important;
            height: 60vh !important;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            padding: 0;

            &.small:before{
                display: none;
            }

            & .imagenText{
                display: flex;
                align-items: center;
                padding-left: 3rem;
            }

            img{
                z-index: 1;
                height: 50%;
                width: 100%;
                position: relative;
            }

            & .content{
                width: 100%;
                height: 50%;
                z-index: 3;
                color: #fff;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 5px;
                padding-left: 3rem;
                padding-top: 10px;
                position: relative;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
    }
`

export const OptionsButtons = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    z-index: 4;
    margin: 15px;
    gap: 5px;
`

export const Edit = styled(AiFillEdit)`
    color: #FFF;
    font-size: 3rem;
    cursor: pointer;
    background-color: black;
    border-radius: 15px;
    padding: 10px;
`

export const Delete = styled(AiFillDelete)`
    color: #FFF;
    font-size: 3rem;
    cursor: pointer;
    background-color: black;
    border-radius: 15px;
    padding: 10px;
`
export const Reject = styled(AiOutlineClose)`
  color: #FFF;
  font-size: 3rem;
  cursor: pointer;
  background-color: #E95612;
  border-radius: 15px;
  padding: 10px;
`

export const Accept = styled(AiOutlineCheck)`
  color: #FFF;
  font-size: 3rem;
  cursor: pointer;
  background-color: #E95612;
  border-radius: 15px;
  padding: 10px;
`
export const Map = styled(FaMapMarkedAlt)`
  color: #FFF;
  font-size: 3rem;
  cursor: pointer;
  background-color: #E95612;
  border-radius: 15px;
  padding: 10px;
`
export const MapDisabled = styled(FaMapMarkedAlt)`
  color: #FFF;
  font-size: 3rem;
  cursor: pointer;
  background-color: #646464;
  border-radius: 15px;
  padding: 10px;
`
