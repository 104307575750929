import styled from "styled-components"

export const Table = styled.table`
    text-align: center;
    border-collapse: collapse;
    margin-bottom: 20px;

    thead{

        & :first-child{
            border: none;
        }
    }

    td, th{
        width: 225px;
        border: solid 1px #ccc;
        padding: 10px 0;
    }

    th{
        background-color: #e95612;
        color: #FFF;
    }

    td{
        padding: 0;

        & input{
            width: 100%;
            height: 50px;
            text-align: center;
            border: none;
            outline: none;
            font-size: 1.5rem;
        }

        & input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        & input[type=number] {
            -moz-appearance: textfield;
        }
    }
`