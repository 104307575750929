import {
    Wrapper,
    StyledInput,
    RadioGroupWrapper
} from './elements'

const InputRadio = ({ width, idValue, options, values, changeValues ,textColor }) => {

    const changeHandler = (id, e) => {
        changeValues(
            {
                ...values,
                [id]: e.target.value
            }
        )
    }

    return (
        <Wrapper width={width} color={textColor}>
           
            {
                 
                        
                 options.map(option => (
                     <RadioGroupWrapper>
                         <StyledInput  type={'radio'} value={option.id} id={idValue} name={idValue}  onChange={(e) => changeHandler(idValue, e)}/>
                         {option.nombre}
                     </RadioGroupWrapper>
                 
                 ))
                        
                   
             

            }
        </Wrapper>
    )
}

export default InputRadio

