const {token_LIBRA, url} = require('./constantes');

async function create(token,novedad, callBack) {
    try{
        //Creamos el form data
        const formData = new FormData()
        formData.append('titulo', novedad.titulo)
        formData.append('subtitulo', novedad.subtitulo)
        formData.append('descripcion', novedad.descripcion)
        formData.append('imagen', novedad.imagen)
        formData.append('link_video', novedad.link_video)
        formData.append('tipo_modelo', novedad.tipo_modelo)

        // send `POST` request
        await fetch(`${url}/novedades/create`, {
            method: 'POST',
            headers: {
                'authorization': 'Bearer '+ token
            },
            body: formData,
        })
        .then(res => {
            if(res.status===401 && window.localStorage.getItem('user-libra')) {
                window.localStorage.removeItem('user-libra');
                window.location.href = '/portal';
            }
            return res.json()
        })
        .then(json => callBack(json.data))
        .catch(err => console.error(err))
    }catch (e){
        //console.log(e);
    }
}

async function deleteNovedad(token,id, callBack) {
    try{
        // send `DELETE` request
        await fetch(`${url}/novedades/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'authorization': 'Bearer '+ token
            }
        })
        .then(res => {
            if(res.status===401 && window.localStorage.getItem('user-libra')) {
                window.localStorage.removeItem('user-libra');
                window.location.href = '/portal';
            }
            return res.json()
        })
        .then(json => callBack(json.data))
        .catch(err => console.error(err))
    }catch (e){
        //console.log(e);
    }
}

async function editNovedad(token,novedad, callBack) {
    try{
        const formData = new FormData()
        formData.append('titulo', novedad.titulo)
        formData.append('subtitulo', novedad.subtitulo)
        formData.append('descripcion', novedad.descripcion)
        formData.append('imagen', novedad.imagen)
        formData.append('link_video', novedad.link_video)
        formData.append('tipo_modelo', novedad.tipo_modelo)
        // send `DELETE` request
        await fetch(`${url}/novedades/update/${novedad.id}`, {
            method: 'PUT',
            headers: {
                'authorization': 'Bearer '+ token_LIBRA
            },
            body: formData,
        })
        .then(res => {
            if(res.status===401 && window.localStorage.getItem('user-libra')) {
                window.localStorage.removeItem('user-libra');
                window.location.href = '/portal';
            }
            return res.json()
        })
        .then(json => callBack(json.data))
        .catch(err => console.error(err))
    }catch (e){
        //console.log(e);
    }
}

async function getAll(callBack) {
    try{
        // send `POST` request
        await fetch(`${url}/novedades/list`, {
            method: 'GET',
            headers: {
                'authorization': 'Bearer '+ token_LIBRA
            }
        })
        .then(res => {
            return res.json()
        })
        .then(json => callBack(json.data))
        .catch(err => callBack([]))
    }catch (e){
        callBack([])
    }
}


// send `POST` request
const NovedadService = {
    create,
    deleteNovedad,
    getAll,
    editNovedad
};


export default NovedadService
