//react
import {useEffect, useState} from "react"

//Styled components
import {
    Wrapper,
    WrapperContent,
    ArrowWrapper,
    HuellaWrapper,
    RadioWrapper,
    ButtonWrapper,
    ListRadioWrapper,
    Form
} from './elements'

//Components
import Searcher from '../../components/Searcher'
import Button from '../../components/Button'
import GoogleMap from '../../components/GoogleMap'
import Input from "../../components/Input"
import TelefonoInput from "../../components/TelefonoInput"
import Message from "../../components/Message"
import StyledSelect from "../../components/StyledSelect"

//Context
import { useTicketContext } from '../../context/TicketContext'

//Services
import ClienteService from '../../services/Cliente'
import AutoService from '../../services/Auto'

//Data
import { provincias, isInProvincias } from "./provincias"

const states = [
    'Sin iniciar',
    'identificado', //etapa 2
    'UbicacionMaps',//etapa 3
    'completaDesde',//etapa 4
    'selecciono falla',//etapa 5
    'UbicacionMaps',//etapa 6
    'ConfirmarUbicacion',//etapa 6
    'Cuantos viajan',//etapa 6
    'Resumen',//etapa 7

]

const WebApp = () => {
    const { state, rollBack } = useTicketContext();
    const [error, setError] = useState(null);
    const [component, setComponent] = useState(getComponent());
    const [ modal, setModal ] = useState({
        value: 'error',
        message: 'Informamos que este medio de comunicación ha sido deshabilitado, por favor comuníquese al 08001226883'
    });

    function getComponent() {
        switch (state){
            case 0 :
                return (<IdentificacionCliente setError ={setError}/>)
            case 1 :
                return (<SeleccionRandom setError ={setError}/>)
            case 2 :
                return (<UbicacionMaps tipo={'origen'} tittle={"Seleccione su ubicación actual"} setError ={setError}/>)
            case 3 :
                return (<ConfirmarUbicacion tittle={"Ubicacion desde donde traslada"} tipo={'origen'} setError ={setError}/>)
            case 4 :
                return (<ElegirFalla setError ={setError}/>)
            case 5 :
                return (<Telefono setError ={setError}/>)
            case 6 :
                return (<UbicacionMaps tipo={'destino'} tittle={"Seleccione a donde desea ir"} setError ={setError}/>)
            case 7 :
                return (<ConfirmarUbicacion tittle={"Ubicacion hacia donde traslada"}  tipo={'destino'} setError={setError} subSuelo={false}/>)
            case 8 :
                return (<CuantosViajan setError ={setError}/>)
            case 9 :
                return (<Resumen setError ={setError}/>)
            default:
                return (<IdentificacionCliente setError ={setError}/>)
        }
    }

    useEffect(()=>{
        var x = getComponent();
        if(x !== component)
            setComponent(x);
    },[state]);

    return (
        <Wrapper>
            {
                error?
                    <Message mensaje={error} action={
                            () => {
                                setError(null)
                                if(state === 1)
                                {
                                    rollBack()
                                }
                            }
                        }
                    />
                :
                    ""
            }
            {
                (state+1) <= 9?
                    <h3>Paso {state + 1} de 9</h3>
                    :
                    <h3>Resumen</h3>
            }
            
            <HuellaWrapper>
                {
                    states.map((val,index)=>{
                        return(
                            <ArrowWrapper key={index} color={state > index?'#E95612':'#222222' }>
                                <div className={'downRectangle'}></div>
                                <div className={'topRectangle'}></div>
                            </ArrowWrapper>
                        )
                    })
                }
            </HuellaWrapper>
            {
                //component
            }
            {
                modal.value?
                    <Message type={modal.value} mensaje={modal.message} action={
                        modal.value !== 'loading' &&  modal.value !== 'error'? () => {
                            window.location.href = `/`;
                        } : () => {
                            setModal({
                                value: null,
                                message: ''
                            })
                        }
                    }
                    />
                    :
                    ""
            }
            {
                !modal.value || modal.value === 'error'?
                     <> 
                     </>
                 :
                     ''
             }
            <Button clase={"fixed-button"} text={"Reset"} clickHandler={rollBack}/>
        </Wrapper>
    )
}
const IdentificacionCliente = ({setError}) => {
    const { getCar, nextState } = useTicketContext();
    const [values, setValues] = useState({});
    const [filters, setFilters] = useState({marca:[],color:[],modelo:[]});

    const continuar =async ()  => {
        if(values.dni && values.dni !== "")
        {
            if(values.patente && values.patente !== "")
            {
                let resultado = await getCar(values)
                if(resultado === true)
                    nextState();
                else
                    setError(`No se encontro el vehiculo en nuestra base de datos. Comunicate con nosotros por <a href='https://wa.link/1ko1xl'>Whats App</a> al 1156347195.`);
            }
            else
                setError("Brindanos tu patente para poder identificarte como cliente.");
        }
        else
            setError("Brindanos tu DNI para poder identificarte como cliente.");
    }


    const changeHandler = (obj, name) => {
        setValues({
            ...values,
            [name]: obj
        })
    }

    useEffect( ()=>{
        AutoService.getFilters(['marca','modelo','color'],(json)=>{
            if(json.status === "SUCCESS")
                setFilters(json.data);
        });
    },[]);

    return (
            <WrapperContent>
                <h1>Identificacion del Cliente</h1>
                <Input idValue={"dni"} values={values} changeValues={setValues} placeholder={"DNI"}/>
                <Input idValue={"patente"} values={values} changeValues={setValues} placeholder={"Patente"}/>
                {
                    /*
                    <Searcher add={changeHandler} fieldName={'marca'} listValues={filters.marca} placeholder='Marca'/>
                    <Searcher add={changeHandler} fieldName={'modelo'} listValues={filters.modelo} placeholder='Modelo'/>
                    <Searcher add={changeHandler} fieldName={'color'} listValues={filters.color} placeholder='Color'/>
                    <Input idValue={"poliza"} values={values} changeValues={setValues} placeholder={"Numero de poliza (no obligatorio)"}/>
                    */
                }
                <ButtonWrapper>
                    <Button clickHandler={() => window.close()} text={'Volver'}/>
                    <Button clickHandler={continuar} text={'Continuar'}/>
                </ButtonWrapper>
            </WrapperContent>
    )
}

const SeleccionRandom = ({setError}) => {
    const {lastState ,ticket,nextState} = useTicketContext();
    const [list,setList] = useState([]);

    useEffect(() => {
        if(!ticket.auto)
            setError("No se encontro el cliente.")
         ClienteService.getRandomWith(5,ticket.auto.titular,(json)=>{
            if(json.status === "SUCCESS")
                setList(json.data);
        });
    },[]);


    const continuar = async ()  => {
        let selected = document.querySelector('input[name="cliente"]:checked');
        if(selected && ticket.auto.titular == selected.value)
            nextState();
        else
            setError("Selección erronea.");
    }
    return (
        <WrapperContent>
            <h1>Seleccione al titular de la póliza</h1>
            <ListRadioWrapper>
                {
                    list.map((val,index)=>{
                        if(val !== null) 
                            return(
                                <RadioWrapper key={index}>
                                    <input type={'radio'} name={'cliente'} value={val.id} id={val.id}></input>
                                    <label htmlFor={val.id}>{`${val.apellido}, ${val.nombre}`}</label>
                                </RadioWrapper>
                            );
                        else
                            return(<></>)
                    })
                }
            </ListRadioWrapper>
            <ButtonWrapper>
                <Button clickHandler={lastState} text={'Volver'}/>
                <Button clickHandler={continuar} text={'Continuar'}/>
            </ButtonWrapper>
        </WrapperContent>
    )
}

const ElegirFalla = ({setError}) => {
    const {lastState, ticket,setTicket,handleTicket, nextState} = useTicketContext();
    const listFallas=[
        {
            nombre: 'Batería'
        }    , {
            nombre: 'Neumáticos'
        }    , {
            nombre: 'Accidentes tráfico'
        }    , {
            nombre: 'Motor'
        }    , {
            nombre: 'Bomba inyección diésel'
        }    , {
            nombre: 'Alternador'
        }    , {
            nombre: 'Motor de arranque'
        }    , {
            nombre: 'Discos de embrague'
        }    , {
            nombre: 'Cable de embrague'
        }    , {
            nombre: 'Cambio de marchas'
        }]

    const continuar =async ()  => {
        if(ticket.falla === '')
        {
            setError("Debe seleccionar una falla")
        }
        else
        {
            if((ticket.neumaticos === 'No' && ticket.neumaticosDescripcion === '') || (ticket.neumaticos === 'No' && !ticket.neumaticosDescripcion))
            {
                setError("Describa el estado de sus ruedas.")
            }
            else
            {
                if(ticket.accidente === '')
                {

                    setError("Establezca si ha sufrido un accidente.")
                }
                else
                {
                    if (ticket.accidente === 'Si')
                        ticket.accidente = ticket.tipoAccidente;
                    else
                        ticket.tipoAccidente = '';
                    nextState();
                }
            }
        }
        return;
    }

    const addValue =async (e)  => {
        handleTicket(e.target.id,e.target.value);
    }

    const changeHandler = (obj,name) => {
        let aux = {...ticket};
        aux[name] = obj.nombre;
        setTicket(aux);

    }
    return (
        <WrapperContent>
            <h1>Seleccione la falla del vehiculo</h1>
            <Form column={true}>
                <p>Tipo de vehiculo</p>
                <StyledSelect options={['', 'Auto', 'Moto', 'Camioneta']} id={'tipoVehiculo'} data={ticket} setter={setTicket}/>
            </Form>
            {
                ticket.tipoVehiculo && ticket.tipoVehiculo !== ''?
                    <Form column={true}>
                        <p>Descripcion adicional {`${ticket.tipoVehiculo === 'Auto' ? '¿Presenta modificaciones? Por ej. Altura' : ticket.tipoVehiculo === 'Moto' ? '¿Cilindrada?' : '¿Tiene caja? ¿altura? ¿largo?'}`}</p>
                        <textarea id={'vehiculoDescripcion'} value={ticket.vehiculoDescripcion} onChange={addValue}/>
                    </Form>
                :
                    ''
            }
            <Form column={true}>
                <p>¿Has sufrido un accidente, choque o siniestro?</p>
                <StyledSelect options={['', 'Si', 'No']} id={'accidente'} data={ticket} setter={setTicket}/>
            </Form>
            {
                ticket.accidente && ticket.accidente === 'Si'?
                    <Form column={true}>
                        <p>Elegí el suceso ocurrido correspondiente:</p>
                        <StyledSelect options={['Siniestro', 'Choque', 'Accidente']} id={'tipoAccidente'} data={ticket} setter={setTicket}/>
                    </Form>
                :
                    ''
            }
            <Form column={true}> 
                <Searcher data={ticket} add={changeHandler} fieldName={'falla'} listValues={listFallas} placeholder='Ingrese la falla que presenta el vehiculo.' valueFromList={true}/>
            </Form>
            <Form column={true}>
                <p>Descripcion adicional sobre la falla</p>
                <textarea id={'fallaDescripcion'} value={ticket.fallaDescripcion} onChange={addValue}/>
            </Form>
            <Form column={true}>
                <p>¿Giran las ruedas con normalidad?</p>
                <StyledSelect options={['', 'Si', 'No']} id={'neumaticos'} data={ticket} setter={setTicket}/>
            </Form>
            {
                ticket.neumaticos && ticket.neumaticos === 'No'?
                    <Form column={true}>
                        <p>Descripcion adicional (¿Cuantas? ¿Cuales?)</p>
                        <textarea id={'neumaticosDescripcion'} value={ticket.neumaticosDescripcion} onChange={addValue}/>
                    </Form>
                :
                    ''
            }
            <Form column={true}>
                <p>¿Tipo de asistencía?</p>
                <StyledSelect options={['Grua', 'Asistencia Mecanica']} id={'tipoSolicitud'} data={ticket} setter={setTicket}/>
            </Form>
            <ButtonWrapper>
                <Button clickHandler={lastState} text={'Volver'}/>
                <Button clickHandler={continuar} text={'Continuar'}/>
            </ButtonWrapper>
        </WrapperContent>
    )
}

const UbicacionMaps = ({tipo, tittle, setError}) => {
    const { lastState, setGoogleAddress, nextState, setAddress, address } = useTicketContext();

    const continuar = async ()  => {
        if(address.longitud && address.latitud && address.longitud !== '' && address.latitud !== '' )
        {
            setGoogleAddress(tipo);
            nextState();
        }
        else
        {
            setError('Debe seleccionar su ubicacion en el mapa.')
        }
    }

    return(
        <WrapperContent>
            <h1>{tittle}</h1>
            <GoogleMap setAddress={setAddress}/>
            <ButtonWrapper>
                <Button clickHandler={lastState} text={'Volver'}/>
                <Button clickHandler={continuar} text={'Continuar'}/>
            </ButtonWrapper>
        </WrapperContent>
    )
}

const ConfirmarUbicacion = ({ tittle, setError, tipo, subSuelo = true }) => {
    const { lastState, setUbicacion, nextState, address, setAddress } = useTicketContext();
    const [provincia, setProvicia] = useState({})
    const [localidades, setLocalidades] = useState([])
    const [first, setFirst] = useState(true)
    const [opcion, setOpcion] = useState(address.ruta && address.ruta !== '' ? 'ruta' : 'calle')

    console.log(address);
    const continuar = async ()  => {
        if((address.calle && address.calle !== "") || (address.ruta && address.ruta !== ""))
        {
            if((address.calle !== "" && address.numero && address.numero !== "") || (address.ruta !== "" && address.km && address.km !== ""))
            {
                if(address.localidad && address.localidad !== "" && address.provincia && address.provincia !== "")
                {
                    if(subSuelo && address.lugar !== '' && ((address.lugar === 'SUBSUELO' || address.lugar === 'GARAJE CERRADO') && ((!address.alto || (address.alto && (parseFloat(address.alto) <= 0 || address.alto === ''))) || (!address.ancho || (address.ancho && (parseFloat(address.ancho) <= 0 || address.ancho === ''))))))
                    {
                        setError("Complete los datos referidos al lugar.")
                    }
                    else
                    {
                        let resultado = await setUbicacion(tipo);
                        if(resultado === true)
                            nextState();
                        else
                            setError(resultado);
                    }
                }
                else
                {
                    setError("Complete su ubicación para una mejor atención. ¿En que localidad y provincia se encuentra?")
                }
            }
            else
            {
                setError("Complete su ubicación para una mejor atención. ¿En que KM o numeración se encuentra?")
            }
        }
        else
        {
            setError("Complete su ubicación para una mejor atención. ¿En que calle o ruta se encuentra?")
        }
    }

    const addProvincia = (obj, nombre) => {
        setAddress({
            ...address,
            provincia: obj.nombre
        })
        setProvicia(obj)
    }


    const addLocalidad = (obj, nombre) => {
        setAddress({
            ...address,
            localidad: obj.nombre
        })
    }

    const handleOpcion = (e) => {
        setOpcion(e.target.value)
    }

    useEffect(() => {
        if(!first)
        {
            if(provincia.id)
            {
                if(!localidades || localidades.length <= 0)
                {
                    fetch(`https://apis.datos.gob.ar/georef/api/municipios?provincia=${provincia.id}&campos=id,nombre&max=1000`)
                    .then((data) => data.json())
                    .then((json) => setLocalidades(json.municipios))
                }
            }
            else
            {
                setAddress({
                    ...address,
                    localidad: ""
                })
                setLocalidades([])
            }
        }
        else
            setFirst(false)
    }, [provincia])

    useEffect(() => {
        if(address.provincia !== "")
        {
            provincias.forEach((value) => {
                if(address.provincia.toLowerCase().includes(value.nombre.toLowerCase()))
                {
                    setAddress({
                        ...address,
                        provincia: value.nombre
                    })
                    setProvicia(value)
                }
            })
        }
    }, [])

    useEffect(() => {
        if(opcion === 'ruta')
        {
            setAddress({
                ...address,
                calle: '',
                numero: '',
                lugar: '',
                alto: null,
                ancho: null,
                entreC1: '',
                entreC2: '',
            })
        }
        else if(opcion === 'calle')
        {
            setAddress({
                ...address,
                ruta: '',
                km: '',
            })
        }
    }, [opcion])
    
    return(
        <WrapperContent morePadding={true}>
            <h1>{tittle}</h1>
            <h2>Complete los datos faltantes para una mejor atencion</h2>
            <Form className="radio">
                <RadioWrapper>
                    {
                        opcion === 'calle'?
                            <input type={'radio'} name={'tipo'} value={'calle'} id={'calle'} onChange={e => handleOpcion(e)} checked/>
                        :
                            <input type={'radio'} name={'tipo'} value={'calle'} id={'calle'} onChange={e => handleOpcion(e)}/>
                    }
                    <label htmlFor={'calle'}>{`Calle`}</label>
                </RadioWrapper>
                <RadioWrapper>
                    {
                        opcion === 'ruta'?
                            <input type={'radio'} name={'tipo'} value={'ruta'} id={'ruta'} onChange={e => handleOpcion(e)} checked/>
                        :
                            <input type={'radio'} name={'tipo'} value={'ruta'} id={'ruta'} onChange={e => handleOpcion(e)}/>
                    }
                    <label htmlFor={'ruta'}>{`Ruta`}</label>
                </RadioWrapper>
            </Form>
            {
                opcion === 'calle'?
                    <Form>
                        <Form half={true} column={true}>
                            <Input idValue={"calle"} values={address} changeValues={setAddress} text={"Calle"}/>
                            <Input idValue={"entreC1"} values={address} changeValues={setAddress} text={"Entre calle 1"}/>
                            {
                                subSuelo?
                                    <div
                                        style={
                                            {
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '15px',
                                                margin: '10px 0'
                                            }
                                        }
                                    >
                                        <p>¿Donde se encuentra?</p>
                                        <StyledSelect options={['', 'ESTACIONAMIENTO', 'GARAJE CERRADO', 'PLAYON', 'SUBSUELO', 'ZANJAS', 'PENDIENTE', 'NINGUNA DE LAS ANTERIORES']} id={'lugar'} data={address} setter={setAddress}/>
                                    </div>
                                :
                                    ''
                            }
                            {
                                address.lugar && (address.lugar === 'SUBSUELO' || address.lugar === 'GARAJE CERRADO')?
                                    <div
                                        style={
                                            {
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '15px',
                                                margin: '10px 0'
                                            }
                                        }
                                    >
                                        <Input type="number" idValue={"alto"} values={address} changeValues={setAddress} text={"Alto (mts)"}/>
                                        <Input type="number" idValue={"ancho"} values={address} changeValues={setAddress} text={"Ancho (mts)"}/>
                                    </div>
                                :
                                    ''
                            }
                        </Form>
                        <Form half={true} column={true}>
                            <Input type="number" idValue={"numero"} values={address} changeValues={setAddress} text={"Numero"}/>
                            <Input idValue={"entreC2"} values={address} changeValues={setAddress} text={"Entre calle 2"}/>
                        </Form>
                    </Form>
                :
                    <Form>
                        <Input idValue={"ruta"} values={address} changeValues={setAddress} text={"Ruta"}/>
                        <Input type="number" idValue={"km"} values={address} changeValues={setAddress} text={"KM"}/>
                    </Form>
            }
            <Form column={true}>
                <Searcher text={"Provincia"} add={addProvincia} listValues={provincias} inputDefaultValue={address.provincia} valueFromList={true}/>
                <Searcher text={"Localidad"} add={addLocalidad} listValues={localidades} inputDefaultValue={address.localidad}/>
            </Form>
            <Form column={true}>
                <p>Comentarios y Referencias</p>
                <textarea value={address.referencias} onChange={ (e) =>
                    setAddress(
                        {
                            ...address,
                            referencias: e.target.value
                        }
                    )
                }/>
            </Form>
            <ButtonWrapper>
                <Button clickHandler={lastState} text={'Volver'}/>
                <Button clickHandler={continuar} text={'Continuar'}/>
            </ButtonWrapper>
        </WrapperContent>
    )
}

const Telefono = ({setError}) => {
    const { lastState,setTicket,ticket, telefonos, setTelefonos, nextState } = useTicketContext();
    const [cantidad, setCantidad] = useState(1)


    const continuar = async ()  => {
        let x = false
        let ticketAux = {...ticket};
        for (let step = 1; step <= cantidad; step++) {
            if(telefonos['cod'+step] !== '' && telefonos['numero'+step] !== ''){
                let aux = `(${telefonos['cod'+step]}) ${telefonos['numero'+step]}`;
                ticketAux['telefono'+step] = aux;
                x = true;
            }
        }
        if(x){
            setTicket(ticketAux);
            nextState();
        } else{
            setError('Debe ingresar al menos un telefono.')
        }
    }

    const actualizarCantidad = (add = true) => {
        if(add)
        {
            if(cantidad < 3)
                setCantidad(cantidad + 1)
            else
                alert('Solo se aceptan hasta 3 telefonos')
        }
        else
            if(cantidad !== 0)
                setCantidad(cantidad - 1)
    }

    let components = []
    for (let step = 0; step < cantidad; step++) {
        components.push(<TelefonoInput id={step+1} value={telefonos} setValue={setTelefonos}/>)
    }

    return(
        <WrapperContent>
            <h1>Indique sus celulares de contacto. (codigo de area + numero de celular)</h1>
            {
                components
            }
            <ButtonWrapper>
                <button className="add" onClick={() => actualizarCantidad(false)}>-</button>
                <button className="add" onClick={() => actualizarCantidad()}>+</button>
            </ButtonWrapper>
            <ButtonWrapper>
                <Button clickHandler={lastState} text={'Volver'}/>
                <Button clickHandler={continuar} text={'Continuar'}/>
            </ButtonWrapper>
        </WrapperContent>
    )
}

const CuantosViajan = ({setError}) => {
    const {lastState, ticket, setTicket, handleTicket, nextState} = useTicketContext();

    const continuar = async ()  => {
        if(ticket.trasladoOcupantes && ticket.trasladoOcupantes === 'Si' && (!ticket.cantidad || ticket.cantidad === 0))
        {
            setError("Complete la cantidad de ocupantes, o en su defecto seleccione sin ocupantes.")
        }
        else
        {
            nextState();
            return;
        }
    }

    const addValue =async (e)  => {
        handleTicket(e.target.id, e.target.value);
    }

    return (
        <WrapperContent>
            <h1>Cantidad de acompañantes</h1>
            <Form column={true}>
                <p className={'texto'}>¿El vehiculo se encuentra cargado (objetos)?</p>
                <StyledSelect options={['', 'Si', 'No']} id={'carga'} data={ticket}
                    setter={
                        (objetoNuevo) => {
                            if(objetoNuevo.carga !== 'Si')
                            {
                                setTicket({
                                    ...objetoNuevo,
                                    cargaDescripcion: ''
                                })
                            }
                            else 
                            {
                                setTicket(objetoNuevo)
                            }
                        }
                    }
                />
            </Form>
            {
                ticket.carga && ticket.carga === 'Si'?
                    <Form column={true}>
                        <p>Especifique la carga</p>
                        <textarea value={ticket.cargaDescripcion} id={'cargaDescripcion'} onChange={addValue}/>
                    </Form>
                :
                    ''
            }
            <Form column={true}>
                <p className={'texto'}>¿Se van a trasladar ocupantes?</p>
                <StyledSelect options={['', 'Si', 'No']} id={'trasladoOcupantes'} data={ticket} setter={setTicket}/>
            </Form>
            {
                ticket.trasladoOcupantes && ticket.trasladoOcupantes === 'Si'?
                    <>
                        <Form>
                            <Form  column={true}>
                            <p className={'texto'}>Cantidad de adultos:</p>
                                <Input type={'number'} idValue={"cantidad"} values={ticket} changeValues={setTicket} placeholder={"ingrese la cantidad de adultos"} />
                            </Form>
                        </Form>
                        <p 
                            style={
                                {
                                    margin: '0 75px',
                                    textAlign: 'center'
                                }
                            }
                        >
                            Sólo pueden viajar en el móvil que acude a resolver el desperfecto 
                            tanta cantidad de pasajeros como asientos disponibles de fábrica tiene dicho móvil (habitualmente sólo uno). 
                            Cuando los acompañantes son menores de edad, no pueden ser trasladados en los móviles de Move ON dado que la Ley Nacional de 
                            Tránsito vigente Nº 24.449, artículo 40, inciso g, no permite que los mismos viajen en asientos delanteros, 
                            único tipo con el que cuentan los mismos. 
                        </p>
                    </>
                :
                    ''
            }
            <ButtonWrapper>
                <Button clickHandler={lastState} text={'Volver'}/>
                <Button clickHandler={continuar} text={'Continuar'}/>
            </ButtonWrapper>
        </WrapperContent>
    )
}

const Resumen = ({ setError }) => {
    const { ticket, saveTicket, lastState } = useTicketContext();
    const [ resumen, setResumen ] = useState({})
    const [ modal, setModal ] = useState({
        value: null,
        message: ''
    });

    const finalizar = async ()  => {
        //setModal({
        //    value: 'loading',
        //    message: 'Su ticket está siendo generado, por favor aguarde.'
        //})
        setModal({
            value: 'error',
            message: "No se pudo generar su ticket. Reinicia el ticket e intentalo nuevamente, o comuniquese a 0800 122 6883"
        })
        /*let x = await saveTicket();
        if(x)
        {
            setModal({
                value: 'success',
                message: "Su ticket fue derivado con éxito. Aguarde y sera contactado a través de un representante."
            })
        }else
        {
            setModal({
                value: 'error',
                message: "No se pudo generar su ticket. Reinicia el ticket e intentalo nuevamente, o comuniquese a 0800 122 6883"
            })
        }*/

    }

    useEffect(() => {
        let aux = {...resumen}

        aux = {...ticket}
        aux.patente = ticket.auto.patente
        aux.color = ticket.auto.color
        aux.origen = ticket.origen.calle !== '' ? `${ticket.origen.calle} ${ticket.origen.numero}, ${ticket.origen.localidad}, ${ticket.origen.provincia}` : `${ticket.origen.ruta} ${ticket.origen.km}, ${ticket.origen.localidad}, ${ticket.origen.provincia}`
        aux.destino = ticket.destino.calle !== '' ? `${ticket.destino.calle} ${ticket.destino.numero}, ${ticket.destino.localidad}, ${ticket.destino.provincia}` : `${ticket.destino.ruta} ${ticket.destino.km}, ${ticket.destino.localidad}, ${ticket.destino.provincia}`
        aux.falla = `${ticket.falla}${ticket.fallaDescripcion && ticket.fallaDescripcion !== '' ? `: ${ticket.fallaDescripcion}` : ''}`

        ClienteService.getById(ticket.auto.titular, (json) => {
            aux.titular = `${json.data.apellido}, ${json.data.nombre} (${json.data.dni})`
            AutoService.getById(ticket.auto.modelo, (json) => {
                aux.vehiculo = `${json.data.marca.nombre}, ${json.data.nombre}`
                setResumen(aux)
            })
        })

    }, [])

    return (
        <>
            {
                modal.value?
                    <Message type={modal.value} mensaje={modal.message} action={
                        modal.value !== 'loading' &&  modal.value !== 'error'? () => {
                            window.location.href = `/`;
                        } : () => {
                            setModal({
                                value: null,
                                message: ''
                            })
                        }
                    }
                    />
                    :
                    ""
            }
            <WrapperContent>
                <h1>Resumen</h1>
                <h3>Revise la información en su resumen y presione finalizar para enviar su solicitud.</h3>
                    <Input onlyRead={true} text={"Vehiculo"} idValue={"vehiculo"} values={resumen}/>
                    <Input onlyRead={true} text={"Patente"} idValue={"patente"} values={resumen}/>
                    <Input onlyRead={true} text={"Color"} idValue={"color"} values={resumen}/>
                    <Input onlyRead={true} text={"Titular"} idValue={"titular"} values={resumen}/>
                    {
                        ticket.telefono1 && ticket.telefono1 !== "" ?
                            <Input onlyRead={true} text={"Telefono 1"} idValue={"telefono1"} values={resumen}/>
                        :
                            ""
                    }
                    {
                        ticket.telefono2 && ticket.telefono2 !== "" ?
                            <Input onlyRead={true} text={"Telefono 2"} idValue={"telefono2"} values={resumen}/>
                        :
                            ""
                    }
                    {
                        ticket.telefono3 && ticket.telefono3 !== "" ?
                            <Input onlyRead={true} text={"Telefono 3"} idValue={"telefono3"} values={resumen}/>
                        :
                            ""
                    }
                    <Input onlyRead={true} text={"Origen"} idValue={"origen"} values={resumen}/>
                    <Input onlyRead={true} text={"Falla"} idValue={"falla"} values={resumen}/>
                    <Input onlyRead={true} text={"Destino"} idValue={"destino"} values={resumen}/>
                    {
                        resumen.cantidad > 0?
                            <Input onlyRead={true} text={"Adultos"} idValue={"cantidad"} values={resumen}/>
                        :
                            ''
                    }
                <ButtonWrapper>
                    {
                        !modal.value || modal.value === 'error'?
                            <> 
                                <Button clickHandler={lastState} text={'Volver'}/>
                                <Button clickHandler={finalizar} text={'Finalizar'}/>
                            </>
                        :
                            ''
                    }
                </ButtonWrapper>
            </WrapperContent>
        </>
    )
}

export default WebApp
