

const {token_LIBRA, url} = require('./constantes');

async function getCoordinates(address, callBack) {
    try{
        await fetch(`${url}/google/getcoordinates?address=${address}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+ token_LIBRA
            },
        }).then(res => {
            return res.json()
        }).catch(res => {
            return res.json()
        }).then((json) => callBack(json))
    }catch (e){
        //console.log(e);
    }
}

async function googleGeocode(desde,hasta,base,token,callBack) {
    try{
        let body = {
            desde:desde,
            hasta:hasta,
            base:base
        }
        await fetch(`${url}/google/geocode`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+token
            },
            body:JSON.stringify(body)
        }).then(res => {
            if(res.status===401 && window.localStorage.getItem('user-libra')) {
                window.localStorage.removeItem('user-libra');
                window.location.href = '/portal';
            }
            return res.json()
        }).catch(res => {
            return res.json()
        }).then((json) => callBack(json.data))
    }catch (e){
        //console.log(e);
    }
}

async function getTickets({initDate, finishDate, estado, page, offset, ticket_numero,ticket_patente, base},token,callBack) {
    try{
        await fetch(`${url}/grueros/getmytickets?from=${initDate}&to=${finishDate}&prestadorId=${base}&estado=${estado}&page=${page}&offset=${offset}${ticket_numero ? `&ticket_numero=${ticket_numero}&ticket_patente${ticket_patente}` : ''}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+token
            },
        }).then(res => {
            if(res.status===401 && window.localStorage.getItem('user-libra')) {
                window.localStorage.removeItem('user-libra');
                window.location.href = '/portal';
            }
            return res.json()
        }).catch(res => {
            callBack(null)
        }).then((json) => {

            callBack(json)
        })
    }catch (e){
        //console.log(e);
    }
}
async function getComprobantes(nroTicket,Fecha,token) {
    try{
        let response = await fetch(`${url}/grueros/servicio/getComprobantes?ticket=${nroTicket}&Fecha=${Fecha}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+token
            },
        }).then(res => {
            if(res.status===401 && window.localStorage.getItem('user-libra')) {
                window.localStorage.removeItem('user-libra');
                window.location.href = '/portal';
            }
            return res.json();
        }).catch(res => {
            console.log(res);
            return null;
        }).then((json) => {
            return json;
        })
        return response;
    }catch (e){
        //console.log(e);
    }
}
async function getRechazoImg(nroTicket,Fecha,token) {
    try{
        let response = await fetch(`${url}/grueros/servicio/getRechazosImg?ticket=${nroTicket}&Fecha=${Fecha}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+token
            },
        }).then(res => {
            if(res.status===401 && window.localStorage.getItem('user-libra')) {
                window.localStorage.removeItem('user-libra');
                window.location.href = '/portal';
            }
            return res.json();
        }).catch(res => {
            console.log(res);
            return null;
        }).then((json) => {
            return json;
        })
        return response;
    }catch (e){
        //console.log(e);
    }
}
// send `POST` request


async function sendFactura(values,token) {
    try{
        //add file to formData object
        let fd = new FormData();
        Object.keys(values).forEach(
            key => {
                if (key === 'archivo'){
                    fd.append(key+'[]', values[key].file)
                }
                else
                    fd.append(key, values[key])
            }
        );

        let response = await fetch(`${url}/grueros/sendFacturaMensual`, {
            method: 'POST',
            headers: {
                'authorization': 'Bearer '+ token
            },
            body: fd,
        }).then(res => {
            if(res.status===401 && window.localStorage.getItem('user-libra')) {
                window.localStorage.removeItem('user-libra');
                window.location.href = '/portal';
            }
            return res.json()
        }).catch(res => {
            return res.json()
        })
        return response;
    }catch (e){
        //console.log(e);

    }
}

async function changeState(values,token) {
    try{
        //add file to formData object
        let fd = new FormData();
        Object.keys(values).forEach(
            key => {
                if (key === 'archivo'){
                    values[key].forEach(file =>{
                        fd.append('archivo', file)
                    })
                }
                else if(key==='adicionales'){
                    fd.append('adicionales',JSON.stringify(values[key]))
                }
                else {
                    fd.append(key, values[key])
                }
            }
        );
        let response = await fetch(`${url}/grueros/servicio/changeEstado`, {
            method: 'POST',
            headers: {
                'authorization': 'Bearer '+ token
            },
            body: fd,
        }).then(res => {
            if(res.status===401 && window.localStorage.getItem('user-libra')) {
                window.localStorage.removeItem('user-libra');
                window.location.href = '/portal';
            }
            return res.json()
        }).catch(res => {
            return res.json()
        })
        return response;
    }catch (e){
        //console.log(e);

    }
}

async function getImage(src,token) {
    try{

        let response = await fetch(`${url}/grueros/servicio/getImage?file=${src}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+ token
            },
        }).then(async res => {
            if(res.status===401 && window.localStorage.getItem('user-libra')) {
                window.localStorage.removeItem('user-libra');
                window.location.href = '/portal';
            }
            if(res.status===500 ) {
                return {status:'ERROR',message:'Ocurrio un error en el servidor.\n Contacte con su administrador.'}
            }
            return res.blob();
        }).then((response)=>{
            return response
        })
        .catch(res => {
            return res
        })
        return response;
    }catch (e){
        //console.log(e);

    }
}

async function getBases(token,callBack) {
    try{
        await fetch(`${url}/grueros/getBases/`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+ token
            },
        }).then(res => {
            if(res.status === 401 && window.localStorage.getItem('user-libra')) {
                window.localStorage.removeItem('user-libra');
                window.location.href = '/portal';
            }
            return res.json()
        }).catch(res => {
            callBack(null)
        }).then((json) => callBack(json))
    }catch (e){
        //console.log(e);
    }
}



const GruerosService = {
    getTickets,
    googleGeocode,
    sendFactura,
    changeState,
    getCoordinates,
    getComprobantes,
    getRechazoImg,
    getImage,
    getBases
};

export default GruerosService
