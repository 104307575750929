import { createContext, useContext, useState } from 'react'
import SumateFormDTO from '../DTOs/SumateForm';
import ContactoFormDTO from '../DTOs/ContactoForm';
import FormService from '../services/Forms';


const FormContext = createContext([])

export const useFormContext = () => useContext(FormContext)

export const FormContextProvider = ({ children }) => {

	 const saveForm = async (values,type) => {

		let res;
		
	 	switch (type) {
	 		case 'Sumate':
	 			res = await FormService.guardarSumateForm(new SumateFormDTO(values));
	 			break;

	 		case 'Consultas':
	 			res = await FormService.guardarContactoForm(new ContactoFormDTO(values));
	 			break;
	 		default:
	 			res = {
					status: 'FAILURE',
					message: "Formulario no encontrado",
				};
	 		break;
		}
		return res;
    }

	//funcion que devuelve el form al estado inicial
	const rollback = (type) => {

	}

    return(
    	<FormContext.Provider value={
    		{
    		saveForm,
			rollback
    	}}>
    		{children}
    		</FormContext.Provider>	
    	)
	
}


