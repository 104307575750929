import styled from 'styled-components'

export const Wrapper = styled.div`
    max-height: calc(100vh - 130px);
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    margin-top: 130px;

    @media screen and (max-width: 1260px){
        margin-top: 84px;
    }

    .fondo{
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        transform: scaleX(-1); 

        @media screen and (max-width: 1400px){
            transform: translateX(-1px) scaleX(-1.00001);
        }
    }

    &:before{
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 1;
    }
`

export const WrapperContent = styled.div`
    position: absolute;
    z-index: 2;
    padding: 30vh 8vw;

    h1{
        z-index: 2;
        color: #E95612;
        font-size: 4vw;
        font-weight: 900;
    }

    h2{
        z-index: 2;
        color: white;
        font-size: 2vw;
        font-weight: 300;
    }

    @media screen and (max-width: 1020px){
        padding: 10vh 5vw;

        h1{
            font-size: 6vw !important;
        }

        h2{
            font-size: 4vw !important;
        }
    }
`