import { createContext, useContext, useState } from 'react'

const HeaderContext = createContext([])

export const useHeaderContext = () => useContext(HeaderContext)

export const HeaderContextProvider = ({ children }) => {
    const [sectionVisible, setSectionVisible] = useState("home")

    return (
        <HeaderContext.Provider value={{
            sectionVisible,
            setSectionVisible
        }}>
            { children }
        </HeaderContext.Provider>
    )
}

