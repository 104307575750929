//React
import { Link } from 'react-router-dom'

//Styled Components
import {
  FooterWrapper,
  FooterPart,
  Column,
  Line
} from './elements'


const Footer = () => {
  return (
    <FooterWrapper>
      <Column>
        <FooterPart>
          <img src={`${process.env.PUBLIC_URL}/assets/images/logos/moveonlibra.webp`} alt='logo'/>
        </FooterPart>
        <FooterPart>
          <p>AUXILIAR-T S.A.</p>
        </FooterPart>
        <FooterPart>
          <p>Lavalle 1528</p>
        </FooterPart>
        <FooterPart>
          <p>Ciudad Autonoma de Buenos Aires, Buenos Aires - CP:1048</p>
        </FooterPart>
      </Column>
      <Line/>
      <Column>
        <FooterPart>
          <Link to='/#seguridad'>Datos útiles</Link>
        </FooterPart>
        <FooterPart>
          <Link to='/forms/consultas' target="_blank">Consultas y sugerencias</Link>
        </FooterPart>
        <FooterPart>
          <p><Link to='/terminosycondiciones'>Terminos y condiciones</Link></p>
        </FooterPart>
        <FooterPart>
          <p><Link to='/politicas'>Politicas y privacidad</Link></p>
        </FooterPart>
      </Column>
      <Line/>
      <Column>
        <FooterPart>
          <p>MOVE ON by Libra</p>
        </FooterPart>
        <FooterPart>
          <p>Todos los derechos reservados</p>
        </FooterPart>
        {/*<FooterPart>
          <p><a href='https:\\www.cat-technologies.com'>Desarrollado por CAT Technologies</a></p>
  </FooterPart>*/}
      </Column>
    </FooterWrapper>
  )
}

export default Footer