import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { FaBars } from 'react-icons/fa'
import {
    ImCross
} from 'react-icons/im'

export const HeaderWrapper = styled.div`
    height: ${(props) => props.height ? props.height : '135px'};
    width: 100vw;
    background-color: ${(props) => props.changeHeader ? props.background === 'white'? 'rgba(255, 255, 255, 0.75)' : 'rgba(34, 34, 34, 0.75)' :  props.background === 'white' ? 'white' : 'rgb(34, 34, 34)'};
    transition: all 500ms ease;
    color: ${(props) => props.background === 'white'? 'rgba(34, 34, 34)' : 'white'};
    padding: 0 25px;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 5;
    border-bottom: ${(props) => props.background === 'white' ? 'solid 3px #E95612' : 'none'};

    @media screen and (max-width: 1260px){
        height: 84px;
        justify-content: center;
    }
`

export const WrapperLogo = styled.div`
    height: 100%;
    a{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            height: 85%;
        }
    }
`
export const Nav = styled.nav`
    height: 100%;
    width:100%;
    display: flex;
    justify-content: space-around; 
    top: ${(props) => props.open ? '0' : '-100vw'};
    right: ${(props) => props.open ? '0' : '-100vw'};
    align-items: center;
    gap: 20px;
    z-index: 22;
    transition: 1s;

    @media (max-width: 1260px) {
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: ${(props) => props.open ? '0' : '-100vw'};
        background-color: ${(props) => props.portal ? 'white' : '#E95612'};
        flex-direction: column;
        justify-content: center;
        gap: 0px;
    }      
`

export const NavPortal = styled.nav`
    position: fixed;
    height: 100%;
    width:250px;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    top: ${(props) => props.open ? '0' : '-100vw'};
    right: ${(props) => props.open ? '0' : '-100vw'};
    align-items: flex-start;
    padding-left: 90px;
    gap: 20px;
    z-index: 22;
    transition: 1s;
    background-color: #222222;
    box-shadow: -10px -8px 5px rgba(0, 0, 0, 0.7);
    box-shadow: inset 0px 0px 20px 3px #e95612;
`

const linkStyle = css`
    width: 104px;
    height: 90px;
    cursor: pointer;
    border: solid 2px transparent;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    text-align: center;
    color:overscroll-behavior-block;
    color: #000000;
    text-decoration: none;

    img{
        width: 55px;
        position: absolute;
        top: 15px;
    } 
  svg{ 
    position: absolute;
    color: white;
    background: black;
    width: 55px;
    border-radius: 50%;
    padding: 5px;
    height: 55px;
    top:15px;
  } 

    .hover-img{
        display: none;
    }

    p{
        width: 140px;
        position: absolute;
        top: 60%;
        line-height: 16px;
    }

    &.hover{
        color: #E95612;

        .hover-img{
            display: block;
        }

        .noHover-img{
            display: none;
        }
    }

    &:hover{
        color: #E95612;

        svg {
          position: absolute;
          color: white;
          background: #E95612;
        }
        .hover-img{
            display: block;
        }

        .noHover-img{
            display: none;
        }
    }

    @media (max-width: 1260px) {
        width: 250px;
        flex-direction: row;
        position: relative;
        justify-content: space-around;
        align-items: center;
        text-align: center;

        img,svg{
            position: relative;
            top: unset;
        }
        span{
          width: 140px;
        }
        p{
            position: relative;
            top: unset;
        }

        
    &.hover{
        color: white;
        
        img,svg{
            border-radius: 100%;
            border: solid 2px white;
        }
        

        .hover-img{
            display: none;
        }

        .noHover-img{
            display: block;
        }
    }

        &:hover{
            color: white;

            img{
                border-radius: 100%;
                border: solid 2px white;
            }

            .hover-img{
                display: none;
            }

            .noHover-img{
                display: block;
            }
        }
    }
`

export const StyledLink = styled(Link)`
    ${linkStyle}
`

export const StyledA = styled.a`
    ${linkStyle}
`

export const MenuLogo = styled(FaBars)`
    position: absolute;
    right: 20px;
    top: calc(50% - 18px);
    font-size: 36px;
    color: black;
    cursor: pointer;
    display: none;

    ${
        ({portal}) => 
            portal?
                'display: block !important;'
            :
                ''
    }

    @media (max-width: 1260px) {
        display: block;
    }
`

export const MiMenuLogoWrapper = styled.div`
  width: ${({width}) => width ? `${width}px` : '40px'};
  height: 35px; 
  background-color: transparent;
  cursor: pointer;

`
export const MiMenuLogo = styled.div`
    width: ${({width}) => width ? `${width}px` : '35px'};
    height: 3px;
    background-color: white;
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;

    &:before{
        content: '';
        position: absolute;
        top: 10px;
        right: 0;
        width: ${({width}) => width ? `${width*0.75}px` : `${35*0.7}px`};
        height: 3px;
        background-color: white;
    }

    &:after{
        
        content: '';
        position: absolute;
        top: 20px;
        right: 0;
        width: ${({width}) => width ? `${width*0.5}px` : `${35*0.5}px`};
        height: 3px;
        background-color: white;
    }
`

export const Exit = styled(ImCross)`
    position: absolute;
    font-size: 1.5rem;
    color: black;
    top: 20px;
    right: 20px;
    cursor: pointer;

    ${
        ({portal}) => 
            portal?
                'display: block; color: white;'
            :
                `   display: none;
                    @media (max-width: 1260px) {
                        display: block;
                    }
                `
    }
`

export const LinkPortal = styled(Link)`    
    display: flex;
    gap: 15px;
    font-size: 32px; 
    text-decoration: none;
    color:  white;
    cursor: pointer;

    & p{
        font-size: 28px;
        line-height: 37px;
    }

    &:hover{
        color:  #E95612;
    }   
`
