const {token_LIBRA, url} = require('./constantes');


async function guardarTicket(ticketDTO) {
    try{
        let response = await fetch(`${url}/tickets/create`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+ token_LIBRA
            },
            body: JSON.stringify(ticketDTO),
        }).then(res => {
            return res.json()
        }).catch(res => {
            return res.json()
        })
        return response;
    }catch (e){
        //console.log(e);

    }
}

async function enviarNeotel(ticketDTO) {
    try{
        let response = await fetch(`${url}/tickets/sendNeotel`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+ token_LIBRA
            },
            body: JSON.stringify(ticketDTO),
        }).then(res => {
            return res.json()
        }).catch(res => {
            return res.json()
        })
        return response;
    }catch (e){
        //console.log(e);

    }
}

const TicketService = {
    guardarTicket,
    enviarNeotel,

};


export default TicketService
