import styled from 'styled-components'


export const Wrapper = styled.div`
  position: relative;
  .tooltip {
    display: none;
    width: 0px;
    position: absolute;
    background: #a9a9a9;
    color: white; 
    top: 96%;
    padding: 5px;
    z-index: 5;
    border-radius: 15px;
    border-top-left-radius: 0; 
    left: 5px;
    transition-delay: 1s, 250ms;
    transition-property: display, width;
    transition-duration: 5s;
  }
  .element:hover~.tooltip{
    display: block; 
    width: fit-content;
  }
`
