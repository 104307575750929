const {token_LIBRA, url} = require('./constantes');

async function getOrCreate(ubicacionDTO) {
    try{
        let response = await fetch(`${url}/ubicaciones/create`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+ token_LIBRA
            },
            body: JSON.stringify(ubicacionDTO),
        }).then(res => {
            return res.json()
        }).catch(res => {
                return res.json()
            })
        return response;
    }catch (e){
        //console.log(e);
    }
}
// send `POST` request

const UbicacionService = {
    getOrCreate,
};

export default UbicacionService
