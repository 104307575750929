import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    color: white;
    text-align: end;

    img{
        width: 160px;
        height: 28px;
    }

    @media screen and (max-width: 1020px){
        display: flex;
        flex-direction: column;
    }
`

export const Negrita = styled.strong`
    font-weight: bold;
    color: white;
`

export const Titulo = styled.h1`
    color: white;
    width: 100%;
    text-align: left;
    font-size: 2rem;
`
export const Descripcion = styled.div`
    color: white;
    width: 100%;
    font-size: 1rem;
    text-align: left;
    line-height: 2rem;
`

export const MoveOnLogo = styled.img`
    width: 80%;
    align-self: flex-start;

    @media screen and (max-width: 1020px){
        width: 35%;
    }

`