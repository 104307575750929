//react
import { useState, useEffect, useRef, Children, isValidElement, cloneElement } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";

//Service
import GruerosService from "../../services/GruerosAppService"

const render = (status) => {
  return <h1>{status}</h1>;
};

const Map = ({
  onClick,
  onIdle,
  children,
  style,
  ...options
}) => {
  const ref = useRef(null)
  const [map, setMap] = useState(null)
  const google = window.google

  function handleLocationError(browserHasGeolocation, infoWindow, pos) {
    infoWindow.setPosition(pos);
    infoWindow.setContent(
      browserHasGeolocation
        ? "Error: The Geolocation service failed."
        : "Error: Your browser doesn't support geolocation."
    );
    infoWindow.open(map);
  }

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
    if (map) {
      map.setOptions(options);
      let infoWindow = new google.maps.InfoWindow();
      const locationButton = document.createElement("button");
      locationButton.textContent = "Ubicación Actual";
      locationButton.classList.add("custom-map-control-button");
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(locationButton);
      locationButton.addEventListener("click", () => {
        // Try HTML5 geolocation.
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };

              infoWindow.setPosition(pos);
              infoWindow.setContent("Location found.");
              infoWindow.open(map);
              map.setCenter(pos);
            },
            () => {
              handleLocationError(true, infoWindow, map.getCenter());
            }
          );
        }
        else {
          // Browser doesn't support Geolocation
          handleLocationError(false, infoWindow, map.getCenter());
        }
      });
    }
  }, [ref, map]);

  useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <div ref={ref} style={style} />
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          // set the map prop on the child component
          return cloneElement(child, { map });
        }
      })}
    </>
  );
};

const GoogleMap = ({ defaultAddress = null, setData = null, setAddress = null, readClick = null }) => {
  const [clicks, setClicks] = useState([])
  const [zoom, setZoom] = useState(15) // initial zoom
  const [center, setCenter] = useState({
    lat: -38.416097,
    lng: -63.616672
  })
  const [ubicacion, setUbicacion] = useState(null)
  const [dir, setDir] = useState(null)
  const google = window.google

  const Marker = (options) => {
    const [marker, setMarker] = useState();

    useEffect(() => {
      if (!marker) {
        setMarker(new google.maps.Marker());
      }

      // remove marker from map on unmount
      return () => {
        if (marker) {
          marker.setMap(null);
        }
      };
    }, [marker]);

    useEffect(() => {
      if (marker) {
        marker.setOptions(options);
      }
    }, [marker, options]);

    return null;
  };


  const onClick = (e) => {
    if(readClick)
      readClick(true)
    if(setAddress)
    {
      fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${e.latLng.lat()},${e.latLng.lng()}&key=AIzaSyASt-NINMRlLKrAW737rajd-yZERxWyPig`)
      .then((response) => response.json())
      .then((json) => {
        let result = {}
        result.longitud = json.results[0].geometry.location.lng
        result.latitud = json.results[0].geometry.location.lat
  
        json.results[0].address_components.forEach((value) => {
          if(value.types[0] === "street_number" || value.types[0] === "route" || value.types[0] === "locality" || value.types[0] === "country" || value.types[0] === "administrative_area_level_1")
          {
            result = {
              ...result,
              [value.types[0]]: value.long_name
            }
          }
        })
  
        if (result.street_number) {
          Object.defineProperty(result, "numero",
              Object.getOwnPropertyDescriptor(result, "street_number"));
          delete result["street_number"];
        }
  
        if (result.route) {
          Object.defineProperty(result, "calle",
              Object.getOwnPropertyDescriptor(result, "route"));
          delete result["route"];
        }
  
        if (result.administrative_area_level_1) {
          Object.defineProperty(result, "provincia",
              Object.getOwnPropertyDescriptor(result, "administrative_area_level_1"));
          delete result["administrative_area_level_1"];
        }
  
        if (result.locality) {
          Object.defineProperty(result, "localidad",
              Object.getOwnPropertyDescriptor(result, "locality"));
          delete result["locality"];
        }
  
        if (result.country) {
          Object.defineProperty(result, "pais",
              Object.getOwnPropertyDescriptor(result, "country"));
          delete result["country"];
        }
  
        setAddress(result)
        setDir(result)
      })
    }
    // avoid directly mutating state
    setClicks([e.latLng]);
  };

  const onIdle = (m) => {
    setZoom(m.getZoom());
    setCenter(m.getCenter().toJSON());
  };

  useEffect(() => {
    let ub = {
      lat: null,
      lng: null
    }

    if(defaultAddress)
    {
      if(typeof defaultAddress !== 'object')
      {
        GruerosService.getCoordinates(defaultAddress, (json) => {
          ub = json.data.geometry.location
        })
      }
      else
      {
        ub = {...defaultAddress}
      }
      setUbicacion(ub)
      setCenter(ub)
      setClicks([ub])
      if(setData)
        setData(ub)
    }
    else{
      navigator.geolocation.getCurrentPosition(
        function(position) {
          let result = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }
          setUbicacion(result)
          setCenter(result)
          setClicks([result])
          if(setData)
            setData(result)
              }
      );
    }
  }, [defaultAddress])

  useEffect(() => {
    if(clicks && clicks.length > 0 && setData)
    {
      let {lat, lng} = clicks[0]
      let aux
      if(typeof lat === 'function')
        aux = {lat: lat(), lng: lng()}
      else
        aux = {lat: lat, lng: lng}
      setData(aux, dir)
    }
  }, [clicks])

  var mapStyle =[
    {featureType:'poi',stylers:[{visibility:'off'}]}
  ]
  return (
    <div
      id={'map'}
      style={
        {
          height: '400px',
          display: 'flex',
          width: '100%',
        }
      }
    >
      {
        !ubicacion?
          ''
        :
          <Wrapper apiKey={"AIzaSyASt-NINMRlLKrAW737rajd-yZERxWyPig"} render={render}>
            <Map
              center={center}
              onClick={onClick}
              onIdle={onIdle}
              zoom={zoom}
              styles={mapStyle}
              style={{ flexGrow: "1", height: "100%", width: "100%" }}
            >
              {clicks.map((latLng, i) => (
                <Marker key={i} position={latLng} />
              ))}
            </Map>
          </Wrapper>
      }
    </div>
  )
}

export default GoogleMap
