import styled from 'styled-components'


export const DirectionPane = styled.div`
    position:relative;
    text-align: left; 
    place-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-content:center;
    padding: 5px;
  max-width: 25%;
    margin: 5px;
`
export const ImgWrapper = styled.div`
     
`

export const DirectionWrapper = styled.div`
    font-size: 18px;
`
export const WrapperMap = styled.div`
  height: 100%;
  display: flex;
  width: 100%;
  @media screen and (max-width: 1024px) {
    display: block;
    & #directionPane{
      max-width: 100%;
    }
  }
`
export const Row = styled.div`
      display: flex;
      height: auto;
      width: 100%;
      align-items: center;

`

export const EmptyWrapper = styled.div`
  margin-left: 5%;
  width: 90%;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin-top: 50px;
  padding: 15px;
  border: 3px dashed #a1a1a1;
`
