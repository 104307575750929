//react
import {
 useState
} from 'react'

//Styled Components
import {
    Wrapper,
    IconGreen,
    IconWhite,
    Animacion,
    Message
} from './elements'

const LogoWhatsApp = () => {
  const [show, setShow] = useState(false)
  const [hover, setHover] = useState(false)
  const [text, setText] = useState("")

  const contactar = (e) => {
    e.preventDefault()
    openWhatsApp(text)
  }

  const handleChange = (e) => {
    setText(e.target.value)
  }

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      openWhatsApp(text)
    }
  }

  const openWhatsApp = (mensaje) => {
    window.open(`https://api.whatsapp.com/send?phone=5491156347195&text=${text}`, "_blank")
    cleanUp()
  }

  const cleanUp = () => {
    setShow(!show)
    setHover(!hover)
    setText("")
  }

  return (
    <>
      <Animacion className={ hover ? 'hovering' : ''}>
        <Wrapper 
          className='logo'
          onClick={cleanUp}
        >
          <a>
            <IconGreen className='noHover'/>
            <IconWhite className='hover'/>
          </a>
        </Wrapper>
      </Animacion>
      {
        show?
          <Message>
            <p>Escribi tu mensaje:</p>
            <textarea onChange={e => handleChange(e)} onKeyDown={e => handleEnter(e)}>{text}</textarea>
            <a className={"button"} href={`https://api.whatsapp.com/send?phone=5491156347195&text=${text}`}>Enviar</a>
          </Message>
        :
          ""
      }
    </>
  )
}

export default LogoWhatsApp