import {
    Wrapper,
    StyledInput,
    OptionsWrapper,
    Option
} from './elements'

var invalidChars = [
    ".",
    "-",
    "+",
    "e",
];
const Input = ({ type='text', text, width, idValue, values, changeValues, onlyReading = false, noNegative = false,  onlyInteger = false, placeholder='', options = null }) => {

    const changeHandler = (id, e) => {
        let agregar = true

        if(noNegative && type === 'number' && e.target.value < 0)
            agregar = false

        if(agregar)
            changeValues(
                {
                    ...values,
                    [id]:  e.target.value
                }
            )
    }
    
    return (
            type === 'checkbox' || type === 'radio'?
                <Wrapper width={width}>
                    <OptionsWrapper>
                        <h2>{text}</h2>
                        {
                            options.map((element, index) => {
                                return(
                                    <Option key={index}>

                                        <StyledInput onWheel={() => {this.blur()}} type={type} value={element} name={idValue} id={`checkbox-${idValue}-${index}`} onChange={(e) => changeHandler(idValue, e)}/>
                                        <label htmlFor={`checkbox-${idValue}-${index}`}>
                                            {element}
                                        </label>
                                    </Option>
                                )
                            })
                        }
                    </OptionsWrapper>
                </Wrapper>
            :
                <Wrapper width={width}>
                    {
                        text?
                            <p>{ text }</p>
                        :
                            ''
                    }
                    {
                        onlyReading ?
                            <StyledInput onWheel={() => {this.blur()}}type={type} value={values && values[idValue] ? values[idValue] : ''} onChange={(e) => changeHandler(idValue, e)} placeholder={placeholder} onlyRead/>
                        :
                            onlyInteger?

                            <StyledInput
                                onKeyDown={(e)=>{
                                    if (invalidChars.includes(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                type={type} value={values && values[idValue] ? values[idValue] : ''} onWheel={(e) => {e.target.blur()}} onChange={(e) => {changeHandler(idValue, e)}} placeholder={placeholder}/>
                            :
                                <StyledInput onWheel={(e) => {e.target.blur()}} type={type} value={values && values[idValue] ? values[idValue] : ''} onChange={(e) => changeHandler(idValue, e)} placeholder={placeholder}/>

                    }
                </Wrapper>
    )
}

export default Input
