//Styled Components
import {
    FormBoxWrapper,
    FormBoxHeader,
    FromBoxBody,
    Danger
  } from './elements'

  export const FormBox = ({isHeader,title,children,isRequired, show=true}) => {
    return (
        <FormBoxWrapper isHeaderBox={isHeader} show={show}>
            <FormBoxHeader  isHeaderBox={isHeader}>
                <h2>{title}<Danger isRequired={isRequired}> *</Danger></h2>
                <img loading={"lazy"} src={`${process.env.PUBLIC_URL}/assets/images/logo-negro.webp`}></img>
            </FormBoxHeader>
            <FromBoxBody>
                {children}
            </FromBoxBody>
           
        </FormBoxWrapper>
    )
  }
