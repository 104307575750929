
class TicketDTO {
    id;//int
    auto;//autoDTO
    telefono1;//string
    telefono2; //string
    telefono3;//string
    solicitado_en;//string
    traslada_a;//string
    falla;//string
    falla_descripcion;//string
    cantidad;//int
    menores;//int
    origen;//ubicacionDTO
    destino;//ubicacionDTO
    fecha_emision;// date

    constructor({id = null, auto = {}, telefono1 = '', telefono2 = '',falla= '',
                    falla_descripcion= '', telefono3 = '', solicitado_en = '', traslada_a = '',cantidad =  0, menores = 0, origen = {}, destino = {}, fecha_emision = new Date()}) {
        this.id =id;
        this.auto =auto;
        this.telefono1 =telefono1;
        this.telefono2 =telefono2;
        this.telefono3 =telefono3;
        this.solicitado_en =solicitado_en;
        this.traslada_a =traslada_a;
        this.cantidad =cantidad;
        this.menores =menores;
        this.falla =falla;
        this.falla_descripcion =falla_descripcion;
        this.origen =origen;
        this.destino =destino;
        this.fecha_emision =fecha_emision;
    }
}

export default TicketDTO
