//Styled components
import {
    DatePickerWrapper
} from './elements'

//DatePicker
import DatePicker, { registerLocale } from 'react-datepicker'
import es from "date-fns/locale/es"; // the locale you want
import "react-datepicker/dist/react-datepicker.css";

registerLocale("es", es);

const DatePickerComponent = ({ filterDate,filterTime,dateFormat="dd/MM/yyyy",datePicker, setDatePicker, title, minDate,showTimeSelect, maxDate, className }) => {

    return (
        <DatePickerWrapper className={className}>
            <h5>{title}</h5>
            <DatePicker
                dateFormat={dateFormat}
                closeOnScroll={true}
                selected={datePicker}
                locale={"es"}
                onChange={date => setDatePicker(date)}
                minDate={minDate ? minDate : null}
                maxDate={maxDate ? maxDate : null}
                filterTime={filterTime ? filterTime : null}
                filterDate={filterDate ? filterDate : null}
                showTimeSelect={showTimeSelect ? showTimeSelect : null}
            />
        </DatePickerWrapper>
    )
}

export default DatePickerComponent
