import {
    Wrapper
} from './elements'

//Components
import Section from '../../components/Section'
import Button from '../../components/Button'




const Seguridad = ({popupButtonHandler}) => {



  return (
    <Section
      recta={true}
      styled={true}
      image={`${process.env.PUBLIC_URL}/assets/images/seguridad-imagen.webp`}
      icono={`${process.env.PUBLIC_URL}/assets/images/seguridad-icono.webp`}
      compName='seguridad'
    >
        <Wrapper>
            <h1>
            Seguridad
            </h1>
            <p>
                La seguridad vial es el conjunto de acciones y mecanismos que garantizan el buen funcionamiento de la circulación del tránsito; mediante la utilización de conocimientos y normas de conducta; bien sea como peatón, pasajero o conductor, a fin de usar correctamente la vía pública previniendo los accidentes de tránsito.
            </p>
            <img loading={"lazy"} src={`${process.env.PUBLIC_URL}/assets/images/huella.webp`} alt='imagen'/>
            <Button text={'PARA MÁS INFORMACIÓN'} clickHandler={popupButtonHandler} isOrangeSection={true}></Button>
        </Wrapper>
    </Section>
  )
}

export default Seguridad
