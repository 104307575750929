//React
import { useState, useEffect } from 'react'

//Styled Elements
import {
    Wrapper,
    WrapperEstructura,
    WrapperDigitos,
    Digito,
    FlechaUp,
    FlechaDown
} from './elements'


const RelojDigital = ({ hora, setHora }) => {
    const [horaInterna, setHoraInterna] = useState(
        {
            digito1: hora ? parseInt(hora[0]) : 1,
            digito2: hora ? parseInt(hora[1]) : 9,
            digito3: hora ? parseInt(hora[3]) : 3,
            digito4: hora ? parseInt(hora[4]) : 0
        }
    )
    const [cambio, setCambio] = useState({})

    const handleCambioHora = (cambio = 'up') => {
        let horaActual = horaInterna.digito1 * 10 + horaInterna.digito2
        if(cambio === 'up')
        {
            horaActual = (horaActual + 1) % 24
        }
        else if(cambio === 'down')
        {
            if(horaInterna.digito1 === 0 && horaInterna.digito2 === 0)
                horaActual = 23
            else
                horaActual = horaActual - 1
        }
        let digito1 = Math.floor(horaActual / 10)
        let digito2 = horaActual - (digito1 * 10)
        setCambio(
            {
                digito1: digito1,
                digito2: digito2
            }
        )
    }

    const handleCambioMinutos = (cambio = 'up') => {
        let minutosActuales = horaInterna.digito3 * 10 + horaInterna.digito4
        if(cambio === 'up')
        {
            minutosActuales = (minutosActuales + 15) % 60
        }
        else if(cambio === 'down')
        {
            if(horaInterna.digito3 === 0 && horaInterna.digito4 === 0)
                minutosActuales = 45
            else
                minutosActuales = minutosActuales - 15
        }
        let digito3 = Math.floor(minutosActuales / 10)
        let digito4 = minutosActuales - (digito3 * 10)
        setCambio(
            {
                digito3: digito3,
                digito4: digito4
            }
        )
    }


    useEffect(() => {
        let keys = Object.keys(cambio)
        if(keys.length !== 0)
        {
            if(('digito1' in cambio && 'digito2' in cambio) && (cambio.digito1 !== horaInterna.digito1 || cambio.digito2 !== horaInterna.digito2))
            {
                setTimeout(() => {
                    let nuevoDigito1 = 'digito1' in cambio ? cambio.digito1 : horaInterna.digito1
                    let nuevoDigito2 = 'digito2' in cambio ? cambio.digito2 : horaInterna.digito2
                    setHoraInterna({
                        ...horaInterna,
                        digito1: nuevoDigito1,
                        digito2: nuevoDigito2
                    })
                    setCambio({})
                    setHora(`${nuevoDigito1}${nuevoDigito2}:${horaInterna.digito3}${horaInterna.digito4}`)
                }, 300)
            }
            if(('digito3' in cambio && 'digito4' in cambio) && (cambio.digito3 !== horaInterna.digito3 || cambio.digito4 !== horaInterna.digito4))
            {
                setTimeout(() => {
                    let nuevoDigito3 = 'digito3' in cambio ? cambio.digito3 : horaInterna.digito3
                    let nuevoDigito4 = 'digito4' in cambio ? cambio.digito4 : horaInterna.digito4
                    setHoraInterna({
                        ...horaInterna,
                        digito3: nuevoDigito3,
                        digito4: nuevoDigito4
                    })
                    setCambio({})
                    setHora(`${horaInterna.digito1}${horaInterna.digito2}:${nuevoDigito3}${nuevoDigito4}`)
                }, 300)
            }
        }
    }, [cambio])

    return (
        <Wrapper>
            <WrapperEstructura>
                <FlechaUp onClick={() => handleCambioHora('up')}/>
                <WrapperDigitos>
                    <Digito className={`${'digito1' in cambio && cambio.digito1 !== horaInterna.digito1 ? 'play' : ''}`}>
                        <div className='display'>
                            <div className='display-top'>{'digito1' in cambio ? cambio.digito1 : ''}</div>
                            <div className='display-bottom'>{horaInterna.digito1}</div>
                        </div>
                        <div className='flipper'>
                            <div className={`flipper-top`}>{horaInterna.digito1}</div>
                            <div className={`flipper-bottom`}>{'digito1' in cambio ? cambio.digito1 : ''}</div>
                        </div>
                    </Digito>
                    <Digito className={`${'digito2' in cambio && cambio.digito2 !== horaInterna.digito2 ? 'play' : ''}`}>
                        <div className='display'>
                            <div className='display-top'>{'digito2' in cambio ? cambio.digito2 : ''}</div>
                            <div className='display-bottom'>{horaInterna.digito2}</div>
                        </div>
                        <div className='flipper'>
                            <div className={`flipper-top`}>{horaInterna.digito2}</div>
                            <div className={`flipper-bottom`}>{'digito2' in cambio ? cambio.digito2 : ''}</div>
                        </div>
                    </Digito>
                </WrapperDigitos>
                <FlechaDown onClick={() => handleCambioHora('down')}/>
            </WrapperEstructura>
            <span>
                <span className='dot'></span>
                <span className='dot'></span>
            </span>
            <WrapperEstructura>
                <FlechaUp onClick={() => handleCambioMinutos('up')}/>
                <WrapperDigitos>
                    <Digito className={`${'digito3' in cambio && cambio.digito3 !== horaInterna.digito3 ? 'play' : ''}`}>
                        <div className='display'>
                            <div className='display-top'>{'digito3' in cambio ? cambio.digito3 : ''}</div>
                            <div className='display-bottom'>{horaInterna.digito3}</div>
                        </div>
                        <div className='flipper'>
                            <div className={`flipper-top`}>{horaInterna.digito3}</div>
                            <div className={`flipper-bottom`}>{'digito3' in cambio ? cambio.digito3 : ''}</div>
                        </div>
                    </Digito>
                    <Digito className={`${'digito4' in cambio && cambio.digito4 !== horaInterna.digito4 ? 'play' : ''}`}>
                        <div className='display'>
                            <div className='display-top'>{'digito4' in cambio ? cambio.digito4 : ''}</div>
                            <div className='display-bottom'>{horaInterna.digito4}</div>
                        </div>
                        <div className='flipper'>
                            <div className={`flipper-top`}>{horaInterna.digito4}</div>
                            <div className={`flipper-bottom`}>{'digito4' in cambio ? cambio.digito4 : ''}</div>
                        </div>
                    </Digito>
                </WrapperDigitos>
                <FlechaDown onClick={() => handleCambioMinutos('down')}/>
            </WrapperEstructura>
        </Wrapper>
    )
}

export default RelojDigital
