
class ContactoFormDTO {
    email;//string
    tipoasistencia;//string
    nombrecompleto;//string
    poliza;//string
    mail_titular;//string
    telefono; //string
    telefono_productor
    mail_productor
    tipogestion;//String
    observaciones;//String
    archivo;//objeto
    constructor({email = '', tipo_asistencia ='', nombrecompleto = '', poliza = '', mail_titular ='',codtelefono = '', numerotelefono='', codtelefono_productor='',numerotelefono_productor='',mail_productor='', provincia = '',localidad = '',tipogestion='',observaciones = '',archivo={}}) {
        this.email = email;
        this.tipoasistencia         = tipo_asistencia;
        this.nombrecompleto         = nombrecompleto;
        this.poliza                 = poliza;  
        this.mail_titular           = mail_titular; 
        this.telefono               = codtelefono+numerotelefono; 
        this.telefono_productor  = codtelefono_productor+numerotelefono_productor; 
        this.mail_productor     = mail_productor; 
        this.tipogestion        = tipogestion; 
        this.observaciones      = observaciones;
        this.archivo            = archivo;
    }
}


export default ContactoFormDTO
