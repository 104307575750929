import styled from "styled-components"
import { Link } from 'react-router-dom'
import { FaUserAlt, FaLock } from 'react-icons/fa'
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri'

export const GruerosHomePage = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #e95612;  
  display: grid;
  place-items: center;
`
export const RowTwoColumns = styled.div`
  width: 100%;      
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  gap: 15px;

  & div{
    width: 100%;
  }

  & input, select{
    width: 100%;
    padding: 15px 0;
    border: none;
    border-bottom: 2px solid #e95612;
    color: #e95612;
    font-size: 18px;
    outline: none;

    & option{
      color: black;
    }
  }
`

export const Filter = styled.div`
  & input, select{
    width: 100%;
    padding: 15px 0;
    border: none;
    border-bottom: 2px solid #e95612;
    color: #e95612;
    font-size: 18px;
    outline: none;
  }
  
  & option{
    color: black;
  }
`

export const EmptyWrapper = styled.div`
  margin-left: 5%;
  width: 90%;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin-top: 50px;
  padding: 15px;
  border: 3px dashed #a1a1a1;
`

export const Imagen = styled.img`
    position: absolute;
    z-index: 1;
    opacity: 0.5;

  &.inferior{
    bottom: 0px;
    left: 0px;
  }

  &.superior{
    top: 0px;
    right: 0px;
  }
`
export const GruerosHomeWrapper = styled.div` 
  width: 100vw;
  height: 100%;
  padding: ${props => props.padding? "140px 30px 30px 30px":"100px 30px 30px 30px"};
  display: grid;
  grid-template-columns: 300px 1fr; 
  gap: 30px;
  
  & hr{
    height: 1px;
    margin-top: 5px;
  }
  & h3{
    text-align: center;
    width: 100%;
  }
  
  @media screen and (max-width: 1260px){
    padding: 110px 30px 30px 30px;
  }
  @media screen and (max-width: 800px){
    grid-template-columns: 100%;
    grid-template-rows: 190px 600px 200px auto auto;
    gap: 15px;
    padding: 100px 0px 0px; 
    & #serviciosList,#infoProveedor,#fileUploaod,#graphs,#statics{
      border-radius: 0;
    }
    & #serviciosList{
      grid-column: 1;
      grid-row: 2; 
     }
    & #infoProveedor{
      grid-column: 1;
      grid-row: 3; 
     }
    & #fileUploaod{
      grid-column: 1;
      grid-row: 4;  
     }
    & #graphs{
      grid-column: 1;
      grid-row: 5; 
      overflow-x: auto;
     }
  }
`
export const Estadisticas = styled.div` 
  grid-column: 1;
  grid-row: 1 / 2;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  padding: 5px 15px 15px 15px;
  display: grid;
  grid-auto-flow: dense;
  border-radius: 8px;
  box-shadow: 10px 5px 5px #0000007d;z-index: 1;
`
export const AgregarTicket = styled.button`
{
  display: flex;
  position: absolute;
  left: 90px;
  top: 90px;
  border: none;
  background: white;
  border-radius: 15px; 
  color: #e95612; 
  font-size: 20px;
  padding: 6px;
  box-shadow: 5px 3px 10px #0000007d;
  z-index: 5;
  @media screen and (max-width: 1260px){ 
    top: 20px;
    padding: 8px;
    font-size: 12px;
    font-weight: bold;
    box-shadow: inset 0px 0px 6px 0px #0000007d;
  }
  @media screen and (max-width: 650px){
    left: 10px; 
    padding: 8px;
    font-size: 12px;
    font-weight: bold; 
  }
}`
export const Volver = styled(Link)`
  display: flex;
  position: absolute;
  left: 30px;
  top: 90px;
  background: white;
  border-radius: 15px;
  padding: 5px;
  color: #e95612;
  font-size: 30px;
  box-shadow: 10px 5px 5px #0000007d;
  z-index: 5;
  @media screen and (max-width: 1260px){
    top: 20px;
    box-shadow: inset 0px 0px 6px 0px #0000007d;
  }
  @media screen and (max-width: 650px){
    left: 10px;
    top: 68px;
    left: 10px; 
  }
`
export const InfoProveedor = styled.div` 
  grid-column: 1;
  grid-row: 2 / 3;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  padding: 5px 15px 15px 15px;
  border-radius: 8px;
  box-shadow: 10px 5px 5px #0000007d;z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;

  select {
    width: 80%;
    outline: none;
    border: none;
    padding: 5px 0;  
  }
`
export const LoadingWrapper = styled.div`
  width: 200px;
  height: 200px;
  background-color: #fafafa;
  padding: 5px;
  display: grid;
  border-radius: 8px;
  box-shadow: 10px 5px 5px #0000007d;
  z-index: 1;
`
export const Dates = styled.div`
  display: flex;
  gap: 15px;
  margin: 20px 0;
  
  input{
    border: none !important;
    border-bottom: solid 2px #e95612 !important;
    padding: 15px 0;
    font-size: 18px;
    color: #e95612;
  }
`
export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column; 
  max-width: 300px;
  & .row{
    padding: 25px;
    label{
      font-size: 0.83em;
      font-weight:bold;
    }
    textarea{
      resize: none;
      width: 100%;
      height: 50px;
    }
  }
`
export const Wrapper = styled.div`
  display: flex; 
  width: 100%;
  justify-content: center;
`
export const ButtonWrapper = styled.div`
  display: flex;
  gap: 15px;
  padding: 25px;
  justify-content: space-around;
`
export const Button = styled.button` 
  padding: 10px 0px;
  background-color:  ${(props) => props.color? props.color : '#50dd00' };
  color: white;
  border:none;
  font-weight: bold;
  border-radius: 8px;
  box-shadow: 4px 4px 2px rgba(0, 0, 0, 0.5);
  width: 40%;
`
export const ServiciosListWrapper = styled.div` 
  grid-column: 2; grid-row: 1 / span 3;
  background-color: #fff;
  padding: 5px 25px 25px 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  grid-auto-flow: dense;
  border-radius: 8px;
  box-shadow: 10px 5px 5px #0000007d;z-index: 1;

  & #tittle{
    position: relative;
    top: 0; 
    @media screen and (max-width: 700px){
       & input,select{
          padding: 3px;
       }
    }
  }
  & #lista{
    overflow: auto;
    max-height: 700px;
    min-height: 700px;

    &.loading{
      display: grid;
      place-items: center;
    }
  }
`
export const Graphs = styled.div`
  grid-column: 1 / span 2;
  grid-row: 4;
  background-color: #fafafa;
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  grid-auto-flow: dense;
  border-radius: 8px;
  box-shadow: 10px 5px 5px #0000007d;
  z-index: 0;
  align-items: center;
  & tspan{
    fill: black;
  }
`
export const FileUploadWrapper = styled.div` 
  grid-row: 3;
  background-color: #fafafa;   
  padding: 5px 15px 15px 15px;
  display: flex; 
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 10px 5px 5px #0000007d;z-index: 1;
`


export const Pagination = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;

  & p{
    font-size: 14px;
  }
`

export const PaginationButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & button{
    background-color: #fafafa;
    border-radius: 0;
    outline: none;
    padding: 4px 8px;
    border: 1px solid #ddd;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active{
      background-color: #e95612;
      border-color: #e95612;
      color: white;

      :hover{
        background-color: #e95612;
        cursor: default;
      }
    }

    :disabled{
      cursor: no-drop;
    }

    :hover{
      background-color: #eee;
    }

    &:first-child{
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
    }

    &:last-child{
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
`

export const BackArrow = styled(RiArrowLeftSLine)`
  font-size: 16px;
`

export const NextArrow = styled(RiArrowRightSLine)`
  font-size: 16px;
`
