import styled from "styled-components"
import { FaUserAlt, FaLock } from 'react-icons/fa'

export const AdminHomePage = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #e95612;  
  display: grid;
  place-items: center;
  #toast {
    visibility: hidden;
    max-width: 320px;
    height: 50px;
    /*margin-left: -125px;*/
    margin: auto;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 2px;

    position: fixed;
    z-index: 999;
    left: 0;right:0;
    bottom: 30px;
    font-size: 17px;
    white-space: nowrap;
  }
  #toast #img{
    width: 50px;
    height: 50px;

    float: left;

    padding-top: 16px;
    padding-bottom: 16px;

    box-sizing: border-box;


    background-color: #111;
    color: #fff;
  }
  #toast #desc{


    color: #fff;

    padding: 16px;

    overflow: hidden;
    white-space: nowrap;
  }

  #toast.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, expand 0.5s 0.5s,stay 3s 1s, shrink 0.5s 2s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, expand 0.5s 0.5s,stay 3s 1s, shrink 0.5s 4s, fadeout 0.5s 4.5s;
  }

  @-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
  }

  @keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
  }

  @-webkit-keyframes expand {
    from {min-width: 50px}
    to {min-width: 350px}
  }

  @keyframes expand {
    from {min-width: 50px}
    to {min-width: 350px}
  }
  @-webkit-keyframes stay {
    from {min-width: 350px}
    to {min-width: 350px}
  }

  @keyframes stay {
    from {min-width: 350px}
    to {min-width: 350px}
  }
  @-webkit-keyframes shrink {
    from {min-width: 350px;}
    to {min-width: 50px;}
  }

  @keyframes shrink {
    from {min-width: 350px;}
    to {min-width: 50px;}
  }

  @-webkit-keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 60px; opacity: 0;}
  }

  @keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 60px; opacity: 0;}
  }
`
export const BodyModal = styled.div`
  width: 100%;
  min-height: 150px;
  padding: 5px; 
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  p{
    min-width: 80px;
  }
  svg{ 
    max-width: 50px;
    height: 35px; 
    color: red;
  }
`
export const Dates = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin: 20px 0;
  
  input{
    border: none !important;
    border-bottom: solid 2px #e95612 !important;
    padding: 15px 0;
    font-size: 18px;
    color: #e95612;
  }
`
export const Overlay = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  background: #adaaaa99;
  z-index: 1050;
  display: ${props => props.loading!=='false'  ?  "block" : "none"}; 
  overflow-y: auto;
  padding-top: 40px;
`
export const Instructivo = styled.div`   
    position: fixed;
    display: ${props => props.loading!=='false'  ?  "flex" : "none"};
    flex-direction: column;
    background: white;
    padding: 10px; 
    border-radius: 15px;  
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    /* width */
    left: 30%;
    right: 30%; 
  
    ::-webkit-scrollbar {
        width: 10px;
    }
    
    /* Track */
    
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    
    /* Handle */
    
    ::-webkit-scrollbar-thumb {
        background: #d57834;
        border-radius: 50px;
    }
    
    /* Handle on hover */
    
    ::-webkit-scrollbar-thumb:hover {
        background: #d57834;
    }
    
    & a {
        margin: 5px;
    
    & :hover {
            cursor: pointer;
        }
    }
    
    &Link {
    & :hover {
            cursor: pointer;
        }
    }
    
    & .close {
        bottom: 20px;
        right: 20px;
        align-items: center;
        justify-content: flex-end;
        grid-column: 3;
    
    & Button {
            display: flex;
            align-items: center;
            font-size: 14px;
            padding: 0 10px;
            gap: 10px;
        }
    }
    
    & #errorMsg {
        color: #D70909FC;
    }
    
    & .tittle {
        width: 100%;
        font-size: 22px;
    }
    
    svg {
        width: 100% !important;
    }
    & .row{
      display: flex;
      gap: 20px;
      justify-content: space-around;
      button{ 
        width: 120px;
        font-size: 13px;
      }
    }
    @media (max-width: 500px) {
        width: 100%;
    }
`
export const EmptyWrapper = styled.div`
  margin-left: 5%;
  width: 90%;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin-top: 50px;
  padding: 15px;
  border: 3px dashed #a1a1a1;
`

export const Imagen = styled.img`
  position: absolute;
  z-index: 1;
  opacity: 0.5;

  &.inferior{
    bottom: 0px;
    left: 0px;
  }

  &.superior{
    top: 0px;
    right: 0px;
  }
`
export const AdminHomeWrapper = styled.div` 
  width: 100vw;
  height: 100%;
  padding: 100px 30px 30px 30px;
  z-index: 3;

  & h3{
    text-align: center;
    width: 100%;
    margin-bottom: 5px;
  } 
`

export const LoadingWrapper = styled.div`
  width: 200px;
  height: 200px;
  background-color: #fafafa;
  padding: 5px;
  display: grid;
  border-radius: 8px;
  box-shadow: 10px 5px 5px #0000007d;z-index: 1;
`

export const PrestadoresListWrapper = styled.div` 
  width: 100%;
  background-color: #fff; 
  padding: 5px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 10px 5px 5px #0000007d;

  & #lista{
    & #mapContainer{
      min-height: 500px; 
    }
    & th{
      background-color: #e95612;
      color: #fff;
    }

    & tr{
      &:hover{
        background-color: rgba(233, 86, 18, 0.7);
        color: #fff;
      }
    }
    
    & input{
      border: 1px solid black;
    }
  }
`
