import styled from 'styled-components'

export const StyledSection = styled.section`
    min-height: ${({ minHeight }) => minHeight ? minHeight : '100vh'};
    width: 100%;
    overflow-x: hidden;
    background-color: ${({ color }) => color ? color : ''};
    position: relative;

    .SectionTitle{
        width: 100%;
        text-align: center;
        padding: 75px 0px;
        font-size: 2vw; 
        font-weight: 300;
        text-align: center;

        @media screen and (max-width: 1020px){
            padding: 50px 0px;
            font-size: 5vw;
        }
    }

    @media screen and (max-width: 1260px){
        min-height: unset;
    }
`

export const Wrapper = styled.div`
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: #E95612;

    &:before{
        content: "";
        position: absolute;
        top: 0;
        
        width: 0;
        height: 0;
        border-top: 620px solid transparent;
        border-bottom: 680px solid transparent;
        border-left: 400px solid white;
        z-index: 2;
        transform: translateY(-300px);
        animation-duration: 3s;
        animation-name: desplazamiento;
        //terminar de hacer, agregarlo en un hover!
        @keyframes desplazamiento {
          0% {  left: 0px; }
          30% {  left: -10px; }
          68%, 72% { left: -30px; }
          100% { left: -50px; }
        }

        @media screen and (max-width: 1020px){
            display: none;
        }
    }

    .fondo{
        height: 100%;
        width: 80%;
        transform: ${(props) => props.recta ? '' : 'scaleX(-1) '} ${(props) => `translateX(${props.translate})`};
        z-index: 2;
    }
    
    .icono{
        margin-left: 25px;
        width: 320px;
        position: absolute;
        z-index: 2;
        top: 267px;
    }

    @media screen and (max-width: 1020px){
        height: fit-content;
        display: flex;
        flex-direction: column-reverse;

        .fondo{
            position: relative;
            height: unset;
            transform: ${(props) => props.recta ? '' : 'scaleX(-1) '};
            width: 100%;
            z-index: 0;
        }

        .icono{
            width: 150px;
            top: 10px;
            left: 10px;
            margin-left: 0;
        }
    }
`

export const SectionContent = styled.div`
    position: absolute;
    width: 35vw;
    height: 100vh;
    background-color: #E95612;
    top: 0;
    left: 65vw;
    z-index: 1;
    padding: 75px 35px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 20px;
    color: white;
    text-align: end;

    &:before{
        content: "";
        position: absolute;
        top: -917px;
        left: -399px;
        border-top: 620px solid transparent;
        border-bottom: 680px solid transparent;
        border-right: 400px solid #E95612;
        z-index: 2;
        transform: rotate(-3deg);
    }

    &:after{
        content: "";
        position: absolute;
        top: 320px;
        left: -370px;
        border-top: 620px solid transparent;
        border-bottom: 680px solid transparent;
        border-right: 400px solid #E95612;
        z-index: 2;
        transform: rotate(-2.2deg);
    }

    @media screen and (max-width: 1020px){
        position: relative;
        min-height: 40vh;
        height: fit-content;
        width: 100%;
        left: 0;
        top: 0;

        &:before{
            display: none;
        }

        &:after{
            display: none;
        }
    }
`

export const Limit = styled.div`
    position: absolute;
    top: -130px;

    @media screen and (max-width: 1260px){
        top: -84px;
    }
`
