import Button from '../Button'
import Loading from '../Loading'
import {
  Overlay,
  Wrapper,
  TextContent,
  ErrorIcon,
  InfoIcon,
  SuccessIcon
} from './elements'

const Message = ({ type = null, mensaje, action }) => {
  return (
    <>
      <Overlay/>
      <Wrapper>
        <img loading={"lazy"} src={`${process.env.PUBLIC_URL}/assets/images/logo-negro.webp`} alt={"imagen"}/>
        <TextContent>
          {
            !type ?
              <ErrorIcon/>
            :
              type === "success"?
                <SuccessIcon/>
              :
                type === "loading"?
                  <Loading/>
                :
                  <InfoIcon/>
          }
          <span dangerouslySetInnerHTML={{__html: mensaje}}></span>
        </TextContent>
        <Button type={'button'} text={"Aceptar"} clickHandler={(e) => {
            action()
          }}
        />
      </Wrapper>
    </>
  )
}

export default Message
