//Styled Components
import { useEffect } from 'react'
import { useState } from 'react'
import {
    BarChart,
    CartesianGrid,
    YAxis,
    XAxis,
    Tooltip,
    Legend,
    Bar,
} from 'recharts'

const BarChartComp = ({children, height=250,labels= null,XdataKey="Date",listBars= [{
    barDataKey : "totalCOunt",
    barColor : "#8884d8"
}], data=[
    {
        "Date": "2022-06-01",
        "prestadorID": 35,
        "totalCOunt": 4
    },
    {
        "Date": "2022-06-02",
        "prestadorID": 35,
        "totalCOunt": 11
    }] }) => {
        const [widthChart, setWidthChart] = useState(window.innerWidth * 0.8)

        useEffect(() => {
            const updateWindowDimensions = () => {
                setWidthChart(window.innerWidth)
            }

            window.addEventListener("resize", updateWindowDimensions);

            return () => window.removeEventListener("resize", updateWindowDimensions) 
        }, [window.innerWidth])
        
    return (
        <BarChart width={widthChart * 0.85} height={height} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            {
                labels?
                    <XAxis label={labels} />
                    :
                    <XAxis dataKey={XdataKey} />
            }
            <YAxis />
            <Tooltip />
            {
                listBars ?
                    listBars.map((values, index) => {
                        return (
                            <Bar key={index} dataKey={values.barDataKey} fill={values.barColor}/>
                        )
                    })
                    :
                    ''
            }

        </BarChart>
    )
}

export default BarChartComp
