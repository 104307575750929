import styled from "styled-components"
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri'

export const Styles = styled.div`
  width: 100%;
  background-color: white;
  overflow-x: auto;
  
  table {
    width: 100%;
    background-color: white;
    border-spacing: 0;
    border: 0px solid black;
    font-size: 14px;
    padding: 1rem;

    thead{
      th{
        background-color: #d2d6de;
        div{
          display: flex;
          align-items: center;
          justify-content: center;
          svg{
            stroke-width: 1px;
          }
        }
      }
    }

    tr {
      cursor: default;
      &:hover{
        background-color: #cce6ff;
      }

      &:last-child {
        td {
          border-bottom: 0;
        }
      }

    }

    th,
    td {
      min-width: 150px;
      margin: 0;
      padding: 1rem;
      text-align: center;
      border: 1px solid #f4f4f4;
      border-top: 0;

      :last-child {
        border-right: 0;
      }

      :first-child{
        border-left: 0;
      }
    }
  }
`

export const HeaderFilters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  font-size: 14px;
  font-weight: bold;

  & input{
    width: 150px;
    border-radius: 10px;
    border: 1px solid #E4E6E8;
    padding: 4px 8px;
    outline: none;
    margin-left: 5px;
  }

  @media screen and (max-width: 720px){
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
  }
`

export const PageSizeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  & select{
    padding: 5px 10px;
  }
`

export const Pagination = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;

  & p{
    font-size: 14px;
  }
`

export const PaginationButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & button{
    background-color: #fafafa;
    border-radius: 0;
    outline: none;
    padding: 4px 8px;
    border: 1px solid #ddd;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active{
      background-color: #e95612;
      border-color: #e95612;
      color: white;

      :hover{
        background-color: #e95612;
        cursor: default;
      }
    }

    :disabled{
      cursor: no-drop;
    }

    :hover{
      background-color: #eee;
    }

    &:first-child{
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
    }

    &:last-child{
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
`

export const BackArrow = styled(RiArrowLeftSLine)`
  font-size: 16px;
`

export const NextArrow = styled(RiArrowRightSLine)`
  font-size: 16px;
`
