//Styled Components
import {
    Wrapper,
    Negrita,
    Titulo,
    Descripcion,
    MoveOnLogo
} from './elements'

//Components
import Section from "../../components/Section"

const QuienesSomos = () => {
  return (
    <Section
      translate='70px'
      image={`${process.env.PUBLIC_URL}/assets/images/imagen_quienessomos.webp`}
      icono={`${process.env.PUBLIC_URL}/assets/images/icono_quienessomos.webp`}
      styled={true}
      compName='quienesSomos'
    >   <MoveOnLogo src={`${process.env.PUBLIC_URL}/assets/images/logos/moveonlibra.webp`} alt={"Logo move on"}/>
        <Wrapper>
          <Titulo>¿QUIÉNES SOMOS?</Titulo>
          <Descripcion>
            <p>Somos una empresa del <Negrita>Grupo Libra Seguros</Negrita>, nacimos a partir de la búsqueda de excelencia en el servicio.</p><br/>
            <p>La palabra <Negrita>AUXILIO</Negrita> define nuestra respuesta, implica urgencia, ayuda y contención.</p><br/>
            <p>Venimos a cambiar el paradigma del Auxilio Mecánico, sabemos lo que esperan los clientes, trabajamos para superar y superarnos.</p><br/>
            <p>Tenemos un compromiso con la agilidad de respuesta y la atención de todos los participes del servicio.</p>
          </Descripcion>
          <img loading={"lazy"} src={`${process.env.PUBLIC_URL}/assets/images/huella.webp`} alt='imagen'/>
        </Wrapper>
    </Section>
  )
}

export default QuienesSomos
