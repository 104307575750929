import styled from 'styled-components'

import {
  AiFillEdit,
} from 'react-icons/ai'
import {
  BiDetail,
} from 'react-icons/bi'


export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 500px){
    justify-content: space-around;
  }
  & input, select{
  width: 100%;
  padding: 15px 0;
  border: none;
  border-bottom: 2px solid #e95612;
  color: #e95612;
  font-size: 18px;
  outline: none;
}
  .textCenter{
    text-align: center!important;
  }
  & option{
    color: black;
  }

`
export const Wrapper = styled.div`
  position: relative; 
`

export const Button = styled.button`
  padding: 10px 18px;
  background-color: ${(props) => props.cancelar ? '#5f5f5f' : '#05af05'};
  color: white;
  border: none;
  font-weight: bold;
  border-radius: 8px;
  box-shadow: 4px 4px 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;

  &.reject {
    background-color: #b40404;
  }

  &.classic {
    width: fit-content;
    background-color: #e95612;

    &:hover {
      transform: translate(1px, 1px);
    }
  }
`


export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-content:center; 
    border-radius: 15px;
    box-shadow: 10px 5px 5px #0000007d;
    background-color: #ffffff;
    gap: 7px;
    border: 1px solid #d3d3d378;
    padding: 5px;
    margin: 5px;

    & .Pendiente {
      background: #ffb600;
      padding: 6px;
      border-radius: 15px;
      color: #ffffff;
    }
  
    & .Rechazado {
      background: #e11515;
      padding: 6px;
      color: white;
      border-radius: 15px;
    }
  
    & .Aprobado {
      background: #3ec83e;
      padding: 6px;
      border-radius: 15px;
      color: white;
    }

    & .adicionales, .adicionalesComputer{
      font-size: 12px;
      font-weight: 900;
      color: #e95612;
    }

    @media screen and (max-width: 500px){
      text-align: center; 
    }
  
`



export const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;  
  align-items: center;
  padding: 25px 0;
  
  & h3{
    text-align: start;
  }

  & .row{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    button span{
      display: none;
    }
    
    & input, textarea{
      padding: 5px;
      font-size: 0.83em;
      border:none;
      border-bottom: 2px solid #e95612; 
      font-weight:bold;
      outline-color: #e95612;
    }
    & select{
      border:none;
      border-bottom: 2px solid #e95612; 
      outline: none;
      color: #e95612;
      & option{
        color: black;
      }
    }

    textarea{
      resize: none;
      width: 100%;
      height: 50px;
    }
  }
`

export const FormItem = styled.div`
  width: 100%;
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 25px;

  & .map{
    min-height: 500px;
  }

  & textarea{
    resize: none;
    width: 100%;
    height: 50px;
    padding: 5px;
  }
  button span{
    display: none;
  }

`

export const ModalFormWrapper = styled.div`
  display: flex; 
  width: 100%;
  justify-content: center;
`
export const EmptyWrapper = styled.div`
  margin-left: 5%;
  width: 90%;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin-top: 50px;
  padding: 15px;
  border: 3px dashed #a1a1a1;
`

export const Edit = styled(AiFillEdit)`
    color: #FFF;
    font-size: 3rem;
    cursor: pointer;
    background-color: #e95612;
    border-radius: 15px;
    padding: 10px;
`
export const Detail = styled(BiDetail)`
    color: #FFF;
    font-size: 3rem;
    cursor: pointer;
    background-color: #e95612;
    border-radius: 15px;
    padding: 10px;
`
