import styled from 'styled-components'

export const StyledButton = styled.button`
    padding: 8px 32px;
    margin: 20px 0;
    background-color:  ${(props) => props.background? 'rgba(255, 255, 255, 1)' : '#E95612' };
    font-size: 18px;
    font-weight: 300;
    border: none;
    color: ${(props) => props.background? '#E95612' : 'rgba(255, 255, 255, 1)' }; 
    font-weight: 600;
  border-radius: 20px;
    box-shadow: 4px 4px 2px rgba(0, 0, 0, 0.5);
    cursor: pointer;

    &:hover{
        transform: translate(1px, 1px);
    }
`
