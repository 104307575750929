import styled, {keyframes} from "styled-components"

const flipTopAnimation = keyframes`
    0% { 
        transform: rotateX(0deg);
    }
    50%,
    100% { 
        transform: rotateX(-90deg);
    }
`

const flipBottomAnimation = keyframes`
    0%, 50% { 
        transform: rotateX(90deg);
    }
    100% { 
        transform: rotateX(0deg);
    }
`


export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    color: #e95612;
    --flip-height: 70px;
    --flip-width: calc(var(--flip-height) * 0.6);
    --line-height: calc(var(--flip-height) * 0.5);
    --flip-border-radius: calc(var(--flip-height) * 0.05);
    --flip-color: #333;
    --flip-text-color: #e95612;
    --animation-time: 300ms;
    --animation-ease: linear;
    --perspective: 200px;

    & span{
        height: 40%;
        width: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 25px;

        & .dot{
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #333;
        }
    }
`

export const Digito = styled.div`
    width: var(--flip-width);
    height: var(--flip-height);
    border-radius: var(--flip-border-radius);
    font-size: calc(var(--flip-height) * 0.5);
    font-weight: 700;
    position: relative;
    margin: 0 0.2rem;

    & .display{
    width: var(--flip-width);
    height: var(--flip-height);
    display: flex;
    flex-direction: column;
    z-index: 1;
    }

    & .display-top, .display-bottom{
        position: relative;
        text-align: center;
        overflow: hidden;
        width: 100%;
        height: calc(var(--flip-height) * 0.5);
        background-color: var(--flip-color);
        line-height: calc(var(--line-height) * var(--i));
    }
    
    & .display-top{
        --i: 2;
        border-top-left-radius: var(--flip-border-radius);
        border-top-right-radius: var(--flip-border-radius);

        &:after{
            content: "";
            position: absolute;
            top: 46px;
            left: 0;
            z-index: 5;
            width: 100%;
            height: 3px;
            background-color: #000;
            background-color: rgba(0, 0, 0, 0.4);
        }
    }

    & .display-bottom{
        --i: -2;
        border-bottom-left-radius: var(--flip-border-radius);
        border-bottom-right-radius: var(--flip-border-radius);
    }

    & .flipper{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        height: var(--flip-height);
        width: var(--flip-width);
        perspective: var(--perspective);
    }

    
    & .flipper-top, .flipper-bottom{
        position: absolute;
        left: -10%;
        right: -10%;
        width: var(--flip-width);
        margin: auto;
        text-align: center;
        height: calc(var(--flip-height) * 0.5);
        line-height: calc(var(--line-height) * var(--i));
        overflow: hidden;
        background-color: var(--flip-color);
        color: var(--flip-text-color);
    }

    & .flipper-top{
        --i: 2;
        top: 0;
        transform-origin: bottom;
        transform: rotateX(0deg);
        border-top-left-radius: var(--flip-border-radius);
        border-top-right-radius: var(--flip-border-radius);
    }

    & .flipper-bottom{
        --i: -2;
        transform: rotateX(90deg);
        transform-origin: top;
        bottom: 0;
        border-bottom-left-radius: var(--flip-border-radius);
        border-bottom-right-radius: var(--flip-border-radius);
    }

    &.play .flipper-top{
        animation: ${flipTopAnimation} var(--animation-ease) var(--animation-time);
    }

    &.play .flipper-bottom{
        animation: ${flipBottomAnimation} var(--animation-ease) var(--animation-time);
    }
`

export const WrapperDigitos = styled.div`
    display: flex;
`


export const WrapperEstructura = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`


export const FlechaUp = styled.div`
    width: 0;
    height: 0;
    border-right: calc(var(--flip-height) * 0.25) solid transparent;
    border-top: calc(var(--flip-height) * 0.25) solid transparent;
    border-left: calc(var(--flip-height) * 0.25) solid transparent;
    border-bottom: calc(var(--flip-height) * 0.25) solid #e95612;
    cursor: pointer;
`


export const FlechaDown = styled.div`
    width: 0;
    height: 0;
    border-right: calc(var(--flip-height) * 0.25) solid transparent;
    border-top: calc(var(--flip-height) * 0.25) solid #e95612;
    border-left: calc(var(--flip-height) * 0.25) solid transparent;
    border-bottom: calc(var(--flip-height) * 0.25) solid transparent;
    cursor: pointer;
`