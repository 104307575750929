//React
import {useEffect} from "react";
import ReactGA from "react-ga4";
import { Route, Routes} from "react-router-dom";

//Components
import Header from "../../components/Header";
import ProtectedPath from "../../components/ProtectedPath";

//Pages
import Login from "../../pages/Login";
import GruerosHome from "../../pages/HomeGrueros"
import Tarifa from "../../pages/Tarifa/Index"
import AltaTarifa from "../../pages/Tarifa/AltaTarifa"
import CMSNovedades from "../../pages/CMSNovedades"
import Admin from "../../pages/Admin"
import SeguimientoGrua from "../../pages/SeguimientoGrua"

//Contexts
import { PortalTicketContextProvider } from '../../context/PortalTicketContext';
import { RealTimeContextProvider } from '../../context/RealTimeContext';
import { NovedadesContextProvider } from '../../context/NovedadesContext'
import { TarifaContextProvider } from "../../context/TarifaContext";
import Resumenes from "../../pages/Resumenes";

const PortalCorporativo = () =>{

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }, []);

    return (
        <PortalTicketContextProvider>
            <Routes>
                <Route path="/prestador">
                    <Route index
                        element={
                            <ProtectedPath allowedRoles={['GRUERO']}>
                                <Header height={'80px'} noLinks={true} background={'black'} portal={true}/>
                                    <GruerosHome/>
                            </ProtectedPath>
                        }
                    />
                </Route>
                <Route path="/admin">
                    <Route index
                        element={
                            <ProtectedPath allowedRoles={['ADMINISTRADOR']}>
                                <Header height={'80px'} noLinks={true} background={'black'} portal={true}/>
                                <Admin/>
                            </ProtectedPath>
                        }
                    />
                    <Route
                        path="cms/*"
                        element={
                            <NovedadesContextProvider>
                                <ProtectedPath allowedRoles={['ADMINISTRADOR']}>
                                    <Header height={'80px'} noLinks={true} background={'black'} portal={true}/>
                                    <CMSNovedades/>
                                </ProtectedPath>
                            </NovedadesContextProvider>
                        }
                    />
                    <Route
                        path="exportar"
                        element={
                                <ProtectedPath allowedRoles={['ADMINISTRADOR']}>
                                    <Header height={'80px'} noLinks={true} background={'black'} portal={true}/>
                                    <Resumenes/>
                                </ProtectedPath>
                        }
                    />
                    <Route path="tarifa/*" >
                        <Route index element={
                            <TarifaContextProvider>
                                <ProtectedPath allowedRoles={['ADMINISTRADOR']}>
                                    <Header height={'80px'} noLinks={true} background={'black'} portal={true}/>
                                    <Tarifa/>
                                </ProtectedPath>
                            </TarifaContextProvider>
                        }/>
                        <Route path="alta" element={
                            <TarifaContextProvider>
                                <ProtectedPath allowedRoles={['ADMINISTRADOR']}>
                                    <Header height={'80px'} noLinks={true} background={'black'} portal={true}/>
                                    <AltaTarifa/>
                                </ProtectedPath>
                            </TarifaContextProvider>
                        }/>
                        <Route path="alta/:id" element={
                            <TarifaContextProvider>
                                <ProtectedPath allowedRoles={['ADMINISTRADOR']}>
                                    <Header height={'80px'} noLinks={true} background={'black'} portal={true}/>
                                    <AltaTarifa/>
                                </ProtectedPath>
                            </TarifaContextProvider>
                        }/>
                    </Route>
                    <Route
                        path="prestador/:id"
                        element={
                            <ProtectedPath allowedRoles={['ADMINISTRADOR']}>
                                <Header height={'80px'} noLinks={true} background={'black'} portal={true}/>
                                    <GruerosHome/>
                            </ProtectedPath>
                        }
                    />
                </Route>
                <Route path="/*" element={ <Login/> } />

            </Routes>
        </PortalTicketContextProvider>
    );
}

export default PortalCorporativo
/*

                <Route
                    path="/seguimiento"
                    element={
                    <RealTimeContextProvider>
                        <ProtectedPath allowedRoles={['DEVELOPER']}>
                            <Header height={'80px'} noLinks={true} background={'black'} portal={true}/>
                            <SeguimientoGrua/>
                        </ProtectedPath>
                    </RealTimeContextProvider>
                    }
                />
 */
