//Styled Components
import {
  Grid,
  GridElement
} from './elements'

//Components
import Novedad from '../Novedad'
import Loading from '../Loading'

//Context
import { useNovedadesContext } from '../../context/NovedadesContext'
import { useEffect } from 'react'

const calculateModel = (model) => {
  if(model === 1)
      return 'small'
  if(model === 2)
      return 'medium-vertical'
  if(model === 3)
      return 'medium-horizontal'
  if(model === 4)
      return 'large'
}

const Preview = ({ newModel = false, editable = true, background }) => {
  const { novedades, nuevaNovedad, getNovedades, novedadesLoading, novedadesDeleting } = useNovedadesContext()

  useEffect(() => {
    getNovedades()
  }, [])
  
  return (
    <>
      {
        novedadesLoading?
          <Loading text={'Cargando novedades...'}/>
        :
            novedadesDeleting?
              <Loading text={'Borrando novedad...'}/>
            :
              <Grid background={background}>
                {
                  novedades.map((novedad, index) => {
                    return(
                      <>
                        {
                          nuevaNovedad.id && novedad.id === nuevaNovedad.id?
                            <Novedad key={index} novedad={nuevaNovedad} editable={editable}/>
                          :
                            <Novedad key={index} novedad={novedad} editable={editable}/>
                        }
                      </>
                    )
                  })
                }
                {
                  newModel && !nuevaNovedad.id ?
                    <Novedad novedad={nuevaNovedad} editable={editable}/>
                  :
                    ''
                }
              </Grid>
      }
    </>
  )
}

export default Preview