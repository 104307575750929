import { useEffect } from 'react'
import { useNavigate, Navigate } from 'react-router-dom'
import { useUserContext } from '../../context/UserContext'

const ProtectedPath = ({ children, allowedRoles }) => {
    const { user, logged , logOut, verifyToken} = useUserContext()
    const navigate = useNavigate()
    if(logged && user && user.roles === undefined)
        logOut()
    async function checkToken() {
        let res = await verifyToken();
        if (res) {
            logOut()
        }
    }
    /*
    useEffect(() => {
        if(!logged)
            navigate('/portal')
    }, [logged])
    useEffect( () => {
        checkToken();
    }, [])
    */
    return (
        <>
            {
                logged?
                    allowedRoles.find(element => user.roles.includes(element))?
                        children
                    :
                        <Navigate to={'/portal'}/>
                :
                    <Navigate to={'/portal'}/>
            }
        </>
    )
}

export default ProtectedPath
