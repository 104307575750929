import {
    LogoWrapper
} from './elements'

import ImageService from '../../services/ImageService';
import {useEffect,useState} from "react";
const Logo = ({ backgroundColor }) => {
    const [loading, setLoading] = useState(`${process.env.PUBLIC_URL}/assets/images/logo-negro-contain.webp`)
    const setBuffer = (buffer) =>{
        const b64 = btoa(String.fromCharCode(...new Uint8Array(buffer.data)));
        const mimeType =  "image/png"
        document.getElementById('logo').src = `data:${mimeType};base64,${b64}`
    }
    useEffect(()=>{
        let patDest = `${process.env.PUBLIC_URL}/assets/images/logo-negro-250.webp`;
        //ImageService.resizePublicImage(`${process.env.PUBLIC_URL}/assets/images/logo-negro.webp`,patDest,250,250,'inside',setBuffer);
    },[])
    return (
        <LogoWrapper backgroundColor={backgroundColor}>
            <img loading={"lazy"} id="logo" src={loading} alt="LOGO"/>
        </LogoWrapper>
    )
}

export default Logo
