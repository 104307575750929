
const { url} = require('./constantes');
const download = require('downloadjs');

async function getPrestadores(filters,token,callBack) {
    try{
        await fetch(`${url}/admin/getProveedores${filters?`?from=${filters.initDate}&to=${filters.finishDate}`:""}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+ token
            },
        }).then(res => {
            if(res.status===401 && window.localStorage.getItem('user-libra')) {
                window.localStorage.removeItem('user-libra');
                window.location.href = '/portal';
            }
            return res.json()
        }).catch(res => {
            callBack(null)
        }).then((json) => callBack(json))
    }catch (e){
        //console.log(e);
    }
}

async function getBases(id,token,callBack) {
    try{
        await fetch(`${url}/admin/getBases/${id}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+ token
            },
        }).then(res => {
            if(res.status === 401 && window.localStorage.getItem('user-libra')) {
                window.localStorage.removeItem('user-libra');
                window.location.href = '/portal';
            }
            return res.json()
        }).catch(res => {
            callBack(null)
        }).then((json) => callBack(json))
    }catch (e){
        //console.log(e);
    }
}
/*
async function getBasesAndPrestadores(token,callBack) {
    try{
        await fetch(`${url}/admin/getBases`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+ token
            },
        }).then(res => {
            if(res.status===401 && window.localStorage.getItem('user-libra')) {
                window.localStorage.removeItem('user-libra');
                window.location.href = '/portal';
            }
            return res.json()
        }).catch(res => {
            callBack(null)
        }).then((json) => callBack(json))
    }catch (e){
        //console.log(e);
    }
}
*/
async function getBasesAndPrestadores(token,callBack) {
    try{
        await fetch(`${url}/admin/getBasesAndPrestadores`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+ token
            },
        }).then(res => {

            if(res.status===401 && window.localStorage.getItem('user-libra')) {
                window.localStorage.removeItem('user-libra');
                window.location.href = '/portal';
            }

            return res.json()
        }).catch(res => {
            callBack(null)
        }).then((json) => callBack(json))
    }catch (e){
        //console.log(e);
    }
}

async function getRouteID(uuid,token) {
    try{
        let response = await fetch(`${url}/route/getNewRoute?uuid=${uuid}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+ token
            },
        }).then(res => {
            if(res.status===401 && window.localStorage.getItem('user-libra')) {
                window.localStorage.removeItem('user-libra');
                window.location.href = '/portal';
            }
            return res.json()
        }).catch(res => {
            console.log(res);
            return null;
        });
        return response;
    }catch (e){
        //console.log(e);
    }
}

async function getInfoPrestador({initDate, finishDate, estado, page, offset, ticket_numero,ticket_patente, base}, provId, token, callBack) {
    try{
        await fetch(`${url}/admin/getProveedor/${provId}?from=${initDate}&to=${finishDate}&prestadorId=${base}&estado=${estado}&page=${page}&offset=${offset}${ticket_numero ? `&ticket_numero=${ticket_numero}&ticket_patente=${ticket_patente}` : ''}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+token
            },
        }).then(res => {
            if(res.status===401 && window.localStorage.getItem('user-libra')) {
                window.localStorage.removeItem('user-libra');
                window.location.href = '/portal';
            }
            return res.json()
        }).catch(res => {
            callBack(null)
        }).then((json) => {
            callBack(json)
        })
    }catch (e){
        //console.log(e);
    }
}

async function getTarifaWithFilters(token,callBack) {
    try{
        await fetch(`${url}/grupo/list`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+token
            },
        }).then(res => {
            if(res.status===401 && window.localStorage.getItem('user-libra')) {
                window.localStorage.removeItem('user-libra');
                window.location.href = '/portal';
            }
            return res.json()
        }).catch(res => {
            callBack(null)
        }).then((json) => callBack(json.data))
    }catch (e){
        //console.log(e);
    }
}

async function saveTarifa(token, body, callBack) {
    try{
        await fetch(`${url}/grupo/create`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+token
            },
            body: JSON.stringify(body),
        }).then(res => {
            if(res.status===401 && window.localStorage.getItem('user-libra')) {
                window.localStorage.removeItem('user-libra');
                window.location.href = '/portal';
            }
            return res.json()
        }).catch(res => {
            callBack(null)
        }).then((json) => callBack(json.data))
    }catch (e){
        //console.log(e);
    }
}

async function editTarifa(token, body, callBack) {
    try{
        await fetch(`${url}/grupo/update/${body.id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+token
            },
            body: JSON.stringify(body),
        }).then(res => {
            if(res.status===401 && window.localStorage.getItem('user-libra')) {
                window.localStorage.removeItem('user-libra');
                window.location.href = '/portal';
            }
            return res.json()
        }).catch(res => {
            callBack(null)
        }).then((json) => callBack(json.data))
    }catch (e){
        //console.log(e);
    }
}

async function getTarifa(token, id, callBack) {
    try{
        await fetch(`${url}/grupo/${id}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer ' + token
            }
        }).then(res => {
            if(res.status===401 && window.localStorage.getItem('user-libra')) {
                window.localStorage.removeItem('user-libra');
                window.location.href = '/portal';
            }
            return res.json()
        }).catch(res => {
            callBack(null)
        }).then((json) => callBack(json.data))
    }catch (e){
        //console.log(e);
    }
}

async function asignarTarifas(values,token) {
    try{

        let response = await fetch(`${url}/admin/asignar/tarifa-prestador`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+ token
            },
            body: JSON.stringify(values),
        }).then(res => {
            if(res.status===401 && window.localStorage.getItem('user-libra')) {
                window.localStorage.removeItem('user-libra');
                window.location.href = '/portal';
            }
            return res.json()
        }).catch(res => {
            return res.json()
        })
        console.log(response)
        return response;
    }catch (e){
        //console.log(e);

    }
}
async function addTicket(ticketNro,token) {
    try{

        let response = await fetch(`${url}/admin/crearByNro`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+ token
            },
            body: JSON.stringify({ticketNro:ticketNro}),
        }).then(res => {
            if(res.status===401 && window.localStorage.getItem('user-libra')) {
                window.localStorage.removeItem('user-libra');
                window.location.href = '/portal';
            }
            return res.json()
        }).catch(res => {
            return res.json()
        })
        console.log(response)
        return response;
    }catch (e){
        //console.log(e);

    }
}

async function getResumen(values,token) {
    try{

        let response = await fetch(`${url}/admin/resumen`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+ token
            },
            body: JSON.stringify(values),
        }).then(async res => {
            if(res.status===401 && window.localStorage.getItem('user-libra')) {
                window.localStorage.removeItem('user-libra');
                window.location.href = '/portal';
            }
            if(res.status===500 ) {
                return {status:'ERROR',message:'Ocurrio un error en el servidor.\n Contacte con su administrador.'}
            }
            return res.blob();
        }).then((response)=>{
            download(response,"Attachment.zip","application/zip") //this is third party it will prompt download window in browser.
            return {status:'SUCCESS'}
        })
            .catch(res => {
            return res
        })
        return response;
    }catch (e){
        //console.log(e);

    }
}

async function editServicio(token, servicio, callback) {
    try{
        fetch(`${url}/admin/editar/servicio`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+ token
            },
            body: JSON.stringify(servicio),
        }).then(res => {
            if(res.status===401 && window.localStorage.getItem('user-libra')) {
                window.localStorage.removeItem('user-libra');
                window.location.href = '/portal';
            }
            return res.json()
        }).then(res => {
            callback(res)
        }).catch(res => {
            callback(res.json())
        })
    }catch (e){
        //console.log(e);
    }
}

async function getPositions(token,callBack) {
    try{
        await fetch(`${url}/realTime/getPositions`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+ token
            },
        }).then(res => {
            /*
            if(res.status===401 && window.localStorage.getItem('user-libra')) {
                window.localStorage.removeItem('user-libra');
                window.location.href = '/portal';
            }
             */
            return res.json()
        }).catch(res => {
            callBack(null)
        }).then((json) => callBack(json))
    }catch (e){
        //console.log(e);
    }
}
const AdminService = {
    getBasesAndPrestadores,
    getPrestadores,
    getInfoPrestador,
    getTarifaWithFilters,
    asignarTarifas,
    editServicio,
    saveTarifa,
    getTarifa,
    editTarifa,
    getResumen,
    getPositions,
    getBases,
    getRouteID,
    addTicket
};

export default AdminService
