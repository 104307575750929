//Styled Components
import {
  Wrapper
} from './elements'

//Components
import Section from "../../components/Section"

const NuestrasAsistencias = () => {
  return (
    <Section
      translate='100px'
      image={`${process.env.PUBLIC_URL}/assets/images/imagen_asistencia.webp`}
      icono={`${process.env.PUBLIC_URL}/assets/images/icono_asistencia.webp`}
      styled={true}
      compName='asistenciaVehicular'
    >
      <Wrapper>
        <h1>
          Asistencia Vehicular
        </h1>
        <p>
          Circulá con la tranquilidad de contar con la mejor asistencia las 24 hs. Servicios de urgencias mecánicas y traslados en todo el país para cualquier tipo de vehículo: automóviles, motos, camiones, para resolver cualquier desperfecto, siniestro o urgencia que pueda ocurrir.
        </p>
        <img loading={"lazy"} src={`${process.env.PUBLIC_URL}/assets/images/huella.webp`} alt={"huella"}/>
      </Wrapper>
    </Section>
  )
}

export default NuestrasAsistencias
