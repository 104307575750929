import Section from "../../components/Section"

//Style

import {StyledImg} from "./elements"

const NuevosSocios = () => {
  return(
    <Section>
      <table  style={{width: '100%', align:"center",cellpadding:"0" ,cellspacing:"0"}}>
      <tbody>
    <tr>
		<td>
			<StyledImg src={`${process.env.PUBLIC_URL}/assets/images/img_01.webp`} alt="MoveOn Libra Seguros" style={{'max-width': '1100px'}}/>
		</td>
	</tr>     
    <tr>
		<td>
			<StyledImg src={`${process.env.PUBLIC_URL}/assets/images/img_03.webp`} alt="MoveOn Libra Seguros"  style={{'max-width': '880px'}}/>
		</td>
	</tr> 
	<tr>
		<td>
			<a href="https://docs.google.com/forms/d/e/1FAIpQLSdLy3Gz0JNhhggyD5tkYk3eij9FOY2l9jZQmzDIOV58ctamKg/viewform?utm_source=Landing&utm_medium=Bdd&utm_id=Landing+al+Form" target="_blank" rel="noreferrer">
				<StyledImg src={`${process.env.PUBLIC_URL}/assets/images/img_05.webp`} alt="MoveOn Libra Seguros" style={{'max-width': '880px'}}/>
			</a>
		</td>
	</tr>
    <tr  style={{background: '#4f4f4f;', bgcolor:"#4f4f4f"}}>
		<td>
			<StyledImg src={`${process.env.PUBLIC_URL}/assets/images/img_06.webp`} alt="MoveOn Libra Seguros" style={{'max-width': '880px'}}/>
		</td>
	</tr>  
    <tr>
		<td>
			<StyledImg src={`${process.env.PUBLIC_URL}/assets/images/img_07.webp`} alt="MoveOn Libra Seguros" style={{'max-width': '1100px'}}/>
		</td>
	</tr> 
  </tbody>
</table>
    </Section>
  )
}

export default NuevosSocios
