//Styled Components
import {
    Wrapper,
    WrapperNumerosTelefono,
    FilaTelefonos
} from './elements'

//Components
import Section from "../../components/Section"

const TelefonosUtiles = () => {
  return (
    <Section
      image={`${process.env.PUBLIC_URL}/assets/images/imagen_telefonosutiles.webp`}
      icono={`${process.env.PUBLIC_URL}/assets/images/icono_telefonosutiles.webp`}
      styled={true}
      recta={true}
      compName='telefonosUtiles'
    >
        <Wrapper>
            <h2>TELÉFONOS ÚTILES</h2>
            <span>
              Ante una emergencia comunícate a los siguientes números de teléfono
            </span>
            <img loading={"lazy"} className='huella' src={`${process.env.PUBLIC_URL}/assets/images/huella.webp`} alt={'imagen'}/>
            <WrapperNumerosTelefono>
              <FilaTelefonos>
                <img loading={"lazy"} src={`${process.env.PUBLIC_URL}/assets/images/botones/Boton_Emergencias_140.webp`} onClick={()=>window.open("tel:140", "_blank")} alt={'140 para emergencias en autopistas'}/>
              </FilaTelefonos>
              <FilaTelefonos>
                <img loading={"lazy"} src={`${process.env.PUBLIC_URL}/assets/images/botones/Bomberos.webp`} onClick={()=>window.open("tel:100", "_blank")} alt={'100 Para bomberos'}/>
                <img loading={"lazy"} src={`${process.env.PUBLIC_URL}/assets/images/botones/Boton_Policia.webp`} onClick={()=>window.open("tel:101", "_blank")} alt={'101 o 911 Para policia'}/>
                <img loading={"lazy"} src={`${process.env.PUBLIC_URL}/assets/images/botones/Boton_Same.webp`} onClick={()=>window.open("tel:107", "_blank")} alt={'107 Para SAME'}/>
                <img loading={"lazy"} src={`${process.env.PUBLIC_URL}/assets/images/botones/Centro de Atencion al Usuario.webp`} onClick={()=>window.open("tel:08002226272", "_blank")} alt={'08002226272 o 08003330073 para Centro de atencion al usuario de vialidad nacional'}/>
              </FilaTelefonos>
              <FilaTelefonos>
                <img loading={"lazy"} src={`${process.env.PUBLIC_URL}/assets/images/botones/sur.webp`} onClick={()=>window.open("tel:08009990800", "_blank")} alt={'08009990800 para Autopista del Sur'}/>
                <img loading={"lazy"} src={`${process.env.PUBLIC_URL}/assets/images/botones/Boton_Del Oeste.webp`} onClick={()=>window.open("tel:08009992985", "_blank")} alt={'08009992985 para Autopista del Oeste'}/>
                <img loading={"lazy"} src={`${process.env.PUBLIC_URL}/assets/images/botones/Boton_Del Buen Ayre.webp`} onClick={()=>window.open("tel:08008887442", "_blank")} alt={'08008887442 para Autopista del Buen Ayre'}/>
                <img loading={"lazy"} src={`${process.env.PUBLIC_URL}/assets/images/botones/Boton_Bs. As. La Plata.webp`} onClick={()=>window.open("tel:08006668353", "_blank")} alt={'08006668353 para Autopista Bs. As. La Plata'}/>
                <img loading={"lazy"} src={`${process.env.PUBLIC_URL}/assets/images/botones/BotonSol.webp`} onClick={()=>window.open("tel:08009999765", "_blank")} alt={'08009999765 para Autopista del Sol'}/>
              </FilaTelefonos>
            </WrapperNumerosTelefono>

        </Wrapper>
    </Section>
  )
}

export default TelefonosUtiles
