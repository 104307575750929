//React
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useState,useEffect } from 'react'

//Global Style
import GlobalStyle from './GlobalStyle'

//Components
import Header from './components/Header'
import WebApp from "./sections/WebApp"
import Forms from "./sections/Forms";
import NuevosSocios from "./sections/nuevossocios";
import TerminosYCondiciones from "./pages/TerminosYCondiciones";
import Landing from "./pages/landing";
import Politicas from "./pages/Politicas";
import Footer from "./components/Footer";

//Apps
import PortalCorporativo from "./aplicaciones/portalCorporativo"

//analytics
import ReactGA from "react-ga4";

//Contexts
import { NovedadesContextProvider } from './context/NovedadesContext'
import { UserContextProvider } from './context/UserContext'
import { TicketContextProvider } from './context/TicketContext'

//Hooks
import ScrollToTop from "./hooks/ScrollToTop";

//inicializamos
const TRACKING_ID = "UA-230879312-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  return (
    <BrowserRouter onUpdate={() => window.scrollTo(0, 0)} basename="">

        <GlobalStyle/>
        <Routes >
          <Route path="/webapp" element={
              <>
                <Header noLinks={true} background='black'/>
                <TicketContextProvider>
                  <WebApp/>
                </TicketContextProvider>
              </>
            }
          />
          <Route path="/forms">
            <Route index element={ <Forms/> } />
            <Route path="consultas" element={ <Forms form_id={'consultas'}/> } />
            <Route path="sumate" element={ <Forms form_id={'sumate'}/> } />
          </Route>
          <Route path="/portal/*" element={
            <UserContextProvider>
              <PortalCorporativo/>
            </UserContextProvider>
          } />
          <Route path="/nuevossocios/*" element={ <NuevosSocios/> } />
          <Route path="/terminosycondiciones" 
            element={ 
              <>
                <ScrollToTop/>
                <TerminosYCondiciones/>
              </> 
            } 
          />
          <Route path="/politicas"
            element={ 
              <>
                <ScrollToTop/>
                <Politicas/>
              </> 
            } 
          />
          <Route path="*" element={
            <NovedadesContextProvider>
              <Landing/>
            </NovedadesContextProvider>
          } />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
