import {
    StyledSelect
} from './element'


const Select = ({idValue, values, options, changeValues}) => {

    const changeHandler = (id, e) => {
        changeValues(
            {
                ...values,
                [id]: e.target.value
            }
        )
    }

    return (

        
        <StyledSelect name={idValue} id={idValue} defaultValue={options.find(element => element.selected) ? options.find(element => element.selected).id : ''} onChange={(e) => changeHandler(idValue, e)}>
             <option value={null}></option>
            {
               
                options.map((option) => {
                    return(
                        <option value={option.id}>{option.nombre}</option>
                    )
                })
            }
            
        </StyledSelect>
    )
}

export default Select
