import {  useRef } from "react"
import {
    FileUploadWrapper,
    FormField,
    InputLabel,
    DragDropText,
    UploadFileBtn,
    UploadIcon,
    UploadedFileIcon,
    TrashFiles
} from './elements'

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 600000;
const KILO_BYTES_PER_BYTE = 1000;

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const FileUpload = ({
        files,
        setFiles,
        label,
        updateFilesCb,
        maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
        ...otherProps
    }) => {
    const fileInputField = useRef(null);

    /*const addNewFiles = (file) => {
        if (file.size <= maxFileSizeInBytes) {
            console.log('entro');
            return { file };
        }
    }*/

    const removeFile = () => {
        setFiles({});
    }

    const handleUploadBtnClick = e => {
        fileInputField.current.click();
    };

    const handleNewFileUpload = (e) => {
        const file = e.target.files;
        if (file.length) {
            //let updatedFiles = addNewFiles(file[0]);
            setFiles({file: file[0]})
        }
    }

    function dragOverHandler(ev) {
        ev.preventDefault();
    }

    return (
        <FileUploadWrapper onDragOver={dragOverHandler}>
            <InputLabel>{label}</InputLabel>
            {
                Object.keys(files).length === 0
                ?
                    <>
                        <DragDropText>Arrastre sus archivos aqui o</DragDropText>
                        <UploadFileBtn type="button" onClick={handleUploadBtnClick}>
                            <UploadIcon/>
                            <span>Suba un archivo</span>
                        </UploadFileBtn>
                        <FormField
                            type="file"
                            ref={fileInputField}
                            onChange={handleNewFileUpload}
                            title=""
                            value=""
                            {...otherProps}
                        />
                    </>
                :
                    <>
                        <TrashFiles onClick={removeFile}/>
                        <UploadedFileIcon/>
                        <DragDropText>{files.file.name}</DragDropText>
                    </>
            }
        </FileUploadWrapper>
    )
}

export default FileUpload;
