import styled from "styled-components"

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`

export const Modal = styled.div`
    max-width: 100%;
    min-height: 500px;
    background-color: white;
    margin: 150px 50px 0 50px;
    border-radius: 30px;
    padding: 30px 50px;
    color: #E95612;

    & p{
        margin: 15px 0;
        text-align: justify;
        line-height: 22px;
    }

    @media screen and (max-width: 680px){
        width: 100%;
        margin: 100px 0 0 0;
        padding: 30px;
        
        & p{
            line-height: auto;
        }
    }
`