import { createRoot } from "react-dom/client"
import { HeaderContextProvider } from './context/HeaderContext'
import { FormContextProvider } from "./context/FormContext"
import App from "./App";
//google oAuth
import { GoogleOAuthProvider } from '@react-oauth/google';
const oAuth_clientId ='284686396131-h20moo4u6c5er4h1sivpubbnntltfr01.apps.googleusercontent.com';

//Fin configuracion
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
      <HeaderContextProvider>
        <FormContextProvider>
          <GoogleOAuthProvider clientId={oAuth_clientId}>
              <App />
          </GoogleOAuthProvider>
        </FormContextProvider>
      </HeaderContextProvider>
);
