import styled from 'styled-components'

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    min-height: calc(100vh - 130px);
    display: flex;
    flex-direction: column;
    padding-top: 25px;
    justify-content: flex-start;
    align-items: center;
    color: #E95612;
    background-color: #E95612;
    padding-bottom: 25px;

    @media screen and (max-width: 1260px){
        min-height: calc(100vh - 84px);
    }

    .fixed-button{
        z-index: 2;
        margin-top: 40px;
        width: 150px;
    }
`

export const RadioWrapper = styled.div`
    display: flex; 
    gap: 40px;
    width: 100%;
    justify-content: start;
    align-items: center;
    font-weight: bold;
    label{
      color: grey;
    }
    input[type="radio"] {
        appearance: none;
        background-color: #fff;
        margin: 0;
        font: inherit;
        color: currentColor;
        width: 1.15em;
        height: 1.15em;
        border: 1px solid currentColor;
        border-radius: 50%;
    }
    input[type="radio"] {
        /* ...existing styles */
        display: grid;
        place-content: center;
    }
    
    input[type="radio"]::before {
        content: "";
        width: 0.65em;
        height: 0.65em;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em var(--form-control-color);
    }
    
    input[type="radio"]:checked::before {
      transform: scale(1);
      background-color: currentColor;
    }
    input[type="radio"]:focus {
        outline: max(2px, 0.15em) solid currentColor;
        outline-offset: max(2px, 0.15em);
    }
`
export const ListRadioWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 50%;
    justify-content: start;
`

export const HuellaWrapper = styled.div`
    display: flex;
    position: relative;
    gap: 40px;
    width: 100%;
    justify-content: center;
    height: 100px;
    min-height: 100px;
    align-items: center;
    margin-bottom: 10px;
    padding-right: 20px;
`


export const ArrowWrapper = styled.div`
    .downRectangle{
      position: absolute;
      width: 43px;
      height: 20px;
      background: ${props => props.color};
      transform: rotate(315deg) translateY(12px);
    }
    .topRectangle{
      position: absolute;
      width: 43px;
      height: 20px;
      background: ${props => props.color};
      transform: rotate(45deg) translateY(-12px);
    }
`
export const ButtonWrapper = styled.div`
    display: flex; 
    gap: 40px;
    width: 100%;
    justify-content: center;

    button{
      width: 150px;
    }
`

export const WrapperContent = styled.div`
    width: 60vw;
    height:fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    z-index: 1;

    #map{
        width: 100%;

        @media screen and (max-width: 1260px) {
            height: 550px;
        }
    }

    .custom-map-control-button{
        background-color: #E95612;
        color: white;
        padding: 8px 16px;
        margin-top: 55px;
        transform: translateX(-180px);
        outline: none;
        border: none;
        box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.6);
        border-radius: 2px;
        font-weight: 300;
        font-size: 18px;
        white-space: nowrap;
        cursor: pointer;
    }

    h1{
        text-align: center;
    }

    .add{
        width: 45px;
        height: 45px;
        font-size: 18px;
        font-weight: 600;
        border-radius: 100%;
        border: none;
        background-color: #E95612;
        color: #fff;
        cursor: pointer;
        box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.5);

        &:hover{
            transform: translate(1px, 1px);
        }
    }

    @media screen and (max-width: 1260px) {
        width: 90vw;
    }
`

export const Form = styled.div`
    width: ${({ half }) => half ? '50%' : '100%'};
    display: flex;
    flex-direction: ${({ column }) => column ? "column" : "row"};
    column-gap: 15px;
    row-gap: 5px;
    font-weight: 300;
    font-size: 18px;
    .texto{
      padding-top: 15px;
      width: 30%;
    }
    textarea{
        width: 100%;
        min-height: 100px;
        border: 2px solid #E95612;
        outline: none;
        padding: 8px 8px;
        color: black;
        font-size: 18px;
    }
`
