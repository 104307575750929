import styled from "styled-components"
import { FaUserAlt, FaLock } from 'react-icons/fa'
import {Link} from "react-router-dom";

export const Dates = styled.div`
  display: flex;
  gap: 15px;
  margin: 20px 0;
  
  input{
    border: none !important;
    border-bottom: solid 2px #e95612 !important;
    padding: 15px 0;
    font-size: 18px;
    color: #e95612;
  }
`

export const ModalWrapper = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2050;
`

export const Volver = styled(Link)`
  display: flex;  
  width: fit-content;
  background: white;
  border-radius: 15px;
  padding: 5px;
  color: #e95612;
  font-size: 30px;
  box-shadow: 10px 5px 5px #0000007d;z-index: 1;
  margin-bottom: 15px;
`
export const Wrapper = styled.div` 
  width: 100%; 
  margin-top:90px;
  
  padding: 5px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  grid-auto-flow: dense; 
  z-index: 1;

  & #tittle{
    line-height: 1.6rem;
    position: relative;
    top: 0;
    p {
      line-height: 1rem;
      font-style: italic;
      font-size: 14px;
    }
  }
  & #lista{ 

    & th{
      background-color: #e95612;
      color: #fff;
    }

    & tr{    
      white-space: break-spaces;
      &:hover{
        background-color: rgba(233, 86, 18, 0.7);
        color: #fff;
      }
    }
    
    & input{
      border: 1px solid black;
    }
    .row{
      display: flex;
      justify-content: space-around;
      
    }
  }
`
export const Instructivo = styled.div`   
    position: fixed;
    display: ${props => props.loading!=='false'  ?  "flex" : "none"};
    flex-direction: column;
    background: white;
    padding: 10px; 
    border-radius: 15px;  
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    /* width */
    left: 30%;
    right: 30%; 
  
    ::-webkit-scrollbar {
        width: 10px;
    }
    
    /* Track */
    
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    
    /* Handle */
    
    ::-webkit-scrollbar-thumb {
        background: #d57834;
        border-radius: 50px;
    }
    
    /* Handle on hover */
    
    ::-webkit-scrollbar-thumb:hover {
        background: #d57834;
    }
    
    & a {
        margin: 5px;
    
    & :hover {
            cursor: pointer;
        }
    }
    
    &Link {
    & :hover {
            cursor: pointer;
        }
    }
    
    & .close {
        bottom: 20px;
        right: 20px;
        align-items: center;
        justify-content: flex-end;
        grid-column: 3;
    
    & Button {
            display: flex;
            align-items: center;
            font-size: 14px;
            padding: 0 10px;
            gap: 10px;
        }
    }
    
    & #errorMsg {
        color: #D70909FC;
    }
    
    & .tittle {
        width: 100%;
        font-size: 22px;
    }
    
    svg {
        width: 100% !important;
    }
    & .row{
      display: flex;
      gap: 20px;
      justify-content: space-around;
      button{
        display: ${props => props.loading!=='false'  ?  "block" : "none"};
        width: 120px;
        font-size: 13px;
      }
    }
    @media (max-width: 500px) {
        width: 100%;
    }
`
export const EmptyWrapper = styled.div`
  margin-left: 5%;
  width: 90%;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin-top: 50px;
  padding: 15px;
  border: 3px dashed #a1a1a1;
`

export const Imagen = styled.img`
  position: absolute;
  z-index: 1;
  opacity: 0.5;

  &.inferior{
    bottom: 0px;
    left: 0px;
  }

  &.superior{
    top: 0px;
    right: 0px;
  }
`
export const AdminHomeWrapper = styled.div` 
  width: 100vw;
  height: 100%;
  padding: 100px 30px 30px 30px;
  z-index: 3;

  & h3{
    text-align: center;
    width: 100%;
    margin-bottom: 5px;
  }
`

export const LoadingWrapper = styled.div`
  width: 200px;
  height: 200px;
  background-color: #fafafa;
  padding: 5px;
  display: grid;
  border-radius: 8px;
  box-shadow: 10px 5px 5px #0000007d;z-index: 1;
`

export const PrestadoresListWrapper = styled.div` 
  width: 100%;
  background-color: #fff; 
  padding: 5px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 10px 5px 5px #0000007d;

  & #lista{
    & th{
      background-color: #e95612;
      color: #fff;
    }

    & tr{
      &:hover{
        background-color: rgba(233, 86, 18, 0.7);
        color: #fff;
      }
    }
    
    & input{
      border: 1px solid black;
    }
  }
`
