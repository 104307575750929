
const {token_LIBRA, url} = require('./constantes');

async function specialSearch({patente,modelo,marca,color,poliza,dni}) {
    try{
        let searchBy = {
            auto:{
                patente: patente.toUpperCase() ?? patente,
                modelo:modelo,
                marca:marca,
                color:color,
                poliza:poliza
            },
            cliente:{
                dni:dni
            }
        }
        let response = await fetch(`${url}/clientes/search/byCar`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+ token_LIBRA
            },
            body: JSON.stringify(searchBy),
        }).then(res => {
            return res.json()
        }).catch(res => {
            return res.json()
        })
        return response;
    }catch (e){
        //console.log(e);

    }
}
async function getRandomWith(cantidad,idCliente,then) {
    try{
        await fetch(`${url}/clientes/random?cantidad=${cantidad}&id=${idCliente}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+ token_LIBRA
            }
        }).then(res => {
            return res.json()
        }).catch(res => {
            return res.json()
        }).then(then)
    }catch (e){
        //console.log(e);
    }
}

async function getById(id, callback) {
    try{
        await fetch(`${url}/clientes/${id}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+ token_LIBRA
            },
        }).then(res => {
            return res.json()
        }).catch(res => {
            return res.json()
        }).then((json) => callback(json))
    }catch (e){
        //console.log(e);
    }
}
// send `POST` request

// send `POST` request


const ClienteService = {
    specialSearch,
    getRandomWith,
    getById
};

export default ClienteService
