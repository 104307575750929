//Styled Components
import {
    Wrapper,
    Modal
} from './elements'

//Components
import Header from "../../components/Header";
import LogoWhatsApp from "../../components/LogoWhatsApp";
import Footer from "../../components/Footer";

const Politicas = () => {
  return (
    <>
        <Header noLinks={true}/>
        <Wrapper>
            <Modal>
                <h3>Políticas de privacidad</h3>
                <p>
                    Move On está certificado por SSL (Secure Socket Layer), que actualmente es el estándar utilizado por los sitios webs denominados e-commerce, en donde se requiere realizar transacciones electrónicas seguras, lo que significa que toda tu información viaja de manera encriptada y no podrá ser leída ni capturada por terceros mientras viaja por la red.
                </p>
                <h3>Declaración de Privacidad</h3>
                <p>
                    Es importante que sepas que Move On. no traspasará, bajo ninguna modalidad a ninguna empresa y/o a ningún tercero, datos personales de los clientes registrados en una aseguradora y te asegura que éstos serán manejados en forma absolutamente confidencial y conforme lo dispone la legislación vigente.
                </p>
                <h3>Compromiso con la Seguridad</h3>
                <p>
                    En relación con nuestro sitio web Move On By Libra hace esta declaración de seguridad y privacidad en orden a demostrar y comunicar su compromiso con una práctica de negocios de alto nivel ético y dotada de los controles internos apropiados.
                </p>
                <h3>Información requerida del usuario</h3>
                <p>
                    El formulario constituido por la totalidad de los campos a rellenar por el cliente en nuestro sitio web, pide a los usuarios cierta información como su nombre, patente, póliza, dirección física y número telefónico. Esta información es usada para poder acceder al servicio de auxilio mecánico ofrecido. También se utiliza para mantener contacto con nuestros clientes. Bajo ninguna circunstancia, esta información es compartida con empresas y/o con terceros.                
                </p>
                <h3>Links con otros sitios</h3>
                <p>
                    Este sitio puede contener «links» a otros sitios. Move On no es responsable de las prácticas de seguridad o privacidad, o el contenido de esos sitios. Asimismo, Move On no avala ningún producto o servicio ofrecido en dichos sitios.
                </p>
                <h3>Datos Personales</h3>
                <p>
                    El Usuario podrá ejercer los derechos de acceso, rectificación y/o supresión sobre sus datos conforme lo dispuesto por la Ley 25.326 y modificatorias. De acuerdo con lo establecido en la Disposición 10/2008 DNPDP: «La DIRECCION NACIONAL DE PROTECCION DE DATOS PERSONALES, Órgano de Control de la Ley Nº 25.326, tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de datos personales.

                    Todo usuario presta conformidad para que la información referida a sus datos personales pueda ser utilizada para fines publicitarios, promocionales y/o de marketing.
                </p>
                <h3>Divulgación de los Datos Personales. Orden de autoridades competentes – Requerimientos Legales</h3>
                <p>
                    Move On no entregará a terceros los datos personales de los usuarios, a menos que sea para cumplir una obligación legal y/o por requerimiento y/o autorización judicial del juez competente.

                    Asimismo, puede difundir sus Datos Personales en casos especiales cuando pensemos que proporcionar esta información puede servir para identificar, localizar o realizar acciones legales contra personas que pudiesen infringir los Términos y Condiciones Generales y Particulares de uso de Move On y causar daños o interferencia (intencionadamente o no) sobre los derechos de Move On. o los usuarios de Move On o de cualquier otra persona que pudiese resultar perjudicada por dichas actividades. Move On. coopera con las autoridades competentes y con otros terceros para garantizar el cumplimiento de las leyes, en materia de protección de derechos de propiedad industrial e intelectual, prevención del fraude y otras materias, por ello podrá revelar la Información Personal de sus usuarios bajo requerimiento de la autoridades judiciales o gubernamentales competentes para efectos de investigaciones conducidas por ellas, aunque no exista una orden ni una citación ejecutiva o judicial, o por ejemplo (y sin limitación a este supuesto) cuando se trate de investigaciones de carácter penal o de fraude o las relacionadas con piratería informática o la violación de derechos de autor. En tales situaciones, Move On. colaborará con las autoridades competentes con el fin de salvaguardar la integridad y la seguridad de sus usuarios.
                </p>
                <h3>Cambios en las Condiciones y Políticas de Privacidad</h3>
                <p>
                    Move On se reserva el derecho a modificar las presentes Políticas de Privacidad para adaptarla a novedades legislativas o jurisprudenciales, por lo que recomendamos que las consulte periódicamente. Sin perjuicio de lo expuesto y sin deslindar responsabilidad del usuario, en caso de que hubiera cambios en nuestras Políticas de Privacidad, se publicará a través de este medio para que siempre esté informado. Todas las modificaciones entrarán en vigencia a los 10 (diez) días de su publicación. Dentro de los 5 (cinco) días siguientes a la publicación de las modificaciones introducidas, el usuario deberá comunicar por e-mail, (XXXXXXXXXXXX) siendo este el único medio que las partes aceptan como medio de notificación suficiente para no aceptar las mismas. Vencido este plazo, se considerará que el usuario acepta las nuevas Políticas y el presente Acuerdo continuará vinculando a ambas partes.
                </p>
                <h3>Jurisdicción y Ley Aplicable</h3>
                <p>
                    Las presentes Políticas de Privacidad estará regida en todas sus disposiciones por las leyes vigentes en la República Argentina. Cualquier controversia derivada de las presentes, su existencia, validez, interpretación, alcance o cumplimiento, serán sometidas a los Tribunales ordinarios en lo Comercial con asiento en la Capital Federal.
                </p>
                <h3>Domicilio</h3>
                <p>
                    Cualquier notificación o comunicación dirigida a Move On. deberá enviarse (de no mencionarse otra dirección de mail en los distintos apartados previos) al Correo Electrónico moveon@moveonbylibra.com.ar. Se fija como domicilio de Move On en Lavalle 1528 Piso 2 Oficina C, Ciudad Autónoma de Buenos Aires. Si tienes alguna duda sobre las Políticas de Privacidad que rigen en Move On no dude en comunicarse con nosotros.
                </p>
            </Modal>
        </Wrapper>
        <Footer/>
        <LogoWhatsApp/>
    </>
  )
}

export default Politicas