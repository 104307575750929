
class SumateFormDTO {
    email;//string
    nombre;//string
    apellido; //string
    provincia; //string
    localidad; //string
    telefono; //string
    mensaje; //string
    constructor({email = '', nombre = '', apellido = '',provincia = '',localidad = '',codtelefono = '',numerotelefono='',mensaje = ''}) {
        this. email = email;
        this. nombre  =nombre;
        this. apellido  = apellido;
        this. provincia  = provincia;  
        this. localidad  = localidad; 
        this. telefono  = codtelefono+numerotelefono; 
        this. mensaje  = mensaje; 
    }
}


export default SumateFormDTO
