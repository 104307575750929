import {GiContract,GiExpand} from 'react-icons/gi';
export const data =
    {
        "tarifas": [
            {
                id: 1,
                tipo: "COMUN",
                tipoAsistencia:"LIVIANO",
                codigo:"MOVIDA 10KM",
                descripcion:"Se calcula el valor cada 10 km",
                valor:3500.00,
                grupos:[1,5],
                fechaInicio: new Date((new Date()).setMonth(new Date().getMonth() -1)),
                fechaFin: new Date((new Date()).setMonth(new Date().getMonth() +2)),

            },
            {
                id: 2,
                tipo: "COMUN",
                tipoAsistencia:"LIVIANO",
                codigo:"KM (TOTALES)",
                descripcion:"Se calcula el valor cada 10 km",
                valor:80.00,
                grupos:[1,5],
                fechaInicio: new Date((new Date()).setMonth(new Date().getMonth() -1)),
                fechaFin: new Date((new Date()).setMonth(new Date().getMonth() +2)),

            },
            {
                id: 3,
                tipo: "COMUN",
                tipoAsistencia:"LIVIANO",
                codigo:"EXTRACCION",
                descripcion:"Extraccion del vehiculo de un garage, estacionamiento.",
                valor:3500.00,
                grupos:[1,5],
                fechaInicio: new Date((new Date()).setMonth(new Date().getMonth() -1)),
                fechaFin: new Date((new Date()).setMonth(new Date().getMonth() +2)),

            },
            {
                id: 4,
                tipo: "COMUN",
                tipoAsistencia:"LIVIANO",
                codigo:"HORA DE TRABAJO",
                descripcion:"Asistencia de mecanica ligera en el lugar.",
                valor:3500.00,
                grupos:[1,5],
                fechaInicio: new Date((new Date()).setMonth(new Date().getMonth() -1)),
                fechaFin: new Date((new Date()).setMonth(new Date().getMonth() +2)),

            },
            {
                id: 5,
                tipo: "COMUN",
                tipoAsistencia:"SEMIPESADO",
                codigo:"MOVIDA 10KM",
                descripcion:"Se calcula el valor cada 10 km",
                valor:4000.00,
                grupos:[2,5],
                fechaInicio: new Date((new Date()).setMonth(new Date().getMonth() -1)),
                fechaFin: new Date((new Date()).setMonth(new Date().getMonth() +2)),

            },
            {
                id: 6,
                tipo: "COMUN",
                tipoAsistencia:"SEMIPESADO",
                codigo:"KM (TOTALES)",
                descripcion:"Se calcula el valor cada 10 km",
                valor:90.00,
                grupos:[2,5],
                fechaInicio: new Date((new Date()).setMonth(new Date().getMonth() -1)),
                fechaFin: new Date((new Date()).setMonth(new Date().getMonth() +2)),

            },
            {
                id: 7,
                tipo: "COMUN",
                tipoAsistencia:"SEMIPESADO",
                codigo:"EXTRACCION",
                descripcion:"Extraccion del vehiculo de un garage, estacionamiento.",
                valor:4000.00,
                grupos:[2,5],
                fechaInicio: new Date((new Date()).setMonth(new Date().getMonth() -1)),
                fechaFin: new Date((new Date()).setMonth(new Date().getMonth() +2)),

            },
            {
                id: 8,
                tipo: "COMUN",
                tipoAsistencia:"SEMIPESADO",
                codigo:"HORA DE TRABAJO",
                descripcion:"Asistencia de mecanica ligera en el lugar.",
                valor:4000.00,
                grupos:[2,5],
                fechaInicio: new Date((new Date()).setMonth(new Date().getMonth() -1)),
                fechaFin: new Date((new Date()).setMonth(new Date().getMonth() +2)),

            },
            {
                id: 9,
                tipo: "ESPECIAL",
                tipoAsistencia:"LIVIANO",
                codigo:"MOVIDA 10KM",
                descripcion:"Se calcula el valor cada 10 km",
                valor:4200.00,
                grupos:[3,5],
                fechaInicio: new Date((new Date()).setMonth(new Date().getMonth() -1)),
                fechaFin: new Date((new Date()).setMonth(new Date().getMonth() +2)),

            },
            {
                id: 10,
                tipo: "ESPECIAL",
                tipoAsistencia:"LIVIANO",
                codigo:"KM (TOTALES)",
                descripcion:"Se calcula el valor cada 10 km",
                valor:94.00,
                grupos:[3,5],
                fechaInicio: new Date((new Date()).setMonth(new Date().getMonth() -1)),
                fechaFin: new Date((new Date()).setMonth(new Date().getMonth() +2)),

            },
            {
                id: 11,
                tipo: "ESPECIAL",
                tipoAsistencia:"LIVIANO",
                codigo:"EXTRACCION",
                descripcion:"Extraccion del vehiculo de un garage, estacionamiento.",
                valor:4200.00,
                grupos:[3,5],
                fechaInicio: new Date((new Date()).setMonth(new Date().getMonth() -1)),
                fechaFin: new Date((new Date()).setMonth(new Date().getMonth() +2)),

            },
            {
                id: 12,
                tipo: "ESPECIAL",
                tipoAsistencia:"LIVIANO",
                codigo:"HORA DE TRABAJO",
                descripcion:"Asistencia de mecanica ligera en el lugar.",
                valor:4200.00,
                grupos:[3,5],
                fechaInicio: new Date((new Date()).setMonth(new Date().getMonth() -1)),
                fechaFin: new Date((new Date()).setMonth(new Date().getMonth() +2)),

            },
            {
                id: 13,
                tipo: "ESPECIAL",
                tipoAsistencia:"SEMIPESADO",
                codigo:"MOVIDA 10KM",
                descripcion:"Se calcula el valor cada 10 km",
                valor:4800.00,
                grupos:[4,5],
                fechaInicio: new Date((new Date()).setMonth(new Date().getMonth() -1)),
                fechaFin: new Date((new Date()).setMonth(new Date().getMonth() +2)),

            },
            {
                id: 14,
                tipo: "ESPECIAL",
                tipoAsistencia:"SEMIPESADO",
                codigo:"KM (TOTALES)",
                descripcion:"Se calcula el valor cada 10 km",
                valor:108.00,
                grupos:[4,5],
                fechaInicio: new Date((new Date()).setMonth(new Date().getMonth() -1)),
                fechaFin: new Date((new Date()).setMonth(new Date().getMonth() +2)),

            },
            {
                id: 15,
                tipo: "ESPECIAL",
                tipoAsistencia:"SEMIPESADO",
                codigo:"EXTRACCION",
                descripcion:"Extraccion del vehiculo de un garage, estacionamiento.",
                valor:4800.00,
                grupos:[4,5],
                fechaInicio: new Date((new Date()).setMonth(new Date().getMonth() -1)),
                fechaFin: new Date((new Date()).setMonth(new Date().getMonth() +2)),
            },
            {
                id: 16,
                tipo: "ESPECIAL",
                tipoAsistencia:"SEMIPESADO",
                codigo:"HORA DE TRABAJO",
                descripcion:"Asistencia de mecanica ligera en el lugar.",
                valor:4800.00,
                grupos:[4,5],
                fechaInicio: new Date((new Date()).setMonth(new Date().getMonth() -1)),
                fechaFin: new Date((new Date()).setMonth(new Date().getMonth() +2)),
            },
        ],
        "grupos": [
            {
                id: 1,
                nombre: "COMUN - Liviano",

            },
            {
                id: 2,
                nombre: "COMUN - Semi",

            },
            {
                id: 3,
                nombre: "ESPECIAL - Liviano",

            },
            {
                id: 4,
                nombre: "ESPECIAL - Semi",

            },
            {
                id: 5,
                nombre: "Tarifas 3er trimestre",

            },
        ]
    }

export const columns = [
    {
        Header: 'Nombre',
        accessor: 'nombre',
    },
    {
        Header: 'Desde',
        accessor: 'fechaInicioSTR',
    },
    {
        Header: 'Hasta',
        accessor: 'fechaFinSTR',
    },
]
export const columns_tarifa = [
    {
        // Build our expander column
        id: 'expander', // Make sure it has an ID
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
            <span {...getToggleAllRowsExpandedProps()}>
                {isAllRowsExpanded ? <GiContract width={'30px'}/> : <GiExpand width={'30px'}/>}
                    </span>
        ),
        Cell: ({ row }) => {
            // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
            // to build the toggle for expanding a row
            return (
                <span
                    {...row.getToggleRowExpandedProps({
                        style: {
                            paddingLeft: `${row.depth * 2}rem`,
                        },
                    })}
                >
                          {row.isExpanded ? <GiContract width={'30px'}/> : <GiExpand width={'30px'}/>}
                        </span>
            )
        }
    },
    {
        Header: 'Tipo',
        accessor: 'tipo',
    },
    {
        Header: 'Tipo de asistencia',
        accessor: 'tipoVehiculo',
    },
    {
        Header: 'Precio x KM',
        accessor: 'total',
    },
    {
        Header: 'Carga',
        accessor: 'conCarga',
    },
    {
        Header: 'Extracción',
        accessor: 'extraccion',
    },
    {
        Header: 'Espera x hs',
        accessor: 'horaEspera',
    },
    {
        Header: 'Mecanica x hs',
        accessor: 'horaMecanica',
    },
    {
        Header: 'Movida',
        accessor: 'movida',
    },
]
export const columns_programacion = [
    {
        Header: 'Lunes',
        accessor: 'lunes',
    },
    {
        Header: 'Martes',
        accessor: 'martes',
    },
    {
        Header: 'Miercoles',
        accessor: 'miercoles',
    },
    {
        Header: 'Jueves',
        accessor: 'jueves',
    },
    {
        Header: 'Viernes',
        accessor: 'viernes',
    },
    {
        Header: 'Sabado',
        accessor: 'sabado',
    },
    {
        Header: 'Domingo',
        accessor: 'domingo',
    },
    {
        Header: 'Feriado',
        accessor: 'Feriado',
    },
]
export const columns_prestadores = [
    {
        Header: '# Base',
        accessor: 'cantBase',
    },
    {
        Header: '# Tickets',
        accessor: 'cantTickets',
    },
    {
        Header: 'Nombre',
        accessor: 'nombre',
    },
    {
        Header: 'Usuario',
        accessor: 'username',
    },
]
