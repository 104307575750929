import styled from 'styled-components'
import { FaBars } from 'react-icons/fa'
import {
    ImCross
} from 'react-icons/im'

export const FormBoxWrapper = styled.div`

    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 20px;
    border-top:  ${(props) => props.isHeaderBox? '10px solid #E95612' : '' };
    width: 83%;
    padding: 10px;
    color: #000000;
    display: ${(props) => props.show? 'block' : 'none' };
`

export const FormBoxHeader =  styled.div`

    display: flex;
    margin: 10px;

    img{
        width: 100px;
        justify-content: space-between;
        margin-left: auto;
        display:${(props) => props.isHeaderBox? 'block' : 'none' };

    }

    h2{
        color:black;
        margin-block-start: revert;
        font-size: ${(props) => props.isHeaderBox? '32' : '24' };
    }

    @media screen and (max-width: 280px){
        img{
            width: 80px;
            height:50px
        }

     
        }
`

export const FromBoxBody = styled.div`
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    margin: 10px;
    
    input{
        justify-content: flex-end;
         display: flex;
        
         bottom:10px;
        right:10px;
    }
`

export const Danger =  styled.span`

    color: red;
    font-weight: bold;
    display:${(props) => props.isRequired? 'inline' : 'none' };
`

