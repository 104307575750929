import socketIOClient from "socket.io-client";
import {public_url} from "../services/constantes";
const socket =null// socketIOClient(public_url);
//socket.connect();
var watchId = [];
class Socket {
    static getSocket(){
        return socket;
    }
    static addWatcher(id){
        if(watchId.includes(id)===undefined)
            watchId.push(id);
        return socket;
    }
    static getWatcher(){
        return watchId;
    }
    static clearWatchers(){
        watchId.forEach(id=>{
            navigator.geolocation.clearWatch(id);
        })
        watchId=[]
    }
    static close(user){
        socket.emit("unRegister",JSON.stringify(user));
        socket.disconnect();
        watchId.forEach(id=>{
            navigator.geolocation.clearWatch(id);
        })
        watchId=[]
    }
}

export default Socket
