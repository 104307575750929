import styled from "styled-components"

//React Icons
import { RiArrowDropDownLine } from 'react-icons/ri'

export const Wrapper = styled.div`
    min-width: 350px;
    max-width: 350px;
    min-height: 38px;
    max-height: 38px;
    border: 1px solid #e95612;
    border-radius: 2px;
    padding: 6px 35px 6px 12px;   
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;    
    word-wrap: break-word;
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    
    span{
        overflow: hidden;
        line-height: 25px;
    }
`

export const Options = styled.div`
    width: 350px;
    top: 38px;
    left: -1px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgb(0 0 0 / 20%);
    margin-bottom: 0;
    max-height: 320px;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    z-index: 10;
`

export const Item = styled.div`
    width: 100%;
    background-color: #fff;
    color: #333;
    padding: 10px 20px 10px 15px;
    overflow: hidden;
    word-wrap: break-word;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: block;
    min-height: 41.42px;

    &:hover{
        background-color: #f4f4f4;
    }

    &.active{
        background-color: #f4f4f4;
        border: 1px dashed #e95612;
    }

    &.active{
        background-color: #f4f4f4;
    }

    span{
        font-size: 15px;
        line-height: 1.42857143;
    }
`

export const DropDown = styled(RiArrowDropDownLine)`
    position: absolute;
    height: 30px;
    width: 30px;
    right: 6px;
    font-size: 2rem;
    color: #666;
`