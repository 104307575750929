//React
import {useEffect, useState} from "react";
import { useLocation } from "react-router-dom";

//Styled Components
import {
    WrapperPreview
} from './elements'

//Components
import Header from "../../components/Header";
import PopUp from "../../components/PopUp";
import LogoWhatsApp from "../../components/LogoWhatsApp";
import Footer from "../../components/Footer";
import Preview from "../../components/Preview";

//Sections
import Home from "../../sections/Home";
import Comunicate from "../../sections/Comunicate";
import Seguridad from "../../sections/Seguridad";
import TelefonosUtiles from "../../sections/TelefonosUtiles";
import NuestrasAsistencias from "../../sections/NuestrasAsistencias";
import QuienesSomos from "../../sections/QuienesSomos";
import Novedades from "../../sections/Novedades"

//Contexts
import { useNovedadesContext } from '../../context/NovedadesContext'

const Landing = () =>{
    const [showPopUp, setShowPopUp] = useState(false);
    const { novedades,getNovedades } = useNovedadesContext()
    let location = useLocation();

    const changePopUp = () => {
        setShowPopUp(!showPopUp)

    }

    const closePopUp = () => {
        setShowPopUp(false)
    }
    useEffect(()=>getNovedades(),[]);

    useEffect(() => {
        if(location.hash && location.hash !== '')
        {
            const element = document.getElementById(location.hash.replace('#', ''));
            if (element) {
                // 👇 Will scroll smoothly to the top of the next section
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location])

    return(
        <>
            <Header/>
            <Home/>
            <Comunicate/>
            <Seguridad popupButtonHandler={() => changePopUp()}/>
            <TelefonosUtiles/>
            <NuestrasAsistencias/>
            <QuienesSomos/>
            {
                novedades && novedades.length > 0?
                <>
                    <Novedades/>
                    <WrapperPreview>
                        <Preview editable={false} background={'orange'}/>
                    </WrapperPreview>
                </>
                :
                    ''
            }
            <PopUp show={showPopUp} title={'¿Qué hacer si se te queda el auto en la ruta?'} closeHander={() => changePopUp()} popUpCloser={() => closePopUp()}>
                <div>
                    <div>
                        Si bien el riesgo de sufrir un percance vehicular en la ruta es mucho menor cuando se han tomado las medidas necesarias tanto en el aspecto mecánico para el vehículo como en la forma de conducir, siempre es necesario considerar qué hacer en caso de que se presente una avería:<br/><br/>
                    </div>

                    <div>
                        ¿Qué hacer ante los incidentes más comunes en ruta?:<br/>
                        <br/>
                        <h3>Pinchadura de un neumático:</h3>

                        En el caso menos grave, la presión de aire dentro del neumático disminuye gradualmente, por lo que se tiene tiempo para orillar el vehículo. Se deben tomar medidas inmediatas para evitar un accidente, ya que al disminuir la presión del neumático baja también la velocidad del vehículo, por lo cual se recomienda disminuir la velocidad muy gradualmente, encender las balizas y con toda precaución realizar la maniobra de orillarse y detenerse en un lugar seguro para cambiar el neumático, evitando acelerar o frenar de manera brusca el vehículo. Asegurate de llevar siempre tu rueda de auxilio en perfectas condiciones.

                        Un caso extremo es que el neumático se reviente, en ese caso es recomendable sujetar firmemente el volante y tratar de guiar de manera gradual el vehículo hacia un lugar seguro y al mismo tiempo detenerse lentamente evitando oprimir el pedal del freno a fondo, lo cual traería consecuencias graves. Una vez detenido el vehículo se deben encender las balizas, (respirar hondo, agradecerle a quien le que le hayas rezado durante el susto) y realizar el cambio de neumático.<br/><br/>
                    </div>
                    <div>
                        <h3>Falla física de los elementos de suspensión y dirección</h3>

                        Algunos elementos del vehículo están sometidos a grandes esfuerzos y vibraciones, los cuales pueden llegar a fatigarse, tanto que en cualquier momento podrían sufrir una rotura o desperfecto. Hay que estar alerta ya que pueden ser desde los tornillos de sujeción hasta algunos elementos vitales del vehículo.<br/><br/>
                    </div>
                    <div>
                        <h3>¿Qué hacer después de detenerse?</h3>

                        Si por alguna causa de fuerza mayor tuvieras que detenerte sobre la calzada de alguna ruta, procurá ocupar el mínimo posible de la misma dejando una distancia de visibilidad suficiente en ambos sentidos, enciendiendo las balizas y colocando de manera cuidadosa los dispositivos de advertencia reglamentarios (triángulos de emergencia)

                        Esta operación debe realizarse con toda la precaución posible, si vas acompañado es recomendable que un pasajero baje y comience a desviar el tránsito con mucho cuidado, mientras que el conductor pone las señales correspondientes en el lugar y sitio precisos; si viajás solo, encendé las balizas y bajá de la unidad con mucha precaución, observando que no se aproximen otros vehículos, de lo contrario siempre esperar dentro de la unidad hasta que tengas la oportunidad de bajar para colocar los señalamientos de advertencia, que siempre tenés que llevarlos en el baúl.<br/><br/>
                    </div>
                    <div>
                        <h3>Ubicación correcta de los dispositivos de advertencia.</h3>

                        Si la ruta es de un solo sentido, colocá un triángulo de advertencia 30 metros hacia atrás del lugar en el que se encuentra el vehículo, ubicándolo en el centro del carril que se está ocupando. Colocar otro aproximadamente a 90 mts del lado derecho del carril, esto ayudará a canalizar el tránsito.
                    </div>
                    <img loading={"lazy"} src={`${process.env.PUBLIC_URL}/assets/images/seguridad/imagen1.webp`} alt='imagen'/>
                    <div>
                        Si la ruta es doble mano, es necesario colocar además de los triángulos otro dispositivo a 30 metros hacia adelante en el centro de carril que ocupa el vehículo.
                    </div>
                    <img loading={"lazy"} src={`${process.env.PUBLIC_URL}/assets/images/seguridad/imagen2.webp`} alt='imagen'/>
                    <div>
                        Si el vehículo tiene más de dos metros de ancho, tendrás que colocar un dispositivo adicional atrás del mismo a no menos de tres metros y a una distancia tal que indique la parte del carril o carriles que se ocupa.

                        En caso de detenerse en una curva se deberán colocar los dispositivos de advertencia donde inicia la curva.
                    </div>
                    <img loading={"lazy"} src={`${process.env.PUBLIC_URL}/assets/images/seguridad/imagen3.webp`} alt='imagen'/>
                    <div>
                        De no contar con triángulos de señalización se puede utilizar una lata de aceite, un trapo unido a un palo, alguna prenda de vestir unida a una piedra o cualquier elemento que sirva para captar la atención de otros conductores. Al terminar la reparación no te olvides  de retirar los objetos y despejar la vía de todo obstáculo.
                    </div>
                </div>
            </PopUp>
            <LogoWhatsApp/>
            <Footer/>
        </>
    )
}

export default Landing
