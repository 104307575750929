//react
import { useState } from 'react'

//Styled-Components
import {
    Wrapper,
} from './elements'

//Components
import Input from '../Input'

const TelefonoInput = ({id, value, setValue, textColor  }) => {
    const [telefono, setTelefono] = useState({
        cod: "",
        numero: "",
    })

    return (
        <Wrapper>
            <Input type={'number'} idValue={"cod"+id} text={"Cod. de area"} values={value} changeValues={setValue} textColor={textColor} onlyNumbers={true}/>
            <Input type={'number'} idValue={"numero"+id} text={"Numero"} values={value} changeValues={setValue}  textColor={textColor}  onlyNumbers={true}/>
        </Wrapper>
    )
}

export default TelefonoInput
