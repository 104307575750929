import {
    PullRight,
    Modal1,
    ModalWrapper, Tittle,

} from './elements'
import React, { useState } from 'react'
import {AiFillCloseCircle} from "react-icons/ai";

const Modal = ({id, setFlag, tittle, width,children}) => {



    return (
        <ModalWrapper id="load" tabIndex="-1">
            <Modal1 id={id} className="modal-dialog" role="document" width={width} heigth={width}>
                <Tittle>
                    <div className={"tittle"}><h2>{tittle}</h2></div>
                    {
                        setFlag ?
                            <PullRight><a onClick={setFlag}><AiFillCloseCircle size={"1.6em"}/></a></PullRight>
                        :
                            ''
                    }
                </Tittle>
                {children}
            </Modal1>
        </ModalWrapper>
    )
}

export default Modal
