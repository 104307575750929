import {
    StyledButton
} from './elements'

const Button = ({ text, clickHandler, isOrangeSection, clase }) => {
  return (
    <StyledButton className={clase} type='button' onClick={clickHandler} background={isOrangeSection}>{text}</StyledButton>
  )
}

export default Button
