import {
    Overlay,
    PopupWrapper,
    Popupheader,
    PopupClose,
    PopupContent,
    PopupTitle
} from './elements'
import {FaTimes} from 'react-icons/fa'

const closeHandler = (props) => {
    props.show = false;
  };

const PopUp = ({ show,title,children,closeHander, popUpCloser }) => {
    return (
    <Overlay show={show} onClick={popUpCloser}>
      <PopupWrapper>
        <PopupClose onClick={closeHander}>
          <FaTimes ></FaTimes>
        </PopupClose>
        <Popupheader>
          <PopupTitle>{title}</PopupTitle>
        </Popupheader>
          {children}
      </PopupWrapper>
    </Overlay>
    )
}

export default PopUp
