import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
    color: white;
    text-align: start;
    padding-bottom: 250px;

    @media screen and (max-width: 1020px){
        padding-bottom: 0;
    }

    img{
        width: 160px;
        height: 28px;
    }

    h1{
        width: 100%;
        font-size: 2rem;
    }

    p{
        width: 100%;
        font-size: 1rem;
        line-height: 2rem;
    }
`