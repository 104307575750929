import styled from 'styled-components'

import {
    FiArrowLeftCircle
} from 'react-icons/fi'

import {
    AiOutlineCloseCircle
} from 'react-icons/ai'

export const WrapperCMS = styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-top: 130px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #E95612;
    font-size: 18px;
    transition: all 500ms ease;

    @media screen and (max-width: 1260px){
        margin-top: 84px;
    }

    h1{
        font-size: 2rem;
        margin-top: 25px;
        color: #E95612;
    }

    .loader{
        margin-top: 150px;
    }
`

export const WrapperNovedad = styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-top: 130px;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #E95612;
    font-size: 18px;
    transition: all 500ms ease;

    @media screen and (max-width: 1260px){
        margin-top: 84px;
    }

    h1{
        font-size: 2rem;
        margin-top: 25px;
        color: #E95612;
    }

    .atras{
        position: absolute;
        top: 25px;
        left: 25px;
    }

    .loader{
        margin-top: 150px;
    }
`

export const FormWrapper = styled.div`
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`
export const Textarea = styled.div`
    width: 100%;
    margin: 15px 0;
    
    textarea{
        border: 2px #E95612 solid;
        border-radius: 10px;
        resize: none;
        outline: none;
        padding: 10px;
        width: 100%;
        height: 150px;
    }
`

export const Select = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    gap: 5px;
    
    select{
        border: 2px #E95612 solid;
        border-radius: 5px;
        color: #E95612;
        width: 150px;
        text-align: center;
        outline: none;
        padding: 5px;
    }
`


export const NovedadWrapper = styled.div`
    width: fit-content;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const FileWrapper = styled.div`
    width: 100%;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const Volver = styled(FiArrowLeftCircle)`
    font-size: 3rem;
    color: #E95612;
    cursor: pointer;

    :hover{
        filter: drop-shadow(1px 1px 1px rgba(0,0,0, 0.6));
    }
`

export const Close = styled(AiOutlineCloseCircle)`
    font-size: 3rem;
    color: black;
    cursor: pointer;
    position: fixed;
    z-index: 22;

    :hover{
        filter: drop-shadow(1px 1px 1px rgba(0,0,0, 0.6));
    }
`

export const FixedWrapper = styled.div`
    position: absolute;
    top: 25px;
    right: 75px;
`


export const Overlay = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 6;
    display: flex;
    justify-content: center;
    align-items: flex-start;
`

export const WrapperPreview = styled.div`
    width: 90%;
    height: 80%;
    background-color: #FFF;
    margin-top: 90px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    position: relative;

    h1{
        font-size: 24px;
        color: #E95612;
        margin: 15px 0 30px 0;
    }
`