export const rutas = [
    {
        nombre: 'Home',
        ruta: '/portal/prestador',
        rol: 'GRUERO'
    },
    {
        nombre: 'Home',
        ruta: '/portal/admin',
        rol: 'ADMINISTRADOR'
    },
    {
        nombre: 'CMS',
        ruta: '/portal/admin/cms',
        rol: 'ADMINISTRADOR'
    },
    {
        nombre: 'Tarifa',
        ruta: '/portal/admin/tarifa',
        rol: 'ADMINISTRADOR'
    },
    {
        nombre: 'Exportar',
        ruta: '/portal/admin/exportar',
        rol: 'ADMINISTRADOR'
    },
    {
        nombre: 'Mapa',
        ruta: '/portal/seguimiento',
        rol: 'DEVELOPER'
    },
]
