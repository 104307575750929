import styled from "styled-components"

export const PageWrapper = styled.section`
    width: 100%;
    min-height: 100vh;
    background-color: #e95612;  
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 110px;
`

export const Fondo = styled.img`
    position: absolute;
    z-index: 1;
    opacity: 0.5;

    &.inferior{
      bottom: 0px;
      left: 0px;
    }

    &.superior{
      top: 0px;
      right: 0px;
    }
`

export const Container = styled.div`
  width: 90vw;
  padding: 40px 30px 30px 30px;
  z-index: 3;
  background: white;
  border-radius: 10px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 25px;
  
  & hr{
      height: 1px;
      margin-top: 5px;
  }
  
  & h3{
      text-align: center;
      width: 100%;
  }

  & .atras{  
    width: fit-content;
    position: absolute;
    top: 0px;
    right: 20px;
  }

  & .guardar{  
    width: fit-content;
    align-self: center;
  }
`
export const Row = styled.div`
    display: flex;
    padding: 25px; 
    justify-content: center;
    flex-wrap: wrap;
    & .react-datepicker__day--selected .react-datepicker__day--keyboard-selected,.react-datepicker__current-month,.react-datepicker__day-name,.react-datepicker__header,.react-datepicker__day--in-range{
      background-color: #e95612;
      color: white;
    }
    & .react-datepicker__day--in-selecting-range{
      background-color: #e9561270; 
    }
    & label{
      font-weight: bold;
      color: #e95612;
    }
`
export const Column = styled.div`
  width: 100%;
  max-width: 390px;
  display: flex;
  padding: 25px;
  justify-content: center;
  flex-direction: column;
  border: 1px dashed rgba(201, 201, 201 );

  & label {
    font-weight: bold;
    color: #e95612;
  } 

  & h2{
    font-weight: bold;
    color: #e95612;
    align-self: center;
  }

  & [name='selected']{
    box-shadow: 1px 1px 1px 1px cyan;
  }
`
export const Tittle = styled.div`
    display: flex;
    padding-top: 25px;
    justify-content: center; 
  & label{
    font-weight: bold;
    color: #e95612;
  }
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, max-content));
  grid-template-rows: repeat(auto-fit, 600px);
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;

  & .grid-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 25px;
    min-width: 400px;
    min-height: 550px;

    & label{
      font-weight: bold;
      align-self: flex-start;
      color: #e95612;
    }

    & button{
      width: fit-content;
      align-self: flex-start;
      margin-bottom: 0;
      margin-left: 10px;
      border-radius: 50%;
      padding: 5px 12px;

      &.add{
        align-self: center;
        justify-self: center;
      }
    }
  }
`

export const LoadingWrapper = styled.div`
  width: 200px;
  height: 200px;
  background-color: #fafafa;
  padding: 5px;
  display: grid;
  border-radius: 8px;
  box-shadow: 10px 5px 5px #0000007d;
  z-index: 1;
`
