//React Imports
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

//Styled Components
import {
    LoginPage,
    LoginWrapper,HuellaWrapper,
    LogoWrapper,
    FormWrapper,
    Input,
    IconUser,
    IconPassword,
    SubmitWrapper,
    DownloadApp,
} from './elements'

//Components
import Logo from "../../components/Logo"
import Button from '../../components/Button'

//Context
import { useUserContext } from '../../context/UserContext'
import Loading from '../../components/Loading'
import {FiDownload} from "react-icons/fi";


const Login = () => {
    const [loading, setLoading] = useState(false)
    const { user, logged, triedLog,loggedMessage, setTriedLog, handleUser, logIn } = useUserContext()
    const navigate = useNavigate()

    const [isReadyForInstall, setIsReadyForInstall] = useState(false);
    async function downloadApp() {
        console.log("👍", "butInstall-clicked");
        const promptEvent = window.deferredPrompt;
        if (!promptEvent) {
            // The deferred prompt isn't available.
            console.log("oops, no prompt event guardado en window");
            return;
        }
        // Show the install prompt.
        promptEvent.prompt();
        // Log the result
        const result = await promptEvent.userChoice;
        console.log("👍", "userChoice", result);
        // Reset the deferred prompt variable, since
        // prompt() can only be called once.
        window.deferredPrompt = null;
        // Hide the install button.
        setIsReadyForInstall(false);
    }
    useEffect(() => {
        window.addEventListener("beforeinstallprompt", (event) => {
            // Prevent the mini-infobar from appearing on mobile.
            event.preventDefault();
            console.log("👍", "beforeinstallprompt", event);
            // Stash the event so it can be triggered later.
            window.deferredPrompt = event;
            // Remove the 'hidden' class from the install button container.
            setIsReadyForInstall(true);
        });
    }, []);

    const handleLogIn = e => {
        setTriedLog(false)
        setLoading(!loading)
        logIn(e)
        .finally(() => {
            setLoading(false)
            if(user.roles.includes('ADMINISTRADOR'))
                navigate('/portal/admin/seguimiento')
            else
                navigate('/portal/prestador')
        })
    }

    useEffect(() => {
        if(logged)
            if(user.roles.includes('ADMINISTRADOR'))
                navigate('/portal/admin')
            else
                navigate('/portal/prestador')
    }, [logged])
/*

            <DownloadApp show={isReadyForInstall}>
                <button onClick={downloadApp}>
                    <FiDownload size={"25px"}/>
                </button>
            </DownloadApp>
 */
    return (
        <LoginPage>
            <HuellaWrapper>
                <LoginWrapper>
                    <LogoWrapper>
                        <Logo backgroundColor={'transparent'} width={'350px'} height={'150px'}/>
                    </LogoWrapper>
                    <FormWrapper onChange={handleUser}>
                        <div>
                            <Input>
                                <input type={'text'} name={'username'} placeholder={'Usuario'}/>
                                <IconUser/>
                            </Input>
                            <Input>
                                <input type={'password'} name={'password'} placeholder={'Contraseña'}/>
                                <IconPassword/>
                            </Input>
                        </div>
                        {
                            !logged && triedLog?
                                <span>{loggedMessage}</span>
                                :
                                ''
                        }
                        <SubmitWrapper className={loading ? 'center' : 'end'}>
                            {
                                loading?
                                    <Loading/>
                                    :
                                    <Button align={'flex-end'} text={'Ingresar'} clickHandler={handleLogIn}/>

                            }
                        </SubmitWrapper>
                    </FormWrapper>
                </LoginWrapper>
            </HuellaWrapper>
        </LoginPage>
    )
}

export default Login
