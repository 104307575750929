//React
import {
    useState,
    useEffect
} from 'react'
import { Route, Routes, useParams, useNavigate } from "react-router-dom"

//Styled components
import {
    WrapperCMS,
    WrapperNovedad,
    WrapperPreview,
    Overlay,
    FormWrapper,
    Select,
    NovedadWrapper,
    FileWrapper,
    Textarea,
    Volver,
    Close,
    FixedWrapper
} from './elements'

//Components
import Section from '../../components/Section'
import Input from '../../components/Input'
import Novedad from '../../components/Novedad'
import FileUpload from '../../components/FileUpload'
import Button from '../../components/Button'
import Preview from '../../components/Preview'
import Loading from '../../components/Loading'

//Context
import { useNovedadesContext } from '../../context/NovedadesContext'


const calculateModel = (model) => {
    if(model === 1)
        return 'small'
    if(model === 2)
        return 'medium-vertical'
    if(model === 3)
        return 'medium-horizontal'
    if(model === 4)
        return 'large'
}


const CMSNovedades = () => {
    const { novedadesLoading, novedadesDeleting } = useNovedadesContext()
    const navigate = useNavigate()

    return(
        <Section
            minHeight={'fit-content'}
        >
            <Routes>
                <Route
                    exact path="/"
                    element={
                        <WrapperCMS>
                            <>
                                <h1>Grilla de Novedades</h1>
                                <Preview/>
                                {
                                    novedadesDeleting || novedadesLoading?
                                        ''   
                                    :
                                        <Button text={'Nueva Novedad'} clickHandler={() => navigate('novedad')}/>
                                }
                            </>
                        </WrapperCMS>
                    }
                />
                <Route path="/novedad">
                    <Route index
                        element={
                            <WrapperNovedad>
                                <AltaNovedad/>
                            </WrapperNovedad>
                        }
                    />
                    <Route path=":id"
                        element={
                            <WrapperNovedad>
                                <AltaNovedad/>
                            </WrapperNovedad>
                        }
                    />
                </Route>
            </Routes>
        </Section>
    )
}

const AltaNovedad = () => {
    const { nuevaNovedad, setNuevaNovedad, changeHandler, createNovedad, editNovedad, novedadesCreating, limpiarNovedad } = useNovedadesContext()
    const [file, setFile] = useState({})
    const [preview, setPreview] = useState(false)
    const { id } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if(file.file)
        {
            changeHandler(file.file, 'imagen')
        }
    }, [file])

    return (
        <>
            {
                preview?
                    <Overlay>
                        <WrapperPreview>
                            <FixedWrapper>
                                <Close onClick={() => setPreview(false)}/>
                            </FixedWrapper>
                            <h1>Previsualización</h1>
                            <Preview newModel={ id ? false : true } close={setPreview} calculateModel={calculateModel} editable={false} />
                        </WrapperPreview>
                    </Overlay>
                :
                    ''
            }
            <Volver className={'atras'} 
                onClick={() => {
                    limpiarNovedad()
                    navigate('./../')
                }
            }/>
            <h1>Nueva Novedad</h1>
            {
                novedadesCreating?
                    <Loading text={"Creando su novedad..."}/>
                :
                    <>
                        <FormWrapper>
                            <Input idValue={"titulo"} values={nuevaNovedad} changeValues={setNuevaNovedad} placeholder={"Titulo"}/>
                            <Input idValue={"subtitulo"} values={nuevaNovedad} changeValues={setNuevaNovedad} placeholder={"Subtitulo"}/>
                            <Textarea>
                                Descripción
                                <textarea value={nuevaNovedad && nuevaNovedad.descripcion ? nuevaNovedad.descripcion : ''} onChange={e => changeHandler(e.target.value, 'descripcion')}></textarea>
                            </Textarea>
                            <Select>
                                Modelo
                                <select onChange={(e) => changeHandler(parseInt(e.target.value), 'tipo_modelo')} defaultValue={nuevaNovedad && nuevaNovedad.tipo_modelo ? nuevaNovedad.tipo_modelo : ''}>
                                    <option value={1}>Modelo 1</option>
                                    <option value={2}>Modelo 2</option>
                                    <option value={3}>Modelo 3</option>
                                    <option value={4}>Modelo 4</option>
                                </select>
                            </Select>
                            <Input type='radio' idValue={"video"} values={nuevaNovedad} changeValues={setNuevaNovedad} text={"Video"} options={['Si', 'No']}/>
                            {
                                nuevaNovedad && nuevaNovedad.video === 'Si'?
                                    <Input idValue={"link_video"} values={nuevaNovedad} changeValues={setNuevaNovedad} placeholder={"Link"}/>
                                :
                                    ''
                            }
                            <FileWrapper>
                                <FileUpload files={file} setFiles={setFile} label={'Imagen'} accept="image/png, image/gif, image/jpeg"/>
                            </FileWrapper>
                            <NovedadWrapper>
                                <Novedad novedad={nuevaNovedad} preview={true}/>
                            </NovedadWrapper>
                        </FormWrapper>
                        <Button text={'Previsualizar'} clickHandler={() => setPreview(true)}></Button>
                        <Button text={'Guardar'} clickHandler={ () => {
                            id ? editNovedad() : createNovedad()
                            navigate('./../')
                        } }></Button>
                    </>
            }
        </>
    )
}

export default CMSNovedades