import styled from "styled-components"
import {Link} from "react-router-dom";

export const TarifaHomePage = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #e95612;  
  display: grid;
  padding-top:40px;
  place-items: center;
  overflow: auto;
`

export const EmptyWrapper = styled.div`
  margin-left: 5%;
  width: 90%;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin-top: 50px;
  padding: 15px;
  border: 3px dashed #a1a1a1;
`

export const Imagen = styled.img`
    position: absolute;
    z-index: 1;
    opacity: 0.5;

  &.inferior{
    bottom: 0px;
    left: 0px;
  }

  &.superior{
    top: 0px;
    right: 0px;
  }
`
export const TarifaHomeWrapper = styled.div` 
  width: 100%;
  height: 100%;
  padding: 100px 30px 30px 30px;
  z-index: 3;

  & hr{
    height: 1px;
    margin-top: 5px;
  }
  & h3{
    text-align: center;
    width: 100%;
  }
`


export const Volver = styled(Link)`
  display: flex;
  position: absolute;
  left: 30px;
  top: 90px;
  z-index: 4;
  background: white;
  border-radius: 15px;
  padding: 5px;
  color: #e95612;
  font-size: 30px;
  box-shadow: 10px 5px 5px #0000007d; 
`
export const PullRight = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end; 
  padding-right: 25px;
`
export const LoadingWrapper = styled.div`
  width: 200px;
  height: 200px;
  background-color: white;
  padding: 5px;
  display: grid;
  border-radius: 8px;
  box-shadow: 10px 5px 5px #0000007d;z-index: 1;
`
export const RowTwoColumns = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  gap: 30px;
  padding: 0 40px;

  & #cancelar {
    background-color: #ff0000;

  }

  & #aceptar {
    background-color: #32be3f;
  }

  & select {
    width: 95%;
    padding: 15px 0;
    border: none;
    border-bottom: 2px solid #e95612;
    color: #e95612;
    font-size: 18px;
    outline: none;

    & option {
      color: black;
    }
  }
`
export const TarifaListWrapper = styled.div` 
  width: 100%;
  background-color: white;
  padding: 5px;
  display: flex;
  flex-direction: column;
  grid-auto-flow: dense;
  border-radius: 8px;
  box-shadow: 10px 5px 5px #0000007d;
  z-index: 1;

  & .date{
    padding: 0 40px;
    width: 350px;
    align-self: center;
    margin: 20px 0;
    color: #e95612;
    input{
      border: solid 2px #e95612;
    }
  }

  & .submit-button{
    align-self: flex-start;
    margin: 15px 10px;
  }

  & #tittle{
    position: relative;
    top: 0;
    & button{  
      position: absolute;
      margin-bottom: 0;
      margin-left: 10px;
      border-radius: 50%;
      padding: 5px 12px;
    }
  }

  & #lista{ 
    & th{
      background-color: #e95612;
      color: #fff;
    }

    & tr{
      &:hover{
        background-color: rgba(233, 86, 18, 0.7);
        color: #fff;
      }
    }
    & th:first-child, td:first-child { 
      width: 30px;
      min-width: 30px;
      span, svg{
        width: 25px;
        height: 25px;
      }
    }
    & th:last-child, td:last-child {
      width: 30px;
      min-width: 30px;
    }
    .expandible{
      :hover{
        background-color: white;
        color: black;
      }
      padding: 0;
      & .tdExp{
        border: 1px solid black;
        padding: 0px;
        & table{
            padding: 0px;
          th{
            background-color: #a6a6a6;
          }
          tr:hover{
            background-color: white; 
            color: black;
          }
        }
      }
      
    }
    & input{
      border: 1px solid black;
    }
  }
`
export const ModalWrapper = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2050;
`
export const Row = styled.div` 
  display: flex;  
  padding: 5px;
  font-size: 1rem;
`
export const Column = styled.div` 
  display: flex; 
  flex-direction: column; 
  
`
export const Wrapper = styled.div` 
  width: 100%; 
  padding: 5px;
  display: flex;
  flex-direction: column;
  grid-auto-flow: dense; 
  z-index: 1;

  & #tittle{
    position: relative;
    top: 0;
  }
  & #lista{ 

    & th{
      background-color: #e95612;
      color: #fff;
    }

    & tr{
      &:hover{
        background-color: rgba(233, 86, 18, 0.7);
        color: #fff;
      }
    }
    
    & input{
      border: 1px solid black;
    }
  }
`
