//REACT
import { createContext, useContext, useState } from 'react'

//Service
import AdminService from '../services/AdminService'

//Context
import { useUserContext } from './UserContext'

const TarifaContext = createContext([])

export const useTarifaContext = () => useContext(TarifaContext)

export const TarifaContextProvider = ({ children }) => {
    const [tablaTarifa, setTablaTarifa] = useState([
        {
            tipo: 'COMUN',
            tipoVehiculo: 'LIVIANO',
            movida: 0,
            total: 0,
            extraccion: 0,
            horaMecanica: 0,
            asistenciaMecanica: 0,
            barra2km: 0,
            kmripio: 0
        },
        {
            tipo: 'COMUN',
            tipoVehiculo: 'SEMIPESADO',
            movida: 0,
            total: 0,
            extraccion: 0,
            horaMecanica: 0,
            asistenciaMecanica: 0,
            barra2km: 0,
            kmripio: 0
        },
        {
            tipo: 'COMUN',
            tipoVehiculo: 'PESADO',
            movida: 0,
            total: 0,
            extraccion: 0,
            horaMecanica: 0,
            asistenciaMecanica: 0,
            barra2km: 0,
            kmripio: 0
        },
        {
            tipo: 'ESPECIAL',
            tipoVehiculo: 'LIVIANO',
            movida: 0,
            total: 0,
            extraccion: 0,
            horaMecanica: 0,
            asistenciaMecanica: 0,
            barra2km: 0,
            kmripio: 0
        },
        {
            tipo: 'ESPECIAL',
            tipoVehiculo: 'SEMIPESADO',
            movida: 0,
            total: 0,
            extraccion: 0,
            horaMecanica: 0,
            asistenciaMecanica: 0,
            barra2km: 0,
            kmripio: 0
        },
        {
            tipo: 'ESPECIAL',
            tipoVehiculo: 'PESADO',
            movida: 0,
            total: 0,
            extraccion: 0,
            horaMecanica: 0,
            asistenciaMecanica: 0,
            barra2km: 0,
            kmripio: 0
        }
    ])
    const [grupo, setGrupo] = useState({
        horarios: {
            Lunes:{
                desde: '12:30',
                hasta: '12:30',
            },
            Martes:{
                desde: '12:30',
                hasta: '12:30',
            },
            Miercoles:{
                desde: '12:30',
                hasta: '12:30',
            },
            Jueves:{
                desde: '12:30',
                hasta: '12:30',
            },
            Viernes:{
                desde: '12:30',
                hasta: '12:30',
            },
            Sabado:{
                desde: '12:30',
                hasta: '12:30',
            },
            Domingo:{
                desde: '12:30',
                hasta: '12:30',
            },
            Feriados:{
                desde: '12:30',
                hasta: '12:30',
            },
        }
    })
    const [programacionAux, setProgramacionAux] = useState(null)
    const [tarifaAux, setTarifaAux] = useState(null)
    const [horariosExtras, setHorariosExtras] = useState([])
    const { user } = useUserContext()
    const auxDias = ['lunes',
        'martes',
        'miercoles',
        'jueves',
        'viernes',
        'sabado',
        'domingo',
        'feriados'
    ]

    const limpiarTarifa = () => {
        setProgramacionAux(null)
        setTarifaAux(null)
        setHorariosExtras([])
        setGrupo({
            horarios: {
                Lunes:{
                    desde: '12:30',
                    hasta: '12:30',
                },
                Martes:{
                    desde: '12:30',
                    hasta: '12:30',
                },
                Miercoles:{
                    desde: '12:30',
                    hasta: '12:30',
                },
                Jueves:{
                    desde: '12:30',
                    hasta: '12:30',
                },
                Viernes:{
                    desde: '12:30',
                    hasta: '12:30',
                },
                Sabado:{
                    desde: '12:30',
                    hasta: '12:30',
                },
                Domingo:{
                    desde: '12:30',
                    hasta: '12:30',
                },
                Feriados:{
                    desde: '12:30',
                    hasta: '12:30',
                },
            }
        })
        setTablaTarifa([
            {
                tipo: 'COMUN',
                tipoVehiculo: 'LIVIANO',
                movida: 0,
                total: 0,
                extraccion: 0,
                horaMecanica: 0,
                asistenciaMecanica: 0,
                barra2km: 0,
                kmripio: 0
            },
            {
                tipo: 'COMUN',
                tipoVehiculo: 'SEMIPESADO',
                movida: 0,
                total: 0,
                extraccion: 0,
                horaMecanica: 0,
                asistenciaMecanica: 0,
                barra2km: 0,
                kmripio: 0
            },
            {
                tipo: 'COMUN',
                tipoVehiculo: 'PESADO',
                movida: 0,
                total: 0,
                extraccion: 0,
                horaMecanica: 0,
                asistenciaMecanica: 0,
                barra2km: 0,
                kmripio: 0
            },
            {
                tipo: 'ESPECIAL',
                tipoVehiculo: 'LIVIANO',
                movida: 0,
                total: 0,
                extraccion: 0,
                horaMecanica: 0,
                asistenciaMecanica: 0,
                barra2km: 0,
                kmripio: 0
            },
            {
                tipo: 'ESPECIAL',
                tipoVehiculo: 'SEMIPESADO',
                movida: 0,
                total: 0,
                extraccion: 0,
                horaMecanica: 0,
                asistenciaMecanica: 0,
                barra2km: 0,
                kmripio: 0
            },
            {
                tipo: 'ESPECIAL',
                tipoVehiculo: 'PESADO',
                movida: 0,
                total: 0,
                extraccion: 0,
                horaMecanica: 0,
                asistenciaMecanica: 0,
                barra2km: 0,
                kmripio: 0
            }
        ])
    }

    const prepararObjeto = (conIds = false) => {
        let programacion = [{tipo: 'COMUN'}, {tipo: 'ESPECIAL'}]
        let tablaTarifaAux = [...tablaTarifa]
        let horarios = {}

        //JUNTO TODOS LOS HORARIOS POR DIA, Y LOS QUE SON DIA COMPLETO DE COMUN Y ESPECIAL YA LOS PUSHEO
        Object.keys(grupo.horarios).forEach((element) => {
            let elementAux = element.toLowerCase()
            let auxDesde = parseInt(grupo.horarios[element].desde.replace(':', ''))
            let auxHasta = parseInt(grupo.horarios[element].hasta.replace(':', ''))


            if(auxDesde === 0 && auxHasta === 0)
            {
                programacion[0][elementAux] = ['0000 - 2400'];
                programacion[1][elementAux] = null
            }
            else if(auxDesde === auxHasta)
            {
                programacion[1][elementAux] = ['0000 - 2400'];
                programacion[0][elementAux] = null

            }
            else
            {

                horarios[elementAux] = [
                    {
                        desde: auxDesde,
                        hasta: auxHasta,
                        desdeStr: grupo.horarios[element].desde.replace(':', ''),
                        hastaStr: grupo.horarios[element].hasta.replace(':', '')
                    }
                ]

                horariosExtras.forEach((value) => {
                    if(value.dia === element)
                    {
                        let auxDesdeExtra = parseInt(value.desde.replace(':', ''))
                        let auxHastaExtra = parseInt(value.hasta.replace(':', ''))
                        horarios[elementAux].push({
                            desde: auxDesdeExtra,
                            hasta: auxHastaExtra,
                            desdeStr: value.desde.replace(':', ''),
                            hastaStr: value.hasta.replace(':', '')
                        })
                    }
                })
            }
        })

        //VEO QUE NO SE INTERPONGAN LOS HORARIOS Y LOS ORDENO
        Object.keys(horarios).forEach((dias) => {
            if(horarios[dias].length > 1)
            {
                for(let i = 0; i < horarios[dias].length; i++)
                {
                    for(let j = 0; j < horarios[dias].length; j++)
                    {
                        if(i !== j)
                            if((horarios[dias][i].desde < horarios[dias][j].desde && horarios[dias][i].hasta > horarios[dias][j].desde) || (horarios[dias][i].desde < horarios[dias][j].hasta && horarios[dias][i].hasta > horarios[dias][j].hasta))
                            {
                                //Set ERROR y RETURN
                                return;
                            }
                            if(horarios[dias][i].desde < horarios[dias][j].desde)
                            {
                                let aux = {...horarios[dias][i]}
                                horarios[dias][i] = horarios[dias][j]
                                horarios[dias][j] = aux
                            }
                    }
                }


            }
        })

        //CREO LOS COMPLEMENTARIOS DE LOS DIAS NO COMPLETOS
        Object.keys(horarios).forEach((dias) => {
            programacion[0][dias] = []
            programacion[1][dias] = []

            for(let i = 0; i < horarios[dias].length; i++)
            {
                //CALCULO EL COMPPLEMENTO DE SI NO EMPIEZA EN 0000
                if(i === 0)
                {
                    if(horarios[dias][i].desde !== 0)
                        programacion[1][dias].push(`0000 - ${horarios[dias][i].desdeStr}`)
                }
                if(i + 1 < horarios[dias].length)
                {
                    //CALCULO EL COMPPLEMENTO ENTRE "I" e "I + 1"
                    if(horarios[dias][i].hasta !== horarios[dias][i + 1].desde)
                    {
                        programacion[1][dias].push(`${horarios[dias][i].hastaStr} - ${horarios[dias][i + 1].desdeStr}`)
                    }
                }
                //SI LA ULTIMA NO TERMINA A LAS 12 METO EL ULTIMO COMPLEMENTO
                else if(horarios[dias][i].hasta !== 0)
                {
                    programacion[1][dias].push(`${horarios[dias][i].hastaStr} - ${2400}`)
                }
                //POR ULTIMO METO "I" EN LA PROGRAMACION COMUN
                programacion[0][dias].push(`${horarios[dias][i].desdeStr} - ${horarios[dias][i].hastaStr}`)
            }
        })


        auxDias.forEach((value) => {
            if(programacion[0][value])
                programacion[0][value] = programacion[0][value].join(' | ')
            if(programacion[1][value])
                programacion[1][value] = programacion[1][value].join(' | ')
        })



        let objToSave = {
            nombre: grupo.nombre,
            descripcion: grupo.descripcion,
            fechaInicio: grupo.fechaInicio,
            fechaFin: grupo.fechaFin
        }

        //AGREGO TODOS LOS IDS
        if(conIds)
        {
            objToSave.id = grupo.id

            programacion[0].id = programacionAux.find((value) => value.tipo === 'COMUN').id
            programacion[0].grupoID = grupo.id
            programacion[1].id = programacionAux.find((value) => value.tipo === 'ESPECIAL').id
            programacion[1].grupoID = grupo.id


            for(let i = 0; i < 6; i++)
            {
                tablaTarifaAux[i].grupoID = grupo.id
            }

            tablaTarifaAux[tablaTarifa.findIndex((value) => value.tipo === 'COMUN' && value.tipoVehiculo === 'LIVIANO')].id = tarifaAux.find((value) => value.tipo === 'COMUN' && value.tipoVehiculo === 'LIVIANO').id
            tablaTarifaAux[tablaTarifa.findIndex((value) => value.tipo === 'COMUN' && value.tipoVehiculo === 'SEMIPESADO')].id = tarifaAux.find((value) => value.tipo === 'COMUN' && value.tipoVehiculo === 'SEMIPESADO').id
            tablaTarifaAux[tablaTarifa.findIndex((value) => value.tipo === 'COMUN' && value.tipoVehiculo === 'PESADO')].id = tarifaAux.find((value) => value.tipo === 'COMUN' && value.tipoVehiculo === 'PESADO').id
            tablaTarifaAux[tablaTarifa.findIndex((value) => value.tipo === 'ESPECIAL' && value.tipoVehiculo === 'LIVIANO')].id = tarifaAux.find((value) => value.tipo === 'ESPECIAL' && value.tipoVehiculo === 'LIVIANO').id
            tablaTarifaAux[tablaTarifa.findIndex((value) => value.tipo === 'ESPECIAL' && value.tipoVehiculo === 'SEMIPESADO')].id = tarifaAux.find((value) => value.tipo === 'ESPECIAL' && value.tipoVehiculo === 'SEMIPESADO').id
            tablaTarifaAux[tablaTarifa.findIndex((value) => value.tipo === 'ESPECIAL' && value.tipoVehiculo === 'PESADO')].id = tarifaAux.find((value) => value.tipo === 'ESPECIAL' && value.tipoVehiculo === 'PESADO').id
        }
        else
        {
            for(let i = 0; i < 6; i++)
            {
                delete tablaTarifaAux[i].grupoID
                delete tablaTarifaAux[i].id
            }
        }

        objToSave.tarifa = tablaTarifaAux
        objToSave.programacion = programacion

        return objToSave
    }


    const saveTarifa = () => {
        let objToSave = prepararObjeto()

        AdminService.saveTarifa(user.token, objToSave, () => {

        });
    }

    const cargarTarifa = (id, callBack) => {
        AdminService.getTarifa(user.token, id, (result) => {
            setTablaTarifa(result.tarifa)

            let newGrupo = {
                id: result.id,
                nombre: result.nombre,
                descripcion: result.descripcion,
                fechaInicio: Date.parse(result.fechaInicio),
                fechaFin: Date.parse(result.fechaFin),
                horarios: {}
            }
            let auxHorariosExtras = []

            let index = result.programacion.findIndex((value) => value.tipo === 'COMUN')
            auxDias.forEach((dia) => {
                if(result.programacion[index][dia] === null)
                {
                    newGrupo.horarios[dia.charAt(0).toUpperCase() + dia.slice(1)] = {
                        desde: '12:30',
                        hasta: '12:30',
                    }
                }
                else
                {
                    let arregloHorarios = result.programacion[index][dia].split(' | ')
                    newGrupo.horarios[dia.charAt(0).toUpperCase() + dia.slice(1)] = {
                        desde: `${arregloHorarios[0].split(' - ')[0].slice(0, 2)}:${arregloHorarios[0].split(' - ')[0].slice(2, 4)}`,
                        hasta: `${arregloHorarios[0].split(' - ')[1].slice(0, 2)}:${arregloHorarios[0].split(' - ')[1].slice(2, 4)}`,
                    }
                    for(let i = 1; i < arregloHorarios.length; i++)
                    {
                        auxHorariosExtras.push({
                            id: horariosExtras.length,
                            desde: `${arregloHorarios[i].split(' - ')[0].slice(0, 2)}:${arregloHorarios[i].split(' - ')[0].slice(2, 4)}`,
                            hasta: `${arregloHorarios[i].split(' - ')[1].slice(0, 2)}:${arregloHorarios[i].split(' - ')[1].slice(2, 4)}`,
                            dia: dia
                        })
                    }
                }
            })

            setHorariosExtras(auxHorariosExtras)
            setGrupo(newGrupo)
            setProgramacionAux(result.programacion)
            setTarifaAux(result.tarifa)
            callBack()
        })
    }

    const editTarifa = () => {
        let objToSave = prepararObjeto(true)

        AdminService.editTarifa(user.token, objToSave, () => {

        });
    }


    return(
        <TarifaContext.Provider value={
            {
                    tablaTarifa,
                    grupo,
                    horariosExtras,
                    setTablaTarifa,
                    setGrupo,
                    setHorariosExtras,
                    saveTarifa,
                    cargarTarifa,
                    editTarifa,
                    limpiarTarifa
            }
        }>
            {children}
        </TarifaContext.Provider>
    )

}
