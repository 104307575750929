//React
import { useNavigate } from 'react-router-dom'

//Styled Components
import {
    Wrapper,
    OptionsButtons,
    Edit,
    Delete
} from './elements'

//Components
import Button from '../Button'

//Context
import { useNovedadesContext } from '../../context/NovedadesContext'
import {public_url} from "../../services/constantes";

const calculateModel = (model) => {
  if(model === 1)
      return 'small'
  if(model === 2)
      return 'medium-vertical'
  if(model === 3)
      return 'medium-horizontal'
  if(model === 4)
      return 'large'
}

const Novedad = ({ novedad, editable = false, preview = false }) => {
  const { deleteNovedad, setNuevaNovedadById } = useNovedadesContext()
  const navigate = useNavigate()

  return (
    <Wrapper className={`novedad ${calculateModel(novedad.tipo_modelo)} ${preview ? 'preview' : 'noPreview'}`}>
      {
        editable?
          <OptionsButtons>
            <Edit onClick={() => {
              setNuevaNovedadById(novedad.id)
              navigate(`/portal/admin/cms/novedad/${novedad.id}`)}
            }/>
            <Delete onClick={() => deleteNovedad(novedad.id)}/>
          </OptionsButtons>
        :
          ''
      }
      {
        novedad.imagen?
          <>
            <img loading={"lazy"} src={ typeof novedad.imagen !== 'object' ? `${public_url}${novedad.imagen}` : URL.createObjectURL(novedad.imagen)} alt='imagen'/>
          </>
        :
          <div className='imagenText'>
            <p>No imagen</p>
          </div>
      }
      <div className={'content'}>
        <h3>{novedad.titulo}</h3>
        <h4>{novedad.subtitulo}</h4>
        <p className='description'>{novedad.descripcion}</p>
        <Button text={'PARA MÁS INFORMACIÓN'} isOrangeSection={true}></Button>
      </div>
    </Wrapper>
  )
}

export default Novedad
