//React

//Styled Components
import {
    Table
} from './elements'

//Context
import { useTarifaContext } from '../../context/TarifaContext'

const TablaTarifa = () => {
    const { tablaTarifa, setTablaTarifa} = useTarifaContext()

    const handleChange = (e) => {
        let newValue = parseInt(e.target.value)
        if(newValue >= 0)
        {
            const [tipo, tipoVehiculo, adicional] = e.target.id.split('-')
            let aux = [...tablaTarifa]

            let index = aux.findIndex((value) => value.tipo === tipo && value.tipoVehiculo === tipoVehiculo)

            if(index === -1)
            {
                aux.push({
                    tipo: tipo,
                    tipoVehiculo: tipoVehiculo,
                    [adicional]: newValue
                })
            }
            else
            {
                aux[index] = {
                    ...aux[index],
                    [adicional]: newValue
                }
            }

            setTablaTarifa(aux)
        }
    }

    return (
        <>
            <Table>
                <thead>
                    <tr>
                        <td></td>
                        <th colSpan={'3'}>Tarifa Normal</th>
                    </tr>
                    <tr>
                        <td></td>
                        <th>Liviano</th>
                        <th>Semipesado</th>
                        <th>Pesado</th>
                    </tr>
                </thead>
                <tbody onChange={handleChange}>
                    <tr>
                        <th>Movida (Radio segun zona)</th>
                        <td><input id='COMUN-LIVIANO-movida' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'COMUN' && value.tipoVehiculo === 'LIVIANO')].movida : 0}/></td>
                        <td><input id='COMUN-SEMIPESADO-movida' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'COMUN' && value.tipoVehiculo === 'SEMIPESADO')].movida : 0}/></td>
                        <td><input id='COMUN-PESADO1-movida' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'COMUN' && value.tipoVehiculo === 'PESADO1')].movida : 0}/></td>
                    </tr>
                    <tr>
                        <th>KM (totales)</th>
                        <td><input id='COMUN-LIVIANO-total' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'COMUN' && value.tipoVehiculo === 'LIVIANO')].total : 0}/></td>
                        <td><input id='COMUN-SEMIPESADO-total' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'COMUN' && value.tipoVehiculo === 'SEMIPESADO')].total : 0}/></td>
                        <td><input id='COMUN-PESADO1-total' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'COMUN' && value.tipoVehiculo === 'PESADO1')].total : 0}/></td>
                    </tr>
                    <tr>
                        <th>Extraccion</th>
                        <td><input id='COMUN-LIVIANO-extraccion' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'COMUN' && value.tipoVehiculo === 'LIVIANO')].extraccion : 0}/></td>
                        <td><input id='COMUN-SEMIPESADO-extraccion' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'COMUN' && value.tipoVehiculo === 'SEMIPESADO')].extraccion : 0}/></td>
                        <td><input id='COMUN-PESADO1-extraccion' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'COMUN' && value.tipoVehiculo === 'PESADO1')].extraccion : 0}/></td>
                    </tr>
                    <tr>
                        <th>Hora de trabajo</th>
                        <td><input id='COMUN-LIVIANO-horaMecanica' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'COMUN' && value.tipoVehiculo === 'LIVIANO')].horaMecanica : 0}/></td>
                        <td><input id='COMUN-SEMIPESADO-horaMecanica' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'COMUN' && value.tipoVehiculo === 'SEMIPESADO')].horaMecanica : 0}/></td>
                        <td><input id='COMUN-PESADO1-horaMecanica' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'COMUN' && value.tipoVehiculo === 'PESADO1')].horaMecanica : 0}/></td>
                    </tr>
                    <tr>
                        <th>Asistencia Mecanica</th>
                        <td><input id='COMUN-LIVIANO-asistenciaMecanica' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'COMUN' && value.tipoVehiculo === 'LIVIANO')].asistenciaMecanica : 0}/></td>
                        <td><input id='COMUN-SEMIPESADO-asistenciaMecanica' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'COMUN' && value.tipoVehiculo === 'SEMIPESADO')].asistenciaMecanica : 0}/></td>
                        <td><input id='COMUN-PESADO1-asistenciaMecanica' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'COMUN' && value.tipoVehiculo === 'PESADO1')].asistenciaMecanica : 0}/></td>
                    </tr>
                    <tr>
                        <th>KM excedentes (Totales)</th>
                        <td><input id='COMUN-LIVIANO-barra2km' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'COMUN' && value.tipoVehiculo === 'LIVIANO')].barra2km : 0}/></td>
                        <td><input id='COMUN-SEMIPESADO-barra2km' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'COMUN' && value.tipoVehiculo === 'SEMIPESADO')].barra2km : 0}/></td>
                        <td><input id='COMUN-PESADO1-barra2km' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'COMUN' && value.tipoVehiculo === 'PESADO1')].barra2km : 0}/></td>
                    </tr>
                    <tr>
                        <th>KM ripio o nieve</th>
                        <td><input id='COMUN-LIVIANO-kmripio' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'COMUN' && value.tipoVehiculo === 'LIVIANO')].kmripio : 0}/></td>
                        <td><input id='COMUN-SEMIPESADO-kmripio' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'COMUN' && value.tipoVehiculo === 'SEMIPESADO')].kmripio : 0}/></td>
                        <td><input id='COMUN-PESADO1-kmripio' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'COMUN' && value.tipoVehiculo === 'PESADO1')].kmripio : 0}/></td>
                    </tr>
                </tbody>
            </Table>
            <Table>
                <thead>
                    <tr>
                        <td></td>
                        <th colSpan={'3'}>Tarifa Especial</th>
                    </tr>
                    <tr>
                        <td></td>
                        <th>Liviano</th>
                        <th>Semipesado</th>
                        <th>Pesado</th>
                    </tr>
                </thead>
                <tbody onChange={handleChange}>
                    <tr>
                        <th>Movida (Radio segun zona)</th>
                        <td><input id='ESPECIAL-LIVIANO-movida' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'ESPECIAL' && value.tipoVehiculo === 'LIVIANO')].movida : 0}/></td>
                        <td><input id='ESPECIAL-SEMIPESADO-movida' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'ESPECIAL' && value.tipoVehiculo === 'SEMIPESADO')].movida : 0}/></td>
                        <td><input id='ESPECIAL-PESADO1-movida' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'ESPECIAL' && value.tipoVehiculo === 'PESADO1')].movida : 0}/></td>
                    </tr>
                    <tr>
                        <th>KM (totales)</th>
                        <td><input id='ESPECIAL-LIVIANO-total' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'ESPECIAL' && value.tipoVehiculo === 'LIVIANO')].total : 0}/></td>
                        <td><input id='ESPECIAL-SEMIPESADO-total' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'ESPECIAL' && value.tipoVehiculo === 'SEMIPESADO')].total : 0}/></td>
                        <td><input id='ESPECIAL-PESADO1-total' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'ESPECIAL' && value.tipoVehiculo === 'PESADO1')].total : 0}/></td>
                    </tr>
                    <tr>
                        <th>Extraccion</th>
                        <td><input id='ESPECIAL-LIVIANO-extraccion' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'ESPECIAL' && value.tipoVehiculo === 'LIVIANO')].extraccion : 0}/></td>
                        <td><input id='ESPECIAL-SEMIPESADO-extraccion' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'ESPECIAL' && value.tipoVehiculo === 'SEMIPESADO')].extraccion : 0}/></td>
                        <td><input id='ESPECIAL-PESADO1-extraccion' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'ESPECIAL' && value.tipoVehiculo === 'PESADO1')].extraccion : 0}/></td>
                    </tr>
                    <tr>
                        <th>Hora de trabajo</th>
                        <td><input id='ESPECIAL-LIVIANO-horaMecanica' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'ESPECIAL' && value.tipoVehiculo === 'LIVIANO')].horaMecanica : 0}/></td>
                        <td><input id='ESPECIAL-SEMIPESADO-horaMecanica' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'ESPECIAL' && value.tipoVehiculo === 'SEMIPESADO')].horaMecanica : 0}/></td>
                        <td><input id='ESPECIAL-PESADO1-horaMecanica' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'ESPECIAL' && value.tipoVehiculo === 'PESADO1')].horaMecanica : 0}/></td>
                    </tr>
                    <tr>
                        <th>Asistencia Mecanica</th>
                        <td><input id='ESPECIAL-LIVIANO-asistenciaMecanica' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'ESPECIAL' && value.tipoVehiculo === 'LIVIANO')].asistenciaMecanica : 0}/></td>
                        <td><input id='ESPECIAL-SEMIPESADO-asistenciaMecanica' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'ESPECIAL' && value.tipoVehiculo === 'SEMIPESADO')].asistenciaMecanica : 0}/></td>
                        <td><input id='ESPECIAL-PESADO1-asistenciaMecanica' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'ESPECIAL' && value.tipoVehiculo === 'PESADO1')].asistenciaMecanica : 0}/></td>
                    </tr>
                    <tr>
                        <th>KM excedentes (Totales)</th>
                        <td><input id='ESPECIAL-LIVIANO-barra2km' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'ESPECIAL' && value.tipoVehiculo === 'LIVIANO')].barra2km : 0}/></td>
                        <td><input id='ESPECIAL-SEMIPESADO-barra2km' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'ESPECIAL' && value.tipoVehiculo === 'SEMIPESADO')].barra2km : 0}/></td>
                        <td><input id='ESPECIAL-PESADO1-barra2km' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'ESPECIAL' && value.tipoVehiculo === 'PESADO1')].barra2km : 0}/></td>
                    </tr>
                    <tr>
                        <th>KM ripio o nieve</th>
                        <td><input id='ESPECIAL-LIVIANO-kmripio' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'ESPECIAL' && value.tipoVehiculo === 'LIVIANO')].kmripio : 0}/></td>
                        <td><input id='ESPECIAL-SEMIPESADO-kmripio' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'ESPECIAL' && value.tipoVehiculo === 'SEMIPESADO')].kmripio : 0}/></td>
                        <td><input id='ESPECIAL-PESADO1-kmripio' type={'number'} value={tablaTarifa.length > 0 ? tablaTarifa[tablaTarifa.findIndex((value) => value.tipo === 'ESPECIAL' && value.tipoVehiculo === 'PESADO1')].kmripio : 0}/></td>
                    </tr>
                </tbody>
            </Table>
        </>
    )
}

export default TablaTarifa
