import {  useRef } from "react"
import {
    FileUploadWrapper,
    FormField,
    InputLabel,
    WraperList,
    UploadFileBtn,
    UploadIcon,
    Card,
    TrashFiles
} from './elements'
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 600000;
const KILO_BYTES_PER_BYTE = 1000;

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const MultiFileUpload = ({
        files,
        setFiles,
        label,
        updateFilesCb,removeFile,changeState,
        maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
        ...otherProps
    }) => {
    const fileInputField = useRef(null);
    const rmFile = (index) => {
        removeFile(index);
    }

    const handleUploadBtnClick = e => {
        fileInputField.current.click();
    };

    const handleNewFileUpload = (e) => {
        const file = e.target.files;
        if (file.length) {
            updateFilesCb(e.target.files);
        }
        e.target.value = '';
    }

    function dragOverHandler(ev) {
        ev.preventDefault();
    }

    return (
        <>
            <FileUploadWrapper onDragOver={dragOverHandler}>
                <InputLabel>{label}</InputLabel>
                <UploadFileBtn type="button" onClick={handleUploadBtnClick}>
                    <UploadIcon/>
                    <span>Suba un archivo</span>
                </UploadFileBtn>
                <FormField
                    type="file"
                    ref={fileInputField}
                    onChange={handleNewFileUpload}
                    title=""
                    value=""
                    {...otherProps}
                />
            </FileUploadWrapper>

            {
                files.length === 0
                    ?
                    <WraperList>

                    </WraperList>
                    :
                    <WraperList>
                        {
                            files.map((file,index) =>{
                                return(
                                    <Card   key={index}>
                                        <TrashFiles onClick={(e)=>rmFile(index)}/>
                                        {
                                            file.type==="application/pdf"?
                                                <embed  height={'150px'}width={'150px'}
                                                name="plugin"
                                                src={URL.createObjectURL(file)}
                                                />
                                                :
                                            <img loading={"lazy"} height={'150px'}width={'150px'}
                                                     src={URL.createObjectURL(file)}
                                                     alt={ file.name}/>
                                        }
                                    </Card>
                                )
                            })
                        }
                    </WraperList>
            }
        </>
    )
}

export default MultiFileUpload;
