import styled from "styled-components"
import { FaCheckDouble } from "react-icons/fa"
export const GoogleLoginWrapper = styled.div`

text-align: center;
padding: 10px;
margin: auto;
`

export const LoggedWrapper = styled.div`
    display: flex;
    width: 300px;
    margin: auto;
    padding: 5px; 
    
    @media (max-width: 480px) {
        display: grid;
        width: 100%;
        padding: auto;
    }
    
    
`

export const LoggedThumbnailSection = styled.div`
    width: 100px;
    margin: auto;
    img {
  border-radius: 50%;
}
`

export const LoggedData = styled.div`

    margin-block-start: auto;
    text-align: justify;
    margin-left: 20px;
    font-weight:bold ;
    display: inline-flex;
    flex-direction: column;
    @media (max-width: 480px) {
        margin: aut0;
    }
    margin-top: auto;
    margin-bottom: auto;

    .name{
        margin-left: 15px;
    }
`

export const ValidEmail = styled(FaCheckDouble)`
     display: ${({ show }) => show ? 'inline' : 'none'};
     color:lightblue;
     vertical-align: bottom;
`