import { keyframes } from 'styled-components'

export const First = keyframes`
    0% { 
        z-index: 1;
    }

    33% {
        z-index: 3;
        transform: scale(1);
    }

    66% {
        z-index: 2;
        transform: scale(0.6) translateY(-270px) translateX(500px);
    }

    100%{
        z-index: 1;
        transform: scale(0.6) translateY(-270px) translateX(-500px);
    }
`

export const Second = keyframes`
    0%{ 
        z-index: 3;
    }

    33% {
        z-index: 2;
        transform: scale(0.6) translateY(-270px) translateX(500px);
    }

    66% { 
        z-index: 1;
        transform: scale(0.6) translateY(-270px) translateX(-500px);
    }

    100% {
        z-index: 3;
    }
`

export const Third = keyframes`
    0% { 
        z-index: 1;
    }

    33% {
        z-index: 1;
        transform:  scale(0.6) translateY(-270px) translateX(-500px);
    }

    66% { 
        z-index: 3;
        transform: scale(1);
    }

    100% {
        z-index: 2;
        transform: scale(0.6) translateY(-270px) translateX(500px);
    }
`


export const FirstMobile = keyframes`
    0% { 
        z-index: 2;
    }

    33% {
        z-index: 3;
        transform: scale(1);
    }

    66% {
        z-index: 1;
        transform: scale(0.5) translateY(-270px) translateX(180px);
    }

    100%{
        z-index: 2;
        transform: scale(0.5) translateY(-270px) translateX(-180px);
    }
`


export const SecondMobile = keyframes`
    0%{ 
        z-index: 3;
    }

    33% {
        z-index: 1;
        transform: scale(0.5) translateY(-270px) translateX(180px);
    }

    66% { 
        z-index: 2;
        transform: scale(0.5) translateY(-270px) translateX(-180px);
    }

    100% {
        z-index: 3;
    }
`


export const ThirdMobile = keyframes`
    0% { 
        z-index: 1;
    }

    33% {
        z-index: 2;
        transform: scale(0.5) translateY(-270px) translateX(-180px);
    }

    66% {
        z-index: 3;
        transform: scale(1);
    }

    100%{
        z-index: 1;
        transform: scale(0.5) translateY(-270px) translateX(180px);
    }
`