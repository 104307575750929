
//Styled-Components
import {
    Wrapper,
} from './elements'


const Tooltip = ({tooltip, children  }) => {
    return (
        <Wrapper>
            <div className={'element'}>
                {children}
            </div>
            <div className="tooltip">
                {tooltip}
            </div>
        </Wrapper>
    )
}

export default Tooltip
