import styled from 'styled-components'
import {
    ImCross,
    ImInfo,
    ImCheckmark
} from 'react-icons/im'

export const Wrapper = styled.div`
    position: fixed;
    width: 50vw;
    min-height: 40vh;
    top: 160px;
    background-color: white;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px;
    border-radius: 15px;

    img{
        height: 75px;
        align-self: center;
    }

    button{
        width: 150px;
        align-self: flex-end;
    }

    @media screen and (max-width: 960px) {
        width: 90vw;
    }
`


export const TextContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;

    span{
        color: #000;
        font-size: 24px;
        font-weight: 300;
        text-align: center;

        a{
            text-decoration: none;
            color: #E95612;
        }
    }
`

export const ErrorIcon = styled(ImCross)`
    font-size: 3rem;
    color: red;
`

export const InfoIcon = styled(ImInfo)`
    font-size: 3rem;
    color: #E95612;
`

export const SuccessIcon = styled(ImCheckmark)`
    font-size: 3rem;
    color: green;
`

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: opacity 500ms;
    z-index: 2;
`
