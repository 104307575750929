import styled from 'styled-components'

export const Grid = styled.div`
    width: 80%;
    background-color: white;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-flow: dense;
    margin: 25px 0;
    gap: 15px;
    background-color: ${({background}) => background === 'orange' ? '#E95612' : 'white'};

    @media screen and (max-width: 920px){
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
`

export const GridElement = styled.div`
    background-color: #E95612;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    position: relative;

    &.actual{
        background-color: green;
    }
`