//React
import { createContext, useContext, useState, useEffect } from 'react'

//Context
import { useUserContext } from './UserContext'

//Service
import GruerosService from "../services/GruerosAppService"
import AdminService from "../services/AdminService"

const PortalTicketContext = createContext([])

export const usePortalTicketContext = () => useContext(PortalTicketContext)
function getFilters() {
    let aux = JSON.parse(window.localStorage.getItem('filterstickets'));
    aux.finishDate = new Date(aux.finishDate);
    aux.initDate = new Date(aux.initDate);
    return aux;
}
export const PortalTicketContextProvider = ({ children }) => {
    const [id, setId] = useState(null)
    const [ticketsToShow, setTicketsToShow] = useState([])
    const [pagination, setPagination] = useState({
        page: 1,
        offset: 20,
        totalPages: null,
        loading: false
    })
    const [bases, setBases] = useState([])
    const [filters, setFilters] = useState( {
        finishDate: new Date(),
        initDate: new Date(new Date().setDate(new Date().getDate() - 7)),
        estado: 0,
        ticket_numero: '',
        base: null
    })
    const [statics, setStatics] = useState({ prestador: {} })
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const { user } = useUserContext()

    const firstPage = () => {
        if(pagination.totalPages)
        {
            setPagination({
                ...pagination,
                page: 1,
            })
        }
    }

    const lastPage = () => {
        if(pagination.totalPages)
        {
            setPagination({
                ...pagination,
                page: pagination.totalPages,
            })
        }
    }

    const nextPage = () => {
        let page = pagination.page
        if(pagination.totalPages)
        {
            if(page < pagination.totalPages)
                page++
        }

        setPagination({
            ...pagination,
            page: page,
        })
    }

    const previousPage = () => {
        let page = pagination.page
        if(pagination.totalPages)
        {
            if(page > 1)
                page--
        }

        setPagination({
            ...pagination,
            page: page,
        })
    }

    const goToPage = (i) => {
        if(pagination.totalPages && (i > 0 && i <= pagination.totalPages))
        {
            setPagination({
                ...pagination,
                page: i,
            })
        }
    }

    const getBases = (id) => {
        const manageData = (data, status) => {
            if(status !== 'FAILURE')
            {
                if (data.length > 0) {
                    let x = {};
                    let firstBase

                    data.forEach((pr, index) => {
                        if(index === 0)
                            firstBase = pr.id
                        x[pr.base] = pr.id
                    })
                    setBases(x)
                    setFilters({
                        ...filters,
                        base: firstBase
                    })
                }
            }
            else
            {
                setError('Ha ocurrido un error.')
            }
            setLoading(false)
        }
        setLoading(true)
        if (user.tipo === "GRUERO") {
            GruerosService.getBases(user.token, ({data, status}) => {
                manageData(data, status)
            });

        }
        if (user.roles.includes("ADMINISTRADOR")) {
            if (!id) {
                setError("Ruta incorrecta");
                return
            }
            AdminService.getBases(id, user.token, ({data, status}) => {
                manageData(data, status)
            })
        }
    }

    const getCrudo = (id) => {
        const manageData = (data, status) => {
            if(status !== 'FAILURE')
            {
                setStatics({
                    ...data.statics,
                    prestador: data.prestador
                })
                setPagination({
                    ...pagination,
                    totalPages: data.pagination?data.pagination.cantidad_paginas:0,
                    loading: false
                })

                setTicketsToShow(data.tickets)
            }
            else {
                setPagination({
                    ...pagination,
                    loading: false
                })
                setError('Ha ocurrido un error.')
            }
        }
        setPagination({
            ...pagination,
            loading: true
        })
        let filtros = {
            initDate: filters.initDate,
            finishDate: filters.finishDate,
            page: pagination.page - 1,
            offset: pagination.offset,
            ticket_numero: filters.ticket_numero.length > 0 ? filters.ticket_numero : null,
            ticket_patente: filters.ticket_numero.length > 0 ? filters.ticket_numero : null,
            base: filters.base
        }
        if(filters.estado<2)
            filtros.estado = filters.estado;
        if (user.tipo === "GRUERO") {
            GruerosService.getTickets(filtros,
                user.token,
                ({data, status}) => {

                    manageData(data, status)
                }
            );

        }
        if (user.roles.includes("ADMINISTRADOR")) {
            if (!id) {
                setError("Ruta incorrecta");
                return
            }
            AdminService.getInfoPrestador(filtros,
                id,
                user.token,
                ({data, status}) => {
                    manageData(data, status)
                }
            )
        }
    }

    const editServicio = (servicio) => {
        AdminService.editServicio(user.token, servicio, (json) => {
            if(json.status==='SUCCESS')
                setSuccess("El ticket se edito con exito.")
            else
                setError("Ocurrio un error al editar el ticket.")
        })
    }

    useEffect(() => {
        if(filters.base !== null)
        {
            getCrudo(id)
        }
    }, [filters, pagination.page])

    useEffect(() => {
        if(id)
        {
            getBases(id)
        }
    }, [id])

    return(
    	<PortalTicketContext.Provider
            value={
                {
                    loading,
                    statics,
                    bases,
                    filters,
                    ticketsToShow,
                    error,
                    success,
                    pagination,
                    setFilters,
                    getCrudo,
                    setError,setSuccess,
                    setId,
                    editServicio,
                    nextPage,
                    previousPage,
                    firstPage,
                    lastPage,
                    goToPage
                }
            }
        >
            {children}
        </PortalTicketContext.Provider>
    )

}
