import styled from 'styled-components'

export const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    color: white;
    text-align: end;
    z-index: 3;

    .huella{
        width: 160px;
        height: 28px;
    }

    @media screen and (max-width: 1020px){
        height: fit-content;
        display: flex;
        flex-direction: column;
        padding-top: 0;
    }
`

export const FilaTelefonos = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 100%;
    margin-top: 10px;

    img{
        width: 7vw;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 20px;
        border-radius: 20%;
        transition: transform .8s ease-in-out;
    }

    img:hover{
        cursor:pointer;
        transform: rotate(360deg);
        
    }

    @media screen and (max-width: 1260px){
        img{
            width: 9vw;
        }
    }

    @media screen and (max-height: 780px){
        img{
            width: 12vh;
        }
    }

    @media screen and (max-width: 1020px){
        flex-wrap: wrap;
        padding-top: 0;

        img{
            width: 15vw;
        }
    }
`

export const WrapperNumerosTelefono = styled.div`
    width: 100%;
    margin-right: 25px;
    margin-top: 25px;
    margin-right: 6vw;

    @media screen and (max-height: 780px){
        margin-right: 8vh;
    }

    @media screen and (max-width: 1020px){
        margin-right: 0 !important;
    }

    @media screen and (max-width: 1260px){
        margin-right: 9vw;
    }
`
