import styled from "styled-components";

export const DatePickerWrapper = styled.div`
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    input{
      width: 100%;
      margin-top: 10px;
      outline: none;
      padding: 6px 12px;
    }
  & .react-datepicker-popper{
    z-index: 2;
  }
`
