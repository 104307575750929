//React Imports
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

//Styled Components
import {
    EmptyWrapper,
    ModalWrapper,Wrapper,Volver

} from './elements'

//Components

import Table from '../../components/Table'

//Context
import { useUserContext } from '../../context/UserContext'
import Loading from '../../components/Loading'
import {data,columns} from './data';

//service
import AdminService from '../../services/AdminService'
import DatePicker from "../../components/DatePicker";
import Button from "../../components/Button";
import {Dates} from "../Admin/elements";
import adminService from "../../services/AdminService";
import Message from "../../components/Message";
import {RiArrowGoBackLine} from "react-icons/ri";
import {usePortalTicketContext} from "../../context/PortalTicketContext";
const getFirstAndLastDateOfMonth = ({
                                        year = undefined,
                                        month = undefined,
                                    } = {}) => {
    const date = new Date();
    if (year !== undefined && month !== undefined) {
        date.setMonth(month);
        date.setFullYear(year);
    }
    const initDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const finishDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return {
        initDate,
        finishDate,
    };
};
const Resumenes =  () => {
    const [loading, setLoading] = useState(true)
    const [prestadores, setPrestadores] = useState(null)
    const {
        setFilters,
        filters
    } = usePortalTicketContext()
    const [selected, setSelected] = useState([])
    const [success,setSuccess] = useState(null)
    const [error,setError] = useState(null)
    const { user } = useUserContext();
    const tableInitialState = {
        sortBy: [
            { desc: true, id: "cantTickets" },
        ],
    };

    const getResumen = async (notificar) => {
        //no se como sigue esto
        if (selected.length === 0  ) {
            return setError("Debe seleccionar al menos un prestador");
        }
        setLoading(true)
        let res = await adminService.getResumen({prestadores: selected, from:filters.initDate,to:filters.finishDate,notificar: notificar}, user.token)
        if (res.status === 'SUCCESS') {
            if(notificar)
                setSuccess(`Se notificó a todos los prestadores seleccionados.`);
            else
                setSuccess(`Su descarga se realizó con exito.`);
        } else {
            setError(res.message);
        }
        setLoading(false)
    }
    const checkPage = function (){
        let aux = [ ...selected];
        let x = document.getElementById('all-check-prestadores');
        let inputList = document.getElementsByName("filaPrestadores");
        if(inputList.length === 0){
            return x.checked = false;
        }
        let counter = inputList.length;
        inputList.forEach(input =>{
            let id = input.id.split('-')[0];
            if(aux.includes(id))
                counter--;
        });
        setSelected(aux);
        x.checked = counter===0;
        x.onchange = (e)=>{
            checkAll(e,aux)
        }
    }

    const checkAll = function (event, listSelected) {
        let value = event.target.checked;
        let inputList = document.getElementsByName("filaPrestadores");
        let aux = [ ...listSelected];

        if(value){
            inputList.forEach(input =>{
                let id = input.id.split('-')[0];
                input.checked =value;
                if(!aux.includes(id))
                    aux.push(id);
            })
        }else {
            inputList.forEach(input =>{
                let id = input.id.split('-')[0];
                input.checked =value;
                aux = aux.filter(val =>val!==id);
            })
        }
        setSelected(aux)
    }

    const changeSelect = function (row) {
        let idIndex = `${prestadores[row.index].id}`;
        let inputCH = document.getElementById(row.cells[6].value.props.id);
        let aux = [ ...selected];
        if(inputCH.checked){
            if(aux.includes(idIndex)){
                let aux = selected.filter(elem => elem !== idIndex);
                setSelected(aux);
            }
            inputCH.checked = false;
        }else{
            if(!aux.includes(idIndex)){
                aux.push(idIndex);
            }
            inputCH.checked = true;
        }
        setSelected(aux);
    }
    useEffect( () => {
        setLoading(true)
        AdminService.getPrestadores(filters,user.token,(res) => {
            if(res.data && res.data.list && res.data.list.length > 0){
                let aux = [];
                res.data.list.forEach((prestador,index) =>{
                    prestador.check = <input type={'checkbox'} name={'filaPrestadores'} id={prestador.id+'-prcheck'} ></input>;
                })
                if(!columns.find(col => col.accessor==='check'))
                    columns.push(
                        {
                            Header: <input type={'checkbox'} defaultChecked={false} id={"all-check-prestadores"} onChange={(e)=>{checkAll(e)}} ></input>,
                            accessor: 'check',
                        });
                setPrestadores(res.data.list)
            }
            setLoading(false);
        });

    },[filters])

    return(
        <>
                        <Wrapper id={"grupoList"}>
                            <Volver to={'/portal/admin'}>
                                <RiArrowGoBackLine/>
                            </Volver>
                            {
                                loading ?
                                    <Loading iconSize={'100px'}/>
                                    :
                                    <>
                                    <div id={"tittle"}>
                                        <h3>Listado de prestadores</h3>
                                        <p className={'tittle'}>Seleccione los prestadores de los que desea obtener el detalle de servicios</p>
                                        <p>Debe hacer click en los prestadores de la tabla que desea exportar y luego apretar en el boton <strong>Descargar</strong> de abajo.</p>
                                        <p>Si ademas desea notificar a los prestadores via mail, puede hacer click en el boton <strong>Descargar y Notificar</strong> </p>
                                        <br/>
                                        <hr/>
                                    </div>
                                    <Dates>
                                        <DatePicker
                                            title={"Desde:"}
                                            datePicker={filters.initDate}
                                            setDatePicker={(date) => {
                                                let aux = { ...filters };
                                                aux.initDate = date;
                                                setFilters(aux);
                                            }}
                                            minDate={new Date(2022, 5, 1)}
                                            maxDate={filters.finishDate} />
                                        <DatePicker
                                            title={"Hasta:"}
                                            datePicker={filters.finishDate}
                                            setDatePicker={(date) => {
                                                let aux = { ...filters };
                                                aux.finishDate = date;
                                                setFilters(aux);
                                            }}
                                            minDate={filters.initDate}
                                        />
                                    </Dates>
                                    <div id={"lista"}>

                                        {
                                            prestadores && prestadores.length>0?

                                                <Table
                                                    columns={columns}
                                                    data={prestadores}
                                                    initialState={tableInitialState}
                                                    chechAllId={checkPage}
                                                    withCellBorder
                                                    withRowBorder
                                                    withSorting
                                                    withPagination
                                                    rowProps={row => ({
                                                        onClick: () => changeSelect(row),
                                                        style: {
                                                            cursor: "pointer"
                                                        }
                                                    })}/>
                                                :
                                                <EmptyWrapper>No hay prestadores en la base</EmptyWrapper>
                                        }

                                        <div className="row">
                                            <Button clickHandler={()=> {
                                                getResumen(false);
                                            }} isOrangeSection={true} text={'Descargar'}/>
                                            <Button clickHandler={()=> {
                                                getResumen(true);
                                            }} isOrangeSection={true} text={'Descargar y Notificar'}/>
                                        </div>
                                    </div>
                                    </>
                            }
                        </Wrapper>

            <ModalWrapper>

                {
                    error ?
                        <Message mensaje={error} action={
                            () => {
                                setError(null)
                            }
                        }
                        />
                        :
                        ""
                }
                {
                    success ?
                        <Message type={'success'} mensaje={success} action={
                            () => {
                                setSuccess(null)
                            }
                        }
                        />
                        :
                        ""
                }
            </ModalWrapper>
        </>
    )
}

export default Resumenes
