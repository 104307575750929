//Styled components
import {
    Wrapper
} from './elements'

//Components
import Section from '../../components/Section'
import Preview from '../../components/Preview'

const Novedades = () => {
  return(
    <Section
      recta={true}
      styled={true}
      image={`${process.env.PUBLIC_URL}/assets/images/imagen_novedades.webp`}
      icono={`${process.env.PUBLIC_URL}/assets/images/icono_novedades.webp`}
      compName='novedades'
    >
      <Wrapper>
        <h1>
          Novedades
        </h1>
        <p>
          Te contamos las últimas novedades y dónde obtener más información.
        </p>
        <img loading={"lazy"} src={`${process.env.PUBLIC_URL}/assets/images/huella.webp`} alt={"huella"}/>
      </Wrapper>
    </Section>
  )
}

export default Novedades
